import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'

const DenyModal = ({ show, onReject, onHide }) => {
  const [reason, setReason] = useState('')

  return (
    <Modal show={show} size="lg" centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          Deny Verification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>
          Are you sure you want to deny verification? Please enter a reason for that:
        </label>
        <textarea
          className="form-control"
          rows={5}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Cancel</Button>
        <Button variant="danger" onClick={() => { onReject(reason) }}>Deny</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DenyModal
