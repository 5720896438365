import React, { useEffect } from 'react'
import { Card, Table, Button } from 'react-bootstrap'
import swal from 'sweetalert'

import { useUserListMap, useList } from '../../../../../store/hooks'

const SubscribeToList = () => {
  const { lists, loadLists } = useList()
  const { listMap, loadListMap, unSubscribeFromList, subscribeToList } = useUserListMap()

  useEffect(() => {
    (async () => {
      await loadListMap()
      await loadLists(true)
    })()
  }, []) // eslint-disable-line

  const isListSubscribed = listId => (
    listMap.filter(x => x.frn_listid === listId).length > 0
  )

  const handleSubscribe = (listId) => {
    swal({
      title: 'Are you sure?',
      text: 'This will subscribe you to the list.',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (done) => {
      if (done) {
        await subscribeToList(listId)
      }
    })
  }

  const handleUnsubscribe = (listId) => {
    const list = listMap.filter(x => x.frn_listid === listId)
    if (list.length === 1 && list[0].id) {
      swal({
        title: 'Are you sure?',
        text: 'This will un-subscribe you to the list.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(async (done) => {
        if (done) {
          await unSubscribeFromList(list[0].id)
        }
      })
    }
  }

  return (
    <>
      <div className="alert alert-primary notification">
        <p>
          If you are an advertiser who is looking to license data, you can subscribe
          to one or more lists. Each list represents a service that the property owner
          or agent is potentially interested in buying in the near future.
          When you subscribe to a list, you are automatically provided any leads from
          that list within your selected geographic area
        </p>
        <p>
          By default we only deliver leads within 30 miles of your zip code
          in your profile address. To customize your geographic area
          by zip code you can change your settings under My Leads.
        </p>
      </div>
      <Card>
        <Card.Header>
          <Card.Title>Lists Available for Subscription:</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <Table className="table table-responsive-sm table-striped ">
              <tbody>
                {
                  lists && lists.map(l =>
                    <tr key={l.id}>
                      <td>{l.property_label}</td>
                      <td>{l.list_type ? <span class="badge badge-outline-primary">Requires User Only</span> : <span class="badge badge-outline-success">Requires Property</span> }</td>
                      <td>
                        {
                          isListSubscribed(l.id)
                            ?
                            <Button
                              className='btn btn-sm btn-danger'
                              onClick={() => handleUnsubscribe(l.id)}
                            >
                              Unsubscribe
                            </Button>
                            :
                            <Button
                              className='btn btn-sm btn-success'
                              onClick={() => handleSubscribe(l.id)}
                            >
                              Subscribe
                            </Button>
                        }
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default SubscribeToList
