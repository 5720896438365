import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import NavHeader from "./NavHeader";
import Header from "./Header";
import ChatBox from "../ChatBox";
import { useUser } from "../../../store/hooks";
import { ROLE_USER } from '../../../consts'

const Nav = ({ socket }) => {
  const { user } = useUser();
  const userRole = user?.role?.description || ROLE_USER

  const [toggle, setToggle] = useState('');
  const onClick = (name) => setToggle(toggle === name ? '' : name);

  return (
    <Fragment>
      <NavHeader />
      <SideBar userRole={userRole} />
      <Header socket={socket} />
      <ChatBox toggle={toggle} onClick={() => onClick("chatbox")} />
    </Fragment>
  );
};

export default Nav;
