export const USER_INIT = 'USER_INIT';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILED = 'USER_FAILED';
export const USER_RESET = 'USER_RESET';
export const LEAD_ZIPCODE_INIT = 'LEAD_ZIPCODE_INIT';
export const LEAD_ZIPCODE_SUCCESS = 'LEAD_ZIPCODE_SUCCESS';
export const LEAD_ZIPCODE_FAILED = 'LEAD_ZIPCODE_FAILED';
export const LEADS_INIT = 'LEADS_INIT';
export const LEADS_SUCCESS = 'LEADS_SUCCESS';
export const LEADS_FAILED = 'LEADS_FAILED';
export const DELETE_ZIPCODE = 'DELETE_ZIPCODE';
export const LEADS_SETTING_INIT = 'LEADS_SETTING_INIT';
export const LEADS_SETTING_SUCCESS = 'LEADS_SETTING_SUCCESS';
export const LEADS_SETTING_FAILED = 'LEADS_SETTING_FAILED';

export const USER_REQUEST_VERIFY = 'USER_REQUEST_VERIFY'

export const NOTIFICATIONS_LOAD = 'NOTIFICATIONS_LOAD'
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS'
export const NOTIFICATION_READ = 'NOTIFICATION_READ'
export const NOTIFICATION_ADD = 'NOTIFICATION_ADD'

export const USER_REQUEST_EMAIL_UPDATE = 'USER_REQUEST_EMAIL_UPDATE'

export const LEAD_DETAIL_INIT = 'LEAD_DETAIL_INIT'
export const LEAD_DETAIL_SUCCESS = 'LEAD_DETAIL_SUCCESS'
