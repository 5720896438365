import React, { useState, useEffect } from 'react'
import { Card, Row } from 'react-bootstrap'
import StripeCheckout from 'react-stripe-checkout'

import { NOTI_TYPE } from '../../../../../store/types'
import { useUser, useWallet, useNotification, useSetting } from '../../../../../store/hooks'
import DepositByMetaMaskModal from '../../../../components/DepositByMetaMaskModal/nil'
import { DepositPoolConfig } from '../../../../../config'
import QRCode from "react-qr-code";

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY

const DepositETHtoNil = () => {
  const { user } = useUser()
  const { externalWallets, loadExternalWallets, depositDirectly } = useWallet()
  const { setNewNotification } = useNotification()
  const { setting, loadSetting } = useSetting()

  const [amount, setAmount] = useState(100)
  const [promo, setPromo] = useState('')
  const [depsoitByMetaMaskModal, setDepositByMetaMaskModal] = useState(false);
  const [depositeToken, setDepositeToken] = useState("NIL")
  const [tokenRate, setTokenRate] = useState(0.01)

  const [form, setForm] = useState({
    ethWalletKey: '',
  })

  const renderExternalWallets = () => {
    if (!externalWallets.length) {
      return (
        <option value="">No external wallets found.</option>
      )
    }

    return externalWallets.map(wallet => (
      <option key={wallet.eth_address} value={wallet.eth_address}>
        {wallet.eth_address}
      </option>
    ))
  }

  const handleChange = (e) => {
    setForm((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  useEffect(() => {
    (async () => {
      await loadSetting()
      await loadExternalWallets()
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (setting && setting.length) {
      let rate = setting.find(x => x.label === 'token_rate_cc')
      if (rate) {
        setTokenRate(parseFloat(rate.value))
      } else {
        rate = setting.find(x => x.label === 'token_rate')
        if (rate) {
          setTokenRate(parseFloat(rate.value))
        } else {
          setTokenRate(0.01)
        }
      }
    }
  }, [setting])

  const depositByMetaMask = async (token_symbol) => {
    setDepositByMetaMaskModal(true);
    setDepositeToken(token_symbol);
  }

  const handleSubmit = async (token_symbol) => {
    await depositDirectly(form.ethWalletKey, token_symbol);
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h4 className="mb-3">BUY NIL Tokens with ETH Via MetaMask</h4>
          <div className="alert alert-primary">
            In your Metamask Wallet, you can easily buy NIL Tokens using ETH.
            <a href="https://doortoken.gitbook.io/door-help/" target="_blank" rel="noreferrer">please use our Guide</a>.
          </div>
          <hr className="mb-4" />          
          <button className="deposit-button" onClick={() => depositByMetaMask("Ethereum")}>
            <span>Buy NIL Tokens with ETH By MetaMask</span>
          </button>          
        </Card.Body>
      </Card>      
      <DepositByMetaMaskModal
        show={depsoitByMetaMaskModal}
        onHide={() => setDepositByMetaMaskModal(false)}
        depositeToken={depositeToken}
      />
    </>
  )
}

export default DepositETHtoNil
