import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'

import { useList } from '../../../../../store/hooks'

const AddList = () => {
  const [form, setForm] = useState({
    list_name: '',
    property_label: '',
    description: '',
    property_price: 0,
    is_active: false,
    use_access: false,
    list_type: false,
  })

  const { addList } = useList()

  const handleActiveChange = (value) => {
    setForm((prev) => ({ ...prev, is_active: value }))
  }

  const handleUseAccessChange = (event) => {
    setForm((prev) => ({ ...prev, use_access: event.target.checked }))
  }

  const handleListTypeChange = (event) => {
    setForm((prev) => ({ ...prev, list_type: event.target.checked }))
  }

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await addList(form)
  }

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Add List</h4>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>List Name</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                value={form.list_name}
                name="list_name"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Property Label</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                value={form.property_label}
                name="property_label"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Property Description</label>
              <textarea
                rows={4}
                type="text"
                className="form-control"
                placeholder=""
                value={form.description}
                name="description"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Default Price Per Lead</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                value={form.property_price}
                name="property_price"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Is Active</label>
              <div>
                <Dropdown className="dropdown bootstrap-select">
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="btn-light btn dropdown-toggle"
                  >
                    {form.is_active ? "Yes" : "No"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu">
                    <Dropdown.Item
                      className={`filter-option selected ${form.is_active ? "selected active" : ""}`}
                      onClick={() => handleActiveChange(true)}
                    >
                      Yes
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`filter-option  ${form.is_active ? "" : "selected active"}`}
                      onClick={() => handleActiveChange(false)}
                    >
                      No
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="use_access"
                  checked={form.use_access}
                  onChange={handleUseAccessChange}
                />
                <label
                  className="custom-control-label"
                  htmlFor="use_access"
                >
                  Use Access
                </label>
              </div>
            </div>
            <div className="form-group col-md-6">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="list_type"
                  checked={form.list_type}
                  onChange={handleListTypeChange}
                />
                <label
                  className="custom-control-label"
                  htmlFor="list_type"
                >
                  Custom List
                </label>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-info">
            Add
          </button>
        </form>
      </div>
    </div>
  )
}

export default AddList
