import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { format as formatDate, parseISO } from 'date-fns'
import swal from 'sweetalert'

import { useStakingPool } from '../../../../../store/hooks'

const StakingPoolPage = () => {
  const { stakingPools, getStakingPools, activateStakingPool, deleteStakingPool } = useStakingPool()

  useEffect(() => {
    (async () => {
      await getStakingPools()
    })()
  }, []) // eslint-disable-line

  const handleActivate = async (promoId, isActive) => {
    swal({
      title: 'Are you sure?',
      text: `Do you want to ${isActive ? 'activate' : 'deactivate'} this staking pool?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (confirmed) => {
      if (confirmed) {
        await activateStakingPool(promoId, isActive)
      }
    })
  }

  const handleRemove = async (promoId) => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to remove this staking pool?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteStakingPool(promoId)
      }
    })
  }

  const renderPools = () => {
    if (!stakingPools.length) {
      return (
        <tr>
          <td colSpan={7} className="text-center">
            No staking pools found
          </td>
        </tr>
      )
    }

    return stakingPools.map(pool => (
      <tr key={pool.id}>
        <td>
          <Link to={`/admin/staking-pools/${pool.id}`}>
            { pool.name }
          </Link>
        </td>
        <td>
          { parseFloat(pool.percent).toFixed(2) }%
        </td>
        <td>
          { parseInt(pool.duration) } days
        </td>
        <td>
          { parseInt(pool.min_amount || 0) }/{ parseInt(pool.max_amount || 0) }
        </td>
        <td>
          { pool.is_active ? 'Active' : 'Inactive' }
        </td>
        <td>
          { formatDate(parseISO(pool.createdAt), 'MM-dd-yyyy HH:mm') }
        </td>
        <td className="text-right button-row">
          <Link to={`/admin/staking-pools/${pool.id}`} className="btn btn-info btn-xs">
            Update
          </Link>
          <button
            type="button"
            className="btn btn-primary btn-xs"
            onClick={() => handleActivate(pool.id, !pool.is_active)}
          >
            { pool.is_active ? 'Deactivate' : 'Activate' }
          </button>
          <button
            type="button"
            className="btn btn-danger btn-xs"
            onClick={() => handleRemove(pool.id)}
          >
            Remove
          </button>
        </td>
      </tr>
    ))
  }

  return (
    <>
      <div className="d-flex flex-row-reverse mb-md-3">
        <Link to="/admin/staking-pools/add" className="btn btn-primary">
          Add new staking pool
        </Link>
      </div>
      <Card>
        <Card.Header>
          <Card.Title>Staking Pools</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Percent</th>
                    <th>Duration</th>
                    <th>Min/Max Amount</th>
                    <th>Is Active</th>
                    <th>Date Added</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  { renderPools() }
                </tbody>
              </table>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default StakingPoolPage
