import HttpClient from './http-client'

export const StakingPoolService = (function () {
  const getStakingPools = async () => (
    await HttpClient.get('/staking-pools')
  )

  const getStakingPoolById = async id => (
    await HttpClient.get(`/staking-pools/${id}`)
  )

  const addStakingPool = async params => (
    await HttpClient.post('/staking-pools', params)
  )

  const updateStakingPool = async (id, params) => (
    await HttpClient.put(`/staking-pools/${id}`, params)
  )

  const activateStakingPool = async (id, is_active) => (
    await HttpClient.put(`/staking-pools/${id}/activate`, {
      is_active,
    })
  )

  const deleteStakingPool = async id => (
    await HttpClient.delete(`/staking-pools/${id}`)
  )

  return {
    getStakingPools,
    getStakingPoolById,
    addStakingPool,
    updateStakingPool,
    activateStakingPool,
    deleteStakingPool,
  }
})()
