import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { addDays, subDays, format as formatDate, parseISO } from 'date-fns'

import DateRange from '../../../../components/DateRange'
import SearchBox from '../../../../components/SearchBox'
import Paginator from '../../../../components/Paginator'
import PageSize from '../../../../components/PageSize'
import TableHeader from '../../../../components/TableHeader'
import { useProperty } from '../../../../../store/hooks'

// TODO: Implement Edit and Delete functionality.
const LeadSearch = () => {
  const { adminLeads, searchLeads } = useProperty()

  const headers = [
    { name: 'owner', label: 'Name', sortBy: 'owner' },
    { name: 'address', label: 'Address', sortBy: 'address' },
    { name: 'status', label: 'Status' },
    { name: 'createdAt', label: 'Date Created', sortBy: 'createdAt' },
  ]

  const [search, setSearch] = useState('')
  const [range, setRange] = useState([subDays(new Date(), 600), new Date()])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(['createdAt', 'desc'])

  useEffect(() => {
    (async () => {
      await searchLeads({
        search,
        range_start: range[0].toISOString(),
        range_end: addDays(range[1], 1).toISOString(),
        limit,
        page,
        sortBy: sort[0],
        sortOrder: sort[1],
      })
    })()
  }, [search, range, page, limit, sort]) // eslint-disable-line

  const handleSearch = (keyword) => {
    setSearch(keyword)
    setPage(1)
  }

  const handleRangeChange = async (startRange, endRange) => {
    setRange([startRange, endRange])
    setPage(1)
  }

  const handlePageSizeChange = (size) => {
    setLimit(size)
    setPage(1)
  }

  const renderLeads = () => {
    if (!adminLeads || !adminLeads.rows.length) {
      return (
        <tr>
          <td colSpan={5} className="text-center">No leads found.</td>
        </tr>
      )
    }

    return adminLeads.rows.map((lead) => (
      <tr key={lead.id}>
        <td>
          <Link to={`/admin/lead-details/${lead.id}`}>
            { lead.user ? `${lead.user.fname} ${lead.user.lname}` : '' }
          </Link>
        </td>
        <td>
          <Link to={`/admin/lead-details/${lead.id}`}>
            { `${lead.address1} ${lead.address2}` }
          </Link>
        </td>
        <td>
          {
            lead.property_list_map.map(list => (
              <span key={list.id} className="badge badge-success list-label">
                { list.list.property_label }
              </span>
            ))
          }
        </td>
        <td>
          { formatDate(parseISO(lead.createdAt), 'MMMM dd') }
        </td>
        <td>
          <Dropdown className="dropdown d-block" drop="left">
            <Dropdown.Toggle variant="" className="btn btn-success light sharp i-false" data-toggle="dropdown">
              <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-right">
              <Link className="dropdown-item" to={`/admin/lead-details/${lead.id}`}>
                View
              </Link>
              <Link className="dropdown-item" to="/dashboard/lists-search">
                Delete
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    ))
  }

  return (
    <div className="admin-leads-page">
      <div className="form-head d-flex mb-md-3">
        <SearchBox onSearch={handleSearch} />
        <DateRange
          defaultRange={range}
          onChange={handleRangeChange}
        />
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Manage Leads:</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <TableHeader
                  headers={headers}
                  currentSort={sort}
                  onChange={(newSort) => { setSort(newSort) }}
                />
                <tbody>
                  { renderLeads() }
                </tbody>
              </table>
              <div className="table-footer">
                <Paginator
                  recordsCount={adminLeads?.count || 0}
                  recordsPerPage={limit}
                  currentPage={page}
                  onChange={setPage}
                />
                <PageSize
                  currentSize={limit}
                  onChange={handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeadSearch
