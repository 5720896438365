import {
  REQUEST_LEADS_INIT,
  REQUEST_LEADS_SUCCESS,
  REQUEST_LEAD_DETAIL_INIT,
  REQUEST_LEAD_DETAIL_SUCCESS,
} from '../types'

const initialState = {
  requestLeads: null,
  requestLeadDetail: null,
}

export default function leadReducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_LEADS_INIT:
      return {
        ...state,
        requestLeads: null,
      }
    case REQUEST_LEADS_SUCCESS:
      return {
        ...state,
        requestLeads: payload,
      }
    case REQUEST_LEAD_DETAIL_INIT:
      return {
        ...state,
        requestLeadDetail: null,
      }
    case REQUEST_LEAD_DETAIL_SUCCESS:
      return {
        ...state,
        requestLeadDetail: payload,
      }
    default:
      return state
  }
}
