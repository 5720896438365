import { useDispatch, useSelector } from 'react-redux'

import { PropertyService } from '../../services'
import {
  PROPERTY_INIT,
  PROPERTY_SUCCESS,
  PROPERTY_FAILED,
  FETCH_EDIT_PROPERTY_SUCCESS,
  DELETE_PROPERTY,
  ADMIN_LEADS_INIT,
  ADMIN_LEADS_SUCCESS,
  NOTI_TYPE,
} from '../types'
import { useProgress } from './progress.hook'
import { useNotification } from './notification.hook'

export const useProperty = () => {
  const dispatch = useDispatch()
  const { data, initialized, editInitialize, adminLeads } = useSelector(({ property }) => property)

  const properties = data.properties
  const property = data.editProperty || {}
  const { setNewNotification } = useNotification()
  const { startProgress, stopProgress } = useProgress()

  const addProperty = async (form) => {
    try {
      await PropertyService.addProperty(form)
      setNewNotification(NOTI_TYPE.SUCCESS, "Success! Property added.")
      getMyProperties()
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, 'Something went wrong. Please refresh the browser and try again.')
    }
  }

  const editProperty = async (id, data) => {
    try {
      await PropertyService.editMyProperty(id, data)
      setNewNotification(NOTI_TYPE.SUCCESS, "Success! Property Updated.")
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, 'Something went wrong. Please refresh the browser and try again.')
    }
  }

  const editPropertyAdminField = async (id, data) => {
    try {
      startProgress()
      await PropertyService.editPropertyAdminField(id, data)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Property updated successfully.')
      stopProgress()
    } catch (err) {
      setNewNotification(NOTI_TYPE.WARNING, 'Something went wrong. Please refresh the browser and try again.')
      stopProgress()
    }
  }

  const getMyProperties = async (params) => {
    try {
      dispatch({ type: PROPERTY_INIT });
      startProgress();
      const response = await PropertyService.getMyProperties(params)
      const payload = {
        properties: response.data,
      }
      dispatch({ type: PROPERTY_SUCCESS, payload });
      stopProgress();
      return payload;
    } catch ({ response, message }) {
      dispatch({
        type: PROPERTY_FAILED,
        payload: response?.data?.message || message
      });
      stopProgress();
      return false;
    }
  };

  const getPropertyById = async (id) => {
    try {
      startProgress();
      const response = await PropertyService.getMyPropertyById(id);
      const payload = {
        property: response.data,
      }
      dispatch({ type: FETCH_EDIT_PROPERTY_SUCCESS, payload });
      stopProgress();
      return payload;
    } catch ({ response, message }) {
      dispatch({
        type: PROPERTY_FAILED,
        payload: response?.data?.message || message
      });
      stopProgress();
      return false;
    }
  };

  const deleteProperty = async (id) => {
    try {
      startProgress();
      await PropertyService.deleteProperty(id);
      dispatch({ type: DELETE_PROPERTY, payload: { id } });
      setNewNotification(NOTI_TYPE.SUCCESS, "Success! Property deleted.");
      stopProgress();
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
      stopProgress();
    }
  }

  const licenseProperty = async (data) => {
    try {
      startProgress();
      await PropertyService.licenseProperty(data);
      setNewNotification(NOTI_TYPE.SUCCESS, "Success! Property licensed.");
      stopProgress();
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
      stopProgress();
    }
  }

  const searchProperties = async (params) => {
    try {
      startProgress()
      const response = await PropertyService.searchProperties(params)
      const payload = {
        properties: response.data,
      }
      dispatch({ type: PROPERTY_SUCCESS, payload })
      stopProgress()
      return payload;
    } catch ({ response, message }) {
      dispatch({
        type: PROPERTY_FAILED,
        payload: response?.data?.message || message
      })
      stopProgress()
      return false
    }
  }

  const deleteSearchedUserProperty = async (id) => {
    try {
      startProgress();
      await PropertyService.deleteProperty(id);
      setNewNotification(NOTI_TYPE.SUCCESS, "Success! Property deleted.");
      stopProgress();
      return true;
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
      stopProgress();
      return false;
    }
  }

  const requestPropertyVerification = async (id) => {
    try {
      startProgress()
      await PropertyService.requestPropertyVerification(id)
      await getMyProperties()
      setNewNotification(NOTI_TYPE.SUCCESS, 'Due to high volume, it may take up to 72 hours to verify your property.')
      stopProgress()
      return true
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
      stopProgress()
      return false
    }
  }

  const searchLeads = async (params) => {
    dispatch({
      type: ADMIN_LEADS_INIT,
    })
    try {
      startProgress()
      const response = await PropertyService.searchLeads(params)
      dispatch({
        type: ADMIN_LEADS_SUCCESS,
        payload: response.data,
      })
      stopProgress()
    } catch (e) {
      //
    }
  }

  const rateLead = async (id, rate) => {
    try {
      startProgress();
      await PropertyService.rateLead(id, rate)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Your rate is saved.')
      stopProgress()
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, 'Failed to save your rate. Please try again later.')
      stopProgress()
    }
  }

  const rejectProperty = async (id, reason) => {
    try {
      startProgress()
      await PropertyService.rejectProperty(id, reason)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Property verification is denied.')
      stopProgress()
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, 'Failed to deny property verification. Please try again later.')
      stopProgress()
    }
  }

  return {
    properties,
    property,
    initialized,
    editInitialize,
    adminLeads,
    addProperty,
    getMyProperties,
    getPropertyById,
    editProperty,
    deleteProperty,
    licenseProperty,
    searchProperties,
    editPropertyAdminField,
    deleteSearchedUserProperty,
    requestPropertyVerification,
    searchLeads,
    rateLead,
    rejectProperty,
  }
}
