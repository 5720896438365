import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import PhoneVerifyModal  from '../../../../components/PhoneVerifyModal'
import { NOTI_TYPE } from '../../../../../store/types'
import { useUser, useNotification } from '../../../../../store/hooks'

const Verification = () => {
  const { token } = useParams()
  const history = useHistory()
  const location = useLocation()
  const { user, verifyEmail, resendEmailVerification } = useUser()
  const { setNewNotification } = useNotification()

  const [isShowPhoneVerifyModal, setIsShowPhoneVerifyModal] = useState(false)

  useEffect(() => {
    (async () => {
      if (token) {
        await verifyEmail({ token })
      }

      if (location.search) {
        const searchParams = new URLSearchParams(location.search)
        if (searchParams.get('after-signup') !== null) {
          // Facebook Pixel Code.
          const pixel = document.createElement('script')
          pixel.innerHTML = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '410414497206167');
            fbq('track', 'PageView');
            fbq('track', 'CompleteRegistration');
          `
          document.body.appendChild(pixel)

          const noscript = document.createElement('noscript')
          noscript.innerHTML = '<img width="1" height="1" style="display:none" ' +
            'src="https://www.facebook.com/tr?id=410414497206167&ev=PageView&noscript=1" />'
          document.body.appendChild(noscript)
        }
      }
    })()
  }, []) // eslint-disable-line

  const emailVerify = async() => {
    await resendEmailVerification()
  }

  const phoneVerify = async() => {

      setIsShowPhoneVerifyModal(true)
  }

  const idVerify = () => {
      history.push('/dashboard/verify-account')
  }

  return (
    <>
      {
        user && user.verified === 0 && (
          <div className="alert alert-primary">
            Please take a minute and help us verify your account.
            It is critical that your information is accurate and up to date.
            In order to verify your identity, please upload a picture
            of your driver's license or passport.
          </div>
        )
      }
      <Card>
        <Card.Body>
          <Row>
            <Col md={6}>
              <h4 className="mb-3">Email</h4>
            </Col>
            <Col md={6}>
              <h4 className="mb-3">Status</h4>
            </Col>
          </Row>
          <Row>
            <div className="col-md-6 mb-3">
              <label className="align-items-center text-align-center">
                { user && user.email }
              </label>
            </div>
            <div className="col-md-6 mb-3">
              <label>
                { user && user.email_verified === false
                  ?
                  <button className="btn btn-primary btn-md btn-block" onClick={emailVerify}>
                    Send Verification Email
                  </button>
                  : 'Verified' }
              </label>
            </div>
          </Row>
          <hr className="mb-4" />
          <Row>
            <Col md={6}>
              <h4 className="mb-3">Phone</h4>
            </Col>
            <Col md={6}>
              <h4 className="mb-3">Status</h4>
            </Col>
          </Row>
          <Row>
            <div className="col-md-6 mb-3">
              <label className="align-items-center text-align-center">
                { user && user.phone }
              </label>
            </div>
            <div className="col-md-6 mb-3">
              <label>
                { user && user.phone_verified === false
                  ? (
                    <button type="submit" className="btn btn-primary btn-md btn-block" onClick={phoneVerify}>
                      Verify Now
                    </button>
                  ) : 'Verified' }
              </label>
            </div>
          </Row>
          <hr className="mb-4" />
          <Row>
            <Col md={6}>
              <h4 className="mb-3">Identity Verification</h4>
            </Col>
            <Col md={6}>
              <h4 className="mb-3">Status</h4>
            </Col>
          </Row>
          <Row>
            <div className="col-md-6 mb-3">
              <label className="align-items-center text-align-center">
              </label>
            </div>
            <div className="col-md-6 mb-3">
              <label>
                { user && (user.verified === 0 || user.verified === 2)
                  ? (
                    <button type="submit" className="btn btn-primary btn-md btn-block" onClick={idVerify}>
                      { user.verified === 0 ? 'Verify Now' : 'Pending' }
                    </button>
                  )
                  : <label>Verified</label>
                }
              </label>
            </div>
          </Row>
          <hr className="mb-4" />
        </Card.Body>
      </Card>
      <PhoneVerifyModal
        show={isShowPhoneVerifyModal}
        phone={user ? user.phone : ''}
        onHide={() => setIsShowPhoneVerifyModal(false)}
      />
    </>
  );
};

export default Verification;
