import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useWallet } from "../../../store/hooks";

const DepositByMetaMaskModal = (props) => {
    const [amount, setAmount] = useState(0);
    const { depositByMetaMask, depositeEthByMetaMask } = useWallet();

    const deposit = async() => {
        props.onHide();       
        await depositeEthByMetaMask(amount, "ETH");
        
    }

    return (
        <>
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Deposit By MetaMask
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                    <div className="col-md-12 mb-3">
                        <label>
                            Amount Of ETH To Deposit
                        </label>
                        <div className="input-group mb-3  input-primary">
                            <div className="input-group-prepend">
                            <span className="input-group-text">{props.depositeToken}</span>
                            </div>
                            <input
                            type="number"
                            className="form-control"
                            value={amount}
                            min={0}
                            onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                    </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
            <Button onClick={deposit}>Deposit</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default DepositByMetaMaskModal;
