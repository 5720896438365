import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { useUser } from '../../../store/hooks'
import { useNotification } from '../../../store/hooks/notification.hook'
import { NOTI_TYPE } from '../../../store/types'

const ResetPassword = () => {
  const { token } = useParams()
  const history = useHistory()

  const { resetPassword } = useUser()
  const { setNewNotification } = useNotification()

  const [passwordResetData, setPasswordResetData] = useState({})

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (passwordResetData.password !== passwordResetData.confirm_password) {
      setNewNotification(NOTI_TYPE.SUCCESS, 'Password doesn\'t match.')
      return false
    }
    try {
      await resetPassword({
        token,
        password: passwordResetData.password,
      })
      history.push('/auth/login')
    } catch (error) {
      //
    }
  }

  const handleBlur = (event) => {
    const resetData = { ...passwordResetData }
    resetData[event.target.name] = event.target.value
    setPasswordResetData(resetData)
  }

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="auth-form">
                <h4 className="text-center mb-4">
                  Password Reset
                </h4>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>
                      <strong>Password</strong>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      defaultValue=""
                      name="password"
                      onChange={handleBlur}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      <strong>Confirm Password</strong>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      defaultValue=""
                      name="confirm_password"
                      onChange={handleBlur}
                      required
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary btn-block">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
