import HttpClient from "./http-client";

export const PropertyService = (function() {
    const addProperty = async(body) => {
        return await HttpClient.post("/property", body);
    };

    const getMyProperties = async (params) => {
        return await HttpClient.get('/property/me', {
            params,
        })
    }

    const getMyPropertyById = async(id) => {
        return await HttpClient.get(`/property/${id}`);
    };

    const editPropertyAdminField = async (id, data) => {
        return await HttpClient.put(`/property/admin/${id}`, data)
    }

    const editMyProperty = async(id, data) => {
        return await HttpClient.put(`/property/${id}`, data);
    }

    const deleteProperty = async(id) => {
        return await HttpClient.delete(`/property/${id}`);
    }

    const requestPropertyVerification = async(id) => {
        return await HttpClient.post(`/property/verify/${id}`);
    }

    const licenseProperty = async(data) => {
        return await HttpClient.post(`/property/license`, data);
    }

    const searchProperties = async params => (
        await HttpClient.get('/property/search', {
            params,
        })
    )

    const searchLeads = async (params) => {
        return await HttpClient.get('/property/admin/leads', {
            params,
        })
    }

    const rateLead = async (id, rate) => {
        return await HttpClient.post(`/property/rate/${id}`, {
            rate,
        })
    }

    const rejectProperty = async (id, reason) => (
        await HttpClient.put(`/property/${id}/reject`, {
            reason,
        })
    )

    return {
        addProperty,
        getMyProperties,
        getMyPropertyById,
        editMyProperty,
        deleteProperty,
        licenseProperty,
        searchProperties,
        editPropertyAdminField,
        requestPropertyVerification,
        searchLeads,
        rateLead,
        rejectProperty,
    }
})()
