import HttpClient from './http-client'

export const UserService = (function() {
    const getUserLeadZipcode = async() => {
        return await HttpClient.get("/leadZipcode");
    }

    const changeLeadZipcode = async (zipOption) => {
        return await HttpClient.post(`/leadZipcode/changezipoption/${zipOption}`)
    }

    const addUserLeadZipcode = async(data) => {
        return await HttpClient.post(`/leadZipcode`, data);
    }

    const getUserLeads = async params => (
        await HttpClient.get('/property/leads', {
            params,
        })
    )

    const getLeadDetail = async id => (
        await HttpClient.get(`/property/leads/${id}`)
    )

    const deleteZipcode = async(id) => {
        return await HttpClient.delete(`/leadZipcode/${id}`);
    }

    const getUserLeadSetting = async() => {
        return await HttpClient.get("/leadSetting");
    };

    const updateUserLeadSetting = async(data) => {
        return await HttpClient.post("/leadSetting", data);
    };

    const getUser = async () => (
        await HttpClient.get('/users/me')
    )

    const updateProfile = async data => (
        await HttpClient.put('/users/me', data)
    )

  

    const uploadVerificationDocuments = async(data) => {
        return await HttpClient.put("/users/verification", data);
    };

    const getNotifications = async() => {
        return await HttpClient.get("/users/notifications");
    };

    const markNotificationAsRead = async (id) => {
        return await HttpClient.put(`/users/notifications/${id}`)
    };

    const getVerificationDocs = async() => {
        return await HttpClient.get('/users/verification_docs')
    }

    const changeTargetSetting = async (target_entire_us) => (
        await HttpClient.post('/leadZipcode/target', {
            target_entire_us,
        })
    )

    const changeTargetStateCity = async (target_state, target_city) => (
        await HttpClient.post('/leadZipcode/target_state_city', {
            target_state,
            target_city,
        })
    )

    return {
        getUserLeadZipcode,
        changeLeadZipcode,
        addUserLeadZipcode,
        getUserLeads,
        getLeadDetail,
        deleteZipcode,
        getUserLeadSetting,
        updateUserLeadSetting,
        getUser,
        updateProfile,
        uploadVerificationDocuments,
        getNotifications,
        markNotificationAsRead,
        getVerificationDocs,
        changeTargetSetting,
        changeTargetStateCity,
    }
})()
