import React from "react";
import { SnackbarProvider } from "notistack";

/// React router dom
import { HashRouter as Router, Redirect, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";

import AuthPage from "./pages/auth";
import UnAuthPage from "./pages/unauth";
import NotificationComponent from './components/Notification';
import ProgressComponent from './components/Progress';

export default function Markup() {
  
  return (
    <SnackbarProvider maxSnack={3}>
      <Router basename="/">
        <AuthPage />
        <UnAuthPage />
        <Route
          exact
          path="/"
          render={() => {
            return (
              <Redirect to="/auth/register" />
            )
          }}
        />
      </Router>
      <NotificationComponent />
      <ProgressComponent />
    </SnackbarProvider>
  );
}
