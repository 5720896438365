import { useDispatch, useSelector } from 'react-redux'

import { CompanyService } from '../../services'
import {
  GET_COMPANY_SUCCESS,
  NOTI_TYPE,
} from '../types'
import { useNotification } from './notification.hook'
import { useProgress } from "./progress.hook"

export const useCompany = () => {
  const dispatch = useDispatch()

  const { company } = useSelector(({ company }) => company)
  const { setNewNotification } = useNotification()
  const { startProgress, stopProgress } = useProgress()

  const getCompany = async () => {
    try {
      startProgress()
      const response = await CompanyService.getCompany()
      dispatch({ type: GET_COMPANY_SUCCESS, payload: response.data.company })
      stopProgress()
    } catch (error) {
      stopProgress()
    }
  }

  const updateCompany = async (params) => {
    try {
      await CompanyService.updateCompany(params)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Updated the company information successfully.')
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to update the company information.')
    }
  }

  return {
    company,
    getCompany,
    updateCompany,
  }
}
