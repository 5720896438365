import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'

const TransferModal = ({ show, onSend, onHide }) => {
  const [address, setAddress] = useState('')
  const [amount, setAmount] = useState(0)

  return (
    <Modal show={show} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Send DOOR Token
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>What Ethereum Wallet do you want to transfer Door Tokens to?</label>
        <div className="input-group mb-3 input-primary">
          <div className="input-group-prepend">
            <span className="input-group-text">ETH WALLET KEY</span>
          </div>
          <input
            type="text"
            className="form-control"
            onChange={(event) => { setAddress(event.target.value) }}
          />
        </div>
        <label>How many Door Tokens do you want to transfer?</label>
        <div className="input-group mb-3 input-primary">
          <div className="input-group-prepend">
            <span className="input-group-text">DOOR</span>
          </div>
          <input
            type="number"
            className="form-control"
            onChange={(event) => { setAmount(event.target.value) }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Cancel</Button>
        <Button
          disabled={!address || !amount}
          onClick={() => { onSend(address, amount) }}
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TransferModal
