import HttpClient from "./http-client";

export const SettingService = (function() {
  const getSetting = async() => {
    return await HttpClient.get("/setting");
  };

  const updateSetting = async(data) => {
    return await HttpClient.put("/setting", data);
  };

  return {
    getSetting,
    updateSetting,
  };
})();