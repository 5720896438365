import {
  GET_STAKING_POOLS_INIT,
  GET_STAKING_POOLS_SUCCESS,
  GET_STAKING_POOL_BY_ID_SUCCESS,
  ADD_STAKING_POOL_SUCCESS,
  UPDATE_STAKING_POOL_SUCCESS,
  ACTIVATE_STAKING_POOL_SUCCESS,
  DELETE_STAKING_POOL_SUCCESS,
} from '../types'

const initialState = {
  stakingPools: [],
}

export default function stakingPoolReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_STAKING_POOLS_INIT:
      return {
        ...state,
        stakingPools: [],
      }
    case GET_STAKING_POOLS_SUCCESS:
      return {
        ...state,
        stakingPools: payload,
      }
    case GET_STAKING_POOL_BY_ID_SUCCESS:
    case ADD_STAKING_POOL_SUCCESS:
      return {
        ...state,
        stakingPools: [
          payload,
          ...state.stakingPools,
        ],
      }
    case UPDATE_STAKING_POOL_SUCCESS:
    case ACTIVATE_STAKING_POOL_SUCCESS:
      return {
        ...state,
        stakingPools: state.stakingPools.map((pool) => {
          if (parseInt(pool.id, 10) === parseInt(payload.id, 10)) {
            return payload
          }
          return pool
        }),
      }
    case DELETE_STAKING_POOL_SUCCESS:
      return {
        ...state,
        stakingPools: state.stakingPools.filter(pool => (
          parseInt(pool.id, 10) !== parseInt(payload, 10)
        )),
      }
    default:
      return state
  }
}
