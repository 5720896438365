import React, { useState } from 'react'

const SearchBox = ({ onSearch }) => {
  const [keyword, setKeyword] = useState('')
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSearch(keyword)
    }
  }

  return (
    <div className='input-group search-area'>
      <div className='input-group-append'>
        <span className='input-group-text'>
          <i className='flaticon-381-search-2' />
        </span>
      </div>
      <input
        type="text"
        className="form-control"
        placeholder="Search here"
        value={keyword}
        onChange={(event) => { setKeyword(event.target.value) }}
        onKeyPress={handleKeyPress}
      />
    </div>
  )
}

export default SearchBox