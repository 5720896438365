import { useDispatch, useSelector } from 'react-redux'
import { WalletService } from '../../services';
import { CRYPTO_INIT, CRYPTO_SUCCESS, CRYPTO_FAILED } from '../types';
import { useProgress } from "./progress.hook";

export const useCrypto = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(({ crypto }) => crypto);
  const crypto = data.cryptos;
  const { startProgress, stopProgress } = useProgress();

  const loadCryptoData = async (params = {}) => {
    try {
      dispatch({ type: CRYPTO_INIT });
      startProgress();
      const cryptoData = await WalletService.getCryptoInfo(params);
      const payload = {
        cryptos: cryptoData.data,
      }
      dispatch({ type: CRYPTO_SUCCESS, payload });
      stopProgress();
      return payload;
    } catch ({ response, message }) {
      dispatch({
        type: CRYPTO_FAILED,
        payload: response?.data?.message || message
      });
      stopProgress();
      return false;
    }
  };

  const sendToken = async (bankId, address, amount) => {
    try {
      startProgress()
      await WalletService.sendToken({
        bankId,
        address,
        amount,
      })
      stopProgress()
    } catch ({ response, message }) {
      stopProgress()
      return false
    }
  }

  const loadTransactions = async (bankId) => {
    try {
      startProgress()
      const response = await WalletService.getTransactionsByBank(bankId)
      stopProgress()
      return response.data?.transactions
    } catch (e) {
      stopProgress()
      return false
    }
  }

  const loadUnsyncedTransactions = async (params) => {
    try {
      startProgress()
      const response = await WalletService.getUnsyncedTransactions(params)
      stopProgress()
      return response.data
    } catch (e) {
      stopProgress()
      return false
    }
  }

  const syncTransactions = async () => {
    try {
      startProgress()
      const response = await WalletService.syncTransactions()
      stopProgress()
      return response.data
    } catch (e) {
      stopProgress()
      return false
    }
  }

  return {
    crypto,
    loadCryptoData,
    sendToken,
    loadTransactions,
    loadUnsyncedTransactions,
    syncTransactions,
  };
};
