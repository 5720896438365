import React, {useEffect, useState} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import { format as formatDate, parseISO } from 'date-fns'
import { Dropdown, Card, Row, Col } from 'react-bootstrap'
import swal from 'sweetalert'

import PropertyRequestsSection from '../../../../components/PropertyRequestsSection'
import PropertyInfoSection from '../../../../components/PropertyInfoSection'
import FileList from '../../../../components/FileList'

import { useList, useProperty } from '../../../../../store/hooks'
import DenyModal from '../../../../components/DenyModal'

const PropertyDetail = () => {
  const { id: propertyId } = useParams()
  const history = useHistory()
  const { property, getPropertyById, editPropertyAdminField, deleteSearchedUserProperty,
    rejectProperty } = useProperty()
  const { lists, loadLists } = useList()

  const [form, setForm] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    listIds: [],
    area_lot_sf: 0,
    area_building_sf: 0,
    market_value: 0,
    is_verified: false,
    is_owner: 1,
  })
  const [denyVisible, setDenyVisible] = useState(false)

  useEffect(() => {
    (async () => {
      await loadLists()
      await getPropertyById(propertyId)
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!property) {
      return
    }
    setForm({
      address1: property.address1 || '',
      address2: property.address2 || '',
      city: property.city || '',
      state: property.state || '',
      zip: property.zip || '',
      listIds: property.property_list_map?.map(x => x.frn_listid) || [],
      area_lot_sf: property.area_lot_sf || 0,
      area_building_sf: property.area_building_sf || 0,
      market_value: property.market_value || 0,
      is_verified: property.is_verified === 2,
      is_owner: property.is_owner ? 1 : 0,
    })
  }, [property])

  const handleChange = (e) => {
    const field = e.target.name
    let value = e.target.value

    // Limit on the property market value.
    if (field === 'market_value') {
      if (!isNaN(value)) {
        value = parseFloat(value)
        if (value > 10000000) {
          return
        }
      }
    }

    handleBasicInfoChange(field, value)
  }

  const handleBasicInfoChange = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleCheckbox = (e) => {
    setForm((prev) => ({...prev, [e.target.name]: e.target.checked}));
  }

  const doUpdate = async () => {
    await editPropertyAdminField(propertyId, {
      ...form,
      is_verified: form.is_verified ? 2 : 0,
      deletedListIds: property?.property_list_map?.filter(x => !form.listIds.includes(x.frn_listid)).map(x => x.frn_listid)
    })
  }

  const handleUpdate = async () => {
    if (!form.is_verified) {
      doUpdate()
    } else {
      swal({
        title: 'Are you sure?',
        text: 'Are you sure all property information is correct? We will send the rewards if you click the verify button.',
        icon: 'warning',
        buttons: ['Cancel', 'Verify'],
        dangerMode: true,
      }).then(async (willUpdate) => {
        if (willUpdate) {
          doUpdate()
        }
      })
    }
  }

  const handleDelete = async () => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete this property?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const success = await deleteSearchedUserProperty(propertyId)
        if (success) {
          history.goBack()
        }
      }
    })
  }

  const handleListSelect = (event) => {
    let listIds = [...form.listIds]
    const value = Number(event.target.value)
    if (event.target.checked) {
      if (!listIds.includes(value)) {
        listIds = [...listIds, value]
      }
    } else if (listIds.includes(value)) {
      const index = listIds.findIndex(i => i === value)
      listIds.splice(index, 1)
    }

    setForm((prev) => ({
      ...prev,
      listIds,
    }))
  }

  const handleReject = async (reason) => {
    setDenyVisible(false)
    await rejectProperty(propertyId, reason)
  }

  const renderLicenses = () => {
    let tableBody
    if (!property || !property.property_user_map.length) {
      tableBody = (
        <tr>
          <td colSpan={4} className="text-center">No licenses</td>
        </tr>
      )
    } else {
      tableBody = property.property_user_map.map((license, index) => (
        <tr key={license.id}>
          <td>{ index + 1 }</td>
          <td>
            { license.user.fname } { license.user.lname }
            {
              license.user.email && (
                <>
                  <br />
                  Email: { license.user.email }
                </>
              )
            }
            {
              license.user.phone && (
                <>
                  <br />
                  Phone: { license.user.phone }
                </>
              )
            }
          </td>
          <td>
            {
              license.user.company && license.user.company.name && (
                <>
                  { license.user.company.name }<br />
                  { license.user.company.address1 }<br />
                  {
                    license.user.company.address2 && (
                      <>
                        { license.user.company.address2 }<br />
                      </>
                    )
                  }
                  { license.user.company.city }, { license.user.company.state }, { license.user.company.zip }
                </>
              )
            }
          </td>
          <td>
            { formatDate(parseISO(license.createdAt), 'MMMM dd') }
          </td>
        </tr>
      ))
    }

    return (
      <Card>
        <Card.Header>
          <Card.Title>License History</Card.Title>
        </Card.Header>
        <Card.Body>
          <table className="table">
            <thead>
              <tr>
                <th>No.</th>
                <th>User</th>
                <th>Company</th>
                <th>Licensed At</th>
              </tr>
            </thead>
            <tbody>
              { tableBody }
            </tbody>
          </table>
        </Card.Body>
      </Card>
    )
  }

  if (!property.id) {
    return null
  }

  return (
    <Row>
      <Col lg={6}>
        <Card>
          <Card.Header>
            <Card.Title>Property Log</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="widget-media dz-scroll height370 ps">
              <ul className="timeline">
                {
                  property?.is_verified === 2 && (
                  <li>
                    <div className="timeline-panel">
                      <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked={property?.is_verified === 2}
                        />
                        <label className="custom-control-label"></label>
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Property Verified</h5>
                      </div>
                      <Dropdown drop="left">
                        <Dropdown.Toggle variant="success" type="button" className="light btn sharp">
                          <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                              <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                              <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>)
                }
                <li>
                  <div className="timeline-panel">
                    <div className="custom-control custom-checkbox checkbox-warning check-lg mr-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        defaultChecked
                      />
                      <label className="custom-control-label"></label>
                    </div>
                    <div className="media-body">
                      <h5 className="mb-0">Property Added</h5>
                      <small className="text-muted">
                        { formatDate(parseISO(property?.createdAt), 'MM-dd-yyyy HH:mm') }
                      </small>
                    </div>
                    <Dropdown drop="left">
                      <Dropdown.Toggle variant="success" type="button" className="light btn sharp">
                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                          </g>
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={`/admin/edit-user/1`}>
                          View Details
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </li>
              </ul>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6}>
        <PropertyRequestsSection
          lists={lists}
          selectedListIds={form.listIds}
          onChange={handleListSelect}
        />
      </Col>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Property</Card.Title>
          </Card.Header>
          <Card.Body>
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Property Owner First Name</label>
                  <input type="text" className="form-control" readOnly defaultValue={property?.user?.fname}/>
                </div>
                <div className="form-group col-md-6">
                  <label>Property Owner Last Name</label>
                  <input type="text" className="form-control" readOnly defaultValue={property?.user?.lname} />
                </div>
              </div>
              <PropertyInfoSection
                info={form}
                onChange={handleBasicInfoChange}
              />
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label style={{display: "block"}}>Attachments</label>
                  <FileList files={property.attachments || []} />
                </div>
              </div>
              <div className="form-row" style={{marginTop:20}}>
                <div className="form-group col-md-12">
                  <b>Admin Only</b>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label>Property Market Value</label>
                  <input
                    type="number"
                    className="form-control"
                    name="market_value"
                    max="10000000"
                    value={form.market_value}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Building Square Feet</label>
                  <input
                    type="text"
                    className="form-control"
                    name="area_building_sf"
                    value={form.area_building_sf}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Lot Square Feet</label>
                  <input
                    type="text"
                    className="form-control"
                    name="area_lot_sf"
                    value={form.area_lot_sf}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12" style={{marginLeft:20}}>
                  <input type="checkbox"
                    className="form-check-input"
                    id="chk-property"
                    name="is_verified"
                    checked={form.is_verified}
                    onChange={handleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="chk-property">
                    Property is Verified
                  </label>
                  <br/>
                </div>
              </div>
              <div className="form-group">
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      name="is_owner"
                      className="form-check-input"
                      value={1}
                      checked={parseInt(form.is_owner, 10) === 1}
                      onChange={handleChange}
                    />
                    Owner
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      name="is_owner"
                      className="form-check-input"
                      value={0}
                      checked={parseInt(form.is_owner, 10) === 0}
                      onChange={handleChange}
                    />
                    Renter
                  </label>
                </div>
              </div>
              <button type="button" className="btn btn-info" onClick={handleUpdate}>
                Update
              </button>
              <button type="button" className="btn btn-danger ml-2" onClick={handleDelete}>
                Remove
              </button>
              {
                !form.is_verified && (
                  <>
                    <button type="button" className="btn btn-danger ml-2" onClick={() => { setDenyVisible(true) }}>
                      Deny Verification
                    </button>
                    <DenyModal
                      show={denyVisible}
                      onReject={handleReject}
                      onHide={() => { setDenyVisible(false) }}
                    />
                  </>
                )
              }
            </form>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={12}>
        { renderLicenses() }
      </Col>
    </Row>
  )
}

export default PropertyDetail
