import {
  GET_SUPPORTS_INIT,
  GET_SUPPORTS_SUCCESS,
  GET_SUPPORT_BY_ID_SUCCESS,
  ADD_SUPPORT_SUCCESS,
  UPDATE_SUPPORT_SUCCESS,
  DELETE_SUPPORT_SUCCESS,
} from '../types'

const initialState = {
  supports: [],
}

export default function supportReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_SUPPORTS_INIT:
      return {
        ...state,
        supports: [],
      }
    case GET_SUPPORTS_SUCCESS:
      return {
        ...state,
        supports: payload,
      }
    case GET_SUPPORT_BY_ID_SUCCESS:
    case ADD_SUPPORT_SUCCESS:
      return {
        ...state,
        supports: [
          ...state.supports,
          payload,
        ],
      }
    case UPDATE_SUPPORT_SUCCESS:
      return {
        ...state,
        supports: state.supports.map(support => (
          parseInt(support.id, 10) !== parseInt(payload.id, 10) ? support : payload
        )),
      }
    case DELETE_SUPPORT_SUCCESS:
      return {
        ...state,
        supports: state.supports.filter(support => (
          parseInt(support.id, 10) !== parseInt(payload, 10)
        )),
      }
    default:
      return state
  }
}