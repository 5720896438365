import React from "react";
import { useWalletInitializer, useListInitializer } from '../store/hooks';


export default function StateInitalized() {

  useWalletInitializer();
  useListInitializer();
  return (
    <></>
  );
}
