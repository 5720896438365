import React, { useState, useEffect } from 'react'
import { Card, Row } from 'react-bootstrap'
import StripeCheckout from 'react-stripe-checkout'

import { NOTI_TYPE } from '../../../../../store/types'
import { useUser, useWallet, useNotification, useSetting } from '../../../../../store/hooks'
import DepositByMetaMaskModal from '../../../../components/DepositByMetaMaskModal/eth'
import { DepositPoolConfig } from '../../../../../config'
import QRCode from "react-qr-code";

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY

const DepositETH = () => {
  const { user } = useUser()
  const { externalWallets, loadExternalWallets, depositDirectly } = useWallet()
  const { setNewNotification } = useNotification()
  const { setting, loadSetting } = useSetting()

  const [amount, setAmount] = useState(100)
  const [promo, setPromo] = useState('')
  const [depsoitByMetaMaskModal, setDepositByMetaMaskModal] = useState(false);
  const [depositeToken, setDepositeToken] = useState("ETH")
  const [tokenRate, setTokenRate] = useState(0.01)

  const [form, setForm] = useState({
    ethWalletKey: '',
  })

  const renderExternalWallets = () => {
    if (!externalWallets.length) {
      return (
        <option value="">No external wallets found.</option>
      )
    }

    return externalWallets.map(wallet => (
      <option key={wallet.eth_address} value={wallet.eth_address}>
        {wallet.eth_address}
      </option>
    ))
  }

  const handleChange = (e) => {
    setForm((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  useEffect(() => {
    (async () => {
      await loadSetting()
      await loadExternalWallets()
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (setting && setting.length) {
      let rate = setting.find(x => x.label === 'eth_rate_cc')
      if (rate) {
        setTokenRate(parseFloat(rate.value))
      } else {
        rate = setting.find(x => x.label === 'eth_rate')
        if (rate) {
          setTokenRate(parseFloat(rate.value))
        } else {
          setTokenRate(0.01)
        }
      }
    }
  }, [setting])

  const depositByMetaMask = async (token_symbol) => {
    setDepositByMetaMaskModal(true);
    setDepositeToken(token_symbol);
  }

  const handleSubmit = async (token_symbol) => {
    await depositDirectly(form.ethWalletKey, token_symbol);
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h4 className="mb-3">Deposit ETH Via MetaMask</h4>
          <div className="alert alert-primary">
            In your Metamask Wallet, you can easily deposit ETH.
            <a href="https://doortoken.gitbook.io/door-help/" target="_blank" rel="noreferrer">please use our Guide</a>.
          </div>
          <hr className="mb-4" />          
          <button className="deposit-button" onClick={() => depositByMetaMask("Ethereum")}>
            <span>Deposit ETH By MetaMask</span>
          </button>          
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Row style={{ margin: "0px" }}>
            <h4 className="mb-3">Directly Deposit</h4>
            <div className=" col-md-12 alert alert-primary">
              You can send your ETH directly to your MyDoorWallet by sending it to the address below.  &nbsp;
              <br />Please make sure you are sending your ETH to a valid ETH Wallet. If you send your ETH to an invalid address they will be lost.  &nbsp;              
            </div>
            {/* <div className=" col-md-12 alert alert-primary"></div> */}
            <div className="col-md-12 mb-3">
              <label>What Ethereum Wallet do you want to deposit ETH Tokens from?</label>
              <div className="input-group mb-3 input-primary">
                <div className="input-group-prepend">
                  <span className="input-group-text">FROM THIS WALLET</span>
                </div>
                <select name="ethWalletKey" onChange={handleChange} className="form-control" >
                  <option value="">...</option>
                  {renderExternalWallets() }
                </select>
              </div>
              <p>If you do not see your Ethereum Wallet above, you can <a className="external-wallet" href="#/dashboard/NIL/view-wallets">add it here.</a></p>
            </div>
            <hr className="mb-4" />
            <div className="col-md-12">
              <label>Please send ETH here.</label>
              <div className="input-group mb-3 input-primary">
                <div className="input-group-prepend">
                  <span className="input-group-text">TO ETH WALLET</span>
                </div>
                <input type="text" className="form-control" readOnly value={DepositPoolConfig.POOL_ADDRESS}/>
              </div>
            </div>
          </Row>          
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={() => handleSubmit("ETH")}
            >
              Notify DOOR I am depositing ETH
            </button>
            <p>NOTE: After you notify us you are sending us ETH, make sure you go to your wallet and send tokens to the above address ASAP.<br/> Once we see the ETH received by our wallet, we will update your account.</p>
        </Card.Body>
      </Card>
      <DepositByMetaMaskModal
        show={depsoitByMetaMaskModal}
        onHide={() => setDepositByMetaMaskModal(false)}
        depositeToken={depositeToken}
      />
    </>
  )
}

export default DepositETH
