import React from "react";
import {Card} from 'react-bootstrap';

export default function AdminExperience() {

  return (
    <Card>
      <Card.Body>
        <h4>Coming soon.</h4>
      </Card.Body>
    </Card>
  );
};