import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { isAddress } from 'web3-utils';

const ExternalWalletModal = ({ show, onAdd, onHide }) => {
  const [address, setAddress] = useState('');

  const checkWalletAddress = (address) => {

    if(address != "" && isAddress(address)){
      if(address.indexOf('0x') >= 0){
        return true;
      }
    }

    return false;
  }

  return (
    <Modal
      show={show}
      centered
      onShow={() => { setAddress('') }}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Add new external wallet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>
          ETH Wallet Key
        </label>
        <input
          type="text"
          className="form-control"
          value={address}
          onChange={(e) => {
            setAddress(e.target.value)
          }}
        />
        <p>{!checkWalletAddress(address) ? "Input Valid ETH address" : ""}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>Cancel</Button>
        <Button
          disabled={!checkWalletAddress(address)}
          variant="primary"
          onClick={() => { onAdd(address) }}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal >
  )
}

export default ExternalWalletModal
