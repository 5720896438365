import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import { useUsers } from "../../../../../store/hooks";
import PageSize from "../../../../components/PageSize";
import Paginator from "../../../../components/Paginator";

const UserNft = () => {
  const [userNfts, setUserNfts] = useState([]);
  const [totalNfts, setTotalNfts] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const { loadUserNft } = useUsers();

  useEffect(() => {
    (async function () {
      const userNftDb = await loadUserNft({ page, limit: pageLimit });
      setUserNfts(userNftDb?.userNft);
      setTotalNfts(userNftDb?.count);
    })();
  }, [pageLimit, page]);

  const handlePageSizeChange = (size) => {
    setPageLimit(size);
    setPage(1);
  };

  const renderUserNft = () => {
    if (!userNfts.length) {
      return (
        <tr>
          <td colSpan={5} className="text-center">
            No usernft found.
          </td>
        </tr>
      );
    }

    return userNfts.map((userNft, index) => (
      <tr key={index}>
        <td >
          <img src={userNft?.iconURL} className="w-25" alt="NFT image"/>
        </td>
        <td>
          <span>{userNft?.name}</span>
        </td>
        <td>
          <span>{userNft?.subtitle}</span>
        </td>
        <td>
          <button
            type="type"
            className="btn btn-success light sharp"
            onClick={() => window.open(
              `https://nftlocker.io/nft_minted.cfm?nftuuid=${userNft?.nftuuid}&nftmintid=${userNft?.nftmintid}`,
              "_new"
            )}
          >
            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
              </g>
            </svg>
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>My NFTs</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <table className="table table-striped table-responsive-sm">
              <thead>
                <tr>
                  <th style={{width: "15%"}} >NFT</th>
                  <th>Name</th>
                  <th>Info</th>
                  <th>NFTlink</th>
                </tr>
              </thead>
              <tbody>{renderUserNft()}</tbody>
            </table>
            <div className="table-footer mt-5">
              <Paginator
                recordsCount={totalNfts}
                recordsPerPage={pageLimit}
                currentPage={page}
                onChange={setPage}
              />
              <PageSize
                currentSize={pageLimit}
                onChange={handlePageSizeChange}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserNft;
