import { useDispatch, useSelector } from 'react-redux'

import { StakingPoolService } from '../../services'
import {
  GET_STAKING_POOLS_INIT,
  GET_STAKING_POOLS_SUCCESS,
  GET_STAKING_POOL_BY_ID_SUCCESS,
  ADD_STAKING_POOL_SUCCESS,
  UPDATE_STAKING_POOL_SUCCESS,
  ACTIVATE_STAKING_POOL_SUCCESS,
  DELETE_STAKING_POOL_SUCCESS,
  NOTI_TYPE,
} from '../types'
import { useNotification } from './notification.hook'
import { useProgress } from "./progress.hook"

export const useStakingPool = () => {
  const dispatch = useDispatch()

  const { stakingPools } = useSelector(({ stakingPool }) => stakingPool)
  const { setNewNotification } = useNotification()
  const { startProgress, stopProgress } = useProgress()

  const getStakingPools = async () => {
    try {
      dispatch({ type: GET_STAKING_POOLS_INIT })
      startProgress()
      const response = await StakingPoolService.getStakingPools()
      dispatch({type: GET_STAKING_POOLS_SUCCESS, payload: response.data})
      stopProgress()
      return response.data
    } catch (error) {
      stopProgress()
      return []
    }
  }

  const getStakingPoolById = async (id) => {
    try {
      startProgress()
      const response = await StakingPoolService.getStakingPoolById(id)
      dispatch({
        type: GET_STAKING_POOL_BY_ID_SUCCESS,
        payload: response.data.pool,
      })
      stopProgress()
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to retrieve a staking pool.')
      stopProgress()
    }
  }

  const addStakingPool = async (params) => {
    try {
      startProgress()
      const response = await StakingPoolService.addStakingPool(params)
      dispatch({
        type: ADD_STAKING_POOL_SUCCESS,
        payload: response.data.pool,
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Added a new staking pool successfully.')
      stopProgress()
      return true
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to add a staking pool.')
      stopProgress()
      return false
    }
  }

  const updateStakingPool = async (id, params) => {
    try {
      startProgress()
      const response = await StakingPoolService.updateStakingPool(id, params)
      dispatch({
        type: UPDATE_STAKING_POOL_SUCCESS,
        payload: response.data.pool,
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Updated the staking pool successfully.')
      stopProgress()
      return true
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to update the staking pool.')
      stopProgress()
      return false
    }
  }

  const activateStakingPool = async (id, is_active) => {
    try {
      startProgress()
      const response = await StakingPoolService.activateStakingPool(id, is_active)
      dispatch({
        type: ACTIVATE_STAKING_POOL_SUCCESS,
        payload: response.data.pool,
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Updated the staking pool successfully.')
      stopProgress()
      return true
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to update the staking pool.')
      stopProgress()
      return false
    }
  }

  const deleteStakingPool = async (id) => {
    try {
      startProgress()
      await StakingPoolService.deleteStakingPool(id)
      dispatch({
        type: DELETE_STAKING_POOL_SUCCESS,
        payload: id,
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Deleted the staking pool successfully.')
      stopProgress()
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to delete the staking pool.')
      stopProgress()
    }
  }

  return {
    stakingPools,
    getStakingPools,
    getStakingPoolById,
    addStakingPool,
    updateStakingPool,
    activateStakingPool,
    deleteStakingPool,
  }
}
