import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { NOTI_TYPE } from '../../../store/types'
import { useUser, useNotification } from '../../../store/hooks'

const PhoneVerifyModal = (props) => {
    const { phone = '', onHide } = props

    const { sendSMS, verifySMS } = useUser()
    const { setNewNotification } = useNotification()

    const [phoneNumber, setPhoneNumber] = useState(phone)
    const [confirmCode, setConfirmCode] = useState('')

    useEffect(() => {
        if (phone !== phoneNumber && phoneNumber === '') {
            setPhoneNumber(phone)
        }
    }, [phone]) // eslint-disable-line

    const handlePhoneChange = (value) => {
        // Stripe non-digit characters from a phone number.
        setPhoneNumber(value.replace(/\D/g, ''))
    }

    const send = async() => {
        if (!phoneNumber) {
            setNewNotification(
              NOTI_TYPE.WARNING,
              'Please enter your phone number.'
            )
            return
        }
        await sendSMS({ number: `+${phoneNumber}` })
    }

    const confirmVerificationCode = async() => {
        onHide()
        await verifySMS({ confirmCode: confirmCode })
    }

    return (
        <Modal
            {...props}
            className="phone-verify-modal"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Phone Verify
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <label>
                        Phone Number
                    </label>
                    <div className="row">
                        <div className="col-md-8">
                            <PhoneInput
                                country="us"
                                placeholder="Phone"
                                inputProps={{
                                    required: true,
                                }}
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                            />
                        </div>
                        <div className="col-md-4 mt-2 mt-sm-0">
                            <button
                                type="button"
                                className="btn btn-primary btn-md btn-block"
                                onClick={send}
                            >
                                Send/Resend
                            </button>
                        </div>
                    </div>
                </div>
                <hr className="mb-4" />
                <div>
                    <label>
                        Confirm Code
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        value={confirmCode}
                        onChange={(e) => setConfirmCode(e.target.value)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
                <Button onClick={confirmVerificationCode}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PhoneVerifyModal
