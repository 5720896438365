import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Card, Dropdown } from 'react-bootstrap'
import swal from 'sweetalert'
import { format as formatDate, parseISO } from 'date-fns'

import SearchBox from '../../../../components/SearchBox'
import Paginator from '../../../../components/Paginator'
import PageSize from '../../../../components/PageSize'
import TableHeader from '../../../../components/TableHeader'
import { useRequest } from '../../../../../store/hooks'

const MyRequests = () => {
  const { requests, getRequests, deleteRequest } = useRequest()

  const headers = [
    { name: 'title', label: 'Description', sortBy: 'title' },
    { name: 'lists', label: 'Lists' },
    { name: 'updatedAt', label: 'Updated At', sortBy: 'updatedAt', style: { width: 160 } },
    { name: 'action', style: { width: 120 } },
  ]

  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(['updatedAt', 'desc'])

  useEffect(() => {
    (async () => {
      await getRequests({
        search,
        limit,
        page,
        sortBy: sort[0],
        sortOrder: sort[1],
      })
    })()
  }, [search, page, limit, sort]) // eslint-disable-line

  const handleSearch = (keyword) => {
    setSearch(keyword)
    setPage(1)
  }

  const handlePageSizeChange = (size) => {
    setLimit(size)
    setPage(1)
  }

  const handleDelete = async (requestId) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this request.',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteRequest(requestId)
      }
    })
  }

  const renderRequests = () => {
    if (!requests || !requests.rows.length) {
      return (
        <tr>
          <td colSpan={4} className="text-center">No requests found.</td>
        </tr>
      )
    }

    return requests.rows.map((element) => (
      <tr key={element.id}>
        <td>
          <Link to={`/dashboard/edit-request/${element.id}`}>
            { element.description }
          </Link>
        </td>
        <td>
          {
            element.request_list_map.map(x => (
              <span key={x.id} className="badge badge-primary list-label">
                { x.list.property_label }
              </span>
            ))
          }
        </td>
        <td>
          { formatDate(parseISO(element.updatedAt), 'MM-dd-yyyy HH:mm') }
        </td>
        <td>
          <Dropdown drop="left">
            <Dropdown.Toggle
              variant="success"
              type="button"
              className="light sharp"
            >
              <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={`/dashboard/edit-request/${element.id}`}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDelete(element.id)}>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    ))
  }

  return (
    <div className="my-requests-page">
      <div className="form-head d-flex mb-md-3">
        <SearchBox onSearch={handleSearch} />
      </div>
      <Card>
        <Card.Header>
          <Card.Title>Current Requests</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <TableHeader
                  headers={headers}
                  currentSort={sort}
                  onChange={(newSort) => { setSort(newSort) }}
                />
                <tbody>
                  { renderRequests() }
                </tbody>
              </table>
              <div className="table-footer">
                <Paginator
                  recordsCount={requests?.count || 0}
                  recordsPerPage={limit}
                  currentPage={page}
                  onChange={setPage}
                />
                <PageSize
                  currentSize={limit}
                  onChange={handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default MyRequests
