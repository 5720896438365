import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Web3 from 'web3'
import Web3Utils from 'web3-utils'
import { ethers } from "ethers";
import { WalletService } from '../../services'
import {
  WALLET_INIT, WALLET_SUCCESS, WALLET_FAILED, NOTI_TYPE,
  TRANSACTION_SUCCESS, TRANSACTION_INIT, TRANSACTION_FAILED,
  EXTERNAL_WALLETS_INIT, EXTERNAL_WALLETS_SUCCESS, EXTERNAL_WALLETS_ADD,
  EXTERNAL_WALLETS_REMOVE,
  GET_BANKS_INIT,
  GET_BANKS_SUCCESS,
} from '../types'
import { useNotification } from './notification.hook'
import { useProgress } from './progress.hook'
import { DepositPoolConfig } from '../../config'

export const useWallet = () => {
  const dispatch = useDispatch()
  const { data: wallet, initialized, externalWallets, banks } = useSelector(({ wallet }) => wallet)
  const { setNewNotification } = useNotification()
  const { startProgress, stopProgress } = useProgress()

  const depositByStripe = async (token_id, amount, promo, token_symbol) => {
    try{
      startProgress()
      let _symbol = token_symbol ?? "DOOR";
      await WalletService.addDeposit({
        token: token_id,
        product: {
          price: amount,
          name: `Buy ${amount} ${_symbol} Token`,
          description: `Purchasing ${_symbol} token from DoorCoin`
        },
        promo,
        token_symbol: _symbol
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Success! Check email for details.')
      loadWallet()
      stopProgress()
    } catch (err) {
      setNewNotification(NOTI_TYPE.WARNING, 'Something went wrong. Please refresh the browser and try again.')
      stopProgress()
    }
  }

  const loadWallet = async (params) => {
    try {
      dispatch({ type: WALLET_INIT })
      startProgress()
      let token_symbol = "DOOR";
      
      if (params !== undefined && params.token_symbol !== undefined) {
        token_symbol = params.token_symbol;
      }
      
      const balance = await WalletService.getBalance(token_symbol)
      const transactions = await WalletService.getTransactions({...params, token_symbol })

      const payload = {
        balance: balance.data.balance,
        unlockedBalance: balance.data.unlockedBalance,
        totalBalance: balance.data.totalBalance,
        transactions: transactions.data,
      }

      dispatch({ type: WALLET_SUCCESS, payload })
      stopProgress()
      return payload
    } catch ({ response, message }) {
      dispatch({
        type: WALLET_FAILED,
        payload: response?.data?.message || message
      })
      stopProgress()
      return false
    }
  }

  const getTransactions = async (params) => {
    try {
      dispatch({ type: TRANSACTION_INIT })
      startProgress()
      const response = await WalletService.getAllTransactions(params)
      dispatch({
        type: TRANSACTION_SUCCESS,
        payload: response.data,
      })
      stopProgress()
      return response
    } catch ({ response, message }) {
      dispatch({
        type: TRANSACTION_FAILED,
        payload: response?.data?.message || message
      })
      stopProgress()
      return false
    }
  }

  const depositeEthByMetaMask = async (amount, token_symbol = "Door") => {
    try {
      const transactionHash = await sendMetaMaskETHTransaction(DepositPoolConfig.POOL_ADDRESS, amount)
      if (transactionHash === 'failedTransaction') {
        setNewNotification(NOTI_TYPE.WARNING, 'Failed to deposit.')
        return false
      }
      if (transactionHash === 'metaMaskNotFound') {
        setNewNotification(NOTI_TYPE.WARNING, 'Please install MetaMask.')
        return false
      }
      if (transactionHash === 'isNotRightNetwork') {
        setNewNotification(NOTI_TYPE.WARNING, 'Please deposit on main network.')
        return false
      }
      if (transactionHash === 'rejectedByUser') {
        return false
      }

      startProgress()
      await WalletService.depositeEthWithMetaMask({
        transactionHash: transactionHash?.hash,
        amount,
        fromAddress: window.ethereum.selectedAddress,
        toAddress: DepositPoolConfig.POOL_ADDRESS,
        token_symbol,
        name: `Deposit ${amount} ${token_symbol} Token`,
        description: `Purchasing ${token_symbol} token from DoorCoin`
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Deposit succeeded.')
      loadWallet()
      stopProgress()
    } catch({ response, message }) {
      setNewNotification(NOTI_TYPE.WARNING, 'Failed to deposit')
      return false
    }
  }

  const depositByMetaMask = async (amount, token_symbol = "DOOR") => {
    try {
      const transactionHash = await sendMetaMaskTransaction(DepositPoolConfig.POOL_ADDRESS, amount, token_symbol)
      if (transactionHash === 'failedTransaction') {
        setNewNotification(NOTI_TYPE.WARNING, 'Failed to deposit.')
        return false
      }
      if (transactionHash === 'metaMaskNotFound') {
        setNewNotification(NOTI_TYPE.WARNING, 'Please install MetaMask.')
        return false
      }
      if (transactionHash === 'isNotRightNetwork') {
        setNewNotification(NOTI_TYPE.WARNING, 'Please deposit on main network.')
        return false
      }
      if (transactionHash === 'rejectedByUser') {
        return false
      }

      startProgress()
      await WalletService.addDepositWithMetaMask({
        transactionHash: transactionHash,
        amount,
        fromAddress: window.ethereum.selectedAddress,
        toAddress: DepositPoolConfig.POOL_ADDRESS,
        token_symbol,
        name: `Deposit ${amount} ${token_symbol} Token`,
        description: `Purchasing ${token_symbol} token from DoorCoin`
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Deposit succeeded.')
      loadWallet()
      stopProgress()
    } catch({ response, message }) {
      setNewNotification(NOTI_TYPE.WARNING, 'Failed to deposit')
      return false
    }
  }

  const depositDirectly = async (fromAddress, symbol = "DOOR") => {
    try {
      startProgress()
      await WalletService.addDepositDirect({
        fromAddress: fromAddress,
        token_symbol: symbol,
      })
      setNewNotification(NOTI_TYPE.SUCCESS, `After you notify us you are sending us ${symbol} Tokens, make sure you go to your wallet and send tokens to the above address ASAP. Once we see the tokens received by our wallet, we will update your account.`);
      loadWallet()
      stopProgress()
    } catch({ response, message }) {
      setNewNotification(NOTI_TYPE.WARNING, 'Please select the from address')
      stopProgress()
      return false
    }
  }

  const sendMetaMaskTransaction = async(toAddress, token_amount, token_symbol = "DOOR") => {
    if (!window.ethereum || !window.web3) {
      return 'metaMaskNotFound'
    }

    // If contract works on right network.
    if (window.ethereum.chainId !== DepositPoolConfig.NETWORK_CHAIN_ID) {
      return 'isNotRightNetwork'
    }

    try {
      window.web3 = new Web3(window.web3.currentProvider)
      await window.ethereum.enable()

      let Contract_address = DepositPoolConfig.CONTRACT_ADDRESS;
      let amount = Web3Utils.numberToHex(window.web3.utils.toWei(token_amount.toString(), 'ether') )
      if(token_symbol != "DOOR"){
        Contract_address = DepositPoolConfig.CONTRACT_NIL_ADDRESS;
        amount = Web3Utils.numberToHex(window.web3.utils.toWei((token_amount*100000000).toString(), 'wei') );
      }



      const abiInterface = new window.web3.eth.Contract(DepositPoolConfig.ABI, Contract_address)

      const data = abiInterface.methods.transfer(toAddress, amount).encodeABI()

      const transactionHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [{
          to: Contract_address,
          from: window.ethereum.selectedAddress,
          data: data
        }],
      })
      return transactionHash
    } catch (error) {
      if (error.code === 4001) {
        return 'rejectedByUser'
      }
      return 'failedTransaction'
    }
  }

  const sendMetaMaskETHTransaction = async(toAddress, token_amount) => {
    if (!window.ethereum || !window.web3) {
      return 'metaMaskNotFound'
    }

    // If contract works on right network.
    if (window.ethereum.chainId !== DepositPoolConfig.NETWORK_CHAIN_ID) {
      return 'isNotRightNetwork'
    }

    try {
      await window.ethereum.send("eth_requestAccounts");

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      ethers.utils.getAddress(toAddress);
      const transactionHash = await signer.sendTransaction({
        to: toAddress,
        value: ethers.utils.parseEther(token_amount)
      });
      return transactionHash
    } catch (error) {
      if (error.code === 4001) {
        return 'rejectedByUser'
      }
      return 'failedTransaction'
    }
  }


  const withdrawDoorToken = async(data, token_symbol = "DOOR") => {
    try {
      if(token_symbol == "ETH"){
        await WalletService.withdrawETH(data)
      }else{
        await WalletService.withdrawDoorToken(data)
      }      
      await loadWallet({token_symbol})
      setNewNotification(NOTI_TYPE.SUCCESS, 'Withdraw succeeded. Transaction will be confirmed within 1 ~ 2 mins')
      return true
    } catch ({ response, message }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message)
      return false
    }
  }

  const loadExternalWallets = async () => {
    try {
      dispatch({ type: EXTERNAL_WALLETS_INIT })
      startProgress()
      const response = await WalletService.getExternalWallets()
      dispatch({ type: EXTERNAL_WALLETS_SUCCESS, payload: response.data })
      stopProgress()
    } catch (e) {
      stopProgress()
    }
  }

  const addExternalWallet = async (address) => {
    try {
      startProgress()
      await WalletService.addExternalWallet(address)
      dispatch({ type: EXTERNAL_WALLETS_ADD, payload: address })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Added external wallet successfully.')
      stopProgress()
    } catch (e) {
      setNewNotification(NOTI_TYPE.WARNING, e.response?.data?.message)
      stopProgress()
    }
  }

  const removeExternalWallet = async (address) => {
    try {
      startProgress()
      await WalletService.removeExternalWallet(address)
      dispatch({ type: EXTERNAL_WALLETS_REMOVE, payload: address })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Removed external wallet successfully.')
      stopProgress()
    } catch (e) {
      setNewNotification(NOTI_TYPE.WARNING, 'Failed to remove external wallet.')
      stopProgress()
    }
  }

  const loadBanks = async (params) => {
    try {
      dispatch({ type: GET_BANKS_INIT })
      startProgress()
      let token_symbol = "DOOR";
      if(params)
        token_symbol = params?.token_symbol;

      const response = await WalletService.getBanks(token_symbol)
      dispatch({ type: GET_BANKS_SUCCESS, payload: response.data })
      stopProgress()
    } catch (e) {
      stopProgress()
    }
  }

  const loadBank = async (id) => {
    try {
      startProgress()
      const response = await WalletService.getBank(id);
      stopProgress();
      return response.data;
    } catch (e) {
      stopProgress();
      setNewNotification(NOTI_TYPE.WARNING, 'Failed to load wallet data. Please refresh your browser');
    }

    return false;
  }

  return {
    wallet,
    externalWallets,
    banks,
    loadWallet,
    initialized,
    depositByStripe,
    getTransactions,
    depositByMetaMask,
    depositeEthByMetaMask,
    withdrawDoorToken,
    loadExternalWallets,
    addExternalWallet,
    removeExternalWallet,
    loadBanks,
    loadBank,
    depositDirectly
  }
}

// Initializer
export const useWalletInitializer = () => {
  const { loadWallet, initialized } = useWallet()
  //init the wallet if there is not
  useEffect(() => {
    (async () => {
      if (!initialized) {
        loadWallet()
      }
    })()
  }, [loadWallet, initialized])
}
