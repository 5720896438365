/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { format as formatDate, parseISO } from 'date-fns'
import { Card } from 'react-bootstrap'
import 'react-phone-input-2/lib/style.css'
import { useWallet, useUser } from '../../../../../store/hooks'

const WalletDetail = () => {
    const { loadBank } = useWallet();
    const { user } = useUser()
    const { id: bankId } = useParams();
    const [bank, setBank] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const history = useHistory()

    useEffect(() => {
        (async () => {
          const result = await loadBank(bankId);
          if(result){
              setBank(result.bank);
              setTransactions(result.transaction.rows);
          }
        })()
    }, [setBank,setTransactions,bankId]) // eslint-disable-line

    const handleMoreClick = transaction => () => {
        if (transaction.url) {
          window.open(transaction.url, '_new')
        } else if (transaction.type === 'reward') {
          history.push(`/dashboard/edit-property/${transaction.note}`)
        } else if (transaction.type === 'licenced') {
          if (transaction?.to_bank?.user?.id === user?.id) {
            history.push(`/dashboard/edit-property/${transaction.note}`)
          } else {
            history.push(`/dashboard/lead-details/${transaction.note}`)
          }
        }
      }
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Wallet Information</Card.Title>
        </Card.Header>
        <Card.Body>
          { bank != null && <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Wallet Balance</label>
                <input type="text" className="form-control" readOnly defaultValue={ parseFloat(bank.balance).toFixed(2) }/>
              </div>
                <div className="form-group col-md-6">
                <label>Created Date</label>
                <input type="text" className="form-control" readOnly defaultValue={ formatDate(parseISO(bank.createdAt), 'MM-dd-yyyy HH:mm') } />
              </div>
              <div className="form-group col-md-6">
                <label>Is Locked</label>
                <input type="text" className="form-control" readOnly defaultValue={ bank.is_locked ? 'Locked' : 'Active' }/>
              </div>
              <div className="form-group col-md-6">
                <label>Lock Expiry Date</label>
                <input type="text" className="form-control" readOnly defaultValue={ bank.lock_expire ? formatDate(parseISO(bank.lock_expire), 'MM-dd-yyyy HH:mm') : '' }/>
              </div>
            </div>
          </form>}
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>Transaction Details</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <table className="table table-striped table-responsive-sm">
              <thead>
                <tr>
                  <th></th>
                  <th>Note</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                {
                  transactions.map((transaction, index) => (
                    <tr key={transaction.id}>
                      <td><span className='bgl-success p-3 d-inline-block'>
                            {
                            (transaction?.to_bank?.user?.id === user?.id)
                            ? (
                                <svg width={29} height={29} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.6186 15.7207L23.6186 15.7207L23.6353 22.6289C23.6354 22.6328 23.6354 22.6363 23.6353 22.6396M23.6186 15.7207L23.1353 22.6341L23.6353 22.635C23.6353 22.6481 23.6347 22.6583 23.6345 22.6627L23.6344 22.6642C23.6346 22.6609 23.6351 22.652 23.6353 22.6407C23.6353 22.6403 23.6353 22.64 23.6353 22.6396M23.6186 15.7207C23.6167 14.9268 22.9717 14.2847 22.1777 14.2866C21.3838 14.2885 20.7417 14.9336 20.7436 15.7275L20.7436 15.7275L20.7519 19.1563M23.6186 15.7207L20.7519 19.1563M23.6353 22.6396C23.6329 23.4282 22.9931 24.0705 22.2017 24.0726C22.2 24.0726 22.1983 24.0727 22.1965 24.0727L22.1944 24.0727L22.1773 24.0726L15.2834 24.056L15.2846 23.556L15.2834 24.056C14.4897 24.054 13.8474 23.4091 13.8494 22.615C13.8513 21.8211 14.4964 21.179 15.2903 21.1809L15.2903 21.1809L18.719 21.1892L5.53639 8.0066C4.975 7.44521 4.975 6.53505 5.53639 5.97367C6.09778 5.41228 7.00793 5.41228 7.56932 5.97367L20.7519 19.1563M23.6353 22.6396C23.6353 22.6376 23.6353 22.6356 23.6353 22.6336L20.7519 19.1563M22.1964 24.0726C22.1957 24.0726 22.1951 24.0726 22.1944 24.0726L22.1964 24.0726Z" fill='#2BC155' stroke='#2BC155' />
                                </svg>
                            ) : (
                                <svg width={29} height={29} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.13185 13.9043L5.13185 13.9043L5.11515 6.99607C5.11511 6.99224 5.11513 6.98868 5.11517 6.98542M5.13185 13.9043L5.61517 6.99089L5.11517 6.99005C5.11519 6.97692 5.11575 6.96665 5.116 6.96234L5.11608 6.96082C5.11588 6.96411 5.11535 6.97298 5.11519 6.98431C5.11518 6.98468 5.11517 6.98505 5.11517 6.98542M5.13185 13.9043C5.13378 14.6982 5.77881 15.3403 6.57281 15.3384C7.36672 15.3365 8.00877 14.6914 8.00689 13.8975L8.00689 13.8975L7.99856 10.4687M5.13185 13.9043L7.99856 10.4687M5.11517 6.98542C5.11755 6.19684 5.75739 5.55451 6.54875 5.55238C6.55044 5.55236 6.5522 5.55235 6.554 5.55234L6.55606 5.55234L6.57321 5.55239L13.4671 5.56905L13.4658 6.06905L13.4671 5.56905C14.2608 5.57098 14.903 6.21593 14.9011 7.01004C14.8992 7.80394 14.2541 8.44597 13.4602 8.44409L13.4602 8.4441L10.0315 8.43582L23.2141 21.6184C23.7755 22.1798 23.7755 23.0899 23.2141 23.6513C22.6527 24.2127 21.7426 24.2127 21.1812 23.6513L7.99856 10.4687M5.11517 6.98542C5.11516 6.98743 5.11517 6.98943 5.11517 6.99144L7.99856 10.4687M6.5541 5.55237C6.55474 5.55237 6.5554 5.55237 6.55606 5.55238L6.5541 5.55237Z" fill='#FF2E2E' stroke='#FF2E2E' />
                                </svg>
                            )
                            }
                        </span></td>
                      <td>{ transaction.note }</td>
                      <td>{ transaction.amount }</td>
                      <td>{ formatDate(parseISO(transaction.createdAt), 'MMMM dd, yyyy') }</td>
                      <td>
                        <a type="type" className="btn btn-success light sharp" onClick={handleMoreClick(transaction)}>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                                    <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                    <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                                </g>
                            </svg>
                        </a>
                       </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default WalletDetail;