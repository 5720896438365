import { CRYPTO_INIT, CRYPTO_SUCCESS, CRYPTO_FAILED } from '../types';

const initialState = {
    data: {
        cryptos: null,
    },
    initialized: false,
    editInitialize: false,
    loading: false,
    error: '',
};

export default function cryptoReducer(state = initialState, { type, payload }) {
    switch (type) {
        case CRYPTO_INIT:
            return {
                ...state,
                data: {
                    cryptos: null
                },
                loading: false,
                editInitialize: false,
                error: '',
            };
        case CRYPTO_SUCCESS:
            return {
                ...state,
                data:{
                    cryptos: payload.cryptos,
                },
                loading: false,
                initialized: true,
                error: '',
            };
        case CRYPTO_FAILED:
            return {
                ...state,
                loading: false,
                editInitialize: true,
                initialized: false,
                error: payload,
            };
        default:
            return state;
    }
}