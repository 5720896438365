import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import logo from '../../../images/brand-logo.png'
import { useUser } from '../../../store/hooks'

const ForgotPassword = () => {
  const { sendForgotPasswordLink } = useUser();
  const [passwordResetData, setPasswordResetData] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    sendForgotPasswordLink(passwordResetData);
  };

  const handleBlur = (e) => {
    const resetData = { ...passwordResetData };
    resetData[e.target.name] = e.target.value;
    setPasswordResetData(resetData);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-12 col-sm-7 col-md-5 col-lg-4 authfy-panel-left">
          <div className="brand-logo text-center">
            <img src={logo} width="150" alt="Door Coin" />
          </div>
          <div className="authfy-login">
            <div className="authfy-panel panel-forgot active">
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  <div className="authfy-heading">
                    <h3 className="auth-title">Recover your password</h3>
                    <p>Fill in your e-mail address below and we will send you an email with further instructions.</p>
                  </div>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        name="email"
                        onChange={handleBlur}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button className="btn btn-lg btn-primary btn-block" type="submit">
                        Recover your password
                      </button>
                    </div>
                    <div className="form-group">
                      <Link className="text-primary" to="/auth/login">
                        Already have an account?
                      </Link>
                    </div>
                    <div className="form-group">
                      <Link className="text-primary" to="/auth/register">
                        Don’t have an account?
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 col-lg-8 authfy-panel-right hidden-xs hidden-sm">
          <div className="hero-heading">
            <div className="headline">
              <h3>Unlock your Door</h3>
              <p>
                Join our growing community of people being rewarded to connect with advertisers. <br/>
                Every time a consumer opts-in to receive information about a product or service they earn Door Coin.<br/>
                <strong>Your Data. Your Money.</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
