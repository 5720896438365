import React, { useEffect } from "react";
import { useNotification } from "../../../store/hooks";
import { NOTI_TYPE } from "../../../store/types";
import { useSnackbar } from "notistack";

const NotificationComponent = () => {
    const notification = useSnackbar();
    const { new_noti, initNotification } = useNotification();

    useEffect(() => {
        if (new_noti.description !== "") {
            switch (new_noti.type) {
                case NOTI_TYPE.INFO: {
                    notification.enqueueSnackbar(new_noti.description, {
                        variant: "info"
                    });
                    initNotification();
                    break;
                }
                case NOTI_TYPE.SUCCESS: {
                    notification.enqueueSnackbar(new_noti.description, {
                        variant: "success"
                    });
                    initNotification();
                    break;
                }
                case NOTI_TYPE.WARNING: {
                    notification.enqueueSnackbar(new_noti.description, {
                        variant: "error"
                    });
                    initNotification();
                    break;
                }
                default: {
                    initNotification();
                    break;
                }
            }
        }
    }, [new_noti]); // eslint-disable-line

   return (
      <></>
   );
};

export default NotificationComponent;
