import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'

import { useSetting } from '../../../../../store/hooks'

const Setting = () => {
  const { setting, loadSetting, updateSetting } = useSetting()

  const [tokenRate, setTokenRate] = useState('')
  const [tokenRateCC, setTokenRateCC] = useState('')
  const [nilRate, setNilRate] = useState('')
  const [nilRateCC, setNilRateCC] = useState('')
  const [withdrawLimit, setWithdrawLimit] = useState(100000)
  const [transferFee, setTransferFee] = useState(0)
  const [nilTransferFee, setNilTransferFee] = useState(0)
  const [avgPropertySize, setAvgPropertySize] = useState(300000)
  const [ccDelay, setCcDelay] = useState(7)
  const [promoDelay, setPromoDelay] = useState(60)
  const [referPercentage, setReferPercentage] = useState(0)
  const [withdrawMin, setWithdrawMin] = useState(0)
  const [nilWithdrawMin, setNilWithdrawMin] = useState(0)
  const [maxtransactionFee, setMaxTransactionFee] = useState(0.01)

  useEffect(() => {
    (async () => {
      await loadSetting();
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (setting && setting.length > 0) {
      setting.forEach((field) => {
        if (field.label === 'token_rate') {
          setTokenRate(field.value)
        } else if (field.label === 'token_rate_cc') {
          setTokenRateCC(field.value)
        }else if (field.label === 'nil_rate') {
          setNilRate(field.value)
        } else if (field.label === 'nil_rate_cc') {
          setNilRateCC(field.value)
        } else if (field.label === 'withdraw_limit') {
          setWithdrawLimit(field.value)
        } else if (field.label === 'transfer_fee') {
          setTransferFee(field.value)
        } else if (field.label === 'avg_property_size') {
          setAvgPropertySize(field.value)
        } else if (field.label === 'cc_delay') {
          setCcDelay(field.value)
        } else if (field.label === 'promo_delay') {
          setPromoDelay(field.value)
        } else if (field.label === 'refer_bonus') {
          setReferPercentage(field.value)
        } else if (field.label === 'withdraw_min') {
          setWithdrawMin(field.value)
        } else if (field.label === 'nil_transfer_fee') {
          setNilTransferFee(field.value)
        } else if (field.label === 'nil_withdraw_min') {
          setNilWithdrawMin(field.value)
        } else if (field.label === 'max_transaction_fee') {
          setMaxTransactionFee(field.value)
        }
      })
    }
  }, [setting])

  const handleUpdate = async () => {
    await updateSetting({
      token_rate: tokenRate,
      token_rate_cc: tokenRateCC,
      nil_rate: nilRate,
      nil_rate_cc: nilRateCC,
      withdraw_limit: withdrawLimit,
      transfer_fee: transferFee,
      avg_property_size: avgPropertySize,
      cc_delay: ccDelay,
      promo_delay: promoDelay,
      refer_bonus: referPercentage,
      withdraw_min: withdrawMin,
      nil_transfer_fee: nilTransferFee,
      nil_withdraw_min: nilWithdrawMin, 
      max_transaction_fee: maxtransactionFee
    })
  }

  return (
    <Card>
      <Card.Body>
        <div className="form-group">
          <label>Door token rate</label>
          <input
            type="number"
            className="form-control"
            value={tokenRate}
            onChange={e => setTokenRate(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Door Token Price via Credit Card</label>
          <input
            type="number"
            className="form-control"
            value={tokenRateCC}
            onChange={e => setTokenRateCC(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>NIL token rate</label>
          <input
            type="number"
            className="form-control"
            value={nilRate}
            onChange={e => setNilRate(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>NIL Token Price via Credit Card</label>
          <input
            type="number"
            className="form-control"
            value={nilRateCC}
            onChange={e => setNilRateCC(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Withdraw Limits for Users</label>
          <input
            type="number"
            className="form-control"
            value={withdrawLimit}
            onChange={e => setWithdrawLimit(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>DOOR Transfer Fee</label>
          <input
            type="number"
            className="form-control"
            value={transferFee}
            onChange={e => setTransferFee(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>NIL Transfer Fee</label>
          <input
            type="number"
            className="form-control"
            value={nilTransferFee}
            onChange={e => setNilTransferFee(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Average Property Size (in USD)</label>
          <input
            type="number"
            className="form-control"
            value={avgPropertySize}
            onChange={e => setAvgPropertySize(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Credit Card Purchase Delay (in days)</label>
          <input
            type="number"
            className="form-control"
            value={ccDelay}
            onChange={e => setCcDelay(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Promo Code Delay (in days)</label>
          <input
            type="number"
            className="form-control"
            value={promoDelay}
            onChange={e => setPromoDelay(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Refer Percentage</label>
          <input
            type="number"
            className="form-control"
            value={referPercentage}
            onChange={e => setReferPercentage(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Withdraw Min Amount</label>
          <input
            type="number"
            className="form-control"
            value={withdrawMin}
            onChange={e => setWithdrawMin(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>NIL Withdraw Min Amount</label>
          <input
            type="number"
            className="form-control"
            value={nilWithdrawMin}
            onChange={e => setNilWithdrawMin(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Max Network Transaction Fee(ETH)</label>
          <input
            type="number"
            className="form-control"
            value={maxtransactionFee}
            onChange={e => setMaxTransactionFee(e.target.value)}
          />
        </div>
        <button type="button" className="btn btn-info" onClick={handleUpdate}>
          Update
        </button>
      </Card.Body>
    </Card>
  )
}

export default Setting
