import { APROGRESS} from '../types';

const initialState = {
    progress:false    
};

export default function notificationReducer(state = initialState, { type, payload }) {
    switch (type) {
        case APROGRESS.START:
            return {
                    progress: true
                };                
        case APROGRESS.STOP:
            return {
                    progress: false
                };                    
        default:
            return state;
    }
}