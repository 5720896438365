import { useDispatch, useSelector } from 'react-redux'

import { SupportService } from '../../services'
import {
  GET_SUPPORTS_INIT,
  GET_SUPPORTS_SUCCESS,
  GET_SUPPORT_BY_ID_SUCCESS,
  ADD_SUPPORT_SUCCESS,
  UPDATE_SUPPORT_SUCCESS,
  DELETE_SUPPORT_SUCCESS,
  NOTI_TYPE,
} from '../types'
import { useNotification } from './notification.hook'
import { useProgress } from "./progress.hook"

export const useSupport = () => {
  const dispatch = useDispatch()

  const { supports } = useSelector(({ support }) => support)
  const { setNewNotification } = useNotification()
  const { startProgress, stopProgress } = useProgress()

  const getSupports = async () => {
    try {
      dispatch({ type: GET_SUPPORTS_INIT })
      startProgress()
      const response = await SupportService.getSupports()
      dispatch({type: GET_SUPPORTS_SUCCESS, payload: response.data})
      stopProgress()
    } catch (error) {
      stopProgress()
    }
  }

  const getSupportById = async (id) => {
    try {
      startProgress()
      const response = await SupportService.getSupportById(id)
      dispatch({
        type: GET_SUPPORT_BY_ID_SUCCESS,
        payload: response.data.user,
      })
      stopProgress()
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to retrieve a support user.')
      stopProgress()
    }
  }

  const addSupport = async (params) => {
    try {
      startProgress()
      const response = await SupportService.addSupport(params)
      dispatch({
        type: ADD_SUPPORT_SUCCESS,
        payload: response.data.user,
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Added a new support user successfully.')
      stopProgress()
      return true
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to add a support user.')
      stopProgress()
      return false
    }
  }

  const updateSupport = async (id, params) => {
    try {
      startProgress()
      const response = await SupportService.updateSupport(id, params)
      dispatch({
        type: UPDATE_SUPPORT_SUCCESS,
        payload: response.data.user,
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Updated the support user successfully.')
      stopProgress()
      return true
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to update the support user.')
      stopProgress()
      return false
    }
  }

  const deleteSupport = async (id) => {
    try {
      startProgress()
      await SupportService.deleteSupport(id)
      dispatch({
        type: DELETE_SUPPORT_SUCCESS,
        payload: id,
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Deleted the support user successfully.')
      stopProgress()
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to delete the support user.')
      stopProgress()
    }
  }

  return {
    supports,
    getSupports,
    getSupportById,
    addSupport,
    updateSupport,
    deleteSupport,
  }
}
