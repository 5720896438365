import React from "react";
import { useProgress } from "../../../store/hooks";
import ReactLoading from 'react-loading';
import "./style.css";

const ProgressComponent = () => {
    const { progress } = useProgress();
    const type = "cubes";
    const color = "#6418C3";

    return (
        <>
        { progress && (
            <div className="progress-container">
                <ReactLoading type={type} color={color}/>
            </div>
        )}
        </>
    );
};

export default ProgressComponent;
