import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { usePromo } from '../../../../../store/hooks'

const AddPromoPage = () => {
  const history = useHistory()

  const { addPromo } = usePromo()

  const [form, setForm] = useState({
    code: '',
    percent: 0,
    is_active: true,
  })

  const handleChange = (event) => {
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }))
  }

  const handleIsActiveChange = (event) => {
    setForm((prev) => ({
      ...prev,
      is_active: event.target.checked,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const success = await addPromo(form)
    if (success) {
      history.push('/admin/promos')
    }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Promotion Code Information</Card.Title>
      </Card.Header>
      <Card.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Code
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="code"
                required
                value={form.code}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Percent
                <span className="required">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                name="percent"
                min="0"
                required
                value={form.percent}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Is Active
                <span className="required">*</span>
              </label>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="is_active"
                  checked={form.is_active}
                  onChange={handleIsActiveChange}
                />
                <label
                  className="custom-control-label"
                  htmlFor="is_active"
                >
                  Is Active
                </label>
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Add Promotion Code
          </button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default AddPromoPage
