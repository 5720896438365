import React, { useEffect, useState } from 'react'
import { addDays, subDays, format as formatDate, parseISO } from 'date-fns'

import DateRange from '../../../../components/DateRange'
import SearchBox from '../../../../components/SearchBox'
import Paginator from '../../../../components/Paginator'
import PageSize from '../../../../components/PageSize'
import TableHeader from '../../../../components/TableHeader'
import { useCrypto } from '../../../../../store/hooks'

const SyncData = () => {
  const { loadUnsyncedTransactions, syncTransactions } = useCrypto()

  const headers = [
    { name: 'number' },
    { name: 'from_bank', label: 'From Bank', sortBy: 'from_bank' },
    { name: 'to_bank', label: 'To Bank', sortBy: 'to_bank' },
    { name: 'amount', label: 'Amount', sortBy: 'amount' },
    { name: 'createdAt', label: 'Date', sortBy: 'createdAt' },
    { name: 'link', label: 'Link' },
  ]

  const [search, setSearch] = useState('')
  const [range, setRange] = useState([subDays(new Date(), 600), new Date()])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(['createdAt', 'desc'])

  const [ lastSyncDate, setLastSyncDate ] = useState(null)
  const [ transactions, setTransactions ] = useState([])
  const [ totalCount, setTotalCount ] = useState(0)

  useEffect(() => {
    (async () => {
      const { transactions: txs, last_sync_date } = await loadUnsyncedTransactions({
        search,
        range_start: range[0].toISOString(),
        range_end: addDays(range[1], 1).toISOString(),
        limit,
        page,
        sortBy: sort[0],
        sortOrder: sort[1],
      })
      setTransactions(txs.rows || [])

      let count = 0
      txs.count.forEach((c) => {
        count += c.count
      })
      setTotalCount(count)

      setLastSyncDate(last_sync_date)
    })()
  }, [search, range, page, limit, sort]) // eslint-disable-line

  const handleSync = async () => {
    const { last_sync_date } = await syncTransactions()
    setLastSyncDate(last_sync_date)
  }

  const handleSearch = (keyword) => {
    setSearch(keyword)
    setPage(1)
  }

  const handleRangeChange = async (startRange, endRange) => {
    setRange([startRange, endRange])
    setPage(1)
  }

  const handlePageSizeChange = (size) => {
    setLimit(size)
    setPage(1)
  }

  const renderTransactions = () => {
    return transactions.map((transaction, index) => (
      <tr key={transaction.id}>
        <td>{ index + 1 }</td>
        <td>{ transaction.from_bank?.bank_name }</td>
        <td>{ transaction.to_bank?.bank_name }</td>
        <td>{ transaction.total_amount }</td>
        <td>{ formatDate(parseISO(transaction.createdAt), 'MMMM dd, yyyy') }</td>
        <td></td>
      </tr>
    ))
  }

  return (
    <>
      <div className="form-head d-flex mb-md-3">
        <SearchBox onSearch={handleSearch} />
        <DateRange
          defaultRange={range}
          onChange={handleRangeChange}
        />
        {
          lastSyncDate && (
            <div className="last-sync-date">
              Last Sync Date: { formatDate(parseISO(lastSyncDate), 'yyyy-MM-dd HH:mm') }
            </div>
          )
        }
        <button
          type="button"
          className="btn bgl-primary text-primary btn-csv"
          onClick={handleSync}
        >
          Sync Now
        </button>
      </div>
      <div className="card">
        <div className="card-header">
          {
            lastSyncDate && (
              <h4 className="card-title">
                New Transactions from { formatDate(parseISO(lastSyncDate), 'yyyy-MM-dd HH:mm') }
              </h4>
            )
          }
        </div>
        <div className="card-body">
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <TableHeader
                  headers={headers}
                  currentSort={sort}
                  onChange={(newSort) => { setSort(newSort) }}
                />
                <tbody>
                  { renderTransactions() }
                </tbody>
              </table>
              <div className="table-footer">
                <Paginator
                  recordsCount={totalCount}
                  recordsPerPage={limit}
                  currentPage={page}
                  onChange={setPage}
                />
                <PageSize
                  currentSize={limit}
                  onChange={handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SyncData;
