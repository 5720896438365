import React, { useState } from 'react'
import { Card } from 'react-bootstrap'

import { useUser, useNotification } from '../../../../../store/hooks'
import { NOTI_TYPE } from '../../../../../store/types'

const ResetPassword = () => {
  const { resetPasswordAfterLogin } = useUser()
  const { setNewNotification } = useNotification()

  const [form, setForm] = useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  })

  const handleChange = (event) => {
    setForm(prev => ({
      ...prev,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSubmit = async () => {
    const { currentPassword, newPassword, newPasswordConfirm } = form
    if (currentPassword === '') {
      return setNewNotification(NOTI_TYPE.WARNING, 'Please enter the current password.')
    }
    if (newPassword === '') {
      return setNewNotification(NOTI_TYPE.WARNING, 'Please enter the new password.')
    }
    if (newPassword !== newPasswordConfirm) {
      return setNewNotification(NOTI_TYPE.WARNING, 'Passwords do not match.')
    }
    await resetPasswordAfterLogin({
      currentPassword,
      newPassword,
    })
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Reset Password</Card.Title>
      </Card.Header>
      <Card.Body>
        <form method="POST">
          <div className="form-group">
            <label>Current Password</label>
            <input
              type="password"
              className="form-control"
              name="currentPassword"
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>New Password</label>
            <input
              type="password"
              className="form-control"
              name="newPassword"
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input
              type="password"
              className="form-control"
              name="newPasswordConfirm"
              onChange={handleChange}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Change
          </button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default ResetPassword
