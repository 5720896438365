import HttpClient from './http-client'

export const LeadService = (function() {
  const getRequestLeads = async params => (
    await HttpClient.get('/property/request-leads', {
      params,
    })
  )

  const getRequestLeadDetail = async id => (
      await HttpClient.get(`/property/request-leads/${id}`)
  )

  const licenseRequestLead = async (data) => (
    await HttpClient.post(`/property/request-leads`, data)
  )

  const rateRequestLead = async (id, rate) => (
    await HttpClient.post(`/property/rate-request/${id}`, {
      rate,
    })
  )

  return {
    getRequestLeads,
    getRequestLeadDetail,
    licenseRequestLead,
    rateRequestLead,
  }
})()
