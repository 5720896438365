import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import { DropzoneArea } from 'material-ui-dropzone'

import PropertyRequestsSection from '../../../../components/PropertyRequestsSection'
import PropertyInfoSection from '../../../../components/PropertyInfoSection'

import { useProperty, useList, useProgress } from '../../../../../store/hooks'
import { uploadFiles } from '../../../../../utils'

const EditProperty = () => {
  const { id: propertyId } = useParams()
  const { property, getPropertyById, editProperty } = useProperty()
  const { lists, loadLists } = useList()
  const { startProgress, stopProgress } = useProgress()

  const [form, setForm] = useState({
    userFName: '',
    userLName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    is_verified: false,
    listIds: [],
    initFiles: [],
    newFiles: [],
    deletedFiles: [],
    dropZoneInit: false,
    is_owner: 1,
  })

  useEffect(() => {
    (async () => {
      await loadLists()
      await getPropertyById(propertyId)
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!property.user) {
      return
    }

    setForm({
      userFName: property?.user?.fname || '',
      userLName: property?.user?.lname || '',
      address1: property?.address1 || '',
      address2: property?.address2 || '',
      city: property?.city || '',
      state: property?.state || '',
      zip: property?.zip || '',
      is_verified: property?.is_verified === 2 || false,
      listIds: property?.property_list_map?.map(x => x.frn_listid) || [],
      initFiles: property && property.attachments ? property.attachments.map(x => x.attachment_url) : [],
      newFiles: [],
      deletedFiles: [],
      dropZoneInit: !!(property && property.attachments),
      is_owner: property?.is_owner ? 1 : 0,
    })
  }, [property])

  const handleChange = (name, value) => {
     setForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleFileChange = (newFile) => {
    setForm((prev) => ({...prev, newFiles: [...form.newFiles, ...newFile]}))
  }

  const handleFileDelete = (file) => {
    let newFiles = [...form.newFiles.filter(x => file.name !== x.name)]
    let deletedFiles = [...form.deletedFiles]
    if(property.attachments) {
      deletedFiles = [
        ...deletedFiles,
        ...property.attachments.filter(x => x.attachment_url.includes(file.name)).map(x => x.id)
      ]
    }
    setForm((prev) => ({...prev, newFiles, deletedFiles}))
  }

  const handleUpdate = async () => {
    try {
      startProgress()
      form.newFiles = await uploadFiles(form.newFiles)
      await editProperty(propertyId, {
        ...form,
        deletedListIds: property?.property_list_map?.filter(x => !form.listIds.includes(x.frn_listid)).map(x => x.frn_listid)
      })
      stopProgress()
      setForm((prev) => ({
        ...prev,
        deletedFiles: [],
        newFiles: [],
      }))
    } catch (e) {
      stopProgress()
    }
  }

  const handleListSelect = async (e) => {
    let listIds = [...form.listIds]
    const value = Number(e.target.value)

    if (e.target.checked) {
      if (!listIds.includes(value)) {
        listIds = [...listIds, value]
      }
    } else if (listIds.includes(value)) {
      const index = listIds.findIndex(i => i === value)
      listIds.splice(index, 1)
    }

    setForm((prev) => ({
      ...prev,
      listIds,
    }))

    startProgress()
    await editProperty(propertyId, {
      ...form,
      deletedListIds: property?.property_list_map?.filter(x => !listIds.includes(x.frn_listid)).map(x => x.frn_listid),
      listIds,
    })
    
    stopProgress()
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Earn Door Tokens</h4>
            </div>
            <div className="card-body">
              <h4>Are you looking for Home Services?</h4>
              <p>
                If you are looking for home services or products simply check one or more of the options
                available and begin earning door tokens. Every time an advertiser / service provider
                accesses your information you earn door token.
              </p>
              <h4>How does it work?</h4>
              <p>
                It's simple. In order for Advertisers to receive your information as a lead, they have
                to pay to license your data. The payment goes directly into your account. You will
                receive follow up messages from each service provider in hopes to win your business.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <PropertyRequestsSection
            lists={lists}
            selectedListIds={form.listIds}
            onChange={handleListSelect}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Property</h4>
        </div>
        <div className="card-body">
          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Property Owner First Name</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  value={form.userFName}
                />
              </div>
              <div className="form-group col-md-6">
                <label>Property Owner Last Name</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  value={form.userLName}
                />
              </div>
            </div>
            <PropertyInfoSection
              info={form}
              onChange={handleChange}
            />
            {
              form.dropZoneInit &&
              <DropzoneArea
                clearOnUnmount={true}
                acceptedFiles={[".pdf, .doc, .docx, .jpeg, .jpg, .png"]}
                dropzoneText="Upload Documents to Prove Ownership"
                initialFiles={form.initFiles}
                showFileNames
                onDrop={handleFileChange}
                maxFileSize={15000000}
                onDelete={handleFileDelete}
              />
            }
            <div className="form-group">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    type="radio"
                    name="is_owner"
                    className="form-check-input"
                    value={1}
                    checked={form.is_owner === 1}
                    onChange={() => { handleChange('is_owner', 1) }}
                  />
                  Owner
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    type="radio"
                    name="is_owner"
                    className="form-check-input"
                    value={0}
                    checked={form.is_owner === 0}
                    onChange={() => { handleChange('is_owner', 0) }}
                  />
                  Renter
                </label>
              </div>
            </div>
            {
              form.is_verified
              ?
              <button type="button" className="btn btn-info mt-4">
                Property has been verified. Unable to change.
              </button>
              :
              <button type="button" className="btn btn-info mt-4" onClick={handleUpdate}>
                Update
              </button>
            }
          </form>
        </div>
      </div>
    </>
  )
}

export default EditProperty;