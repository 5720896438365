import React from 'react'

const PropertyRequestsSection = ({ lists, selectedListIds, onChange }) => (
  <div className="card">
    <div className="card-header">
      <h4 className="card-title">Property Requests</h4>
    </div>
    <div className="card-body">
      <div className="alert alert-info">
        <strong>Note</strong> When you update any requests
        we will notify service providers in your area.
        If they choose to license your data you will receive
        Door Tokens into your account.
      </div>
      <form>
        <div className="form-group">
          {
            lists.map(list =>
              <div key={list.id} className="form-check mb-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`check-${list.id}`}
                  value={list.id}
                  checked={selectedListIds.includes(list.id)}
                  onChange={onChange}
                />
                <label className="form-check-label" htmlFor={`check-${list.id}`}>
                  { list.property_label }
                </label>
              </div>
            )
          }
        </div>
      </form>
    </div>
  </div>
)

export default PropertyRequestsSection
