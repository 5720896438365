import React from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

import Login from "./Login";
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Registration from './Registration';
import FullRegister from './FullRegister';
import EmailVerify from './EmailVerify'

export default function UnAuthPage() {
  return (
    <Switch>
      <Route path='/auth/email-verify/:token' exact component={EmailVerify} />
      <Route path='/signup' exact component={FullRegister} />
      <Route path='/auth/:path?/:path?' exact>
        <div className="mh100vh unauth-container">
          <Switch>
            <Route path='/auth/forgot-password' exact component={ForgotPassword} />
            <Route path='/auth/reset/:token' exact component={ResetPassword} />
            <Route path='/auth/register' exact component={Registration} />
            <Route path='/auth/login/:token?' exact component={Login} />
          </Switch>
        </div>
      </Route>
    </Switch>
  )
}
