import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { format as formatDate, parseISO } from 'date-fns'
import { Link } from 'react-router-dom'
import { useWallet } from '../../../../../store/hooks'
import swal from 'sweetalert'
import ExternalWalletModal from './ExternalWalletModal'

const ViewWallets = () => {
  const { banks, loadBanks, externalWallets, removeExternalWallet, addExternalWallet, loadExternalWallets } = useWallet()
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    (async () => {
      await loadBanks({token_symbol: "NIL"});
      await loadExternalWallets({token_symbol: "NIL"});
    })()
  }, []) // eslint-disable-line

  const renderBanks = () => {
    if (!banks.length) {
      return (
        <tr>
          <td colSpan={5} className="text-center">No wallets found.</td>
        </tr>
      )
    }

    return banks.map((bank, index) => (
      <tr key={bank.id}>
        <td>
          <Link to={`/dashboard/wallet/${bank.id}`}>
            {index + 1}
          </Link>
        </td>
        <td>
          <Link to={`/dashboard/wallet/${bank.id}`}>
            {parseFloat(bank.balance).toFixed(2)}
          </Link>

        </td>
        <td>
          {formatDate(parseISO(bank.createdAt), 'MM-dd-yyyy')}
        </td>
        <td>
          {bank.is_staking && <span className="badge badge-outline-success">Staked</span>}
          {!bank.is_locked && <span className="badge badge-outline-primary">Active</span>}
          {(!bank.is_staking && bank.is_locked) ? <span className="badge badge-outline-info" >Locked</span> : ''}
        </td>
        <td>
          {bank.lock_expire ? formatDate(parseISO(bank.lock_expire), 'MM-dd-yyyy') : ''}
        </td>
      </tr>
    ))
  }

  const handleExternalWalletAdd = async (address) => {
    await addExternalWallet(address)
    setModalVisible(false)
  }
  const handleExternalWalletRemove = async (address) => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to remove this external wallet?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await removeExternalWallet(address)
      }
    })
  }

  const renderExternalWallets = () => {
    let contents
    if (!externalWallets.length) {
      contents = (
        <tr>
          <td colSpan={2} className="text-center">
            No external wallets found.
          </td>
        </tr>
      )
    }
    contents = externalWallets.map(wallet => (
      <tr key={wallet.eth_address}>
        <td>
          {wallet.eth_address}
        </td>
        <td className="text-right">
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => { handleExternalWalletRemove(wallet.eth_address) }}
          >
            Remove
          </button>
        </td>
      </tr>
    ))
    return (
      <>
        <div className="d-flex flex-row-reverse mt-4 mb-3">
          <button
            type="button"
            className="btn btn-info"
            onClick={() => { setModalVisible(true) }}
          >
            Add new external wallet
          </button>
        </div>
        <Card>
          <Card.Header className="d-block d-sm-flex border-0 pb-0">
            <h4 className="fs-20 text-black">External Wallets</h4>
          </Card.Header>
          <Card.Body>
            <div className="table-responsive">
              <table className="table table-striped table-responsive-sm">
                <thead>
                  <tr>
                    <th>Address</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {contents}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
        <ExternalWalletModal
          show={modalVisible}
          onAdd={handleExternalWalletAdd}
          onHide={() => { setModalVisible(false) }}
        />
      </>
    )
  }

  return (
    <>
      
      <Card>
        <Card.Header>
          <Card.Title>My NIL Wallets</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <table className="table table-striped table-responsive-sm">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Balance</th>
                  <th>Created At</th>
                  <th>Status</th>
                  <th>Expiry Date</th>
                </tr>
              </thead>
              <tbody>
                {renderBanks()}
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
      {renderExternalWallets()}
    </>
  )
}

export default ViewWallets
