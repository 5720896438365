import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Card, Row, Col } from 'react-bootstrap'
import { format as formatDate, parseISO } from 'date-fns'

import { useUser, useProperty } from '../../../../../store/hooks/'

const rates = [
  { label: 'Good', value: 4 },
  { label: 'OK', value: 3 },
  { label: 'Poor', value: 2 },
  { label: 'Report as Fraud', value: 1 },
]

const LeadDetails = () => {
  const { id: leadId } = useParams()
  const { leadDetail, loadLeadDetail } = useUser()
  const { rateLead } = useProperty()

  useEffect(() => {
    (async () => {
      await loadLeadDetail(leadId)
    })()
  }, []) // eslint-disable-line

  const handleRate = async (event) => {
    if (leadDetail.property_user_map.length) {
      await rateLead(leadDetail.property_user_map[0].id, parseInt(event.target.value, 10))
    }
  }

  const renderRateSection = () => {
    let currentRate = -1
    if (leadDetail.property_user_map.length) {
      currentRate = parseInt(leadDetail.property_user_map[0].rate, 10)
    }
    return (
      <Card>
        <Card.Header>
          <Card.Title>Rate This Lead</Card.Title>
        </Card.Header>
        <Card.Body>
          <form>
            <div className="form-group">
              {
                rates.map(rate => (
                  <div key={rate.value} className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        type="radio"
                        name="rate"
                        className="form-check-input"
                        value={rate.value}
                        defaultChecked={rate.value === currentRate}
                        onChange={handleRate}
                      />
                      { rate.label }
                    </label>
                  </div>
                ))
              }
            </div>
          </form>
        </Card.Body>
      </Card>
    )
  }

  if (!leadDetail) {
    return null
  }

  return (
    <Row>
      <Col lg={6}>
        <Card>
          <Card.Header>
            <Card.Title>Lead Contact Information</Card.Title>
          </Card.Header>
          <Card.Body>
            <div>
              { leadDetail.user?.fname} { leadDetail.user?.lname }<br />
              { leadDetail.address1 }<br />
              { leadDetail.address2 ? (<div>{ leadDetail.address2 }</div>) : null }
              <div>
                { leadDetail.city ? `${leadDetail.city}, `  : '' }
                { leadDetail.state ? `${leadDetail.state}, `  : '' }
                { leadDetail.zip }
              </div>
              Phone: {leadDetail.user?.phone}<br />
              Email: {leadDetail.user?.email}<br />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <Card.Header>
            <Card.Title>Current Requests</Card.Title>
          </Card.Header>
          <Card.Body>
            <form>
              <div className="form-group">
                {
                  leadDetail.property_list_map && leadDetail.property_list_map.map(x =>
                  <div className="form-check mb-2" key={x.id}>
                    <input type="checkbox" className="form-check-input" checked readOnly/>
                    <label className="form-check-label">{ x.list.property_label }</label>
                  </div>)
                }
              </div>
            </form>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <Card.Header>
            <Card.Title>Data License</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="basic-form">
              You licensed this data for <b>{leadDetail.amountPaid} Door Tokens</b> on&nbsp;
              {
                leadDetail.property_user_map &&
                formatDate(parseISO(leadDetail.property_user_map[0].createdAt), 'MM-dd-yyyy HH:mm')
              }.
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6}>
        { renderRateSection() }
      </Col>
    </Row>
  )
}

export default LeadDetails
