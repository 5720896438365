import HttpClient from "./http-client";

export const ListUserMapService = (function() {
    const getSubscriptionListMap = async() => {
        return await HttpClient.get("/listUserMap");
    };

    const unSubscribeFromList = async(listID) => {
        return await HttpClient.delete(`/listUserMap/${listID}`);
    };

    const subscribeToList = async(data) => {
        return await HttpClient.post(`/listUserMap`, data);
    };

    return {
        getSubscriptionListMap,
        unSubscribeFromList,
        subscribeToList
    };
})();