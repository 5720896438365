import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { format as formatDate, parseISO, addDays, isBefore } from 'date-fns'
import swal from 'sweetalert'

import { useStaking } from '../../../../../store/hooks'

const MyStaking = () => {
  const { stakings, getStakings, endStaking } = useStaking()

  useEffect(() => {
    (async () => {
      await getStakings()
    })()
  }, []) // eslint-disable-line

  const handleEnd = async (stakingId) => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to end this staking?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (confirmed) => {
      if (confirmed) {
        await endStaking(stakingId)
      }
    })
  }

  const renderStakings = () => {
    if (!stakings.length) {
      return (
        <tr>
          <td colSpan={6} className="text-center">
            No staking found
          </td>
        </tr>
      )
    }

    return stakings.map((staking) => {
      const expiredAt = addDays(parseISO(staking.started_at), staking.staking_pool.duration)
      const endAmount = (1 + staking.staking_pool.percent / 100) * staking.bank.balance
      return (
        <tr key={staking.id}>
          <td>
            { staking.staking_pool.name }
            ({ parseFloat(staking.staking_pool.percent).toFixed(2) }% / { staking.staking_pool.duration }days)
          </td>
          <td>
            { staking.bank.balance }
          </td>
          <td>
            { endAmount.toFixed(2) }
          </td>
          <td>
            { formatDate(parseISO(staking.started_at), 'MM-dd-yyyy') }
          </td>          
          <td>
            { staking.is_ended ? 'Ended' : formatDate(expiredAt, 'MM-dd-yyyy') }
          </td>
          <td>
            { staking.is_active ? 'Active' : 'Inactive' }
          </td>         
        </tr>
      )
    })
  }

  return (
    <>
      <div className="d-flex flex-row-reverse mb-md-3">
        <Link to="/dashboard/add-staking" className="btn btn-primary">
          Add new staking
        </Link>
      </div>
      <Card>
        <Card.Header>
          <Card.Title>My Stakes</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <thead>
                  <tr>
                    <th>Staking Pool</th>
                    <th>Staked Amount</th>
                    <th>Amount received</th>
                    <th>Start Date</th>
                    <th>End Date</th>                    
                    <th>Is Active</th>                    
                  </tr>
                </thead>
                <tbody>
                  { renderStakings() }
                </tbody>
              </table>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default MyStaking
