import {
    LIST_MAP_INIT, LIST_MAP_SUCCESS, LIST_MAP_FAILED, UNSUBSCRIBE_LIST_MAP
} from '../types';

const initialState = {
    data: {
        listMap: [],
    },
    initialized: false,
    loading: false,
    error: '',
};

export default function listMapReducer(state = initialState, {type, payload}) {
    switch (type) {
        case LIST_MAP_INIT:
            return {
                ...state,
                data: {
                    listMap: [],
                },
                loading: false,
                initialized: true,
                error: '',
            };
        case LIST_MAP_SUCCESS:
            return {
                ...state,
                data: {
                    listMap: payload.listMap,
                },
                loading: false,
                error: '',
            };
        case LIST_MAP_FAILED:
            return {
                ...state,
                data: {
                    listMap: [],
                },
                loading: false,
                error: payload,
            };
        case UNSUBSCRIBE_LIST_MAP:
            let {listID} = payload;
            let data = [...state.data.listMap];
            let index = data.findIndex(d => d.id === listID);
            data.splice(index, 1);
            return {
                ...state,
                data: {
                    listMap: data,
                },
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
}