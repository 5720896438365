export const SEARCH_USERS_INIT = 'SEARCH_USERS_INIT';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USER_DELETE = 'SEARCH_USER_DELETE';
export const SEARCH_USER_ACTIVATE = 'SEARCH_USER_ACTIVATE';
export const SEARCH_USERS_FAILED = 'SEARCH_USERS_FAILED';
export const SEARCH_UNVERIFIED_USERS_INIT = 'SEARCH_UNVERIFIED_USERS_INIT';
export const SEARCH_UNVERIFIED_USERS_SUCCESS = 'SEARCH_UNVERIFIED_USERS_SUCCESS';
export const SEARCH_UNVERIFIED_USERS_FAILED = 'SEARCH_UNVERIFIED_USERS_FAILED';
export const SEARCH_UNVERIFIED_USER_DELETE = 'SEARCH_UNVERIFIED_USER_DELETE';
export const SEARCH_VERIFIED_USERS_INIT = 'SEARCH_VERIFIED_USERS_INIT'
export const SEARCH_VERIFIED_USERS_SUCCESS = 'SEARCH_VERIFIED_USERS_SUCCESS'
export const SEARCH_VERIFIED_USERS_FAILED = 'SEARCH_VERIFIED_USERS_FAILED'
export const GET_SEARCH_USER_INIT = 'GET_SEARCH_USER_INIT';
export const GET_SEARCH_USER_SUCCESS = 'GET_SEARCH_USER_SUCCESS';
export const GET_SEARCH_USER_FAILED = 'GET_SEARCH_USER_FAILED';

export const USER_BLOCK = 'USER_BLOCK'
export const USER_UNBLOCK = 'USER_UNBLOCK'

export const USER_REJECT = 'USER_REJECT'

export const USER_UPDATE_BY_ADMIN = 'USER_UPDATE_BY_ADMIN'