import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { addDays, subDays, format as formatDate, parseISO } from 'date-fns'

import DateRange from '../../../../components/DateRange'
import SearchBox from '../../../../components/SearchBox'
import PageSize from '../../../../components/PageSize'
import Paginator from '../../../../components/Paginator'
import TableHeader from '../../../../components/TableHeader'
import { useUsers } from '../../../../../store/hooks'
import { UsersService } from '../../../../../services'
import { exportUsers } from '../../../../../utils'

const Users = () => {
  const { searchedUsers, searchUsers, loginAs, generateDOORId } = useUsers()

  const headers = [
    { name: 'name', label: 'Name', sortBy: 'name' },
    { name: 'doorId', label: 'DoorId' },
    { name: 'status', label: 'Status' },
    { name: 'createdAt', label: 'Date Joined', sortBy: 'createdAt' },
    { name: 'action' },
  ]

  const [search, setSearch] = useState('')
  const [range, setRange] = useState([subDays(new Date(), 700), new Date()])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(['createdAt', 'desc'])

  useEffect(() => {
    (async () => {
      await searchUsers({
        search,
        range_start: range[0].toISOString(),
        range_end: addDays(range[1], 1).toISOString(),
        limit,
        page: page,
        sortBy: sort[0],
        sortOrder: sort[1],
      })
    })()
  }, [search, range, page, limit, sort]) // eslint-disable-line

  const handleSearch = (keyword) => {
    setSearch(keyword)
    setPage(1)
  }

  const handleRangeChange = async (startRange, endRange) => {
    setRange([startRange, endRange])
    setPage(1)
  }

  const handleGenerateDoorId = async (startRange, endRange) => {
    await generateDOORId({
      search,
      range_start: range[0].toISOString(),
      range_end: addDays(range[1], 1).toISOString(),
      limit,
      page: page,
      sortBy: sort[0],
      sortOrder: sort[1],
    })
    setPage(1)
  }

  const handlePageSizeChange = (size) => {
    setLimit(size)
    setPage(1)
  }

  const handleExport = async () => {
    try {
      const response = await UsersService.exportUsers()
      if (response?.data?.length > 0) {
        exportUsers(response.data)
      }
    } catch (e) {
      //
    }
  }

  // Login as another user.
  const handleLogin = userId => async () => {
    const token = await loginAs(userId)
    if (token) {
      window.open(`${window.location.origin}/#/auth/login/${token}`, '_blank')
    }
  }

  const renderUsers = () => {
    if (!searchedUsers.data || !searchedUsers.data.count) {
      let text = 'Loading...'
      if (!searchedUsers.loading) {
        text = 'No users found.'
      }
      return (
        <tr>
          <td colSpan={4} className="text-center">{text}</td>
        </tr>
      )
    }

    return searchedUsers.data.rows.map(user => (
      <tr key={user.id}>
        <td><Link to={`/admin/edit-user/${user.id}`}>{`${user.fname} ${user.lname}`}</Link></td>
        <td>{`${user?.doorid}`}</td>
        <td>
          {
            user.is_deleted
            ? <span className="badge badge-danger">De-activated</span>
            : <div>
              {
                user.verified === 1
                ? <span className="badge badge-success">Verified</span>
                : (
                  user.verified === 0
                    ? <span className="badge badge-danger">Not Verified</span>
                    : <span className="badge badge-info">Pending To Review</span>
                )
              }
              </div>
          }
        </td>
        <td>{ formatDate(parseISO(user.createdAt), 'MM-dd-yyyy HH:mm') }</td>
        <td>
          <button
            type="button"
            className="btn btn-info btn-xs"
            onClick={handleLogin(user.id)}
          >
            Login
          </button>
        </td>
      </tr>
    ))
  }

  return (
    <>
      <div className="form-head d-flex mb-md-3">
        <SearchBox onSearch={handleSearch} />
        <DateRange
          defaultRange={range}
          onChange={handleRangeChange}
        />
        <button type="button" className="btn bgl-primary text-primary btn-csv" onClick={handleGenerateDoorId}>          
          Generate DoorID
        </button>
        &nbsp;
        <button type="button" className="btn bgl-primary text-primary" onClick={handleExport}>
          <svg className="mr-3 scale5" width="16" height="16" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.99989 23.6666H15.9999C16.6439 23.6666 17.1666 23.144 17.1666 22.5C17.1666 21.856 16.6439 21.3333 15.9999 21.3333H1.99989C1.35589 21.3333 0.833225 21.856 0.833225 22.5C0.833225 23.144 1.35589 23.6666 1.99989 23.6666ZM5.49989 9.66665V3.24998C5.49989 2.47648 5.80674 1.73447 6.3539 1.18731C6.90107 0.64014 7.64306 0.333313 8.41656 0.333313H9.58323C10.3567 0.333313 11.0987 0.64014 11.6459 1.18731C12.1919 1.73447 12.4999 2.47648 12.4999 3.24998V9.66665H15.9999C16.4712 9.66665 16.8971 9.95013 17.0779 10.3865C17.2576 10.8228 17.1584 11.3245 16.8247 11.6581L9.82473 18.6581C9.36856 19.1131 8.63006 19.1131 8.17506 18.6581L1.17506 11.6581C0.841391 11.3245 0.741063 10.8228 0.921897 10.3865C1.10273 9.95013 1.52739 9.66665 1.99989 9.66665H5.49989ZM13.1836 12H11.3332C10.6892 12 10.1666 11.4773 10.1666 10.8333C10.1666 10.8333 10.1666 5.95198 10.1666 3.24998C10.1666 3.09481 10.1047 2.94664 9.99507 2.83698C9.88657 2.72731 9.73722 2.66665 9.58323 2.66665C9.20173 2.66665 8.79806 2.66665 8.41656 2.66665C8.26139 2.66665 8.11324 2.72731 8.00357 2.83698C7.89507 2.94664 7.83323 3.09481 7.83323 3.24998V10.8333C7.83323 11.4773 7.31056 12 6.66656 12H4.81623L8.99989 16.1836L13.1836 12Z" fill="#6418C3"></path>
          </svg>
          Save to CSV
        </button>
      </div>
      <Card>
        <Card.Header>
          <Card.Title>Users</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <TableHeader
                  headers={headers}
                  currentSort={sort}
                  onChange={(newSort) => { setSort(newSort) }}
                />
                <tbody>
                  { renderUsers() }
                </tbody>
              </table>
              <div className="table-footer">
                <Paginator
                  recordsCount={searchedUsers.data?.count || 0}
                  recordsPerPage={limit}
                  onChange={setPage}
                  currentPage={page}
                />
                <PageSize
                  currentSize={limit}
                  onChange={handlePageSizeChange}
                />
                </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default Users
