import { ANOTI, NOTI_TYPE} from '../types';

const initialState = {
    new_noti:{
        type: NOTI_TYPE.INFO,    
        description: "",    
    }    
};

export default function notificationReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ANOTI.INIT:
            return {
                ...state,
                new_noti:{
                    type: NOTI_TYPE.INFO,    
                    description: "",    
                }    
            };
        case ANOTI.NEW:
            return {
                ...state,
                new_noti:{
                    type: payload.type,    
                    description: payload.description,    
                }    
            };        
        default:
            return state;
    }
}