import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'

import { useList } from '../../../../../store/hooks'

const UserAccessModal = ({ show, onAdd, onHide }) => {
  const { getUsers } = useList()

  const [selectedUserId, setSelectedUserId] = useState('')
  const [userList, setUserList] = useState([])

  useEffect(() => {
    (async () => {
      if (show) {
        const users = await getUsers()
        setSelectedUserId('')
        setUserList(users)
      }
    })()
  }, [show]) // eslint-disable-line

  const handleAdd = () => {
    onAdd(selectedUserId)
    onHide()
  }

  return (
    <Modal show={show} centered>
      <Modal.Header closeButton onHide={onHide}>
        <Modal.Title id="contained-modal-title-vcenter">
          Allow New User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <select
          className="form-control"
          value={selectedUserId}
          onChange={(event) => { setSelectedUserId(event.target.value) }}
        >
          <option value="">- Select user -</option>
          {
            userList.map(user => (
              <option key={user.id} value={user.id}>
                { user.fname } { user.lname } ({ user.email })
              </option>
            ))
          }
        </select>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Cancel</Button>
        <Button
          disabled={!selectedUserId}
          onClick={handleAdd}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserAccessModal
