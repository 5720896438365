import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import logo from '../../../images/registration-brand-logo.png'
import { useUser, useProgress } from '../../../store/hooks'
import { LOCAL_STORAGE_KEY, ROLE_ADMIN, ROLE_USER, ROLE_SUPPORT } from '../../../consts'
import { DepositPoolConfig } from '../../../config/';
import OtpInput from 'react-otp-input';

const Login = ({ history }) => {
  const { userLogin, getUser, verify2FA } = useUser();
  const [ userNumber, setUserNumber ] = useState("");
  const { startProgress, stopProgress } = useProgress();
  const { token } = useParams()

  const [loginData, setLoginData] = useState({});
  const [isNotRobo, setIsNotRobo] = useState();
  const [twoFAuth, setTwoFAuth] = useState({ is_2fa: false, otpScreen: false, otp: "" });

  useEffect(() => {
    (async () => {
      const { JWT_TOKEN, USER } = LOCAL_STORAGE_KEY;

      if (token) {
        // Token is passed from admin panel to log on
        // as another user, so it always goes to My Wallet page.
        sessionStorage.setItem(JWT_TOKEN, token)
        window.location.href = `${window.location.origin}/#/dashboard/home`;
        window.location.reload(false);

      } else if (localStorage.getItem(JWT_TOKEN)) {
        if (localStorage.getItem(USER)) {
          try {
            const user = JSON.parse(localStorage.getItem(USER))
            const userRole = user?.role?.description || ROLE_USER
            if (userRole === ROLE_ADMIN || userRole === ROLE_SUPPORT) {
              history.push('/admin/search-users')
              return
            }
          } catch (e) {
            //
          }
        }
        history.push('/dashboard/home')
      }
    })()
  })

  const handleBlur = (e) => {
    const newLoginData = { ...loginData };
    newLoginData[e.target.name] = e.target.value;
    setLoginData(newLoginData);
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    startProgress();
    const user = await userLogin(loginData.email, loginData.password);
    if(user) {
      setTwoFAuth({ otpScreen: true });
      stopProgress();
      setUserNumber(user?.userNumber)
    }
  }

  const submitOtp = async () => {
    startProgress();
    const user = await verify2FA(twoFAuth.otp, loginData.email);
    stopProgress();
    if (user) {
      const userRole = user?.role?.description || ROLE_USER
      if (userRole === ROLE_ADMIN || userRole === ROLE_SUPPORT) {
        history.push('/admin/search-users')
      } else {
        history.push('/dashboard/home')
      }
    }
  }

  const handleChangeOTP = (value) => {
    setTwoFAuth({ ...twoFAuth, otp: value });
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-12 col-sm-7 col-md-5 col-lg-4 authfy-panel-left">
          <div className="brand-logo text-center">
            <img src={logo} width="150" alt="Door Coin" />
          </div>
          <div className="authfy-login">
            <div className="authfy-panel panel-login text-center active">
              <div className="authfy-heading">
                <h3 className="auth-title">Login to your account</h3>
                <p>
                  Don’t have an account?&nbsp;
                  <Link className="text-primary" to="/auth/register">
                    <strong>Sign Up Free!</strong>
                  </Link>
                </p>
              </div>
              <div className="row loginOr">
                <div className="col-xs-12 col-sm-12">
                  <span className="spanOr">or</span>
                </div>
              </div>
              {/* twoFAuth.is_2fa && */}
              {!twoFAuth.is_2fa && !twoFAuth.otpScreen ?
                <div className="row">
                  <div className="col-xs-12 col-sm-12">
                    <form onSubmit={(e) => submitHandler(e)}>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control email"
                          placeholder="Email address"
                          name="email"
                          required
                          onChange={handleBlur}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control password"
                          placeholder="Password"
                          name="password"
                          required
                          onChange={handleBlur}
                        />
                      </div>
                      <div className="form-group d-flex justify-content-between">
                        <Link to="/auth/forgot-password">
                          Forgot password?
                        </Link>
                      </div>
                      <div className="form-group d-flex justify-content-between">
                        <ReCAPTCHA sitekey={DepositPoolConfig.RECAPCTHA_V2_API_KEY} onChange={setIsNotRobo} />
                      </div>
                      <div className="form-group">
                        <button disabled={!isNotRobo} className="btn btn-lg btn-primary btn-block" type="submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                :
                <div className="twofa-container">
                  <div className="twofa-header">
                    Verify Phone
                  </div>
                  <div className="twofa-title">
                    Code is send to *******{userNumber?.substr(userNumber?.length - 3)}
                  </div>
                  <div className="twofa-otp">
                    <OtpInput
                      value={twoFAuth.otp}
                      className="otp-input"
                      inputStyle={"otp-input-text"}
                      onChange={handleChangeOTP}
                      numInputs={4}
                      separator={<span>-</span>}
                    />
                  </div>
                  <div>
                    <div className="twofa-title">
                      Didn't recieve code? <b className="bold-text">Request again</b>
                    </div>
                  </div>
                  <div className="form-group">
                    <button disabled={twoFAuth?.otp?.length !== 4} onClick={submitOtp} className="btn btn-lg btn-primary btn-block" type="submit">
                      Login
                    </button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="col-md-7 col-lg-8 authfy-panel-right hidden-xs hidden-sm">
        </div>
      </div>
    </div>
  )
}

export default Login
