import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card, Dropdown } from 'react-bootstrap'
import cls from 'classnames'
import swal from 'sweetalert'
import { format as formatDate, parseISO } from 'date-fns'

import SearchBox from '../../../../components/SearchBox'
import Paginator from '../../../../components/Paginator'
import PageSize from '../../../../components/PageSize'
import TableHeader from '../../../../components/TableHeader'
import { useProperty, useUser } from '../../../../../store/hooks'

const getFullAddress = (address) => {
  const includedFields = [
    "parcel_number",
    "address1",
    "address2",
    "city",
    "state",
    "zip"
  ]
  return includedFields
    .map((x) => address[x])
    .filter(x => x)
    .join(', ')
}

const MyProperty = () => {
  const { properties, getMyProperties, deleteProperty, requestPropertyVerification } = useProperty()
  const { user } = useUser();
  const history = useHistory()

  const headers = [
    { name: 'address', label: 'Address', sortBy: 'address' },
    { name: 'status', label: 'Status' },
    { name: 'updatedAt', label: 'Last Updated At', sortBy: 'updatedAt' },
    { name: 'market_value', label: 'Market Value', sortBy: 'market_value' },
    { name: 'action' },
  ]

  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(['updatedAt', 'desc'])

  useEffect(() => {
    (async () => {
      await getMyProperties({
        search,
        limit,
        page,
        sortBy: sort[0],
        sortOrder: sort[1],
      })
    })()
  }, [search, page, limit, sort]) // eslint-disable-line

  const handleSearch = (keyword) => {
    setSearch(keyword)
    setPage(1)
  }

  const handlePageSizeChange = (size) => {
    setLimit(size)
    setPage(1)
  }

  const handleDeleteClick = async (PropertyID) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this property!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if(willDelete) {
        await deleteProperty(PropertyID);
      }
    })
  }

  const requestVerification = async (property) => {
    // check if account is verified
    if (user && user.verified === 0) {
      swal({
        title: '',
        text: "Please verify your identity first.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willVerify) => {
        if(willVerify) {
          history.push('/dashboard/verify');
        }
      })
    } else if (user && user.verified === 2) {
      swal({
        title: "",
        text: "Your account is on pending now. Please wait until your account is approved.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
    } else {
      await requestPropertyVerification(property.id)
    }
  }

  const renderProperties = () => {
    if (!properties || !properties.rows.length) {
      return (
        <tr>
          <td colSpan={5} className="text-center">No properties found.</td>
        </tr>
      )
    }

    return properties.rows.map((element) => (
      <tr key={element.id}>
        <td>
          <Link to={`/dashboard/edit-property/${element.id}`}>
            { getFullAddress(element) }
          </Link>
        </td>
        <td>
          <span
            className={cls("badge", {
              "badge-success": element.is_verified === 2,
              "badge-info": element.is_verified === 1,
              "badge-danger": element.is_verified === 0,
            })}
          >
            { (element.is_verified === 2) ? "Verified" : (element.is_verified === 1) ? "Pending To Review" : "Not Verified" }
          </span>
        </td>
        <td>
          { formatDate(parseISO(element.updatedAt), 'MM-dd-yyyy HH:mm') }
        </td>
        <td>
          { element.market_value ? `${element.market_value}` : 'Processing' }
        </td>
        <td>
          <Dropdown drop="left">
            <Dropdown.Toggle
              variant="success"
              type="button"
              className="light sharp"
            >
              <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {element.is_verified === 0 &&
                <Dropdown.Item onClick={() => requestVerification(element)}>
                  Request Verification
                </Dropdown.Item>
              }
              <Dropdown.Item as={Link} to={`/dashboard/edit-property/${element.id}`}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteClick(element.id)}>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    ))
  }

  return (
    <>
      <div className="alert alert-primary notification">
        <p>
          If you are a property owner or agent you can add
          one or more properties to your account. Each verified property will
          earn your rewards. You receive rewards for the initial registration
          of the property, and you also receive rewards for additional data
          you provide related to what home services you may need in the near future.
        </p>
        <p>
          To maximize your Door Token rewards, it is important
          to keep each property up to date. The more data you license, the more you earn.
        </p>
      </div>
      <div className="form-head d-flex mb-md-3">
        <SearchBox onSearch={handleSearch} />
      </div>
      <Card>
        <Card.Header>
          <Card.Title>Property</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <TableHeader
                  headers={headers}
                  currentSort={sort}
                  onChange={(newSort) => { setSort(newSort) }}
                />
                <tbody>
                  { renderProperties() }
                </tbody>
              </table>
              <div className="table-footer">
                <Paginator
                  recordsCount={properties?.count || 0}
                  recordsPerPage={limit}
                  currentPage={page}
                  onChange={setPage}
                />
                <PageSize
                  currentSize={limit}
                  onChange={handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default MyProperty;
