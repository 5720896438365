import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { addDays, subDays, format as formatDate, parseISO } from 'date-fns'
import { Card } from 'react-bootstrap'

import DateRange from '../../../../components/DateRange'
import SearchBox from '../../../../components/SearchBox'
import Paginator from '../../../../components/Paginator'
import PageSize from '../../../../components/PageSize'
import TableHeader from '../../../../components/TableHeader'
import { useUsers } from '../../../../../store/hooks'

const Users = () => {
  const {unVerifiedUsers, searchUnVerifiedUsers} = useUsers()

  const headers = [
    { name: 'name', label: 'Name', sortBy: 'name' },
    { name: 'status', label: 'Status' },
    { name: 'createdAt', label: 'Date Joined', sortBy: 'createdAt' },
  ]

  const [search, setSearch] = useState('')
  const [range, setRange] = useState([subDays(new Date(), 700), new Date()])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(['createdAt', 'desc'])

  useEffect(() => {
    (async () => {
      await searchUnVerifiedUsers({
        search,
        range_start: range[0].toISOString(),
        range_end: addDays(range[1], 1).toISOString(),
        page,
        limit,
        sortBy: sort[0],
        sortOrder: sort[1],
      })
    })()
  }, [search, range, page, limit, sort]) // eslint-disable-line

  const handleSearch = (keyword) => {
    setSearch(keyword)
    setPage(1)
  }

  const handleRangeChange = async (startRange, endRange) => {
    setRange([startRange, endRange])
    setPage(1)
  }

  const handlePageSizeChange = (size) => {
    setLimit(size)
    setPage(1)
  }

  const renderUsers = () => {
    if (!unVerifiedUsers.data || !unVerifiedUsers.data.count) {
      let text = 'Loading...'
      if (!unVerifiedUsers.loading) {
        text = 'No users found.'
      }
      return (
        <tr>
          <td colSpan={3} className="text-center">{text}</td>
        </tr>
      )
    }

    return unVerifiedUsers.data.rows.map(user => (
      <tr key={user.id}>
        <td><Link to={`/admin/edit-user/${user.id}`}>{`${user.fname} ${user.lname}`}</Link></td>
        <td>
          {
            user.verified === 0 ? (
              <span className="badge badge-danger">Not Verified</span>
            ) : (
              <span className="badge badge-info">Pending</span>
            )
          }
        </td>
        <td>
          { formatDate(parseISO(user.createdAt), 'MM-dd-yyyy HH:mm') }
        </td>
      </tr>
    ))
  }

  return (
    <>
      <div className="form-head d-flex mb-md-3">
        <SearchBox onSearch={handleSearch} />
        <DateRange
          defaultRange={range}
          onChange={handleRangeChange}
        />
      </div>
      <Card>
        <Card.Header>
          <Card.Title>Unverified Users</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <TableHeader
                  headers={headers}
                  currentSort={sort}
                  onChange={(newSort) => { setSort(newSort) }}
                />
                <tbody>
                  { renderUsers() }
                </tbody>
              </table>
              <div className="table-footer">
                <Paginator
                  recordsCount={unVerifiedUsers.data?.count || 0}
                  recordsPerPage={limit}
                  currentPage={page}
                  onChange={setPage}
                />
                <PageSize
                  currentSize={limit}
                  onChange={handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default Users
