import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { format as formatDate, parseISO } from 'date-fns'
import { Card } from "react-bootstrap";

import { useCrypto } from '../../../../../store/hooks'

import TransferModal from './TransferModal'

const AdminWalletDetail = () => {
  const { id: walletID } = useParams()
  const { crypto, loadCryptoData, sendToken, loadTransactions } = useCrypto()
  const [ curWallet, setCurWallet ] = useState()
  const [ isTransferVisible, setIsTransferVisible ] = useState(false)
  const [ transactions, setTransactions ] = useState([])

  useEffect(() => {
    (async () => {
      await loadCryptoData()

      const txs = await loadTransactions(walletID)
      setTransactions(txs || [])
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!crypto || !crypto.rows.length) {
      return
    }
    const index = crypto.rows.findIndex(record => record.id === parseInt(walletID, 10))
    if (index !== -1) {
      setCurWallet(crypto.rows[index])
    }
  }, [crypto]) // eslint-disable-line

  const handleSendToken = async (address, amount) => {
    if (curWallet) {
      await sendToken(curWallet.id, address, amount)
    }
    setIsTransferVisible(false)
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Wallet Information</Card.Title>
        </Card.Header>
        <Card.Body>
          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Bank Name</label>
                <input type="text" className="form-control" readOnly defaultValue={curWallet?.bank_name} />
              </div>
                <div className="form-group col-md-6">
                <label>Wallet Name</label>
                <input type="text" className="form-control" readOnly defaultValue={curWallet?.wallet.wallet_name} />
              </div>
              <div className="form-group col-md-6">
                <label>Balance</label>
                <input type="text" className="form-control" readOnly defaultValue={curWallet?.balance} />
              </div>
              <div className="form-group col-md-6">
                <label>Blockchain Address</label>
                <input type="text" className="form-control" readOnly defaultValue={curWallet?.wallet.eth_address}/>
              </div>
              <div className="form-group col-md-6">
                <label>Blockchain Private Key</label>
                <input type="text" className="form-control" readOnly defaultValue={curWallet?.wallet.eth_private_key}/>
              </div>
            </div>
            <a
              href={`${process.env.REACT_APP_ETHER_URL}address/` +  curWallet?.wallet.eth_address}
              type="button"
              className="btn btn-info"
              rel="noreferrer"
              target="_blank"
            >
              View on Ethereum Network
            </a>&nbsp;
            <button
              type="button"
              className="btn btn-info"
              onClick={() => { setIsTransferVisible(true) }}
            >
              Send DoorToken
            </button>
          </form>
          <TransferModal
            show={isTransferVisible}
            onSend={handleSendToken}
            onHide={() => { setIsTransferVisible(false) }}
          />
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>Transaction Details</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <table className="table table-striped table-responsive-sm">
              <thead>
                <tr>
                  <th></th>
                  <th>From</th>
                  <th>To</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  transactions.map((transaction, index) => (
                    <tr key={transaction.id}>
                      <td>{ index + 1 }</td>
                      <td>{ transaction.from_bank?.bank_name }</td>
                      <td>{ transaction.to_bank?.bank_name }</td>
                      <td>{ transaction.amount }</td>
                      <td>{ formatDate(parseISO(transaction.createdAt), 'MMMM dd, yyyy') }</td>
                      <td>{ transaction.sync_status ? 'Synced' : '' }</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default AdminWalletDetail;
