import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import StripeCheckout from 'react-stripe-checkout'

import { NOTI_TYPE } from '../../../../../store/types'
import { useUser, useWallet, useNotification, useSetting } from '../../../../../store/hooks'
import DepositByMetaMaskModal from '../../../../components/DepositByMetaMaskModal'

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY

const Deposit = () => {
  const { user } = useUser()
  const { depositByStripe } = useWallet()
  const { setNewNotification } = useNotification()
  const { setting, loadSetting } = useSetting()

  const [amount, setAmount] = useState(100)
  const [promo, setPromo] = useState('')
  const [depsoitByMetaMaskModal, setDepositByMetaMaskModal] = useState(false)
  const [tokenRate, setTokenRate] = useState(0.01)

  useEffect(() => {
    (async () => {
      await loadSetting()
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (setting && setting.length) {
      let rate = setting.find(x => x.label === 'nil_rate_cc')
      if (rate) {
        setTokenRate(parseFloat(rate.value))
      } else {
        rate = setting.find(x => x.label === 'nil_rate')
        if (rate) {
          setTokenRate(parseFloat(rate.value))
        } else {
          setTokenRate(0.01)
        }
      }
    }
  }, [setting])

  const handleToken = async (token) => {    
    if (amount < 20 || !amount) {
      setNewNotification(NOTI_TYPE.WARNING, 'Minimum deposit amount is $20.')
      return
    }
    const token_symbol = "NIL";
    await depositByStripe(token.id, amount, promo, token_symbol)
    setAmount(0)
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h4 className="mb-3">Purchase NIL Tokens</h4>
          <div className="alert alert-primary">
          You can purchase Tokens using a Credit Card. After purchasing the Tokens you will be able to see them in your Door Wallet. 
          There is a standard 7 Day Hold on Credit Card Purchases.
          </div>
          <div className="mb-3">
            <label>
              What US Dollar Amount are you wanting to buy in NIL Tokens? ( Minimum deposit Amount is $20 )
            </label>
            <div className="input-group mb-3 input-primary">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                type="number"
                className="form-control"
                value={amount}
                min="20"
                onChange={(e) => setAmount(e.target.value)}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  for {(amount / tokenRate).toFixed(2)} NIL
                </span>
              </div>
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Promotion code"
                value={promo}
                onChange={(e) => setPromo(e.target.value)}
              />
            </div>
          </div>
          <hr className="mb-4" />
          <StripeCheckout
            stripeKey={STRIPE_KEY}
            token={handleToken}
            amount={amount * 100}
            name="NIL Tokens"
            billingAddress
            shippingAddress
            disabled = { amount < 20 }
            email={user?.email}            
            label="Purchase NIL Tokens with Credit Card"
          />
        </Card.Body>
      </Card>      
    </>
  )
}

export default Deposit
