import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import swal from 'sweetalert'
import { addDays, subDays, format as formatDate, parseISO } from 'date-fns'

import DateRange from '../../../../components/DateRange'
import Paginator from '../../../../components/Paginator'
import PageSize from '../../../../components/PageSize'
import TableHeader from '../../../../components/TableHeader'
import { useNotification, useProperty, useUser, useWallet } from '../../../../../store/hooks'
import { NOTI_TYPE } from '../../../../../store/types'

const MyLeads = () => {
  const history = useHistory()
  const { licenseProperty } = useProperty()
  const {
    leads: { data: leadsData },
    leadZipcode: { data: leadZipcodeData, loaded: isZipcodeLoaded },
    loadUserLeads,
    loadUserLeadZip,
    user
  } = useUser()
  const { wallet: { unlockedBalance }, loadWallet } = useWallet()
  const { setNewNotification } = useNotification()

  const headers = [
    { name: 'address', label: 'Address' },
    { name: 'status', label: 'Status' },
    { name: 'updatedAt', label: 'Last Updated', sortBy: 'updatedAt' },
    { name: 'list', label: 'List', style: { width: 120 } },
  ]

  const [range, setRange] = useState([subDays(new Date(), 600), new Date()])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(['updatedAt', 'desc'])

  useEffect(() => {
    console.log('here');
    loadWallet()
    loadUserLeadZip()
  }, []) // eslint-disable-line

  useEffect(() => {
    (async () => {
      await loadUserLeads({
        range_start: range[0].toISOString(),
        range_end: addDays(range[1], 1).toISOString(),
        limit,
        page,
        sortBy: sort[0],
        sortOrder: sort[1],
      })
    })()
  }, [range, page, limit, sort]) // eslint-disable-line

  useEffect(() => {
    if (isZipcodeLoaded && !leadZipcodeData.length) {
      if(!user.target_entire_us && user.target_state == ''){
        setNewNotification(
          NOTI_TYPE.WARNING,
          'Please input the zip code first.'
        )
        history.push('/dashboard/lead-settings')
      }
      
    }
  }, [isZipcodeLoaded]) // eslint-disable-line

  const handleRangeChange = async (startRange, endRange) => {
    setRange([startRange, endRange])
    setPage(1)
  }

  const handlePageSizeChange = (size) => {
    setLimit(size)
    setPage(1)
  }

  const handleLicenseClick = (lead) => {
    swal({
      title: 'Are you sure?',
      text: `You want to license this property in ${lead.requiredDoorToken} Door token?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        alert(unlockedBalance);
        if (unlockedBalance && (lead.requiredDoorToken <= unlockedBalance)) {
          await licenseProperty({
            doorToken: lead.requiredDoorToken,
            propertyID: lead.id
          })
          await loadUserLeads({
            range_start: range[0].toISOString(),
            range_end: addDays(range[1], 1).toISOString(),
            limit,
            page,
          })
          await loadWallet()
        } else {
          setNewNotification(
            NOTI_TYPE.WARNING,
            `Required Door token: ${lead.requiredDoorToken}, add more door token by deposit.`
          )
          history.push('/dashboard/deposit')
        }
      }
    })
  }

  const renderLeads = () => {
    if (!leadsData || !leadsData.rows.length) {
      return (
        <tr>
          <td colSpan={4} className="text-center">No leads found.</td>
        </tr>
      )
    }

    // FIXME: Backend should NOT return address for un-licensed leads.
    return leadsData.rows.map(lead => (
      <tr key={lead.id}>
        <td>
          {
            lead.property_user_map.length > 0 ? (
              <Link to={`/dashboard/lead-details/${lead.id}`}>{`${lead.address1} ${lead.address2}`}</Link>
            ) : (
              <span style={{filter: 'blur(2px)'}}>XXX {`${lead.address2}`}</span>
            )
          }
        </td>
        <td>
          {
            lead.property_user_map.length > 0 ? (
              <span className="badge badge-primary">Property Licensed</span>
            ) : (
              <button type="button" className="badge badge-success" onClick={() => handleLicenseClick(lead)}>
                License this Property
              </button>
            )
          }
        </td>
        <td>
          { formatDate(parseISO(lead.updatedAt), 'MM-dd-yyyy HH:mm') }
        </td>
        <td>
          {
            lead.property_list_map.map(x => (
              <span key={x.id} className="badge badge-primary list-label">
                { x.list.property_label }
              </span>
            ))
          }
        </td>
      </tr>
    ))
  }

  return (
    <div className="my-leads-page">
      <div className="alert alert-primary notification">
        <p>
          If you are an advertiser and have subscribed to one or more lists,
          you receive leads when a property owner or agent has signaled
          they are interested in a home service.
        </p>
        <p>
          If you have selected to automatically license each lead (recommended)
          you will receive the full profile of the property immediately.
          If you have selected to manually select leads you want to license,
          you will be required to login and license one by one.
          You can adjust these settings under Leads Settings.
        </p>
      </div>
      <div className="form-head d-flex mb-md-3">
        <DateRange
          defaultRange={range}
          onChange={handleRangeChange}
        />
      </div>
      <Card>
        <Card.Header>
          <Card.Title>Leads</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <TableHeader
                  headers={headers}
                  currentSort={sort}
                  onChange={(newSort) => { setSort(newSort) }}
                />
                <tbody>
                  { renderLeads() }
                </tbody>
              </table>
              <div className="table-footer">
                <Paginator
                  recordsCount={leadsData?.count || 0}
                  recordsPerPage={limit}
                  currentPage={page}
                  onChange={setPage}
                />
                <PageSize
                  currentSize={limit}
                  onChange={handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default MyLeads
