import axios from "axios";
import { LOCAL_STORAGE_KEY } from "../consts";

const httpETHClient = axios.create({
  baseURL: process.env.REACT_APP_ETH_API_URL
});

httpETHClient.interceptors.request.use((config) => {
  let token = sessionStorage.getItem(LOCAL_STORAGE_KEY.JWT_TOKEN) || ''
  if (!token) {
    token = localStorage.getItem(LOCAL_STORAGE_KEY.JWT_TOKEN) || ''
  }

  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

httpETHClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      //unauthentication 401
      if(error.response.status === 401){
        localStorage.clear();
        if(!window.location.href.includes('auth')){
          window.location.href = "/auth/login";
        }
      }
    }
    return Promise.reject(error);
  }
);

export default httpETHClient;
