import React from 'react'
import { Dropdown } from 'react-bootstrap'

const sizeList = [10, 20, 50, 100]

const PageSize = ({ currentSize, onChange }) => {
  return (
    <Dropdown className="page-size-wrapper" drop="up">
      <Dropdown.Toggle variant="" className="btn d-flex align-items-center" data-toggle="dropdown">
        <i className="flaticon-381-list"/>
        <div className="ml-2 mr-1 text-black">
          Page Size: { currentSize }
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu align='left' className='dropdown-menu dropdown-menu-right'>
        {
          sizeList.map(size => (
            <Dropdown.Item
              key={size}
              className={`dropdown-item${currentSize === size ? ' active' : ''}`}
              onClick={() => { onChange(size) }}
            >
              { size }
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default PageSize
