import React, {useEffect, useState} from 'react'
import { Card, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

import { NOTI_TYPE } from '../../../../../store/types'
import { useWallet, useUser, useNotification, useSetting, useProgress } from '../../../../../store/hooks'

const Withdrawal = () => {
  const { wallet: { balance, unlockedBalance }, loadWallet, withdrawDoorToken, externalWallets, loadExternalWallets } = useWallet()
  const { user } = useUser()
  const history = useHistory();
  const { setNewNotification } = useNotification()
  const { setting, loadSetting } = useSetting()
  const { startProgress, stopProgress } = useProgress()

  const [transferFee, setTransferFee] = useState(0)
  const [withdrawMin, setWithdrawMin] = useState(100);

  const [form, setForm] = useState({
    ethWalletKey: '',
    tokenAmount: 0,
    token_symbol: "ETH"
  })

  useEffect(() => {
    (async () => {
      await loadWallet({token_symbol: "ETH"})
      await loadSetting()
      await loadExternalWallets()
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (setting && setting.length) {
      const fee = setting.find(x => x.label === 'eth_transfer_fee')
      if (fee) {
        setTransferFee(parseFloat(fee.value))
      }

      const _withdrawMin = setting.find(x => x.label === 'eth_withdraw_min')
      if (_withdrawMin) {
        setWithdrawMin(parseFloat(_withdrawMin.value))
      }
    }
  }, [setting])

  const handleChange = (e) => {
    setForm((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  const handleSubmit = async () => {
    startProgress()
    if (!user) {
      stopProgress()
      return
    }

    if (user.verified !== 1) {
      stopProgress()
      setNewNotification(NOTI_TYPE.WARNING, 'In order to be compliant with KYC regulations we need to verify your account before you are able to make a withdraw.  Thank you for understanding.');
      history.push('/dashboard/verify');
      return
    }

    if (user.is_locked) {
      stopProgress()
      setNewNotification(NOTI_TYPE.WARNING, 'Your account is locked.')
      return
    }

    if (form.ethWalletKey === '') {
      stopProgress()
      setNewNotification(NOTI_TYPE.WARNING, 'Please enter ETH wallet key.')
      return
    }

    if (form.tokenAmount === '') {
      stopProgress()
      setNewNotification(NOTI_TYPE.WARNING, 'Please enter the amount of ETH to transfer.')
      return
    }

    if (parseFloat(form.tokenAmount) > unlockedBalance) {
      stopProgress()
      setNewNotification(NOTI_TYPE.WARNING, `The amount of ETH to transfer cannot be more than ${unlockedBalance}.`)
      return
    }

    
    if (parseFloat(form.tokenAmount) < withdrawMin) {
      stopProgress()
      setNewNotification(NOTI_TYPE.WARNING, `The amount of ETH to transfer cannot be less than ${withdrawMin}.`)
      return
    }

    await withdrawDoorToken(form, "ETH")
    stopProgress()
  }

  function numberWithCommas(x) {
    return parseFloat(x).toFixed(5).replace(/\B(?=(\d{3})+(?!\d))/g, '')
  }

  const renderExternalWallets = () => {
    if (!externalWallets.length) {
      return (
        <option value="">No external wallets found.</option>
      )
    }

    return externalWallets.map(wallet => (
      <option key={wallet.eth_address} value={wallet.eth_address}>
        {wallet.eth_address}
      </option>
    ))
  }

  return (
    <div className="withdrawal-page">
      <Card>
        <Card.Header className="d-block d-sm-flex border-0 pb-0">
          <h4 className="fs-20 text-black">ETH Wallet</h4>
          <p className="mb-0 fs-13">
            This is your account balance.
          </p>
        </Card.Header>
        <Card.Body>
          <div className="balance-wrapper">
            <Link to="/dashboard/ETH/view-wallets" className="balance-value">
              { numberWithCommas(balance) } ETH
            </Link>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <form>
            <h4 className="mb-3">Transfer ETH</h4>
            <div className="alert alert-primary notification">
              <p>
                If you have a verified account, you can transfer ETH
                from your ETH Wallet to your Metamask Wallet.
                You are responsible for transaction fees on each transfer,
                so make sure you take note of the estimated fees in Metamask before transferring.
              </p>
              <p>
                Once you have transferred your ETH onto Metamask,
                you can then use Uniswap to exchange for Ethereum or other crypto currency.
              </p>
            </div>
            <Row>
              <div className="col-md-12 mb-3">
                <label>What Ethereum Wallet do you want to transfer ETH to?</label>
                <div className="input-group mb-3 input-primary">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ETH WALLET KEY</span>
                  </div>
                  <select name="ethWalletKey" onChange={handleChange} className="form-control" >
                    <option value="">...</option>
                    {renderExternalWallets() }
                  </select>
                </div>
                <a className="external-wallet" href="#/dashboard/ETH/view-wallets">Add new external Wallet</a>
              </div>
              <div className="col-md-12 mb-3">
                <label>How many ETH do you want to transfer?</label>
                <div className="input-group mb-3 input-primary">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ETH</span>
                  </div>
                  <input type="number" name="tokenAmount" onChange={handleChange} className="form-control" />
                </div>
                <div>
                  <p className="balance_display">Min withdraw Amount: <span> {withdrawMin} ETH</span></p>                                      
                  <p className="balance_display">Max withdraw Amount per day: <span> {user?.withdraw_eth_per_day} ETH</span></p>
                  <p className="balance_display">Max withdraw Amount per month: <span> {user?.withdraw_eth_per_month} ETH</span></p>
                  <p className="balance_display">Transaction Fee *: <span> { transferFee } ETH </span></p>
                  <p className="balance_display">Receive Amount: <span> {form.tokenAmount} ETH</span></p>
                  <p className="balance_display">Withdraw Amount: <span>{parseFloat(form.tokenAmount) + parseFloat(transferFee)} ETH</span></p>
                  <p className="note_display">* Transaction Fees are based on Ethereum Gas Fees and can change frequently.</p>
                </div>
              </div>
            </Row>
            <hr className="mb-4" />
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={handleSubmit}
            >
              Transfer ETH
            </button>
          </form>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Withdrawal
