import React from "react";
import { Modal, Button } from "react-bootstrap";
import diffConditions from "./conditions";

const HelpModal = (props) => {
  return (
    <Modal
      {...props}
      className="help-modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {diffConditions()[props?.helpNumber].title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div
            className="blog-detail-main skyblue ptb-100"
            dangerouslySetInnerHTML={{ __html: diffConditions()[props?.helpNumber].content }}
          ></div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props?.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HelpModal;
