import React from 'react'

const PropertyInfoSection = ({ info, onChange }) => {
  const handleChange = (event) => {
    let value = event.target.value

    if (event.target.name === 'zip') {
      // Stripe non-digit characters from a zip code.
      value = value.replace(/\D/g, '')

      // Prevent zip codes from being more than 5 digits.
      if (value.length > 5) {
        return
      }
    }

    if (event.target.name === 'state') {
      // Stripe digit characters from a state.
      value = value.replace(/\d/g, '')

      // Prevent state from being more than 2 letters.
      if (value.length > 2) {
        return
      }
    }

    onChange(event.target.name, value)
  }

  return (
    <>
      <div className="form-row">
        <div className="form-group col-md-12">
          <label>
            Property Address
            <span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="address1"
            value={info.address1}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-12">
          <label>Property Address 2</label>
          <input
            type="text"
            className="form-control"
            name="address2"
            value={info.address2}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label>
            City
            <span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="city"
            value={info.city}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-4">
          <label>
            State
            <span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="state"
            value={info.state}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-2">
          <label>
            Zip
            <span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="zip"
            value={info.zip}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  )
}

export default PropertyInfoSection
