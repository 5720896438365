import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { addDays, subDays, format as formatDate, parseISO } from 'date-fns'

import DateRange from '../../../../components/DateRange'
import SearchBox from '../../../../components/SearchBox'
import Paginator from '../../../../components/Paginator'
import PageSize from '../../../../components/PageSize'
import TableHeader from '../../../../components/TableHeader'
import { useProgress, useWallet, useUser } from "../../../../../store/hooks"
import { generateCSVFile, pad, getTransactionType } from '../../../../../utils'

const Transactions = () => {
  const history = useHistory()
  const { wallet, loadWallet } = useWallet()
  const { startProgress, stopProgress } = useProgress()
  const { user } = useUser()

  const headers = [
    { name: 'type', style: { width: 64 } },
    { name: 'id', label: 'ID', sortBy: 'id', style: { width: 64 } },
    { name: 'createdAt', label: 'Date', sortBy: 'createdAt', style: { width: 96 } },
    { name: 'note', label: 'Source', sortBy: 'note', style: { width: 160 } },
    { name: 'amount', label: 'Amount', sortBy: 'amount', style: { width: 64 } },
    { name: 'status', label: 'Status', style: { width: 96 } },
    { name: 'detail', label: 'Detail', style: { width: 96 } },
  ]

  const [search, setSearch] = useState('')
  const [range, setRange] = useState([subDays(new Date(), 600), new Date()])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(['createdAt', 'desc'])

  useEffect(() => {
    (async () => {
      await loadWallet({
        search,
        range_start: range[0].toISOString(),
        range_end: addDays(range[1], 1).toISOString(),
        limit,
        page,
        sortBy: sort[0],
        sortOrder: sort[1],
        token_symbol: "NIL"
      })
    })()
  }, [search, range, page, limit, sort]) // eslint-disable-line

  const saveAsCSV = async () => {
    try {
      if (wallet?.transactions?.rows.length) {
        startProgress()
        // FIXME: Export all transactions, not the current page only.
        generateCSVFile(wallet.transactions.rows, 'user', user?.id)
        stopProgress()
      }
    } catch (e) {
      stopProgress()
    }
  }

  const handleSearch = (keyword) => {
    setSearch(keyword)
    setPage(1)
  }

  const handleRangeChange = async (startRange, endRange) => {
    setRange([startRange, endRange])
    setPage(1)
  }

  const handlePageSizeChange = (size) => {
    setLimit(size)
    setPage(1)
  }

  const handleMoreClick = transaction => () => {
    if (transaction.url) {
      window.open(transaction.url, '_new')
    } else if (transaction.type === 'reward') {
      history.push(`/dashboard/edit-property/${transaction.note}`)
    } else if (transaction.type === 'licenced') {
      if (transaction?.to_bank?.user?.id === user?.id) {
        history.push(`/dashboard/edit-property/${transaction.note}`)
      } else {
        history.push(`/dashboard/lead-details/${transaction.note}`)
      }
    }
  }

  const renderTransactions = () => {
    if (!wallet || !wallet.transactions || !wallet.transactions?.rows?.length) {
      return (
        <tr>
          <td colSpan={7} className="text-center">
            No transaction found.
          </td>
        </tr>
      )
    }

    return wallet.transactions.rows.map((item) => (
      <tr key={item.id} className="table-warning">
        <td className='pr-0 sorting_1'>
          <span className='bgl-success p-3 d-inline-block'>
            {
              (item?.to_bank?.user?.id === user?.id)
              ? (
                <svg width={29} height={29} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.6186 15.7207L23.6186 15.7207L23.6353 22.6289C23.6354 22.6328 23.6354 22.6363 23.6353 22.6396M23.6186 15.7207L23.1353 22.6341L23.6353 22.635C23.6353 22.6481 23.6347 22.6583 23.6345 22.6627L23.6344 22.6642C23.6346 22.6609 23.6351 22.652 23.6353 22.6407C23.6353 22.6403 23.6353 22.64 23.6353 22.6396M23.6186 15.7207C23.6167 14.9268 22.9717 14.2847 22.1777 14.2866C21.3838 14.2885 20.7417 14.9336 20.7436 15.7275L20.7436 15.7275L20.7519 19.1563M23.6186 15.7207L20.7519 19.1563M23.6353 22.6396C23.6329 23.4282 22.9931 24.0705 22.2017 24.0726C22.2 24.0726 22.1983 24.0727 22.1965 24.0727L22.1944 24.0727L22.1773 24.0726L15.2834 24.056L15.2846 23.556L15.2834 24.056C14.4897 24.054 13.8474 23.4091 13.8494 22.615C13.8513 21.8211 14.4964 21.179 15.2903 21.1809L15.2903 21.1809L18.719 21.1892L5.53639 8.0066C4.975 7.44521 4.975 6.53505 5.53639 5.97367C6.09778 5.41228 7.00793 5.41228 7.56932 5.97367L20.7519 19.1563M23.6353 22.6396C23.6353 22.6376 23.6353 22.6356 23.6353 22.6336L20.7519 19.1563M22.1964 24.0726C22.1957 24.0726 22.1951 24.0726 22.1944 24.0726L22.1964 24.0726Z" fill='#2BC155' stroke='#2BC155' />
                </svg>
              ) : (
                <svg width={29} height={29} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.13185 13.9043L5.13185 13.9043L5.11515 6.99607C5.11511 6.99224 5.11513 6.98868 5.11517 6.98542M5.13185 13.9043L5.61517 6.99089L5.11517 6.99005C5.11519 6.97692 5.11575 6.96665 5.116 6.96234L5.11608 6.96082C5.11588 6.96411 5.11535 6.97298 5.11519 6.98431C5.11518 6.98468 5.11517 6.98505 5.11517 6.98542M5.13185 13.9043C5.13378 14.6982 5.77881 15.3403 6.57281 15.3384C7.36672 15.3365 8.00877 14.6914 8.00689 13.8975L8.00689 13.8975L7.99856 10.4687M5.13185 13.9043L7.99856 10.4687M5.11517 6.98542C5.11755 6.19684 5.75739 5.55451 6.54875 5.55238C6.55044 5.55236 6.5522 5.55235 6.554 5.55234L6.55606 5.55234L6.57321 5.55239L13.4671 5.56905L13.4658 6.06905L13.4671 5.56905C14.2608 5.57098 14.903 6.21593 14.9011 7.01004C14.8992 7.80394 14.2541 8.44597 13.4602 8.44409L13.4602 8.4441L10.0315 8.43582L23.2141 21.6184C23.7755 22.1798 23.7755 23.0899 23.2141 23.6513C22.6527 24.2127 21.7426 24.2127 21.1812 23.6513L7.99856 10.4687M5.11517 6.98542C5.11516 6.98743 5.11517 6.98943 5.11517 6.99144L7.99856 10.4687M6.5541 5.55237C6.55474 5.55237 6.5554 5.55237 6.55606 5.55238L6.5541 5.55237Z" fill='#FF2E2E' stroke='#FF2E2E' />
                </svg>
              )
            }
          </span>
        </td>
        <td>{pad(item.id)}</td>
        <td className='wspace-no'>
          { formatDate(parseISO(item.createdAt), 'MM-dd-yyyy HH:mm') }
        </td>
        <td>
          { getTransactionType(item.type) }
        </td>
        <td>
          {
            item.type === 'withdraw'
            ? (
              <span className='font-w700' style={{color : 'red'}}>
                -{ parseFloat(Math.abs(item.amount).toFixed(2)) } &nbsp; {item?.token_symbol}
              </span>
            ) : (
              <span className='font-w700' style={{color : `${item.type === "licenced" && item?.from_bank?.user?.id === user?.id ? 'red' : '#2BC155'}`}}>
                { item.type === "licenced" && item?.from_bank?.user?.id === user?.id ?  '-' : '+' }
                { parseFloat(Math.abs(item.amount).toFixed(2)) } &nbsp; {item?.token_symbol}
              </span>
            )
          }
        </td>        
        {item.amount == 0 ? <td className="text-info">PENDING</td> : <td className="text-success">COMPLETED</td> }        
        <td>
          <button type="type" className="btn btn-success light sharp" onClick={handleMoreClick(item)}>
            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
              </g>
            </svg>
          </button>
        </td>
      </tr>
    ))
  }

  return (
    <div className="transaction-page">
      <div className="form-head d-flex mb-md-3">
        <SearchBox onSearch={handleSearch} />
        <DateRange
          defaultRange={range}
          onChange={handleRangeChange}
        />
        <button
          type="button"
          className="btn bgl-primary text-primary btn-csv"
          onClick={saveAsCSV}
        >
          <svg className='mr-3 scale5' width={16} height={16} viewBox='0 0 18 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M1.99989 23.6666H15.9999C16.6439 23.6666 17.1666 23.144 17.1666 22.5C17.1666 21.856 16.6439 21.3333 15.9999 21.3333H1.99989C1.35589 21.3333 0.833225 21.856 0.833225 22.5C0.833225 23.144 1.35589 23.6666 1.99989 23.6666ZM5.49989 9.66665V3.24998C5.49989 2.47648 5.80674 1.73447 6.3539 1.18731C6.90107 0.64014 7.64306 0.333313 8.41656 0.333313H9.58323C10.3567 0.333313 11.0987 0.64014 11.6459 1.18731C12.1919 1.73447 12.4999 2.47648 12.4999 3.24998V9.66665H15.9999C16.4712 9.66665 16.8971 9.95013 17.0779 10.3865C17.2576 10.8228 17.1584 11.3245 16.8247 11.6581L9.82473 18.6581C9.36856 19.1131 8.63006 19.1131 8.17506 18.6581L1.17506 11.6581C0.841391 11.3245 0.741063 10.8228 0.921897 10.3865C1.10273 9.95013 1.52739 9.66665 1.99989 9.66665H5.49989ZM13.1836 12H11.3332C10.6892 12 10.1666 11.4773 10.1666 10.8333C10.1666 10.8333 10.1666 5.95198 10.1666 3.24998C10.1666 3.09481 10.1047 2.94664 9.99507 2.83698C9.88657 2.72731 9.73722 2.66665 9.58323 2.66665C9.20173 2.66665 8.79806 2.66665 8.41656 2.66665C8.26139 2.66665 8.11324 2.72731 8.00357 2.83698C7.89507 2.94664 7.83323 3.09481 7.83323 3.24998V10.8333C7.83323 11.4773 7.31056 12 6.66656 12H4.81623L8.99989 16.1836L13.1836 12Z' fill='#6418C3' />
          </svg>
          Save to CSV
        </button>
      </div>
      <div className="card">
        <div className="card-body">
          <div className='table-responsive table-hover'>
            <div className='dataTables_wrapper'>
              <table className='table card-table dataTable'>
                <TableHeader
                  headers={headers}
                  currentSort={sort}
                  onChange={(newSort) => { setSort(newSort) }}
                />
                <tbody>
                  { renderTransactions() }
                </tbody>
              </table>
              <div className="table-footer">
                <Paginator
                  recordsCount={wallet.transactions?.count || 0}
                  recordsPerPage={limit}
                  currentPage={page}
                  onChange={setPage}
                />
                <PageSize
                  currentSize={limit}
                  onChange={handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Transactions
