import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { NOTI_TYPE } from '../../../../../store/types'
import {
  useRequest,
  useNotification,
  useList,
  useProgress,
} from '../../../../../store/hooks'

const AddRequest = () => {
  const history = useHistory()
  const { setNewNotification } = useNotification()
  const { addRequest } = useRequest()
  const { loadCustomLists } = useList()
  const { startProgress, stopProgress } = useProgress()

  const [form, setForm] = useState({
    title: 'SERVICE REQUEST',
    description: '',
  })

  const [customLists, setCustomLists] = useState([])
  const [selectedLists, setSelectedLists] = useState([])

  useEffect(() => {
    (async () => {
      const lists = await loadCustomLists()
      setCustomLists(lists)
    })()
  }, []) // eslint-disable-line

  const handleChange = (event) => {
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }))
  }

  const handleListSelect = async (e) => {
    let list = [...selectedLists]
    const value = Number(e.target.value)
    if (e.target.checked) {
      if (!list.includes(value)) {
        list = [...list, value]
      }
    } else if (list.includes(value)) {
      const index = list.findIndex(i => i === value)
      list.splice(index, 1)
    }

    setSelectedLists(list)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!form.title) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please enter the title.'
      )
      return
    }

    if (!selectedLists.length) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please select list(s).'
      )
      return
    }

    try {
      startProgress()
      await addRequest({
        title: form.title,
        description: form.description,
        list_ids: selectedLists,
      })
      stopProgress()

      history.push('/dashboard/my-requests')
    } catch (err) {
      stopProgress()
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Unable to add request. Please try again.'
      )
    }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Request a Service</Card.Title>
      </Card.Header>
      <Card.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-row">            
            <div className="form-group col-md-12">
              <label>Tell us about what service you need and details</label>
              <textarea
                className="form-control"
                rows="3"
                name="description"
                value={form.description}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group mt-4 mb-4">
            {
              customLists.map(l =>
                <div className="form-check mb-2" key={l.id}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`check-${l.id}`}
                    value={l.id}
                    checked={selectedLists.includes(l.id)}
                    onChange={handleListSelect}
                  />
                  <label className="form-check-label" htmlFor={`check-${l.id}`}>
                    {l.property_label}
                  </label>
                </div>
              )
            }
          </div>
          <button type="submit" className="btn btn-primary">
            Submit Request
          </button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default AddRequest
