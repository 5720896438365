import HttpClient from './http-client'

export const RequestService = (function () {
  const getRequests = async (params) => (
    await HttpClient.get('/requests', {
      params,
    })
  )

  const getRequestById = async id => (
    await HttpClient.get(`/requests/${id}`)
  )

  const addRequest = async params => (
    await HttpClient.post('/requests', params)
  )

  const updateRequest = async (id, params) => (
    await HttpClient.put(`/requests/${id}`, params)
  )

  const deleteRequest = async id => (
    await HttpClient.delete(`/requests/${id}`)
  )

  
  const deleteRequestByDescription = async description => (
    await HttpClient.delete(`/requests/bydescription/${description}`)
  )

  return {
    getRequests,
    getRequestById,
    addRequest,
    updateRequest,
    deleteRequest,
    deleteRequestByDescription
  }
})()
