import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Card, Row, Col } from 'react-bootstrap'
import { format as formatDate, parseISO } from 'date-fns'

import { useLead } from '../../../../../store/hooks/'

const rates = [
  { label: 'Good', value: 4 },
  { label: 'OK', value: 3 },
  { label: 'Poor', value: 2 },
  { label: 'Report as Fraud', value: 1 },
]

const RequestLeadDetails = () => {
  const { id: leadId } = useParams()
  const { requestLeadDetail, loadRequestLeadDetail, rateRequestLead } = useLead()

  useEffect(() => {
    (async () => {
      await loadRequestLeadDetail(leadId)
    })()
  }, []) // eslint-disable-line

  const handleRate = async (event) => {
    if (requestLeadDetail.request_user_map.length) {
      await rateRequestLead(requestLeadDetail.request_user_map[0].id, parseInt(event.target.value, 10))
    }
  }

  const renderRateSection = () => {
    let currentRate = -1
    if (requestLeadDetail.request_user_map.length) {
      currentRate = parseInt(requestLeadDetail.request_user_map[0].rate, 10)
    }
    return (
      <Card>
        <Card.Header>
          <Card.Title>Rate This Lead</Card.Title>
        </Card.Header>
        <Card.Body>
          <form>
            <div className="form-group">
              {
                rates.map(rate => (
                  <div key={rate.value} className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        type="radio"
                        name="rate"
                        className="form-check-input"
                        value={rate.value}
                        defaultChecked={rate.value === currentRate}
                        onChange={handleRate}
                      />
                      { rate.label }
                    </label>
                  </div>
                ))
              }
            </div>
          </form>
        </Card.Body>
      </Card>
    )
  }

  if (!requestLeadDetail) {
    return null
  }

  return (
    <Row>
      <Col lg={6}>
        <Card>
          <Card.Header>
            <Card.Title>Lead Information</Card.Title>
          </Card.Header>
          <Card.Body>
            <div>
              Title: { requestLeadDetail.title }<br/>
              Description: { requestLeadDetail.description }<br/>
              Contact: { requestLeadDetail.user?.fname} { requestLeadDetail.user?.lname }<br />
              { requestLeadDetail.user?.address1 }<br />
              { requestLeadDetail.user?.address2 ? (<div>{ requestLeadDetail.user?.address2 }</div>) : null }
              <div>
                { requestLeadDetail.user?.city ? `${requestLeadDetail.user?.city}, `  : '' }
                { requestLeadDetail.user?.state ? `${requestLeadDetail.user?.state}, `  : '' }
                { requestLeadDetail.user?.zip }
              </div>
              Phone: {requestLeadDetail.user?.phone}<br />
              Email: {requestLeadDetail.user?.email}<br />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <Card.Header>
            <Card.Title>Current Requests</Card.Title>
          </Card.Header>
          <Card.Body>
            <form>
              <div className="form-group">
                {
                  requestLeadDetail.request_list_map && requestLeadDetail.request_list_map.map(x =>
                  <div className="form-check mb-2" key={x.id}>
                    <input type="checkbox" className="form-check-input" checked readOnly/>
                    <label className="form-check-label">{ x.list.property_label }</label>
                  </div>)
                }
              </div>
            </form>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <Card.Header>
            <Card.Title>Data License</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="basic-form">
              You licensed this data for <b>{requestLeadDetail.amountPaid} Door Tokens</b> on&nbsp;
              {
                requestLeadDetail.request_user_map &&
                formatDate(parseISO(requestLeadDetail.request_user_map[0].createdAt), 'MM-dd-yyyy HH:mm')
              }.
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6}>
        { renderRateSection() }
      </Col>
    </Row>
  )
}

export default RequestLeadDetails
