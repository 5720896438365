import React from 'react';
import Paginate from 'react-paginate';

const Paginator = ({ recordsCount = 0, recordsPerPage, currentPage, onChange }) => {
  const pageSize = Math.ceil(recordsCount / recordsPerPage)

  if (!pageSize) {
    return null
  }

  return (
    <div className="paginationn issuesPagination">
      <Paginate
        forcePage={currentPage-1}
        pageCount={pageSize}
        marginPagesDisplayed={0}
        pageRangeDisplayed={2}
        onPageChange={(e) => onChange(e.selected+1)}
        nextLabel="Next"
        previousLabel="Previous"
      />
    </div>
  )
}

export default Paginator