import { useDispatch, useSelector } from 'react-redux'

import { PromoService } from '../../services'
import {
  GET_PROMOS_INIT,
  GET_PROMOS_SUCCESS,
  GET_PROMO_BY_ID_SUCCESS,
  ADD_PROMO_SUCCESS,
  UPDATE_PROMO_SUCCESS,
  DELETE_PROMO_SUCCESS,
  NOTI_TYPE,
} from '../types'
import { useNotification } from './notification.hook'
import { useProgress } from "./progress.hook"

export const usePromo = () => {
  const dispatch = useDispatch()

  const { promos } = useSelector(({ promo }) => promo)
  const { setNewNotification } = useNotification()
  const { startProgress, stopProgress } = useProgress()

  const getPromos = async () => {
    try {
      dispatch({ type: GET_PROMOS_INIT })
      startProgress()
      const response = await PromoService.getPromos()
      dispatch({type: GET_PROMOS_SUCCESS, payload: response.data})
      stopProgress()
    } catch (error) {
      stopProgress()
    }
  }

  const getPromoById = async (id) => {
    try {
      startProgress()
      const response = await PromoService.getPromoById(id)
      dispatch({
        type: GET_PROMO_BY_ID_SUCCESS,
        payload: response.data.promo,
      })
      stopProgress()
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to retrieve a promotion code.')
      stopProgress()
    }
  }

  const addPromo = async (params) => {
    try {
      startProgress()
      const response = await PromoService.addPromo(params)
      dispatch({
        type: ADD_PROMO_SUCCESS,
        payload: response.data.promo,
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Added a new promotion code successfully.')
      stopProgress()
      return true
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to add a promotion code.')
      stopProgress()
      return false
    }
  }

  const updatePromo = async (id, params) => {
    try {
      startProgress()
      const response = await PromoService.updatePromo(id, params)
      dispatch({
        type: UPDATE_PROMO_SUCCESS,
        payload: response.data.promo,
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Updated the promotion code successfully.')
      stopProgress()
      return true
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to update the promotion code.')
      stopProgress()
      return false
    }
  }

  const deletePromo = async (id) => {
    try {
      startProgress()
      await PromoService.deletePromo(id)
      dispatch({
        type: DELETE_PROMO_SUCCESS,
        payload: id,
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Deleted the promotion code successfully.')
      stopProgress()
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to delete the promotion code.')
      stopProgress()
    }
  }

  return {
    promos,
    getPromos,
    getPromoById,
    addPromo,
    updatePromo,
    deletePromo,
  }
}
