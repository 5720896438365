import HttpClient from './http-client'
import httpETHClient from './http-eth-client'

export const WalletService = (function () {
    const addDeposit = async params => (
        await HttpClient.post('/wallet/deposit', params)
    )

    const addDepositWithMetaMask = async({ transactionHash, amount, fromAddress, toAddress, name, description, token_symbol }) => {
        return await HttpClient.post("/wallet/depositByMetaMask", { transactionHash, amount, fromAddress, toAddress, name, description, token_symbol });
    };

    const depositeEthWithMetaMask = async({ transactionHash, amount, fromAddress, toAddress, name, description, token_symbol }) => {
        return await HttpClient.post("/wallet/depositByEthMetaMask", { transactionHash, amount, fromAddress, toAddress, name, description, token_symbol });
    };

    const addDepositDirect = async({ fromAddress, token_symbol}) => {
        return await HttpClient.post("/wallet/depositDirectly", { fromAddress, token_symbol });
    };

    const getBalance = async (token_symbol) => (
        await HttpClient.get(`/wallet/balance?token_symbol=${token_symbol}`)
    )

    const getCryptoInfo = async (params) => {
        return await HttpClient.get('/wallet/admin_wallet', {
            params,
        });
    };

    const getTransactions = async (params) => {
        return await HttpClient.get(`/wallet/transactions?token_symbol=${params.token_symbol}`, {
            params,
        })
    }

    const getAllTransactions = async (params) => {
        return await HttpClient.get('/wallet/transactions/all', { params })
    }

    const getTransactionsByBank = async (bankId) => {
        return await HttpClient.get(`/wallet/inner_transaction/${bankId}`);
    }

    const getUnsyncedTransactions = async (params) => {
        return await HttpClient.get('/wallet/transactions/unsynced', {
            params,
        })
    }

    const withdrawDoorToken = async(data) => {
        return await httpETHClient.post("/transactions/withdraw", data)
    }

    const withdrawETH = async(data) => {
        return await httpETHClient.post("/transactions/withdrawETH", data)
    }

    const sendToken = async (data) => {
        return await httpETHClient.post('/transactions/send', data)
    }

    const syncTransactions = async () => {
        return await httpETHClient.post('/transactions/sync')
    }

    const getExternalWallets = async (token_symbol) => (
        await HttpClient.get(`/wallet/external_wallets`)
    )

    const addExternalWallet = async (address) => (
        await HttpClient.post('/wallet/external_wallets', {
            address,
        })
    )

    const removeExternalWallet = async (address) => (
        await HttpClient.delete(`/wallet/external_wallets/${address}`)
    )

    const getBanks = async (token_symbol) => {
        return await HttpClient.get(`/wallet/banks?token_symbol=${token_symbol}`)
    }

    const getBank = async (id) => (
        await HttpClient.get(`/wallet/bank/${id}`)
    )

    return {
        addDeposit,
        getBalance,
        getTransactions,
        getAllTransactions,
        getTransactionsByBank,
        getUnsyncedTransactions,
        addDepositWithMetaMask,
        depositeEthWithMetaMask,
        withdrawDoorToken,
        sendToken,
        syncTransactions,
        getCryptoInfo,
        getExternalWallets,
        addExternalWallet,
        removeExternalWallet,
        getBanks,
        getBank,
        addDepositDirect,
        withdrawETH
    }
})()
