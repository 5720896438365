import { referralService } from '../../services';


export const useReferral = () => {

    const loadReferralUser = async () => {
        try {
            const referralUSerList = await referralService.getReferralUserList();
            if (referralUSerList) {
                return referralUSerList;
            }
        } catch (error) {
            return false;
        }
    }

    return {
        loadReferralUser
    }
}   
