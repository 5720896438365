import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import { format as formatDate, parseISO } from 'date-fns'
import { Dropdown, Card, Row, Col } from 'react-bootstrap'
import swal from 'sweetalert'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { NOTI_TYPE } from '../../../../../store/types'
import { useList, useProperty, useUsers, useNotification } from '../../../../../store/hooks'
import { countryList } from '../../../../../utils'
import FileList from '../../../../components/FileList'
import DenyModal from '../../../../components/DenyModal'

const USER_LOG_TYPE_JOINED = 1
const USER_LOG_TYPE_VERIFIED = 2
const USER_LOG_TYPE_ACTIVATED = 3
const USER_LOG_TYPE_DEACTIVATED = 4
const USER_LOG_TYPE_LOCKED = 5
const USER_LOG_TYPE_UNLOCKED = 6

const UserDetail = () => {
  const { id: userID } = useParams()
  const { lists, loadLists } = useList()
  const { deleteSearchedUserProperty } = useProperty()
  const { editUser, isEditUserLoading, getUserById, updateUser, deleteSearchedUser,
    activateUser, lockUser, unlockUser, rejectUser } = useUsers()
  const { setNewNotification } = useNotification()

  const [form, setForm] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
    verified: false,
    is_email_verified: false,
    is_phone_verified: false,
    withdraw_per_day: 5000,
    withdraw_per_month: 20000,
  })
  const [denyVisible, setDenyVisible] = useState(false)

  useEffect(() => {
    (async () => {
      await loadLists()
      await getUserById(userID)
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!editUser.id) {
      return
    }

    setForm({
      fname: editUser.fname,
      lname: editUser.lname,
      email: editUser.email,
      phone: editUser.phone || '',
      address1: editUser.address1 || '',
      address2: editUser.address2 || '',
      city: editUser.city || '',
      state: editUser.state || '',
      zip: editUser.zip || '',
      country: editUser.country || 'US',
      verified: editUser.verified === 1,
      is_email_verified: editUser.is_email_verified === true,
      is_phone_verified: editUser.is_phone_verified === true,
      withdraw_per_day: parseInt(editUser.withdraw_per_day, 10),
      withdraw_per_month: parseInt(editUser.withdraw_per_month, 10),
    })
  }, [editUser])

  const handleUpdate = async () => {
    if (!form.phone) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please enter your phone number.'
      )
      return
    }
    await updateUser(userID, Object.assign({}, form, {
      phone: `+${form.phone}`,
    }))
  }

  const handlePropertyDelete = async (id) => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete this property?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const success = await deleteSearchedUserProperty(id)
        if (success) {
          await getUserById(userID)
        }
      }
    })
  }

  const handleDeactivate = async () => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to de-activate this user?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteSearchedUser(userID)
        await getUserById(userID)
      }
    })
  }

  const handleActivate = async () => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to activate this user?',
      icon: 'info',
      buttons: true,
      dangerMode: false,
    }).then(async (willActivate) => {
      if (willActivate) {
        await activateUser(userID)
        await getUserById(userID)
      }
    })
  }

  const handleLockUser = async () => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to lock this user?',
      icon: 'warning',
      buttons: true,
      dangerMode: false,
    }).then(async (confirmed) => {
      if (confirmed) {
        await lockUser(userID)
        await getUserById(userID)
      }
    })
  }

  const handleUnlockUser = async () => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to unlock this user?',
      icon: 'info',
      buttons: true,
      dangerMode: false,
    }).then(async (confirmed) => {
      if (confirmed) {
        await unlockUser(userID)
        await getUserById(userID)
      }
    })
  }

  const handleReject = async (reason) => {
    setDenyVisible(false)
    await rejectUser(userID, reason)
  }

  const handleChange = (e) => {
    const checkFields = ['verified', 'is_email_verified', 'is_phone_verified']

    setForm(prev =>({
      ...prev,
      [e.target.name]: checkFields.indexOf(e.target.name) === -1
        ? e.target.value
        : e.target.checked,
    }))
  }

  const handlePhoneChange = (phone) => {
    setForm(prev =>({
      ...prev,
      phone: phone.replace(/\D/g, ''),
    }))
  }

  const renderLogs = () => {
    if (!editUser || !editUser.logs) {
      return null
    }

    return editUser.logs.map((log) => {
      let description = ''
      if (log.type === USER_LOG_TYPE_JOINED) {
        description = 'User Joined'
      } else if (log.type === USER_LOG_TYPE_VERIFIED) {
        description = 'User Verified'
      } else if (log.type === USER_LOG_TYPE_DEACTIVATED) {
        description = 'User Deactivated'
      } else if (log.type === USER_LOG_TYPE_ACTIVATED) {
        description = 'User Activated'
      } else if (log.type === USER_LOG_TYPE_LOCKED) {
        description = 'User Locked'
      } else if (log.type === USER_LOG_TYPE_UNLOCKED) {
        description = 'User Unlocked'
      }
      return (
        <li key={log.id}>
          <div className="timeline-panel">
            <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
              <input type="checkbox" className="custom-control-input" defaultChecked />
              <label className="custom-control-label"></label>
            </div>
            <div className="media-body">
                <h5 className="mb-0">{ description }</h5>
                <small className="text-muted">
                  { formatDate(parseISO(log.createdAt), 'MM-dd-yyyy HH:mm') }
                </small>
            </div>
            <Dropdown drop="left">
              <Dropdown.Toggle variant="success" type="button" className="light sharp">
                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                    <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                    <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                  </g>
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>View Details</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </li>
      )
    })
  }

  const renderList = () => {
    if (!lists) {
      return null
    }

    return lists.map(l => (
      <div key={l.id} className="form-check mb-2">
        <input
          type="checkbox"
          className="form-check-input"
          id={`check-${l.id}`}
          value={l.id}
          checked={editUser?.user_list_map?.filter(x => x.frn_listid === l.id).length > 0}
          readOnly
        />
        <label className="form-check-label" htmlFor={`check-${l.id}`}>{l.property_label}</label>
      </div>
    ))
  }

  const renderProperties = () => {
    if (!editUser || !editUser.properties.length) {
      return (
        <tr><td colSpan={5} className="text-center">No properties found.</td></tr>
      )
    }

    return editUser.properties.map(property => (
      <tr key={property.id}>
        <td>
          <Link to={`/admin/edit-property/${property.id}`}>
            {`${property.address1} ${property.address2}`}
          </Link>
        </td>
        <td>
        {
          property.is_verified === 2
            ? <span className="badge badge-success">Verified</span>
            : (property.is_verified === 1
              ? <span className="badge badge-info">Pending To Review</span>
              : <span className="badge badge-danger">Not Verified</span>)
        }
        </td>
        <td>
          { formatDate(parseISO(property.createdAt), 'MM-dd-yyyy HH:mm') }
        </td>
        <td className="color-primary">{property.market_value}</td>
        <td>
          <Dropdown drop="left">
            <Dropdown.Toggle variant="success" type="button" className="light btn sharp">
              <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={`/admin/edit-property/${property.id}`}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handlePropertyDelete(property.id)}>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    ))
  }

  const renderUserWallets = () => {
    if (!editUser || !editUser.user_wallets.length) {
      return (
        <tr>
          <td className="text-center">
            No external wallets found.
          </td>
        </tr>
      )
    }

    return editUser.user_wallets.map(wallet => (
      <tr key={wallet.eth_address}>
        <td>
          { wallet.eth_address }
        </td>
      </tr>
    ))
  }

  if (!editUser.id || isEditUserLoading) {
    return null
  }

  return (
    <>
      <Row>
        <Col lg={6}>
          <Card>
            <Card.Header>
              <Card.Title>User Log</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="widget-media dz-scroll height370 ps">
                <ul className="timeline">
                  { renderLogs() }
                </ul>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <Card.Header>
              <Card.Title>Data Requests</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="alert alert-info">
                <strong>Note</strong> If the User is looking to buy data they subscribe to lists
              </div>
              <form>
                { renderList() }
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card>
        <Card.Header>
          <Card.Title>User Profile</Card.Title>
        </Card.Header>
        <Card.Body>
          <form className="user-profile-form">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="fname"
                  value={form.fname}
                  onChange={handleChange}
                />
              </div>
                <div className="form-group col-md-6">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lname"
                  value={form.lname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label>Phone</label>
                <PhoneInput
                  country="us"
                  placeholder="Phone"
                  inputProps={{
                    required: true,
                  }}
                  value={form.phone}
                  onChange={handlePhoneChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Address Line 1</label>
                <input
                  type="text"
                  className="form-control"
                  name="address1"
                  value={form.address1}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-12">
                <label>Address Line 2</label>
                <input
                  type="text"
                  className="form-control"
                  name="address2"
                  value={form.address2}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  name="city"
                  value={form.city}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label>State/Province/Region</label>
                <input
                  type="text"
                  className="form-control"
                  name="state"
                  value={form.state}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>ZIP/Postal Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="zip"
                  value={form.zip}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label>Country</label>
                <select
                  className="form-control"
                  name="country"
                  value={form.country}
                  onChange={handleChange}
                >
                  {
                    Object.keys(countryList).map(country => (
                      <option key={country} value={country}>
                        { countryList[country] }
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Withdraw per day</label>
                <input
                  type="number"
                  className="form-control"
                  name="withdraw_per_day"
                  value={form.withdraw_per_day}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label>Withdraw per month</label>
                <input
                  type="number"
                  className="form-control"
                  name="withdraw_per_month"
                  value={form.withdraw_per_month}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label style={{display:"block"}}>Verification Documents</label>
                <FileList files={editUser.verify_attachments || []} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12" style={{marginLeft:20}}>
                {
                  form && (
                    <input type="checkbox" className="form-check-input" id="chk-profile"
                      name="verified"
                      checked={form.verified}
                      onChange={handleChange}
                    />
                  )
                }
                <label className="form-check-label" htmlFor="chk-profile">Profile is Verified</label> <br />
                {
                  form && (
                    <input type="checkbox" className="form-check-input" id="chk-phone-verified"
                      name="is_phone_verified"
                      checked={form.is_phone_verified}
                      onChange={handleChange}
                    />
                  )
                }
                <label className="form-check-label" htmlFor="chk-phone-verified">Phone is Verified</label><br />
                {
                  form && (
                    <input type="checkbox" className="form-check-input" id="chk-email-verified"
                      name="is_email_verified"
                      checked={form.is_email_verified}
                      onChange={handleChange}
                    />
                  )
                }
                <label className="form-check-label" htmlFor="chk-email-verified">Email is Verified</label>
              </div>
            </div>
            <button type="button" className="btn btn-info" onClick={handleUpdate}>Update</button>
            {
              editUser && editUser.is_deleted
              ? <button type="button" className="btn btn-success ml-3" onClick={handleActivate}>Activate This User</button>
              : <button type="button" className="btn btn-danger ml-3" onClick={handleDeactivate}>De-activate This User</button>
            }
            {
              editUser && editUser.is_locked
              ? <button type="button" className="btn btn-success ml-3" onClick={handleUnlockUser}>Unlock This User</button>
              : <button type="button" className="btn btn-warning ml-3" onClick={handleLockUser}>Lock This User</button>
            }
            {
              editUser && editUser.verified === 2 && (
                <>
                  <button type="button" className="btn btn-danger ml-3" onClick={() => { setDenyVisible(true) }}>
                    Deny Verification
                  </button>
                  <DenyModal
                    show={denyVisible}
                    onReject={handleReject}
                    onHide={() => { setDenyVisible(false) }}
                  />
                </>
              )
            }
          </form>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>Property Registered By This User</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <table className="table table-striped table-responsive-sm">
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Status</th>
                  <th>Last Updated</th>
                  <th>Market Value</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                { renderProperties() }
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>External Wallets of This User</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <table className="table table-striped table-responsive-sm">
              <thead>
                <tr>
                  <th>Address</th>
                </tr>
              </thead>
              <tbody>
                { renderUserWallets() }
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default UserDetail
