import HttpClient from './http-client'

export const ListService = (function() {
    const getLists = async (filtered, list_type = '') => (
        await HttpClient.get('/list', {
            params: {
                filtered,
                list_type,
            },
        })
    )
    const getListsByType = async ( list_type = 0, filtered = false) => (
        await HttpClient.get('/list/getLists_by_type', {
            params: {
                filtered,
                list_type,
            },
        })
    )

    const searchLists = async (params) => {
        return await HttpClient.get('/list/search', {
            params,
        })
    }

    const addList = async(body) => {
        return await HttpClient.post("/list", body);
    }

    const getListById = async (id, access) => (
        await HttpClient.get(`/list/${id}`, {
            params: {
                access,
            }
        })
    )

    const editList = async(id, data) => {
        return await HttpClient.put(`/list/${id}`, data);
    }

    const getUsers = async () => (
        await HttpClient.get('/list/users')
    )

    const addUserAccess = async (list_id, user_id) => (
        await HttpClient.post('/list/user_access', {
            list_id,
            user_id,
        })
    )

    const removeUserAccess = async (list_id, user_id) => (
        await HttpClient.delete(`/list/user_access/${list_id}/${user_id}`)
    )

    return {
        getLists,
        searchLists,
        addList,
        getListById,
        editList,
        getUsers,
        addUserAccess,
        removeUserAccess,
        getListsByType
    }
})()