import { useDispatch, useSelector } from 'react-redux'

import { LeadService } from '../../services'
import {
  REQUEST_LEADS_INIT,
  REQUEST_LEADS_SUCCESS,
  REQUEST_LEAD_DETAIL_INIT,
  REQUEST_LEAD_DETAIL_SUCCESS,
  NOTI_TYPE,
} from '../types'
import { useProgress } from './progress.hook'
import { useNotification } from './notification.hook'

export const useLead = () => {
  const dispatch = useDispatch()
  const { requestLeads, requestLeadDetail } = useSelector(({ lead }) => lead)
  const { startProgress, stopProgress } = useProgress()
  const { setNewNotification } = useNotification()

  const loadRequestLeads = async (params) => {
    try {
      dispatch({ type: REQUEST_LEADS_INIT })
      startProgress()
      const response = await LeadService.getRequestLeads(params)
      dispatch({ type: REQUEST_LEADS_SUCCESS, payload: response.data })
      stopProgress()
    } catch (error) {
      stopProgress()
    }
  }

  const licenseRequestLead = async (data) => {
    try {
      startProgress()
      await LeadService.licenseRequestLead(data)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Licensed successfully.')
      stopProgress()
    } catch (error) {
      setNewNotification(NOTI_TYPE.WARNING, 'Something went wrong. Please try again.')
      stopProgress()
    }
  }

  const loadRequestLeadDetail = async (id) => {
    try {
      dispatch({
        type: REQUEST_LEAD_DETAIL_INIT,
      })
      startProgress()
      const response = await LeadService.getRequestLeadDetail(id)
      dispatch({
        type: REQUEST_LEAD_DETAIL_SUCCESS,
        payload: response.data,
      })
      stopProgress()
    } catch ({response, message}) {
      setNewNotification(NOTI_TYPE.WARNING, response?.data?.message || message)
      stopProgress()
    }
  }

  const rateRequestLead = async (id, rate) => {
    try {
      startProgress()
      await LeadService.rateRequestLead(id, rate)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Your rate is saved.')
      stopProgress()
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, 'Failed to save your rate. Please try again later.')
      stopProgress()
    }
  }

  return {
    requestLeads,
    requestLeadDetail,
    loadRequestLeads,
    licenseRequestLead,
    loadRequestLeadDetail,
    rateRequestLead,
  }
}
