import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { ROLE_ADMIN, ROLE_SUPPORT } from '../../../consts'

const AdminRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route {...rest} render={
      props => {
        if (!user) {
          return <Redirect to={{
            pathname: '/auth/login',
            state: {
              from: props.location
            },
          }} />
        }
        if (user.role.description !== ROLE_ADMIN && user.role.description !== ROLE_SUPPORT) {
          return <Redirect to={{
            pathname: '/dashboard/my-wallet',
            state: {
              from: props.location
            },
          }} />
        }
        return <Component {...rest} {...props} />
      }
    } />
  )
}

export default AdminRoute
