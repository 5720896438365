import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import logo from '../../../images/logo.png'
import logoText from '../../../images/logo-text.png'

import { useUser } from '../../../store/hooks'
import { detectMob } from '../../../utils'
import { ROLE_ADMIN, ROLE_USER, ROLE_SUPPORT } from '../../../consts'

const NavHeader = () => {
  const { user } = useUser()
  const userRole = user?.role?.description || ROLE_USER
  const [toggle, setToggle] = useState(true)

  let homeLink
  if (userRole === ROLE_ADMIN || userRole === ROLE_SUPPORT) {
    homeLink = '/admin/search-users'
  } else {
    homeLink = '/dashboard/my-wallet'
  }

  return (
    <div className="nav-header">
        <Link to={homeLink} className="brand-logo">
          <img className="logo-abbr" src={logo} alt="" />
          <img className="logo-compact" src={logoText} alt="" />
          <img className="brand-title" src={logoText} width={94} alt="" />
        </Link>

        <div className="nav-control" onClick={() => setToggle(!toggle)}>
          <div className={`hamburger ${toggle && !detectMob() ? "is-active" : ""}`}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
          </div>
        </div>
    </div>
  )
}

export default NavHeader
