import HttpClient from './http-client'

export const SupportService = (function () {
  const getSupports = async () => (
    await HttpClient.get('/supports')
  )

  const getSupportById = async id => (
    await HttpClient.get(`/supports/${id}`)
  )

  const addSupport = async params => (
    await HttpClient.post('/supports', params)
  )

  const updateSupport = async (id, params) => (
    await HttpClient.put(`/supports/${id}`, params)
  )

  const deleteSupport = async id => (
    await HttpClient.delete(`/supports/${id}`)
  )

  return {
    getSupports,
    getSupportById,
    addSupport,
    updateSupport,
    deleteSupport,
  }
})()
