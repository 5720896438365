import React, { useState, useEffect } from 'react'
import { Card, Alert } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { format as formatDate, parseISO } from 'date-fns'
import ReactTooltip from 'react-tooltip';

import { NOTI_TYPE } from '../../../../../store/types'
import {
  useStaking,
  useNotification,
  useStakingPool,
  useProgress,
  useWallet
} from '../../../../../store/hooks'
import { Wizard, useWizardStep } from "react-wizard-primitive";

const AddStaking = () => {
  const history = useHistory()  
  const { setNewNotification } = useNotification()
  const { addStaking, stakings, getStakings } = useStaking()
  const { banks, loadBanks } = useWallet()
  const { getStakingPools } = useStakingPool()
  const { startProgress, stopProgress } = useProgress()

  const [form, setForm] = useState({
    staking_pool_id: -1,
    balance: 0,
  })

  const [availableToken, setAvailableToken] = useState(0);
  const [selectedStaking, setSelectedStaking] = useState([]);
  const [stakingPools, setStakingPools] = useState([])
  const [stakedAmount, setStakedAmount] = useState(0)
  
  useEffect(() => {
    (async () => {
      const pools = await getStakingPools()      
      setStakingPools(pools)      
      await loadBanks();
      await getStakings();
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    (async () => {      
      let balance = 0;
      banks.map((bank, index) => {
        if(!bank.is_locked){
          balance = balance + parseFloat(bank.balance);
        }
      });
      balance = parseFloat(Math.abs(balance).toFixed(2))
      setAvailableToken(balance);
      setForm((prev) => ({
        ...prev,
        balance: balance,
      }))
    })()
  }, [banks]) // eslint-disable-line

  const handleChange = (event) => {
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }))
  }

  const handleChangeStaking = (_pool) => {    
    setForm((prev) => ({
      ...prev,
      staking_pool_id: _pool.id,
    }))

    //update staked_amount
    let staked_amount = 0
    stakings.map((staking, index) => {
      if(staking.frn_stakingpoolid == _pool.id)
      staked_amount += parseFloat(staking.bank.balance);
    });

    setStakedAmount(staked_amount)
    setSelectedStaking(_pool)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!form.staking_pool_id) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please select the staking pool.'
      )
      return
    }
    if (!form.balance) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please enter the amount of balance.'
      )
      return
    }
    const selectedPool = stakingPools.find(pool => pool.id === parseInt(form.staking_pool_id, 10))

    if (selectedPool) {
      if (stakedAmount <= 0 && selectedPool.min_amount > form.balance) {
        setNewNotification(
          NOTI_TYPE.WARNING,
          `The amount entered is less than minimum allowed.`
        )
        return
      }

      let max_amount = selectedPool.max_amount - parseFloat(stakedAmount);

      if (max_amount < form.balance) {
        setNewNotification(
          NOTI_TYPE.WARNING,
          `The amount entered is greater than maximum allowed.`
        )
        return
      }
    }

    try {
      startProgress()
      await addStaking({
        staking_pool_id: form.staking_pool_id,
        balance: form.balance,
      })
      stopProgress()

      history.push('/dashboard/my-staking');
    } catch (err) {
      stopProgress()
    }
  }

  const RenderPoolSelect = () => {
    const { isActive, nextStep } = useWizardStep();
    if (!stakingPools.length) {
      return (
        <tr>
          <td colSpan={7} className="text-center">
            No staking pools found
          </td>
        </tr>
      )
    }

    return (
      isActive ?
        <div  className="table-responsive">
          <p>Please select the staking pool.</p>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Percent</th>
                <th>Duration(days)</th>
                <th>Min Amount(DOOR)</th>
                <th>Max Amount(DOOR)</th>               
                <th></th>
              </tr>
            </thead>
            <tbody>
              {stakingPools.map(pool => (
                <tr key={pool.id} className="point-tr" onClick={() => {handleChangeStaking(pool); nextStep()}}>
                  <td>                    
                    { pool.name }                    
                  </td>
                  <td>
                    { parseFloat(pool.percent).toFixed(2) }%
                  </td>
                  <td>
                    { parseInt(pool.duration) } days
                  </td>
                  <td>
                    { parseInt(pool.min_amount || 0) }
                  </td>
                  <td>
                    { parseInt(pool.max_amount || 0) }
                  </td>
                  <td>        
                    { pool.id == form.staking_pool_id ? 
                      <span class="badge badge-success">Selected</span> :
                      '' }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>          
        </div>
      : null
    )    
  }

  const SecondStep = () => {
    const { isActive, previousStep} = useWizardStep();
    
    return isActive ? <div>
      <div className="form-row">   
      <div className="form-group col-md-6 input-primary">
        <label>
          Selected Staking Pool Name:
        </label>
        <input                
          className="form-control"                                
          value={form.staking_pool_id >= 0 ? selectedStaking?.name : '-' }
          readOnly
        />
      </div>      
      <div className="form-group col-md-6">
        <label>
          Staking Percent (%)                
        </label>
        <input                
          className="form-control"                                
          value={form.staking_pool_id >= 0 ? selectedStaking?.percent : '-' }
          readOnly
        />
      </div>
      <div className="form-group col-md-6">
        <label>
          Staking Duration (days)
        </label>
        <input                
          className="form-control"                                
          value={form.staking_pool_id >= 0 ? selectedStaking?.duration : '-' }
          readOnly
        />
      </div>
      <div className="form-group col-md-6">
        <label>
          MIN Amount (DOOR) 
        </label>
        <input                
          className="form-control"                                
          value={form.staking_pool_id >= 0 ? selectedStaking?.min_amount : '-' }
          readOnly
        />
      </div>
      <div className="form-group col-md-6">
        <label>
          MAX Amount (DOOR)
        </label>
        <input                
          className="form-control"                                
          value={form.staking_pool_id >= 0 ? selectedStaking?.max_amount : '-' }
          readOnly
        />
      </div>
      <div className="form-group col-md-6">
        <label>
          Token Amount already staked
        </label>
        <input                
          className="form-control"                                
          value={form.staking_pool_id >= 0 ? stakedAmount : '-' }
          readOnly
        />
      </div>
 
      <div className="col-md-12 mb-3 mt-4">
        <hr />
        <label>How many Door Tokens do you want to stake?</label>
        <Alert                    
          variant = "info"
          className="alert-dismissible alert-alt fade show"
        >
          Number of Tokens available to Stake: <b>{availableToken}</b> DOOR
        </Alert>
        <div className="input-group mb-3 input-primary">
          <div className="input-group-prepend">
            <span className="input-group-text">DOOR</span>
          </div>
          <input type="number" autoFocus="autoFocus" name="balance" required min="0" onChange={handleChange} className="form-control"  value={form.balance}/>
        </div>
        <div>              
          <p className="balance_display">Staking Amount: <span> { form.balance } DOOR </span></p>                
          <p className="balance_display">Amount received after Stake: <span> { form.staking_pool_id >= 0 ? (parseFloat(form.balance) + parseFloat(form.balance * selectedStaking?.percent / 100) ) : '-'  } DOOR</span></p>
        </div>
      </div>
    </div>
    <div className="form-group">
      <div className="form-check"  data-for='global' data-tip   data-tip data-iscapture='true'>
        <input          
          id="check-confirm"
          className="form-check-input"
          type="checkbox"
          required
        />
        <label className="form-check-label" htmlFor="check-confirm" >
          I agree to the terms of Staking
        </label>
      </div>
      <ReactTooltip id='global' aria-haspopup='true' place="left" type="info" effect="float">
        <p><b>TERMS OF STAKING</b></p>
        <p>1. Once you have selected a staking pool and transferred your DOOR into a staked wallet you will not be able to access or sell the staked DOOR until the stake term has ended.</p>
        <p>2. You can only stake DOOR that is not currently locked in a wallet.</p>
        <p>3. Once the stake term is complete, the interest earned will be deposited into your staked wallet along with the original deposit and it will be unlocked. </p>
      </ReactTooltip>
    </div>
    <button type="button" className="mr-2 btn btn-outline-primary btn-square" onClick={previousStep}>Previous</button>
    <button type="submit" className="btn btn-primary">
      Add New Staking
    </button>
    </div> : null;
  }
  return (
    <div>
      <div className="alert alert-primary notification">
        Staking your DOOR allows you to earn more DOOR. When you stake your DOOR you are locking it up for the designated period of time to earn high yield interest paid in DOOR.   We offer multiple Staking Pools to choose from depending on how much you want to stake and for how long.  Some pools have limitations including a minimum and maximum stake.  To learn more about staking visit our <a href="https://doortoken.gitbook.io/door-help/">Staking Help Guide.</a>
      </div>
      <Card>
        <Card.Header>
          <Card.Title>Add New Staking</Card.Title>
        </Card.Header>
        <Card.Body>
        <Wizard>
          <RenderPoolSelect />
            <form onSubmit={handleSubmit}>
              <div>
                <SecondStep />
              </div>
            </form>
          </Wizard>
        </Card.Body>
      </Card>
      
    </div>
  )
}

export default AddStaking
