import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { NOTI_TYPE } from '../../../../../store/types'
import { useRequest, useList, useProgress, useNotification } from '../../../../../store/hooks'

const EditRequest = () => {
  const { id: requestId } = useParams()
  const { currentRequest, getRequestById, updateRequest } = useRequest()
  const { loadCustomLists } = useList()
  const { startProgress, stopProgress } = useProgress()
  const { setNewNotification } = useNotification()

  const [form, setForm] = useState({
    title: '',
    description: '',
    listIds: [],
  })
  const [customLists, setCustomLists] = useState([])

  useEffect(() => {
    (async () => {
      const lists = await loadCustomLists()
      setCustomLists(lists)
      await getRequestById(requestId)
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!currentRequest.id) {
      return
    }

    setForm({
      title: currentRequest.title || '',
      description: currentRequest.description || '',
      listIds: (currentRequest.request_list_map || []).map(x => x.frn_listid) || [],
    })
  }, [currentRequest])

  const handleChange = (event) => {
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }))
  }

  const handleUpdate = async () => {
    if (!form.title) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please enter the title.'
      )
      return
    }

    if (!form.listIds.length) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please select list(s).'
      )
      return
    }

    try {
      startProgress()
      await updateRequest(requestId, {
        ...form,
        deletedListIds: (currentRequest.request_list_map || [])
          .filter(x => !form.listIds.includes(x.frn_listid))
          .map(x => x.frn_listid)
      })
      stopProgress()
    } catch (e) {
      stopProgress()
    }
  }

  const handleListSelect = async (event) => {
    let listIds = [...form.listIds]
    const value = Number(event.target.value)
    if (event.target.checked) {
      if (!listIds.includes(value)) {
        listIds = [...listIds, value]
      }
    } else if (listIds.includes(value)) {
      const index = listIds.findIndex(i => i === value)
      listIds.splice(index, 1)
    }

    setForm((prev) => ({
      ...prev,
      listIds,
    }))
  }

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Request</h4>
      </div>
      <div className="card-body">
        <form>
          <div className="form-row">            
            <div className="form-group col-md-12">
              <label>Tell us about what service you need and details</label>
              <textarea
                className="form-control"
                rows="3"
                name="description"
                value={form.description}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group mt-4 mb-4">
            {
              customLists.map(list =>
                <div key={list.id} className="form-check mb-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`check-${list.id}`}
                    value={list.id}
                    checked={form.listIds.includes(list.id)}
                    onChange={handleListSelect}
                  />
                  <label className="form-check-label" htmlFor={`check-${list.id}`}>
                    { list.property_label }
                  </label>
                </div>
              )
            }
          </div>
          <button type="button" className="btn btn-info mt-4" onClick={handleUpdate}>
            Update
          </button>
        </form>
      </div>
    </div>
  )
}

export default EditRequest