import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { DropzoneArea } from 'material-ui-dropzone'

import { NOTI_TYPE } from '../../../../../store/types'
import {
  useProperty,
  useNotification,
  useUser,
  useList,
  useProgress,
} from '../../../../../store/hooks'
import { uploadFiles } from '../../../../../utils'
import { ROLE_ADMIN, ROLE_SUPPORT } from '../../../../../consts'

const AddNewProperty = () => {
  const history = useHistory()
  const { user } = useUser()
  const { setNewNotification } = useNotification()
  const { addProperty } = useProperty()
  const { lists, loadLists } = useList()
  const { startProgress, stopProgress } = useProgress()

  const isAdmin = user?.role?.description === ROLE_ADMIN
    || user?.role?.description === ROLE_SUPPORT

  const [form, setForm] = useState({
    parcel_number: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    area_lot_sf: '',
    area_building_sf: '',
    num_beds: '',
    num_bath: '',
    tax_value: '',
    market_value: '',
    year_built: '',
    type: 'single_family_house',
    files: [],
    is_owner: 1,
  })

  const [selectedLists, setSelectedLists] = useState([])

  useEffect(() => {
    (async () => {
      await loadLists()
    })()
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    let value = e.target.value

    if (e.target.name === 'zip') {
      // Stripe non-digit characters from a zip code.
      value = value.replace(/\D/g, '')

      // Prevent zip codes from being more than 5 digits.
      if (value.length > 5) {
        return
      }
    }

    if (e.target.name === 'state') {
      // Stripe digit characters from a state.
      value = value.replace(/\d/g, '')

      // Prevent state from being more than 2 letters.
      if (value.length > 2) {
        return
      }
    }

    setForm((prev) => ({
      ...prev,
      [e.target.name]: value,
    }))
  }

  const handleFilesChange = (files) => {
    setForm((prev) => ({
      ...prev,
      files,
    }))
  }

  const handleListSelect = async (e) => {
    let list = [...selectedLists]
    const value = Number(e.target.value)
    if (e.target.checked) {
      if (!list.includes(value)) {
        list = [...list, value]
      }
    } else if (list.includes(value)) {
      const index = list.findIndex(i => i === value)
      list.splice(index, 1)
    }

    setSelectedLists(list)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      if (!form.files.length) {
        setNewNotification(
          NOTI_TYPE.WARNING,
          'Please upload atleast one document.'
        )
        return
      }

      startProgress()
      form.files = await uploadFiles(form.files)

      await addProperty({
        parcel_number: form.parcel_number,
        address1: form.address1,
        address2: form.address2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        type: form.type,
        files: form.files,
        lists: selectedLists,
        is_owner: form.is_owner,
      })
      stopProgress()

      setForm({})
      history.push('/dashboard/my-property')
    } catch (err) {
      stopProgress()
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Unable to add property. Please try again.'
      )
    }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Property Information</Card.Title>
      </Card.Header>
      <Card.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-12 text-muted">
              Currently only Properties located in the United States qualify for DOOR Rewards.
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Property Type
                <span className="required">*</span>
              </label>
              <select
                className="form-control"
                name="type"
                required
                value={form.type}
                onChange={handleChange}
              >
                <option value="single_family_house">
                  Single Family House
                </option>
                <option value="condo">Condo</option>
                <option value="townhouse">Townhouse</option>
                <option value="duplex">Duplex</option>
                <option value="commercial">Commercial</option>
              </select>
            </div>
            <div className="form-group col-md-6">
              {isAdmin && (
                <>
                  <label>
                    Parcel Number
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="parcel_number"
                    required
                    value={form.parcel_number}
                    onChange={handleChange}
                  />
                </>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Property Address
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="address1"
                required
                value={form.address1}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Property Address 2</label>
              <input
                type="text"
                className="form-control"
                name="address2"
                value={form.address2}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                City
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="city"
                required
                value={form.city}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-4">
              <label>
                State
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="state"
                required
                value={form.state}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-2">
              <label>
                Zip
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="zip"
                required
                value={form.zip}
                onChange={handleChange}
              />
            </div>
          </div>
          {isAdmin && (
            <div className="form-row">
              <div className="form-group col-md-3">
                <label>
                  Area Lot SF
                  <span className="required">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="area_lot_sf"
                  required
                  value={form.area_lot_sf}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-3">
                <label>
                  Area Building SF
                  <span className="required">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="area_building_sf"
                  required
                  value={form.area_building_sf}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-3">
                <label>
                  Number of Beds
                  <span className="required">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="num_beds"
                  required
                  value={form.num_beds}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-3">
                <label>
                  Number of Bath
                  <span className="required">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="num_bath"
                  required
                  value={form.num_bath}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
          {isAdmin && (
            <div className="form-row">
              <div className="form-group col-md-4">
                <label>
                  Tax Value
                  <span className="required">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="tax_value"
                  step="0.01"
                  required
                  value={form.tax_value}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-4">
                <label>
                  Market Value
                  <span className="required">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="market_value"
                  step="0.01"
                  required
                  value={form.market_value}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-4">
                <label>
                  Year of Built
                  <span className="required">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="year_built"
                  required
                  value={form.year_built}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
          <div className="alert alert-primary notification">
            <p>
              In order to receive Door Token rewards for registering a property,
              you must verify you are the property owner or agent.
              For details on who qualifies as an agent please&nbsp;
              <a href="https://doortoken.gitbook.io/door-token/" target="blank" rel="noreferrer">see our guide</a>.
            </p>
            <p>
              Here is a list of documents that will help with verification (we only require one):<br/>
              ● Utility Bill with your Name (water, electric, cable, etc)<br/>
              ● Listing Agreement (if you are real estate agent/broker)<br/>
              ● Lease Agreement (if you are lessee)<br/>
              ● Home Inspection (if your are home inspector)<br/>
              ● Drivers License (if the address of the property is on the license)<br/>
            </p>
          </div>
          <DropzoneArea
            acceptedFiles={[".pdf, .doc, .docx, .jpeg, .jpg, .png"]}
            dropzoneText="Upload Documents to Prove Ownership"
            showFileNames
            maxFileSize={15000000}
            onChange={handleFilesChange}
          />
          <div className="form-group mt-4 mb-4">
            {
              lists.map(l =>
                <div className="form-check mb-2" key={l.id}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`check-${l.id}`} value={l.id}
                    checked={selectedLists.includes(l.id)}
                    onChange={handleListSelect}
                  />
                  <label className="form-check-label" htmlFor={`check-${l.id}`}>
                    {l.property_label}
                  </label>
                </div>
              )
            }
          </div>
          <div className="form-group">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  type="radio"
                  name="is_owner"
                  className="form-check-input"
                  value={1}
                  defaultChecked={form.is_owner === 1}
                  onChange={handleChange}
                />
                Owner
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  type="radio"
                  name="is_owner"
                  className="form-check-input"
                  value={0}
                  defaultChecked={form.is_owner === 0}
                  onChange={handleChange}
                />
                Renter
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="form-check">
              <input
                id="check-confirm"
                className="form-check-input"
                type="checkbox"
                required
              />
              <label className="form-check-label" htmlFor="check-confirm">
                Check to confirm you are the Owner or Authorized Agent of
                this Property
              </label>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Add Property
          </button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default AddNewProperty
