import {
    SEARCH_USERS_INIT, SEARCH_USERS_SUCCESS, SEARCH_USERS_FAILED,
    SEARCH_UNVERIFIED_USERS_INIT, SEARCH_UNVERIFIED_USERS_SUCCESS, SEARCH_UNVERIFIED_USERS_FAILED,
    SEARCH_USER_DELETE, SEARCH_USER_ACTIVATE, SEARCH_UNVERIFIED_USER_DELETE, GET_SEARCH_USER_FAILED,
    GET_SEARCH_USER_INIT, GET_SEARCH_USER_SUCCESS, USER_BLOCK, USER_UNBLOCK, USER_REJECT,
    USER_UPDATE_BY_ADMIN, SEARCH_VERIFIED_USERS_INIT, SEARCH_VERIFIED_USERS_SUCCESS,
    SEARCH_VERIFIED_USERS_FAILED,
} from '../types';

const initialState = {
    searchedUsers: {
        data: null,
        loading: false,
        error: '',
    },
    unVerifiedUsers: {
        data: null,
        loading: false,
        error: '',
    },
    verifiedUsers: {
        data: null,
        loading: false,
        error: '',
    },
    editUser: {
        data: {},
        loading: false,
        error: '',
    }
};

export default function usersReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SEARCH_USERS_INIT:
            return {
                ...state,
                searchedUsers: {
                    ...state.searchedUsers,
                    loading: true,
                    error: ''
                }
            };
        case SEARCH_USERS_SUCCESS:
            return {
                ...state,
                searchedUsers: {
                    data: payload,
                    loading: false,
                    error: ''
                }
            };
        case SEARCH_USERS_FAILED:
            return {
                ...state,
                searchedUsers: {
                    data: [],
                    loading: false,
                    error: payload
                }
            };
        case SEARCH_USER_DELETE:
            return {...state,
                editUser: {
                    ...state.editUser,
                    data: {...state.editUser.data, is_deleted: true}
                }
            };
        case SEARCH_USER_ACTIVATE:
            return {...state,
                    editUser: {
                        ...state.editUser,
                        data: {...state.editUser.data, is_deleted: false}
                    }
                };
        case SEARCH_UNVERIFIED_USERS_INIT:
            return {
                ...state,
                unVerifiedUsers: {
                    ...state.unVerifiedUsers,
                    loading: true,
                    error: ''
                }
            };
        case SEARCH_UNVERIFIED_USERS_SUCCESS:
            return {
                ...state,
                unVerifiedUsers: {
                    data: payload,
                    loading: false,
                    error: ''
                }
            };
        case SEARCH_UNVERIFIED_USERS_FAILED:
            return {
                ...state,
                unVerifiedUsers: {
                    data: [],
                    loading: false,
                    error: payload
                }
            };
        case SEARCH_VERIFIED_USERS_INIT:
            return {
                ...state,
                verifiedUsers: {
                    ...state.verifiedUsers,
                    loading: true,
                    error: ''
                }
            }
        case SEARCH_VERIFIED_USERS_SUCCESS:
            return {
                ...state,
                verifiedUsers: {
                    data: payload,
                    loading: false,
                    error: ''
                }
            }
        case SEARCH_VERIFIED_USERS_FAILED:
            return {
                ...state,
                verifiedUsers: {
                    data: [],
                    loading: false,
                    error: payload
                }
            }
        case SEARCH_UNVERIFIED_USER_DELETE: {
            const data = [...state.unVerifiedUsers.data];
            let index = data.findIndex(x => x.id === payload.id);
            data.splice(index, 1);
            return {
                ...state,
                unVerifiedUsers: {
                    ...state.unVerifiedUsers,
                    data
                }
            }
        }
        case GET_SEARCH_USER_INIT:
            return {
                ...state,
                editUser: {
                    ...state.editUser,
                    loading: true,
                    error: ''
                }
            };
        case GET_SEARCH_USER_SUCCESS:
            return {
                ...state,
                editUser: {
                    data: payload,
                    loading: false,
                    error: ''
                }
            };
        case GET_SEARCH_USER_FAILED:
            return {
                ...state,
                editUser: {
                    data: {},
                    loading: false,
                    error: payload
                }
            };
        case USER_UPDATE_BY_ADMIN:
            return {
                ...state,
                editUser: {
                    ...state.editUser,
                    data: {
                        ...state.editUser.data,
                        ...payload,
                        verified: payload.verified ? 1 : 0,
                        is_email_verified: payload.is_email_verified,
                        is_phone_verified: payload.is_phone_verified,
                    },
                },
            }
        case USER_BLOCK:
            return {
                ...state,
                editUser: {
                    ...state.editUser,
                    data: { ...state.editUser.data, is_locked: true }
                }
            };
        case USER_UNBLOCK:
            return {
                ...state,
                editUser: {
                    ...state.editUser,
                    data: { ...state.editUser.data, is_locked: false }
                }
            };
        case USER_REJECT:
            return {
                ...state,
                editUser: {
                    ...state.editUser,
                    data: { ...state.editUser.data, verified: 0 }
                }
            };
        default:
            return state;
    }
}