import { useDispatch, useSelector } from "react-redux";
import { UsersService} from "../../services";
import {
    SEARCH_USERS_INIT, SEARCH_USERS_SUCCESS, SEARCH_USERS_FAILED,
    SEARCH_UNVERIFIED_USERS_INIT, SEARCH_UNVERIFIED_USERS_SUCCESS, SEARCH_UNVERIFIED_USERS_FAILED,
    NOTI_TYPE, SEARCH_USER_DELETE, SEARCH_USER_ACTIVATE, SEARCH_UNVERIFIED_USER_DELETE,
    GET_SEARCH_USER_FAILED, GET_SEARCH_USER_INIT, GET_SEARCH_USER_SUCCESS, USER_BLOCK, USER_UNBLOCK,
    USER_REJECT, USER_UPDATE_BY_ADMIN, SEARCH_VERIFIED_USERS_INIT, SEARCH_VERIFIED_USERS_SUCCESS,
    SEARCH_VERIFIED_USERS_FAILED,
} from "../types";
import { useNotification } from './notification.hook';
import { useProgress } from "./progress.hook";

export const useUsers = () => {
    const dispatch = useDispatch()
    const {
        searchedUsers,
        unVerifiedUsers,
        verifiedUsers,
        editUser: { data: editUserData, loading: isEditUserLoading },
    } = useSelector(({ users }) => users)
    const { setNewNotification } = useNotification()
    const { startProgress, stopProgress } = useProgress()

    const getUserById = async (id) => {
        try {
            dispatch({type: GET_SEARCH_USER_INIT})
            startProgress()
            const response = await UsersService.getUserById(id)
            dispatch({type: GET_SEARCH_USER_SUCCESS, payload: response.data})
            stopProgress()
            return response
        } catch ({response, message}) {
            dispatch({
                type: GET_SEARCH_USER_FAILED,
                payload: response?.data?.message || message
            })
            stopProgress()
            return false
        }
    }

    const searchUsers = async (params) => {
        try {
            dispatch({ type: SEARCH_USERS_INIT })
            startProgress()
            const response = await UsersService.searchUsers(params)
            dispatch({type: SEARCH_USERS_SUCCESS, payload: response.data})
            stopProgress()
            return response
        } catch ({response, message}) {
            dispatch({
                type: SEARCH_USERS_FAILED,
                payload: response?.data?.message || message
            })
            stopProgress()
            return false
        }
    }

    const generateDOORId = async (params) => {
        try {
            dispatch({ type: SEARCH_USERS_INIT })
            startProgress()
            const response_doorid = await UsersService.GenerateDoor();
            const response = await UsersService.searchUsers(params)
            dispatch({type: SEARCH_USERS_SUCCESS, payload: response.data})
            stopProgress()
            return response
        } catch ({response, message}) {
            dispatch({
                type: SEARCH_USERS_FAILED,
                payload: response?.data?.message || message
            })
            stopProgress()
            return false
        }
    }

    const searchUnVerifiedUsers = async (params) => {
        try {
            dispatch({type: SEARCH_UNVERIFIED_USERS_INIT});
            startProgress();
            const response = await UsersService.searchUnverifiedUsers(params)
            dispatch({type: SEARCH_UNVERIFIED_USERS_SUCCESS, payload: response.data});
            stopProgress();
            return response;
        } catch ({response, message}) {
            dispatch({
                type: SEARCH_UNVERIFIED_USERS_FAILED,
                payload: response?.data?.message || message
            });
            stopProgress();
            return false;
        }
    };

    const searchVerifiedUsers = async (params) => {
        try {
            dispatch({ type: SEARCH_VERIFIED_USERS_INIT })
            startProgress()
            const response = await UsersService.searchVerifiedUsers(params)
            dispatch({ type: SEARCH_VERIFIED_USERS_SUCCESS, payload: response.data })
            stopProgress()
            return response
        } catch ({response, message}) {
            dispatch({
                type: SEARCH_VERIFIED_USERS_FAILED,
                payload: response?.data?.message || message
            })
            stopProgress()
            return false
        }
    }

    const deleteSearchedUser = async (id) => {
        try {
            startProgress();
            await UsersService.deleteUser(id);
            dispatch({ type: SEARCH_USER_DELETE, payload: { id } });
            setNewNotification(NOTI_TYPE.SUCCESS, "Success! User was de-activated.");
            stopProgress();
        } catch(err) {
            setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
            stopProgress();
        }
    }

    const activateUser = async (id) => {
        try {
            startProgress();
            await UsersService.activateUser(id)
            dispatch({ type: SEARCH_USER_ACTIVATE, payload: { id } });
            setNewNotification(NOTI_TYPE.SUCCESS, "Success! User was activated.");
            stopProgress();
        } catch(err) {
            setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
            stopProgress();
        }
    }

    const deleteUnVerifiedUser = async (id) => {
        try {
            startProgress();
            await UsersService.deleteUser(id);
            dispatch({ type: SEARCH_UNVERIFIED_USER_DELETE, payload: { id } });
            setNewNotification(NOTI_TYPE.SUCCESS, "Success! User deleted.");
            stopProgress();
        } catch(err) {
            setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
            stopProgress();
        }
    }

    const updateUser = async (id, data) => {
        try {
            startProgress();
            await UsersService.updateUser(id, data)
            dispatch({
                type: USER_UPDATE_BY_ADMIN,
                payload: data,
            })
            setNewNotification(NOTI_TYPE.SUCCESS, 'Updated successfully.');
            stopProgress();
        } catch(err) {
            setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
            stopProgress();
        }
    }

    const lockUser = async (id) => {
        try {
            startProgress()
            await UsersService.lockUser(id)
            dispatch({ type: USER_BLOCK, payload: { id } })
            setNewNotification(NOTI_TYPE.SUCCESS, 'User has been blocked successfully.')
            stopProgress()
        } catch (err) {
            setNewNotification(NOTI_TYPE.WARNING, 'Something went wrong, Please refresh the browser and try again.')
            stopProgress()
        }
    }

    const unlockUser = async (id) => {
        try {
            startProgress()
            await UsersService.unlockUser(id)
            dispatch({ type: USER_UNBLOCK, payload: { id } })
            setNewNotification(NOTI_TYPE.SUCCESS, 'User has been unblocked successfully.')
            stopProgress()
        } catch (err) {
            setNewNotification(NOTI_TYPE.WARNING, 'Something went wrong, Please refresh the browser and try again.')
            stopProgress()
        }
    }

    const loginAs = async (userId) => {
        try {
            const response = await UsersService.loginAs(userId)
            return response.data.token
        } catch (err) {
            return null
        }
    }

    const rejectUser = async (id, reason) => {
        try {
            startProgress()
            await UsersService.rejectUser(id, reason)
            dispatch({ type: USER_REJECT, payload: { id } })
            setNewNotification(NOTI_TYPE.SUCCESS, 'User verification has been rejected successfully.')
            stopProgress()
        } catch (err) {
            setNewNotification(NOTI_TYPE.WARNING, 'Something went wrong, Please refresh the browser and try again.')
            stopProgress()
        }
    }

    const loadUserNft = async (params) => {
        try {
          startProgress()
          const response = await UsersService.loadUserNft(params);
          stopProgress();
          return response.data;
        } catch (error) {
          stopProgress();
          setNewNotification(NOTI_TYPE.WARNING, 'Failed to user NFT data. Please refresh your browser');
        }
      }

    return {
        searchedUsers,
        unVerifiedUsers,
        verifiedUsers,
        editUser: editUserData,
        isEditUserLoading,
        updateUser,
        getUserById,
        searchUsers,
        searchUnVerifiedUsers,
        searchVerifiedUsers,
        deleteSearchedUser,
        deleteUnVerifiedUser,
        activateUser,
        lockUser,
        unlockUser,
        loginAs,
        rejectUser,
        loadUserNft,
        generateDOORId
    };
};
