const diffConditions = () => {
  return [
    {
      title: "Door User Terms of Service",
      content: `
      <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="blog-detail-content">
            <ul>
              <li>Updated August 15,2021</li>
            </ul>
            <p>
              These Door User Terms of Service (“Terms”) apply to your
              access to and use of Services (as defined in Section 1
              below) provided by DoorToken.org (“Company”). By accessing
              or using our Services, you agree to these Terms. If you do
              not agree to these Terms, including the mandatory
              arbitration provision and class action waiver in Section
              15, do not access or use our Services. If you have any
              questions about these Terms, please contact us at
              info@doortoken.org
            </p>
            <p>You and Company agree as follows:</p>
            <p>
              <strong>1. Overview and Scope</strong>
              <br />
              Company has released a platform for connecting consumers
              with businesses under MyDoorWallet.com ("Door
              Application"). People who use the Door Application may
              activate the Platform’s rewards feature (“Door Rewards”)
              for making optional contributions in Door Tokens (“DOOR”)
              in exchange for adding and maintaining personal data, real
              property data and related information regarding services
              requested by the property owner or authorized agent. Users
              may also participate in promotions which enable them to
              earn more DOOR in exchange for work including referring
              others to sign up on the Door Application.
            </p>
            <p>
              These Terms govern the services (the “Services”) that the
              Company makes available to Door Application users who
              activate Door Rewards (“Door Rewards Users”), including
              the ability to make DOOR contributions, earn DOOR from
              licensing property and other related data, and obtain
              related services through the Platform.
            </p>
            <p>
              <strong>1.1 Overview of Current Platform</strong>
              <br />
              Currently, the Platform consists of a web application at
              MyDoorWallet.com (“Door Application”). The Door
              Application, which launched in 2021 allows users to
              participate in the exchange of data and information
              including personal data, real estate data and opt-in
              information related to products and services. DOOR users
              contribute personal and property data to the Door
              Application in order to make available to Advertisers to
              license the data. Advertisers purchase Ads and/or License
              data using DOOR via the Door Application.
            </p>
            <p>
              <strong>1.2 Door Token</strong>
              <br />
              DOOR is based on the Ethereum protocol and conforms to the
              ERC20 standard. DOOR will be the utility token for
              conducting transactions and obtaining Data on the Platform
              and also rewarding users for contributing data, referring
              other users and participating in promotions. Further
              details regarding the Platform are described in the Door
              Token Whitepaper (“Whitepaper”), which is available at:
              https://doortoken.org. The information contained in the
              Whitepaper and website are of descriptive nature only, and
              do not, unless explicitly incorporated herein, form part
              of the Terms.
            </p>
            <p>
              <strong>2. Eligibility</strong>
              <br />
              In order to access and use our Services, you must:
              <br />
              (a) be at least 18 years old and have the capacity to
              enter into a legally binding agreement,
              <br />
              (b) comply with all the terms and conditions set forth in
              these Terms, and
              <br />
              (c) activate the Services as described in Section 3 below.
              <br />
            </p>
            <p>
              You may not access or use our Services if:
              <br />
              (d) you have been suspended from using our Services,
              <br />
              (e) under the applicable law of the jurisdiction(s) in
              which you reside or conduct business, you are prohibited
              from using the Services or do not have the requisite
              licenses or other governmental authorizations to use the
              Services,
              <br />
              (f) you are located in a country that is subject to a U.S.
              government embargo or that has been designated by the U.S.
              government as a “terrorist supporting” country,
              <br />
              (g) you are listed on any U.S. government list of
              prohibited or restricted parties, or
              <br />
              (h) your use of the Services breaches any other agreement
              to which you are a party.
            </p>
            <p>
              If you are accessing or using our Services on behalf of
              another person or entity: references to “you” in these
              Terms collectively refer to you and that person or entity,
              and you represent that you are authorized to accept these
              Terms on that person or entity’s behalf and that the
              person or entity agrees to be responsible to us if you or
              the other person or entity violates these Terms.
            </p>
            <p>
              If you are the parent or legal guardian of individual(s)
              between 16 and the legal age of majority in the
              jurisdiction where you reside, you may allow those
              individual(s) to use the Services solely under your direct
              supervision and only in accordance with these Terms,
              provided that you agree that you will remain liable for
              all activities of such individual(s), including their
              breach of these Terms, and references to “you” shall refer
              jointly to you and such individual(s).
            </p>
            <p>
              <strong>
                3. Activation &amp; Deactivation, User Wallet
              </strong>
            </p>
            <p>
              <strong>3.1 Activation</strong>
              <br />
              To become a User, you must activate the Services within
              your Door Application, and review and accept these Terms
              with Company.
            </p>
            <p>
              When you activate the Services, a DOOR user wallet will be
              created for you in the Door Application (“User Wallet”).
              in connection with your use of the User Wallet.
            </p>
            <p>
              When you active the Services, you explicitly agree to
              allow DOOR or it's affilliates to contact you directly via
              Phone, Email, SMS or any other form of communication.
            </p>
            <p>
              <b>TCPA Consent for United States Residents</b>
            </p>
            <p>
              EXPRESS WRITTEN CONSENT. BY SUBMITTING YOUR CONTACT
              INFORMATION, YOU ARE PROVIDING YOUR EXPRESS WRITTEN
              CONSENT TO RECEIVE COMMUNICATIONS FROM US AT THE EMAIL
              ADDRESS AND TELEPHONE NUMBERS YOU ENTERED INTO OUR CONTACT
              FORM, OR THAT YOU LATER PROVIDE TO US OR ENTER INTO YOUR
              CONTACT PAGE.
            </p>
            <p>
              E-MAILS, CALLS, AND TEXTS. THESE COMMUNICATIONS MAY
              INCLUDE TELEMARKETING MESSAGES, THROUGH THE USE OF EMAIL,
              LANDLINE PHONE, FAX, CELLULAR PHONE, AND TEXT MESSAGES
              (INCLUDING SMS AND MMS).
            </p>
            <p>
              AUTODIALING. WE MAY USE AN AUTOMATIC TELEPHONE DIALING
              SYSTEM (OR "AUTO-DIALER"), WHICH MAY EMPLOY AN ARTIFICIAL
              OR PRE-RECORDED VOICE OR "ROBOTEXTS." YOUR CARRIER'S
              STANDARD RATES AND CHARGES MAY APPLY.
            </p>
            <p>
              NO PURCHASE NECESSARY. AGREEING TO THESE COMMUNICATIONS IS
              NOT A CONDITION OF PURCHASING ANY PROPERTY, GOODS, OR
              SERVICES FROM US.
            </p>
            <p>
              REVOKING CONSENT AND OPTING OUT. YOU MAY REVOKE YOUR
              CONSENT TO RECEIVE COMMUNICATIONS AT ANY TIME BY REPLYING
              "STOP" TO ANY OF OUR TEXTS, OR BY ANY OTHER REASONABLE
              MEANS. WE WILL MAKE A COMMERCIALLY REASONABLE EFFORT TO
              COMPLY WITH ANY COMMUNICATIONS FROM YOU OPTING OUT, BUT
              REPLY "STOP" WILL AUTOMATICALLY REVOKE YOUR CONSENT TO
              FURTHER TEXT COMMUNICATIONS, AND WE RECOMMEND THAT METHOD.
              WE MAY TAKE UP TO 30 DAYS TO STOP COMMUNICATIONS IF YOU
              USE A METHOD OTHER THAN THE AUTOMATIC REPLY "STOP." YOU
              CONSENT TO RECEIVE A FINAL TEXT MESSAGE CONFIRMING YOUR
              OPT-OUT. YOU MAY REVOKE YOUR CONSENT TO RECEIVE EMAIL
              COMMUNICATIONS BY USING THE "UNSUBSCRIBE" LINK IN AN EMAIL
              OR ON THE WEBSITE OR BY ANY OTHER REASONABLE MEANS. WE
              WILL MAKE A COMMERCIALLY REASONABLE EFFORT TO COMPLY WITH
              ANY COMMUNICATIONS FROM YOU OPTING OUT OF EMAIL, BUT
              "UNSUBSCRIBE" WILL AUTOMATICALLY REVOKE YOUR CONSENT TO
              FURTHER EMAIL COMMUNICATIONS, AND WE RECOMMEND THAT
              METHOD. WE MAY TAKE UP TO 30 DAYS TO STOP EMAIL
              COMMUNICATIONS IF YOU USE A METHOD OTHER THAN
              "UNSUBSCRIBE". THE "UNSUBSCRIBE" LINK WILL ALSO PERMIT YOU
              TO STOP TEXT COMMUNICATIONS.
            </p>
            <p>
              COMMUNICATION FREQUENCY. HOW OFTEN WE SEND YOU
              COMMUNICATIONS WILL VARY, BECAUSE THE INDIVIDUAL
              SALESPERSON WHO COMMUNICATES WITH YOU WILL DETERMINE IT.
            </p>
            <p>
              <strong>3.2 Deactivation</strong>
              <br />
              You can discontinue being a Door Rewards User at any time
              by deactivating the Services in your Door Application.
            </p>
            <p>
              <strong>3.3 User Wallet Terms and Restrictions</strong>
              <br />
              a) All Door Application Users must verify their identity
              in the Door Application to use all Services
              <br />
              b) All Door Application Users who purchase DOOR via Credit
              Card will be subject to a 10 day holding period where the
              DOOR purchased can not be withdrawn or transferred.
              <br />
              c) All Door Transferred out of the Door Application is the
              responsbility of the the User. The Company is not
              responsible for any DOOR transferred to a 3rd party wallet
              that becomes lost or inaccessible. <br />
              d) There is a Gas Fee that is charged any time Door is
              transferred out of the Door Application. The fee is
              updated based on current estimated Ethereum Gas Fees and
              is charged in DOOR
              <br />
              e) All Users will have withdraw limits and conditions on
              their account to protect from theft, fraud or the
              accidentaly transfer of DOOR to the wrong wallet address.
              These limits are set on daily and monthly withdraws. For
              new users the limits are 2,000 DOOR per day and 10,000
              DOOR per month. These limits can be increased with
              approval of the Company.
              <br />
            </p>
            <p>
              Company has no responsibility or liability with respect to
              your use of your User Wallet, or your inability to use or
              access your User Wallet, regardless of cause.
            </p>
            <p>
              <strong>4. DOOR Tokens </strong>
            </p>
            <p>
              <strong>4.1 Contributions</strong>
              <br />
              The Door Rewards feature in your Door Application allows
              you to receive DOOR in exchange for non-exclusive
              licensing of your Property Data (“Data”). Property Data
              will included the following information:
            </p>
            <p>
              Your contact information including Name, Address, Email
              and Phone.
              <br />
              Your Property Information that is available from Public
              Records
              <br />
              Your open service requests that you have selected in the
              application, which may include (but are not limited to):
              <br />
              Looking for Real Estate Agent
              <br />
              Looking for Home Repair Contractor
              <br />
              Looking for Home Improvement Contractor
              <br />
              Looking for Utility / Service Provider
              <br />
              Looking for Home Financial Provider
              <br />
              Looking for Other Services and Products
              <br />
            </p>
            <p>
              <strong>4.2 Representations and Warranties</strong>
            </p>
            <p>
              Data Advertisers can purchase DOOR to be exclusively used
              to license data within the DOOR Application. You represent
              and warrant that:
            </p>
            <p>
              You have read and understand these Terms;
              <br />
              You have a sufficient understanding of the functionality,
              usage, storage, transmission mechanisms and other material
              characteristics of cryptographic tokens like Bitcoin and
              Ether, token storage mechanisms (such as token wallets),
              blockchain technology and blockchain-based software
              systems to understand these Terms and to appreciate the
              risks and implications of purchasing DOOR;
              <br />
              You have obtained sufficient information about DOOR to
              make an informed decision to purchase DOOR;
              <br />
              You understand the restrictions and risks associated with
              the creation of DOOR by the Smart Contract System as set
              forth herein, and acknowledge and assume all such risks;
            </p>
            <p>
              You understand, acknowledge and assume the risks
              associated with the purchase, holding and use of DOOR in
              connection with the Services and Platform; You understand
              that DOOR confer only the right to receive non-exclusive
              license to data and to access the Platform, and confer no
              other rights of any form with respect to the Platform or
              Company or its corporate affiliates, including, but not
              limited to, any voting, distribution, redemption,
              liquidation, proprietary (including all forms of
              intellectual property), or other financial or legal
              rights;
            </p>
            <p>
              You are purchasing DOOR solely for the purpose of
              licensing Property Data, accessing the Platform, and
              supporting the development, testing, deployment and
              operation of the Platform, being aware of the commercial
              risks associated with the Company and the Platform. You
              are not purchasing DOOR for any other purposes, including,
              but not limited to, any investment, speculative or other
              financial purposes;
            </p>
            <p>
              Your purchase of DOOR complies with applicable law and
              regulation in your jurisdiction, including, but not
              limited to, (i) legal capacity and any other applicable
              legal requirements in your jurisdiction for purchasing
              DOOR, using DOOR, and entering into contracts with us,
              (ii) any foreign exchange or regulatory restrictions
              applicable to such purchase, and (iii) any governmental or
              other consents that may need to be obtained;
            </p>
            <p>
              You will comply with any applicable tax obligations in
              your jurisdiction arising from your purchase of DOOR;
            </p>
            <p>
              If you are purchasing DOOR on behalf of any entity, you
              are authorized to accept these Terms on such entity’s
              behalf and that such entity will be responsible for breach
              of these Terms by you or any other employee or agent of
              such entity (references to “you” in these Terms refer to
              you and such entity, jointly); You are not resident or
              domiciled in New York State or purchasing DOOR from a
              location in New York State;
            </p>
            <p>
              You are not (i) a citizen or resident of a geographic area
              in which access to or use of the Services is prohibited by
              applicable law, decree, regulation, treaty, or
              administrative act, (ii) a citizen or resident of, or
              located in, a geographic area that is subject to U.S. or
              other sovereign country sanctions or embargoes, or (iii)
              an individual, or an individual employed by or associated
              with an entity, identified on the U.S. Department of
              Commerce’s Denied Persons or Entity List, the U.S.
              Department of Treasury’s Specially Designated Nationals or
              Blocked Persons Lists, or the U.S. Department of State’s
              Debarred Parties List. You agree that if your country of
              residence or other circumstances change such that the
              above representations are no longer accurate, that you
              will immediately cease using the Services. If you are
              registering to use the Services on behalf of a legal
              entity, you further represent and warrant that (i) such
              legal entity is duly organized and validly existing under
              the applicable laws of the jurisdiction of its
              organization, and (ii) you are duly authorized by such
              legal entity to act on its behalf; To the fullest extent
              permitted by applicable law, you will indemnify, defend
              and hold harmless Company and our respective past, present
              and future employees, officers, directors, contractors,
              consultants, equity holders, suppliers, vendors, service
              providers, parent companies, subsidiaries, affiliates,
              agents, representatives, predecessors, successors and
              assigns (the “Company Parties”) from and against all
              claims, demands, actions, damages, losses, costs and
              expenses (including attorneys’ fees) that arise from or
              relate to: (i) your purchase or use of DOOR, (ii) your
              responsibilities or obligations under these Terms, (iii)
              your violation of these Terms, or (iv) your violation of
              any rights of any other person or entity.
            </p>
            <p>
              Company reserves the right to exercise sole control over
              the defense, at your expense, of any claim subject to
              indemnification under Section 10(a). This indemnity is in
              addition to, and not in lieu of, any other indemnities set
              forth in a written agreement between you and Company.
            </p>

            <p>
              <strong>4.3 Staking DOOR </strong>
            </p>
            <p>
              You have acknowledged and accepted the terms of staking
              DOOR which includes:
              <br />
              a) You are unable to un-stake and DOOR you have committed
              to a staking pool until the stake expires
              <br />
              b) Any interest paid as a reward for Staking is paid in
              DOOR Tokens.
              <br />
              c) You understand the price of DOOR can change which may
              cause impermanent loss. You understand the value of any
              DOOR you stake can decrease in value during the stake.
              <br />
              d) You understand, acknowledge and assume the risks
              associated of staking DOOR and have researched and
              understand the concept of staking.
            </p>

            <p>Staking (Yield Farming) of DOOR</p>
            <p>
              Users who are actively participating in operating or
              running the DOOR Ad Network can participate in staking
              their DOOR in exchange for DOOR Rewards. When a User
              stakes DOOR they are contributing their DOOR to a
              designated Pool that is used to provide tokens to
              Advertisers. Advertisers require tokens in order to
              purchase advertising on the DOOR Ad Network. In order to
              meet demand of Advertisers, Users stake their tokens for a
              period of time to be used by Advertisers. In order to
              facilitate this transaction, every User must operate or
              co-operate a DOOR Node. A DOOR Node is software that
              faciliates the delivery and tracking of Ads and DOOR
              tokens contributed to the Pool. A DOOR Node can be hosted
              by the User or can be co-located and hosted by the
              Company.
            </p>
            <p>
              NOTE: Rewards are paid to Users in exchange for their
              contributions in Staking only if the User is an active
              participant in operating the DOOR Ad Network. The rewards
              are in exchange for work provided by the User
            </p>

            <p>
              <strong>5. User Reward Pool</strong>
            </p>
            <p>
              The User Reward Pool is allocated to encouraging use of
              the Platform and Services. The Primary use of the User
              Reward Pool will be to reward Property Owners to add and
              update their Property(s) in the app. For each Property
              that is created the Authorized Owner of the Property will
              receive DOOR based on the estimated market value of the
              property(s).
            </p>
            <p>
              <strong>6. Door Messaging</strong>
              Door Rewards includes ads and messaging between Door
              Advertisers and Door Property Owners. When you enable Door
              Rewards, these ads provide you with an opportunity to earn
              DOOR. In order to earn in this way:
            </p>
            <p>
              (a) You must be using a version of the Door Application
              with Door Rewards
              <br />
              (b) You must see or interact with one or more ads or
              messages.
              <br />
              (c) You must be in compliance with these terms, and you
              must not be suspended.
              <br />
              (d) You must update your Property Data once a month.
              <br />
            </p>
            <p>
              If all these requirements are met, you earn DOOR based on
              messages you respond to or advertising you engage with
              through the application.
            </p>
            <p>
              <strong>7. Taxes</strong>
              <br />
              You are responsible for determining what, if any, taxes
              apply to your use of the Services, and for withholding,
              collecting, reporting and remitting the correct taxes, if
              any, to the appropriate tax authorities. Company is not
              responsible for withholding, collecting, reporting, or
              remitting any tax arising from your use of the Services.
            </p>
            <p>
              <strong>8. Door Verified Users and Advertisers</strong>
              <br />
              Company is not a party to and has no responsibility or
              liability with respect to any communications,
              transactions, interactions, disputes or any relations
              whatsoever between you and any Door Verified User or Door
              Advertiser. Company has no control over and has no
              responsibility or liability with respect to Door Verified
              User content. Company and has no responsibility or
              liability with respect to Door Advertiser content. Company
              does not have any obligation to investigate or verify the
              reputation, conduct, morality or criminal background of
              any Door Verified User or Door Advertiser.
            </p>
            <p>
              <strong>9. Privacy</strong>
              <br />
              The information you provide to DoorToken.org via the Door
              Application when accessing the Services is subject to the
              Door Application Privacy Policy.The Door Application sends
              information related to Property Data and Service Requests
              to Advertisers who license the data via DOOR.
            </p>
            <p>
              <strong>10. Prohibited Conduct</strong>
              <br />
              While using or accessing our Services, you will not:
            </p>
            <p>
              (a) violate any applicable law, contract, intellectual
              property or other third-party right or commit a tort;
              <br />
              (b) engage in, encourage or promote any activity that
              violates these Terms;
              <br />
              (c) attempt to circumvent any content-limiting techniques
              we employ;
              <br />
              (d) develop or use any non-Door scripts or applications
              designed to scrape, or extract data from the Services;
              <br />
              (e) use the Services in any manner that could interfere
              with, disrupt, negatively affect or inhibit other users
              from utilizing the Services or that could damage, disable,
              overburden or impair the functioning of the Services in
              any manner;
              <br />
              (f) use the Services for benchmarking purposes or for the
              purpose of developing a competitive product; or
              <br />
              (g) manipulate, or attempt to manipulate, the Services in
              any way.
              <br />
            </p>
            <p>
              <strong>
                11. Acknowledgment of Risks Related to Platform and DOOR
              </strong>
              <br />
              As noted above, the integration of the Platform with the
              Door Application is an integral part of the Services. You
              acknowledge and agree that there are risks associated with
              accessing and using the Platform and holding DOOR.
            </p>
            <p>
              <strong>12. Indemnification</strong>
              <br />
              To the fullest extent permitted by applicable law, you
              will indemnify, defend and hold harmless Company and our
              respective past, present and future employees, officers,
              directors, contractors, consultants, equity holders,
              suppliers, vendors, service providers, parent companies,
              subsidiaries, affiliates, agents, representatives,
              predecessors, successors and assigns (the “Company
              Parties”) from and against all claims, demands, actions,
              damages, losses, costs and expenses (including attorneys’
              fees) that arise from or relate to:
              <br />
              (a) your access or use of the Services, including any
              contributions you make as part of the Services,
              <br />
              (b) your responsibilities or obligations under these
              Terms,
              <br />
              (c) your violation of these Terms, or
              <br />
              (d) your violation of any rights of any other person or
              entity.
              <br />
              Company reserves the right to exercise sole control over
              the defense, at your expense, of any claim subject to
              indemnification under this Section 12. This indemnity is
              in addition to, and not in lieu of, any other indemnities
              set forth in a written agreement between you and Company.
            </p>
            <p>
              <strong>13. Disclaimers</strong>
              <br />
              To the fullest extent permitted by applicable law and
              except as otherwise specified in writing by us:
              <br />
              (a) the Services are made available on an “as is” and “as
              available” basis without warranties of any kind;
              <br />
              (b) we expressly disclaim all implied warranties as to the
              Services, including, without limitation, implied
              warranties of merchantability, fitness for a particular
              purpose, title and non-infringement;
              <br />
              (c) we do not represent or warrant that the Services are
              reliable, current or error-free, meet your requirements,
              or that defects in the Services will be corrected; and
              <br />
              (d) we cannot and do not represent or warrant that the
              Services are free of malware or other harmful components.
              Some jurisdictions do not allow the exclusion of certain
              warranties or disclaimer of implied terms in contracts
              with consumers, so some or all of the exclusions of
              warranties and disclaimers in this section may not apply
              to you.
            </p>
            <p>
              <strong>14. Limitation of Liability</strong>
              <br />
              (a) To the fullest extent permitted by applicable law:
              <br />
              (i) in no event will Company or any of the Company Parties
              be liable to you for any indirect, special, incidental,
              consequential, or exemplary damages of any kind
              (including, but not limited to, where related to loss of
              revenue, income or profits, loss of use or data, or
              damages for business interruption) regardless of the form
              of action, whether based in contract, tort (including, but
              not limited to, simple negligence, whether active, passive
              or imputed), or any other legal or equitable theory (even
              if the party has been advised of the possibility of such
              damages and regardless of whether such damages were
              foreseeable); and
              <br />
              (ii) in no event will the aggregate liability of Company
              and the Company Parties (individually or jointly), whether
              in contract, warranty, tort (including negligence, whether
              active, passive or imputed), or other theory, arising out
              of or relating to the Services or these Terms exceed the
              total amount of DOOR you contribute as part of the
              Services.<strong></strong>
              (b) The limitations set forth in Section 14(a) will not
              limit or exclude liability for the gross negligence, fraud
              or intentional, willful or reckless misconduct of Company.
              <br />
              (c) Some jurisdictions do not allow the limitation or
              exclusion of liability for incidental or consequential
              damages. Accordingly, some of the limitations of this
              section may not apply to you.
            </p>
            <p>
              <strong>15. Release</strong>
              <br />
              To the fullest extent permitted by applicable law, you
              release Company and the other Company Parties from
              responsibility, liability, claims, demands and/or damages
              (actual and consequential) of every kind and nature, known
              and unknown (including, but not limited to, claims of
              negligence), arising out of or related to disputes between
              you and other Door Rewards Users, Door Verified Users,
              Door Advertisers or the acts or omissions of third
              parties. You expressly waive any rights you may have under
              California Civil Code § 1542 as well as any other statute
              or common law principles that would otherwise limit the
              coverage of this release to include only those claims
              which you may know or suspect to exist in your favor at
              the time of agreeing to this release.
            </p>
            <p>
              <strong>16. Dispute Resolution; Arbitration</strong>
              <br />
              Please read the following section carefully because it
              contains additional provisions applicable only to
              individuals located, resident, or domiciled in the United
              States. If you are located, resident, or domiciled in the
              United States, this section requires you to arbitrate
              certain disputes and claims with the Company and limits
              the manner in which you can seek relief from us.
              <br />
            </p>
            <p>
              <strong>1. Binding Arbitration.</strong>
              <br />
              Except for any disputes, claims, suits, actions, causes of
              action, demands or proceedings arising from or related to
              the Services, including the Terms (collectively,
              “Disputes”) in which either Party seeks injunctive or
              other equitable relief for the alleged unlawful use of
              intellectual property, including, without limitation,
              copyrights, trademarks, trade names, logos, trade secrets
              or patents, or small claims disputes in which you or
              Company seeks to bring an individual action in small
              claims court located in the county of your billing
              address, you and Company: (i) waive your and Company’s
              respective rights to have any and all Disputes resolved in
              a court, and (ii) waive your and Company’s respective
              rights to a jury trial. Instead, you and Company will
              arbitrate Disputes through binding arbitration (which is
              the referral of a Dispute to one or more persons charged
              with reviewing the Dispute and making a final and binding
              determination to resolve it instead of having the Dispute
              decided by a judge or jury in court).
            </p>
            <p>
              <strong>
                2. No Class Arbitrations, Class Actions or
                Representative Actions.
              </strong>
              <br />
              Any Dispute is personal to you and Company and will be
              resolved solely through individual arbitration and will
              not be brought as a class arbitration, class action or any
              other type of representative proceeding. There will be no
              class arbitration or arbitration in which an individual
              attempts to resolve a Dispute as a representative of
              another individual or group of individuals. Further, a
              Dispute cannot be brought as a class or other type of
              representative action, whether within or outside of
              arbitration, or on behalf of any other individual or group
              of individuals.
            </p>
            <p>
              <strong>3. Federal Arbitration Act</strong>
              <br />
              The enforceability of this Section 16 will be both
              substantively and procedurally governed by and construed
              and enforced in accordance with the Federal Arbitration
              Act, 9 U.S.C. § 1 et seq. (the “FAA”), to the maximum
              extent permitted by applicable law.
            </p>
            <p>
              <strong>4. Notice; Informal Dispute Resolution</strong>
              <br />
              Each Party will notify the other Party in writing of any
              Dispute within thirty (30) days of the date it arises, so
              that the Parties can attempt in good faith to resolve the
              Dispute informally. Notice to Company shall be sent by
              e-mail to Company at info@doortoken.org. Notice to you
              shall be by email to the then-current email address in
              your Account or using other means of notification via the
              Services as applicable. Your notice must include: (i) your
              name, postal address, email address and telephone number,
              (ii) a description in reasonable detail of the nature or
              basis of the Dispute, and (iii) the specific relief that
              you are seeking. If you and Company cannot agree how to
              resolve the Dispute within thirty (30) days after the date
              notice is received by the applicable Party, then either
              you or Company may, as appropriate and in accordance with
              this Section 16, commence an arbitration proceeding or, to
              the extent specifically provided for in Section 16.1, file
              a claim in court.
            </p>
            <p>
              <strong>5. Venue &amp; Arbitrator</strong>
              <br />
              Any arbitration will occur in Sarasota County, Florida
              although you may elect to hold the arbitration in your
              county of residence. Arbitration will be conducted
              confidentially by a single arbitrator in accordance with
              the Streamlined Arbitration Rules and Procedures of the
              Judicial Arbitration and Mediation Services (“JAMS
              Rules”), which are hereby incorporated by reference. The
              state and federal courts located in Sarasota County,
              Florida will have exclusive jurisdiction over any appeals
              and the enforcement of an arbitration award.
            </p>
            <p>
              <strong>
                6. Authority of Arbitrator; Exchange of Information;
                Fees &amp; Costs
              </strong>
              <br />
              As limited by the FAA, these Terms and the applicable JAMS
              Rules, the arbitrator will have (i) the exclusive
              authority and jurisdiction to make all procedural and
              substantive decisions regarding a Dispute, including the
              determination of whether a Dispute is arbitrable, and (ii)
              the authority to grant any remedy that would otherwise be
              available in court; provided, however, that the arbitrator
              does not have the authority to conduct a class arbitration
              or a representative action, which is prohibited by these
              Terms. The arbitrator may only conduct an individual
              arbitration and may not consolidate more than one
              individual’s claims, preside over any type of class or
              representative proceeding or preside over any proceeding
              involving more than one individual. The arbitration will
              allow for the discovery or exchange of non-privileged
              information relevant to the Dispute. For any arbitration
              you initiate, you will pay the filing fee (up to a maximum
              of $250), and Company will pay the remaining JAMS fees and
              costs. For any arbitration initiated by Company, Company
              will pay all JAMS fees and costs.
            </p>
            <p>
              <strong>7. Rules of JAMS</strong>
              <br />
              The JAMS Rules and additional information about JAMS are
              available on the JAMS website. By agreeing to be bound by
              these Terms, you either (i) acknowledge and agree that you
              have read and understand the JAMS Rules, or (ii) waive
              your opportunity to read the JAMS Rules and any claim that
              the JAMS Rules are unfair or should not apply for any
              reason.
            </p>
            <p>
              <strong>
                8. Severability of Dispute Resolution; Arbitration
              </strong>
              <br />
              If any term, clause or provision of this Section 16 is
              held invalid or unenforceable, it will be so held to the
              minimum extent required by law, and all other terms,
              clauses and provisions of this Section 16 will remain
              valid and enforceable.
            </p>
            <p>
              <strong>9. Opt Out</strong>
              <br />
              You have the right to opt out of binding arbitration
              within 30 days of the date you first accepted the Terms or
              before Jan 1st 2021, whichever is the later, by notifying
              Company via email at info@doortoken.org. In order to be
              effective, the opt-out notice must include your full name
              and address and clearly indicate your intent to opt out of
              binding arbitration. By opting out of binding arbitration,
              you are agreeing to resolve Disputes in accordance with
              Section 17.
            </p>
            <p>
              <strong>17. Governing Law and Venue</strong>
              <br />
              These Terms will be governed by and construed and enforced
              in accordance with the laws of the United States, without
              regard to conflict of law rules that would cause the
              application of the laws of any other jurisdiction. Any
              Dispute between the Parties arising out of relating to
              these Terms or its subject matter or formation (including
              non-contractual Disputes of claims) that are not subject
              to arbitration will be resolved in the courts of the
              United States.
            </p>
            <p>
              <strong>18. Changes to these Terms</strong>
              <br />
              We may make changes to these Terms from time to time. If
              we make changes, we will notify you of the amended Terms
              within the Door Application and update the “Last Updated”
              date above. Unless we say otherwise in our notice, the
              amended Terms will be effective immediately and your
              continued access to or use of the Services after we
              provide notice will confirm your acceptance of the
              changes. If you do not agree to the amended Terms, you
              must stop accessing and using the Services.
            </p>
            <p>
              <strong>19. Termination and Suspension</strong>
              <br />
              We reserve the right, without notice and in our sole
              discretion, to terminate or suspend your right to access
              or use the Services with immediate effect. We are not
              responsible for any loss or harm related to your inability
              to access or use the Services.
            </p>
            <p>
              <strong>20. Severability</strong>
              <br />
              If any term, clause or provision of these Terms is held
              unlawful, void or unenforceable, then that term, clause or
              provision will be severable from these Terms and will not
              affect the validity or enforceability of any remaining
              part of that term, clause or provision, or any other term,
              clause or provision of these Terms.
            </p>
            <p>
              <strong>21. Miscellaneous</strong>
              <br />
              These Terms constitute the entire agreement between you
              and us relating to your access to or use of the Services,
              and supersede any prior agreements with Company or its
              affiliates, express or implied, relating to the Services.
              We may make changes to these Terms from time to time as
              reasonably required to comply with applicable law or
              regulation. You may not assign your rights and obligations
              under these Terms without our express written consent. Our
              failure to exercise or enforce any right or provision of
              these Terms will not operate as a waiver of such right or
              provision. We will not be liable for any delay or failure
              to perform any obligation under these Terms where the
              delay or failure results from any cause beyond our
              reasonable control. Your access to or use of the Services
              does not create any form of partnership, joint venture or
              any other similar relationship between you and us. Except
              as otherwise provided in herein, these Terms are intended
              solely for the benefit of you and us and are not intended
              to confer third-party beneficiary rights upon any other
              person or entity. You agree and acknowledge that all
              agreements, notices, disclosures, and other communications
              that we provide to you, including these Terms, may be
              provided in electronic form.
            </p>
          </div>
        </div>
      </div>
    </div> `},
    {
        title: "Door Legal Disclaimer, Risk Disclosure & General Terms",
        content: `
        <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="blog-detail-content">
                  <ul>
                    <li>Updated August 28,2021</li>
                  </ul>
                  <p>
                    For a full Legal Opinion
                    <a href="https://doorcoin.com//download/Legal_Opinion_DOOR.pdf">
                      please view the following
                    </a>
                  </p>
                  <p style="Margin-top: 20px;Margin-bottom: 20px;">
                    If you are located in one of these countries, your local
                    laws may prohibit ownership of cryptocurrency: Indonesia,
                    Taiwan, Saudi Arabia, The Republic of Macedonia, Qatar,
                    Vanuatu, Vietnam, Nepal, Afghanista, Pakistan, Algeria,
                    Egypt, Cambodia, Colombia, China, Bolivia, Morocco, Ecuador,
                    Iran, Bangladesh.
                  </p>
                  <p style="padding:20px;background-color:white;color:red;font-size:20px;margin-bottom: 30px; font-weight:500;">
                    Buying DOOR or any cryptocurrency is highly risky due to the
                    fluctuation of value. The primary utility of DOOR is to
                    participate in our decentralized Ad Network. Please research
                    DOOR by accessing our DOOR Guide, DOOR White Paper and any
                    other resources we provide in our website.
                  </p>
                  <p>
                    <span class="c12">IMPORTANT</span>
                  </p>
                  <p>
                    DOOR Tokens are digital currency explicitly used to
                    participate on the DOOR Ad Network and purchase advertising
                    or data licensing rights on doortoken.org. DOOR is not a
                    security. Ownership of DOOR carries no rights, neither
                    express or implied. Purchases of DOOR are non- refundable.
                    Advertisers should have no expectation of influence over
                    governance of the DOOR Platform. By participating in the
                    sale of DOOR, you expressly acknowledge and represent that
                    you have carefully reviewed the Terms &amp; Conditions and
                    fully understand the risks, costs, and benefits of
                    purchasing DOOR and agree to be bound by these Terms &amp;
                    Conditions. As set forth below, you further represent and
                    warrant that, to the extent permitted by law, you are
                    authorized to purchase DOOR in your relevant jurisdiction,
                    are of legal age to be bound by these Terms &amp;
                    Conditions, and will not hold liable for any losses or any
                    special, incidental, or consequential damages arising out
                    of, or in any way connected to the sale of DOOR, now or in
                    the future, DOOR and its affiliates, and the officers,
                    directors, agents, joint ventures, employees and suppliers
                    of DOOR or its affiliates.
                  </p>
                  <p>
                    DOOR&nbsp;tokens confer no voting rights, ownership of
                    intellectual property, transfer of ownership upon company
                    sale, control of company assets, or any decision-making
                    ability regarding the DOOR platform or its operations. DOOR
                    tokens are not in any way, either directly or indirectly,
                    considered to be securities.&nbsp;
                  </p>
                  <p>
                    The user acknowledges, understands, and agrees that DOOR
                    tokens are not securities and are not registered with any
                    government entity or regulator as a security, and shall not
                    be considered as such.
                  </p>
                  <p>
                    The purchase of DOOR carries with it various risks. Prior to
                    purchasing DOOR, you should carefully consider the risks
                    listed below and, to the extent necessary, consult an
                    appropriate lawyer, accountant, or tax professional. If any
                    of the following risks are unacceptable to you, you should
                    not purchase DOOR. By purchasing DOOR, and to the extent
                    permitted by law, you are agreeing not to hold any of the
                    DOOR Team Parties liable for any losses or any special,
                    incidental, or consequential damages arising from, or in any
                    way connected, to the sale of DOOR, including losses
                    associated with the risks set forth below.
                  </p>
                  <p>Purchase of DOOR from the doortoken.org Website</p>
                  <p>
                    The DOOR Team will maintain a store interface to be used to
                    purchase DOOR. The store interface will be available on the
                    DOOR website (https://www.doortoken.org). Instructions for
                    purchasing DOOR with BTC and other cryptocurrencies using
                    the store interface will be embedded and available for
                    review on the DOOR website during the duration of the
                    initial sale. Failure to follow these instructions may
                    limit, delay, or prevent an Advertiser from obtaining DOOR.
                  </p>
                  <p>Acceptance of Terms and Conditions of the DOOR Sale</p>
                  <p>
                    As a first step in the purchase process, DOOR Team will
                    present the Advertiser with these Terms, and any other
                    associated documents. By placing an order to buy DOOR, the
                    Advertiser: (i) consents and agrees to the Terms; (ii)
                    represents and warrants that the Advertiser is legally
                    permitted to purchase DOOR in the Advertiser’s jurisdiction;
                    (iii) represents and warrants that the Advertiser is of a
                    sufficient age to legally purchase DOOR or has received
                    permission from a legal guardian who has reviewed and agreed
                    to these Terms; (iv) represents and warrants that the
                    Advertiser will take sole responsibility for any
                    restrictions and risks associated with the purchase of DOOR
                    as outlined below; and (v) represents and warrants that the
                    Advertiser has an understanding of the usage and intricacies
                    of cryptographic tokens, including BTC, and blockchain-based
                    software systems.
                  </p>
                  <p>Receipt of DOOR and the Purchase Email</p>
                  <p>
                    As part of the purchase process, and in order to purchase
                    DOOR, an Advertiser must provide an email address (the
                    “Purchase Email”). The Purchase Email will be used to email
                    the Advertiser a notice of the creation of the Advertiser’s
                    account on its website. By purchasing DOOR, and to the
                    extent permitted by applicable law, the Advertiser agrees
                    not to hold any of the DOOR Team Parties liable for any
                    losses or any special, incidental, or consequential damages
                    arising out of, or in any way connected to, Advertiser’s
                    failure to secure their account.
                  </p>
                  <p>Certain Risks Associated with the Purchase of DOOR</p>
                  <p>
                    The purchase of DOOR carries with it significant risk. Prior
                    to purchasing DOOR, the Advertiser should carefully consider
                    the below risks and, to the extent necessary, consult a
                    lawyer, accountant, and/or tax professionals before
                    determining whether to purchase DOOR.
                  </p>
                  i. It is possible that the value of DOOR will drop
                  significantly in the future, which could effect the ability
                  for DoorToken.org to operate.
                  <br />
                  <br />
                  ii. DOOR will be stored in a wallet, which can only be
                  accessed with a password selected by the Advertiser. If an
                  Advertiser of DOOR does not maintain an accurate record of
                  their password, this may lead to the loss of DOOR. As a
                  result, Advertisers must safely store their password in one or
                  more backup locations that are well separated from the primary
                  location. In order to access one’s DOOR, the password that the
                  Advertiser entered is required; loss of this may lead to the
                  loss of an Advertiser’s DOOR.
                  <br />
                  <br />
                  iii. Any third party that gains access to the Advertiser’s
                  Purchase Email may be able to gain access to the Advertiser’s
                  DOOR. The Advertiser must take care not to respond to any
                  inquiry regarding their purchase of DOOR, including but not
                  limited to, email requests purportedly coming from the
                  www.doortoken.org website or a similar-looking domain.
                  <br />
                  <br />
                  iv. Cryptocurrencies have been the subject of regulatory
                  scrutiny by various regulatory bodies around the globe. The
                  DOOR Platform and DOOR Team could be impacted by one or more
                  regulatory inquiries or regulatory action, which could impede
                  or limit the ability to continue to develop the DOOR Platform.
                  <br />
                  <br />
                  v. It is possible that the DOOR Platform will not be used by a
                  large number of external businesses, individuals, and other
                  organizations and that there will be limited public interest
                  in the use of peer-to-peer currencies for the use of
                  purchasing data licenses. Such a lack of interest could impact
                  the development of the DOOR Platform. DOOR Team cannot predict
                  the success of its own marketing efforts nor the efforts of
                  other third parties. There is no guarantee of revenues or
                  profits resulting from the marketing activities of the DOOR
                  Team.
                  <br />
                  <br />
                  vi. The Advertiser recognizes that the DOOR Platform may have
                  bugs or issues that impact it's ability to function. The
                  Advertiser acknowledges that any expectations regarding the
                  form and functionality of the DOOR Platform held by the
                  Advertiser may not be met upon release of the DOOR Platform,
                  for any number of reasons including a change in the design and
                  implementation plans and execution of the implementation of
                  the DOOR Platform.
                  <br />
                  <br />
                  vii. The Advertiser understands that while the DOOR Team will
                  make reasonable efforts to add key features or fix bugs, it is
                  possible that the delay of repairing or enhancing the platform
                  may effect their ability to use it as intended.
                  <br />
                  <br />
                  viii. Hackers or other groups or organizations may attempt to
                  steal the DOOR and cryptocurrency revenue, thus potentially
                  impacting the ability of DOOR Team to promote the DOOR
                  Platform. To account for this risk, DOOR Team has and will
                  continue to implement comprehensive security precautions to
                  safeguard the DOOR and cryptocurrency obtained from the sale
                  of DOOR. Multi-factor security measures will be taken to
                  protect cryptocurrency and DOOR including but not limited to
                  physical elements, multi-signature keys, splitting of funds,
                  hot/cold wallet partitioning and diversification. Moreover,
                  regular security audits of hot and cold wallets will be
                  conducted by internal and external teams.
                  <br />
                  <br />
                  ix. Advances in code cracking, or technical advances such as
                  the development of quantum computers, could present risks to
                  cryptocurrencies and the DOOR Platform, which could result in
                  the theft or loss of DOOR. To the extent possible, DOOR
                  intends to update the protocol underlying the DOOR Platform to
                  account for any advances in cryptography and to incorporate
                  additional security measures but cannot it cannot predict the
                  future of cryptography or the success of any future security
                  updates.
                  <br />
                  <br />
                  x. As with other cryptocurrencies, the Ethereum blockchain
                  used for the DOOR Platform is susceptible to mining attacks,
                  including but not limited to double-spend attacks, majority
                  mining power attacks, “selfish-mining” attacks, and race
                  condition attacks. Any successful attacks present a risk to
                  the DOOR Platform and expected proper payment operations.
                  <br />
                  <br />
                  xi. The loss or destruction of a private key by DOOR Team used
                  to access may be irreversible. DOOR Team’s loss of access to
                  its private keys or a data loss relating to DOOR Team could
                  adversely affect the value of DOOR Platform.
                  <br />
                  <br />
                  xii. DOOR Platform is a new product, thus contributing to
                  price volatility that could adversely affect the value of
                  DOOR. The factors affecting the further development of the
                  digital assets industry, as well as the DOOR Platform,
                  include: a) continued worldwide growth in the adoption and use
                  of DOOR and other digital assets; b) government and
                  quasi-government regulation of DOOR and other digital assets
                  and their use, or restrictions on or regulation of access to
                  and operation of the DOOR Platform or similar digital asset
                  systems; c)the maintenance and development of the software of
                  the DOOR Platform; d) changes in consumer demographics and
                  public tastes and preferences; e) the availability and
                  popularity of other similar products; and f) general economic
                  conditions and the regulatory environment relating to the DOOR
                  Platform and digital assets.
                  <br />
                  <br />
                  xiii. Intellectual property rights claims may adversely affect
                  the operation of the DOOR Platform. Third parties may assert
                  intellectual property claims relating to the holding and
                  transfer of digital assets and their source code. Regardless
                  of the merit of any intellectual property or other legal
                  action, any threatened action that reduces confidence in the
                  DOOR Platform’s long-term viability or the ability of
                  end-users to hold and transfer DOOR may adversely affect the
                  value of DOOR. Additionally, a meritorious intellectual
                  property claim could prevent end-users from accessing the DOOR
                  Platform or holding or transferring their DOOR.
                  <br />
                  <br />
                  xiv. Cryptocurrency exchanges on which DOOR may trade may be
                  relatively new and largely unregulated and may therefore be
                  more exposed to fraud and failure than established, regulated
                  exchanges for other products. To the extent that the
                  cryptocurrency exchanges representing a substantial portion of
                  the volume in DOOR trading are involved in fraud or experience
                  security failures or other operational issues, such
                  cryptocurrency exchange failures may result in a reduction in
                  the price and can materially and adversely affect the value of
                  DOOR. A lack of stability or liquidity in the cryptocurrency
                  exchanges and the closure or temporary shutdown of
                  cryptocurrency exchanges due to fraud, business failure,
                  hackers or malware, or government-mandated regulation may
                  reduce confidence in the DOOR Platform and result in greater
                  volatility in the price.
                  <br />
                  <br />
                  xv. Political or economic crises may motivate large-scale
                  sales of DOOR, which could result in a reduction in the price
                  and adversely affect the value of DOOR. Digital assets such as
                  DOOR, which are relatively new, are subject to supply and
                  demand forces based upon the desirability of an alternative,
                  decentralized means of transacting, and it is unclear how such
                  supply and demand will be impacted by geopolitical events.
                  Large volume sales of DOOR would result in a reduction in its
                  price.
                  <br />
                  <br />
                  xvi. It is possible that a digital asset other than DOOR could
                  have features that make it more desirable to a material
                  portion of the digital asset user base, resulting in a
                  reduction in demand for DOOR, which could have a negative
                  impact on the use and price of DOOR. It is possible that a
                  comparable product could become materially popular due to
                  either a perceived or exposed shortcoming of the DOOR Platform
                  that is not immediately addressed by the DOOR Team, or a
                  perceived advantage of a comparable product that includes
                  features not incorporated into the DOOR Platform. If this
                  product obtains significant market share, it could have a
                  negative impact on the demand for, and price of, DOOR.
                  <br />
                  <br />
                  xvii. DOOR transactions are irrevocable and stolen or
                  incorrectly transferred DOOR may be irretrievable. As a
                  result, any incorrectly executed DOOR transactions could
                  materially and adversely affect the value of DOOR.
                  Cryptocurrency transactions are not, from an administrative
                  perspective, reversible without the consent and active
                  participation of the recipient of the transaction or, in
                  theory, control or consent of a majority of the processing
                  power on the host blockchain platform. Once a transaction has
                  been verified and recorded in a block that is added to the
                  blockchain, an incorrect transfer of DOOR or a theft of DOOR
                  generally will not be reversible and there may be no
                  compensation for any such transfer or theft. Such loss could
                  materially and adversely affect the value of DOOR.
                  <br />
                  <br />
                  xviii. Some DOOR tokens may be issued on the Ethereum
                  blockchain. As such, any malfunction or unexpected functioning
                  of the Ethereum protocol may impact the Advertiser’s ability
                  to transfer or securely hold DOOR. Such impact could
                  materially and adversely affect the value of DOOR.
                  <br />
                  <br />
                  xix. It is possible that, due to any number of reasons,
                  including without limitation the failure of business
                  relationships or marketing strategies, that the DOOR Platform
                  and all subsequent marketing from the money raised from the
                  same of DOOR may fail to achieve success.
                  <p>All Purchases of DOOR Are Non-Refundable</p>
                  <p>
                    All purchases of DOOR are final. purchases of DOOR are
                    non-refundable. by purchasing DOOR, the Advertiser
                    acknowledges that neither DOOR team nor any other of the
                    DOOR team parties are required to provide a refund for any
                    reason, and that the Advertiser will not receive money or
                    other compensation for any DOOR that is not used or remains
                    unused.
                  </p>
                  <p>Staking of DOOR</p>
                  <p>
                    Users who are actively participating in operating or running
                    the DOOR Ad Network can participate in staking their DOOR in
                    exchange for DOOR Rewards. When a User stakes DOOR they are
                    contributing their DOOR to a designated Pool that is used to
                    provide tokens to Advertisers. Advertisers require tokens in
                    order to purchase advertising on the DOOR Ad Network. In
                    order to meet demand of Advertisers, Users stake their
                    tokens for a period of time to be used by Advertisers. In
                    order to facilitate this transaction, every User must
                    operate or co-operate a DOOR Node. A DOOR Node is software
                    that faciliates the delivery and tracking of Ads and DOOR
                    tokens contributed to the Pool. A DOOR Node can be hosted by
                    the User or can be co-located and hosted by the Company.
                  </p>
                  <p>
                    NOTE: Rewards are paid to Users in exchange for their
                    contributions in Staking only if the User is an active
                    participant in operating the DOOR Ad Network. The rewards
                    are in exchange for work provided by the User
                  </p>
                  <p>
                    Taxation of DOOR and Taxation Related to the Initial Sale
                  </p>
                  <p>
                    DOOR&nbsp;Team makes no representations concerning the tax
                    implications of the sale of DOOR or the possession or use of
                    DOOR. The Advertiser bears the sole responsibility to
                    determine if the purchase of DOOR with BTC or the potential
                    appreciation or depreciation in the value of DOOR over time
                    has tax implications for the Advertiser in the Advertiser’s
                    home jurisdiction. By purchasing DOOR, and to the extent
                    permitted by law, the Advertiser agrees not to hold any of
                    the DOOR Team Parties liable for any tax liability
                    associated with or arising from the purchase of DOOR. You
                    are solely responsible for determining what, if any, taxes
                    apply to your DOOR Token transactions. Neither The DOOR Team
                    nor any other party is responsible for determining taxes
                    that apply to DOOR Token transactions.
                  </p>
                  <p>Privacy</p>
                  <p>
                    Although DOOR Team requires that Advertisers provide an
                    email address, DOOR Team will not publish any identifying
                    information related to DOOR purchases, without the prior
                    written consent of the Advertiser. The privacy of the
                    Advertiser is not in any way shared with third-party
                    sources. Advertisers may be contacted by email by DOOR Team
                    regarding a purchase. Such emails will be informational
                    only. DOOR Team will not request any information from
                    Advertisers in an email.
                  </p>
                  <p>Disclaimer of Warranties</p>
                  <p>
                    The Advertiser expressly agrees that the Advertiser is
                    purchasing DOOR at the Advertiser’s sole risk and that DOOR
                    is provided on an “as is” basis without warranties of any
                    kind, either express or implied, including, but not limited
                    to, warranties of title or implied warranties,
                    merchantability or fitness for a particular purpose (except
                    only to the extent prohibited under applicable law with any
                    legally required warranty period to the shorter of thirty
                    days from first use or the minimum period required). without
                    limiting the foregoing, none of the DOOR team parties
                    warrant that the process for purchasing DOOR will be
                    uninterrupted or error-free.
                  </p>
                  <p>Limitations Waiver of Liability</p>
                  <p>
                    The Advertiser acknowledges and agrees that, to the fullest
                    extent permitted by any applicable law, the disclaimers of
                    liability contained herein apply to any and all damages or
                    injury whatsoever caused by or related to use of, or
                    inability to use, DOOR or the DOOR platform under any cause
                    or action whatsoever of any kind in any jurisdiction,
                    including, without limitation, actions for breach of
                    warranty, breach of contract or tort (including negligence)
                    and that none of the DOOR team parties shall be liable for
                    any indirect,incidental, special, exemplary or consequential
                    damages, including for loss of profits, goodwill or data, in
                    any way whatsoever arising out of the use of, or inability
                    to use, or purchase of, or inability to purchase, DOOR. The
                    Advertiser further specifically acknowledges that DOOR team
                    parties are not liable for the conduct of third parties,
                    including other Advertisers of DOOR, and that the risk of
                    purchasing DOOR rests entirely with the Advertiser. To the
                    extent permissible under applicable laws, under no
                    circumstances will any of the DOOR team parties be liable to
                    any Advertiser for more than the amount the Advertiser may
                    have paid to DOOR team for the purchase of DOOR. Some
                    jurisdictions do not allow the exclusion of certain
                    warranties or the limitation or exclusion of liability for
                    certain types of damages. Therefore, some of the above
                    limitations in this section and elsewhere in the terms may
                    not apply to an Advertiser. In particular, nothing in these
                    terms shall affect the statutory rights of any Advertiser or
                    exclude injury arising from any wilful misconduct or fraud
                    of DOOR team.
                  </p>
                  <p>Dispute Resolution</p>
                  <p>
                    a) DOOR Team and Advertiser (the “Parties”) agree to make
                    good faith efforts to resolve any dispute, controversy or
                    claim arising between them relating to this pre-sale and
                    their respective rights and obligations hereunder arising
                    under this Agreement (a “Dispute”). b) If the Parties, or
                    their designated representatives, are unable to resolve the
                    Dispute within ten (10) business days after referral of the
                    matter to them, the Parties will submit the Dispute for
                    resolution pursuant to paragraph c. of this Section. c)
                    Except with respect to Disputes concerning(i) the right of
                    either Party to apply to a court of competent jurisdiction
                    for an interim or interlocutory injunction or other
                    provisional remedy to preserve the status quo or prevent
                    irreparable harm or (ii) any Disputes that may arise in
                    connection with a breach of a Party’s obligations of
                    confidentiality hereunder, if any Dispute is not resolved
                    pursuant to paragraphs a. and b. above, the Parties will,
                    acting reasonably, agree mutually on the forum for
                    resolution of the Dispute by arbitration as set out in this
                    Section. d) After the completion of the procedures set forth
                    in paragraph b. and agreement by the Parties to enter into
                    binding arbitration in accordance with paragraph c. of this
                    Section, either Party may within thirty (30) calendar days
                    refer the Dispute to arbitration by serving written notice
                    of its intentionto arbitrate the Dispute to the other Party.
                    e) The arbitration will be conducted by a single arbitrator
                    to be mutually agreed to by the Parties within three (3)
                    business days following the date of the referral of the
                    Dispute to arbitration.
                  </p>
                  <p>Force Majeure</p>
                  <p>
                    DOOR&nbsp;Team is not liable for failure to perform solely
                    caused by: • unavoidable casualty, • delays in delivery of
                    materials, • embargoes, • government orders, • acts of civil
                    or military authorities, • acts by common carriers, •
                    emergency conditions (including weather conditions), or •
                    any similar unforeseen event that renders performance
                    commercially implausible. If an event of force majeure
                    occurs, the party injured by the other’s inability to
                    perform may elect to suspend the Agreement, in whole or
                    part, for the duration of the force majeure circumstances.
                    The party experiencing the force majeure circumstances shall
                    cooperate with and assist the injured party in all
                    reasonable ways to minimize the impact of force majeure on
                    the injured party.
                  </p>
                  <p>Complete Agreement</p>
                  <p>
                    These Terms set forth the entire understanding between each
                    Advertiser and DOOR Team with respect to the purchase and
                    sale of DOOR. For facts relating to the sale and purchase,
                    the Advertiser agrees to rely only on this document in
                    determining purchase decisions and understands that this
                    document governs the sale of DOOR and supersedes any public
                    statements about the initial sale made by third parties or
                    by DOOR Team or individuals associated with any DOOR Team
                    parties, past and present and during the initial sale. There
                    are no warranties, representations, covenants, or
                    agreements, express or implied, between the parties except
                    those expressly set forth in this Agreement. This Agreement
                    may only be amended by a written document duly executed by
                    the parties.
                  </p>
                  <p>Severability</p>
                  <p>
                    The Advertiser and DOOR Team agree that if any portion of
                    these Terms is found illegal or unenforceable, in whole or
                    in part, such provision shall, as to such jurisdiction, be
                    ineffective solely to the extent of such determination of
                    invalidity or unenforceability without affecting the
                    validity or enforceability thereof in any other manner or
                    jurisdiction and without affecting the remaining provisions
                    of the Terms, which shall continue to be in full force and
                    effect.
                  </p>
                  <p>No Waiver</p>
                  <p>
                    The failure of DOOR Team to require or enforce strict
                    performance by the Advertiser of any provision of these
                    Terms or DOOR Team’s failure to exercise any right under
                    these agreements shall not be construed as a waiver or
                    relinquishment of DOOR Team’s right to assert or rely upon
                    any such provision or right in that or any other instance.
                    The express waiver by DOOR Team of any provision, condition,
                    or requirement of these Terms shall not constitute a waiver
                    of any future obligation to comply with such provision,
                    condition or requirement. Except as expressly and
                    specifically set forth in this these Terms, no
                    representations, statements, consents, waivers, or other
                    acts or omissions by DOOR Team shall be deemed a
                    modification of these Terms nor be legally binding, unless
                    documented in physical writing, hand signed by the
                    Advertiser and a duly appointed officer, employee, or agent
                    of DOOR Team.
                  </p>
                  <p>
                    Updates to the Terms and Conditions of the DOOR Initial Sale
                  </p>
                  <p>
                    DOOR&nbsp;Team reserves the right, at its sole discretion,
                    to change, modify, add, or remove portions of the Terms at
                    any time during the sale by posting the amended Terms on the
                    DOOR website (https://www.doortoken.org). Any Advertiser
                    will be deemed to have accepted such changes by purchasing
                    DOOR. The Terms may not be otherwise amended except in a
                    signed writing executed by both the Advertiser and DOOR
                    Team. For purposes of this agreement, “writing” does not
                    include an e-mail message and a signature does not include
                    an electronic signature. If at any point you do not agree to
                    any portion of the then-current version of the Terms, you
                    should not purchase DOOR.
                  </p>
                  <p>1. ESSENTIALS</p>
                  <p>
                    DOOR Tokens (the “Tokens”) are a payment token and are being
                    used as a currency on the DOOR Platform for licensing data.
                    They have been issued by DOOR LLC (the “Issuer”), a company
                    incorporated under the laws of the United States. The Tokens
                    do not represent any claim for repayment of a monetary sum
                    against the Issuer, nor have persons holding Tokens (“Token
                    Holders”) any claim against the Issuer for payment of
                    interests or for sharing of profits generated by the Issuer.
                    A total loss of the value of DOOR Tokens or any investment
                    due to various causes cannot be excluded.
                  </p>
                  <p>
                    The Issuer will undertake best efforts to have the DOOR
                    Token listed at a cryptocurrency exchange or trading
                    platform, but cannot guarantee if and when such listing will
                    take place. Until a listing has been completed, Token
                    Holders can dispose of tokens only by way of selling them on
                    a bilateral basis. There is no guarantee that buyers for
                    Tokens will be available, or that they will be willing to
                    pay the price paid by the Token Holder at the time of the
                    acquisition of the Token.
                  </p>
                  <p>
                    An acquisition of Tokens is suitable only for experienced
                    persons who are in a position to evaluate the risks,
                    including the risks related to the underlying technology,
                    and who have sufficient resources to be able to bear any
                    losses, including a complete loss, which may result from
                    such acquisition. Before subscribing to or otherwise
                    acquiring any Tokens, prospective Advertisers should
                    specifically ensure that they understand the structure of,
                    and the risk inherent to, the Tokens.
                  </p>
                  <p>
                    Prior to the acquisition of DOOR Token, persons should
                    independently assess any possible risks, seek advice with
                    respect to the economic, legal, regulatory and tax
                    implications of the purchase of DOOR Token and should
                    consult with his/her own investment, legal, tax, accounting
                    or other advisors to determine the potential benefits,
                    burdens, risks and other consequences of a purchase of DOOR
                    Token.
                  </p>
                  <p>
                    Prospective Token Holders are required to study the white
                    paper, the website, the FAQ’s and all other available
                    information sources, and are encouraged to clarify all their
                    questions prior to the acquisition of Tokens.
                  </p>
                  <p>2. DOOR TOKEN INFORMATION</p>
                  <p>
                    DOOR is a payment token that enables its holders to purchase
                    data which is available on the DOOR Platform.
                  </p>
                  <p>3. RISK FACTORS RELATING TO THE ISSUER</p>
                  <p>
                    The Issuer is a newly incorporated company, and there is no
                    historical financial data or key figures to assess the
                    financial situation of the Issuer with regard to previous
                    years. Negative developments such as the destruction of
                    assets or an economic downturn could negatively affect the
                    Issuer’s liquidity and/or solvency.
                  </p>
                  <p>
                    The Issuer’s success depends to a large extent on the
                    continued involvement of key personnel. Such key personnel
                    are either employed by the Issuer or made available through
                    an agreement with its sole shareholder DOORToken.org.
                  </p>
                  <p>
                    Existing laws, regulations and financial oversight practice
                    relevant to DOOR and the DOOR Token are subject to political
                    debate and legislative changes in all jurisdictions where
                    Tokens are offered for subscription. Changes in applicable
                    laws and regulations may be implemented and enter into force
                    without warning and with immediate or even retrospective
                    effect leading to impacts on the Issuer’s operational
                    results, the value of the investments made, the ability to
                    offer, distribute and trade the Tokens. In very severe
                    circumstances, jurisdictions may enforce a closing of the
                    platform and the Issuer respectively. This could lead to
                    losses incurred by the Token Holders.
                  </p>
                  <p>
                    Token Holders are strictly reminded to observe legal and
                    regulatory developments at all times and to consider their
                    own position vis-à-vis those requirements. The Issuer has
                    institutionalized internal processes to ensure compliance
                    with the laws and regulations of the United States. If,
                    despite these precautions, breaches of statutory or
                    regulatory provisions occur, this could adversely affect the
                    Issuer’s business activities and/or the price of the Tokens.
                    Furthermore, changes to data protection laws or regulations
                    may force the Issuer to disclose any kind of additional
                    information to authorities in the future.
                  </p>
                  <p>4. RISK FACTORS RELATING TO THE TOKEN</p>
                  <p>
                    Prior to the listing of tokens at a cryptocurrency exchange
                    or trading platform no liquid market for Tokens will exist.
                    Markets for crypto assets are not mature nor fully developed
                    markets with sufficient liquidity and volume to provide
                    stable prices, leading to high bid/ask spreads, very high
                    volatility and vulnerability to price manipulation of large
                    players in the market. The price at which Tokens will be
                    traded will depend upon a number of factors, most of which
                    are beyond the Issuer’s control. In addition, cryptocurrency
                    markets in general are subject to significant price and
                    volume fluctuations.
                  </p>
                  <p>
                    A number of legal questions, qualifications and
                    categorizations concerning Tokens and related new fields of
                    technology (such as Blockchain) and investments using such
                    tools and providing rights through Tokens are still in
                    relatively early states of scholarship discussions and not
                    finally decided and harmonized throughout jurisdictions.
                    These conditions may lead a court to the conclusion that the
                    transfer of Tokens is ineffective, void, or voidable.
                    Jurisdictions may restrict trading of or investing in
                    Tokens, for instance by imposing economic sanctions or
                    currency restrictions.
                  </p>
                  <p>
                    The tax characterization of tokens is under development in
                    different jurisdictions and may vary even within
                    jurisdiction. Token Holders must seek their own tax advice
                    in the relevant jurisdictions in connection with acquiring
                    tokens, which may result in adverse tax consequences,
                    including withholding taxes, income taxes and tax reporting
                    requirements.
                  </p>
                  <p>
                    All transactions on the Ethereum blockchain are irrevocable
                    and final as soon as the committed transactions have been
                    signed and exchanged. Erroneous token transactions may not
                    be recoverable and lead to loss of Tokens. The Token Holder
                    bears the entire responsibility regarding the correct
                    execution of token transactions.
                  </p>
                  <p>
                    Additionally, Tokens may be lost or become inaccessible, in
                    particular if the Token Holder loses the respective
                    passwords, pincodes and/or private keys to dispose of its
                    Tokens, or due to malfunctioning of the e-wallet in which
                    the Tokens are stored. The Token Holder bears the entire
                    responsibility regarding the secure storage of passwords,
                    pincodes and private keys (necessary to access and use the
                    DOOR Token).
                  </p>
                  <p>5. RISK FACTORS RELATING TO THE TECHNOLOGY</p>
                  <p>
                    Underlying software applications and software platforms
                    (including the Ethereum blockchain) are still in an early
                    development stage and unproven. Therefore, an inherent risk
                    that the software could contain weaknesses, vulnerabilities
                    or bugs causing, inter alia, partial or the complete loss of
                    Tokens exists.
                  </p>
                  <p>
                    As with other tokens based on the Ethereum blockchain, the
                    DOOR Tokens are susceptible to attacks by miners during
                    validating token transactions on the Ethereum blockchain,
                    including, but not limited, to double-spend attacks,
                    majority mining power attacks, and selfish-mining attacks.
                    Hackers or other malicious groups or organizations may
                    attempt to interfere with the DOOR platform or DOOR Token in
                    a variety of ways, including, but not limited to, malware
                    attacks, denial of service attacks, consensus-based attacks,
                    Sybil attacks, smurfing and spoofing. Any successful attacks
                    present a risk to the DOOR platform and the DOOR Token,
                    including, but not limited to accurate execution and
                    recording of transactions involving the token.
                  </p>
                  <p>
                    Direct or indirect damage may be incurred by the Token
                    Holder in connection with transmission errors, transmission
                    cutouts, technical defects, overload, service interruptions
                    (e.g. systems maintenance), disruptions, interference,
                    illegal intervention (e.g. hacking) and willful blockage of
                    telecommunication devices and networks (e.g. “mail bombing”,
                    attacks intended to cripple services, etc.) or in connection
                    with other malfunctions or deficiencies on the part of
                    telecommunication and network operators.
                  </p>
                  <p>
                    In using the DOOR Platform, data is transmitted over open,
                    generally public networks (e.g. the Internet). Data is
                    regularly transmitted in an unsupervised manner across
                    borders, even if the sender and the recipient are both
                    located in the same place. Even where the data itself is
                    encrypted, the sender and recipient can sometimes remain
                    unencrypted, such that third parties may be able to infer
                    their identity. DOOR accepts no liability and gives no
                    guarantee that data transmitted and published via the
                    Internet are correct, accurate and complete. In particular,
                    account-related data (transaction confirmations, account
                    statements, account balances, etc.) and information in the
                    public domain, e.g. exchange prices or exchange rates, shall
                    not be binding.
                  </p>
                  <p>&nbsp;6. EXCLUSION OF LIABILITY</p>
                  <p>
                    The Issuer is not liable, not even in the case of
                    negligence, for damage or consequential damage arising from
                    or in connection with access to or use of the DOOR Platform
                    or the impossibility of access or use. Furthermore, the
                    Issuer excludes any liability to the extent permitted by law
                    in connection with the purchase, holding, sale or other
                    disposal of DOOR Tokens.
                  </p>
                  <p>7. NO BASIS FOR DECISION, NO ADVICE</p>
                  <p>
                    Sales documents or agreements for the purchase of Tokens
                    from the Issuer and this document are not intended to
                    provide investment advice or any other form of
                    decision-making and do not constitute financial, legal, tax
                    and/or other advice. These documents do not replace the
                    qualified advice required prior to any purchase decision, in
                    particular with regard to all associated risks. No
                    investment or other decisions should be made based on these
                    documents.
                  </p>
                  <p>8. QUESTIONS AND CONTACT INFORMATION</p>
                  <p>
                    Please check the White Paper and the FAQ on our website
                    before contacting our support. If you have general inquiries
                    or comments about this Legal Disclaimer and Risk Disclosure,
                    please contact us using the information below:
                  </p>
                  <p>
                    DOOR LLC
                    <br />
                    317 Commercial St. NE <br />
                    Suite A, PMB 443
                    <br />
                    Albuquerque, New Mexico 87102
                    <br />
                    DOORToken.org
                  </p>
                  <p>E-Mail: info@doortoken.org</p>
                </div>
              </div>
            </div>
          </div>`
    },
    {
        title: "Door Privacy Policy",
        content: `
        <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="blog-detail-content">
                  <ul>
                    <li>Updated March 20,2021</li>
                  </ul>

                  <p>
                    Your privacy is very important to us. This Site is hosted by
                    DOORTOKEN.org or one of its affiliates (collectively,
                    "Company").This Privacy Policy has been prepared to explain
                    how we collect, use, protect, and share information and data
                    when you use this Web site (the "Site"), including
                    information and data that we share with service providers
                    who are Authorized Users of doortoken.org.
                  </p>
                  <p>
                    By visiting or using all or any part of this Site, you
                    consent to this Privacy Policy.
                  </p>
                  <p>
                    <strong>Visitors</strong>
                    <br />
                    This Site links to this Privacy Policy because the Company
                    collects, uses, and retains all information, including
                    Personal Information and Usage Data, that users share with
                    or otherwise make available via the Site. For example, when
                    you provide your contact information, this information is
                    collected and stored by the Company under the terms of
                    service agreement.
                  </p>
                  <p>
                    <strong>Information We Collect</strong> <br />
                    Personal Information. Company collects information that
                    personally identifies, relates to, describes, references, is
                    capable of being associated with, or could reasonably be
                    linked, directly or indirectly, with a particular consumer
                    or device ("Personal Information").In particular, the Site
                    has collected the following categories of Personal
                    Information from its consumers within the past twelve (12)
                    months:
                  </p>
                  <p></p>
                  <table cellpadding="10">
                    <tbody>
                      <tr>
                        <td valign="TOP">Category</td>
                        <td valign="TOP">Examples</td>
                        <td valign="TOP">Collected</td>
                      </tr>

                      <tr>
                        <td valign="TOP">A. Identifiers.</td>
                        <td valign="TOP">
                          A real name, alias, postal address, unique personal
                          identifier, online identifier, Internet Protocol
                          address, email address, account name, Social Security
                          number, driver's license number, passport number, or
                          other similar identifiers.
                        </td>
                        <td valign="TOP">YES</td>
                      </tr>

                      <tr>
                        <td valign="TOP">
                          B. Personal information categories listed in the
                          California Customer Records statute (Cal. Civ. Code §
                          1798.80(e)).
                        </td>
                        <td valign="TOP">
                          A name, signature, Social Security number, physical
                          characteristics or description, address, telephone
                          number, passport number, driver's license or state
                          identification card number, insurance policy number,
                          education, employment, employment history, bank
                          account number, credit card number, debit card number,
                          or any other financial information, medical
                          information, or health insurance information. (Some
                          personal information included in this category may
                          overlap with other categories.)
                        </td>
                        <td valign="TOP">YES</td>
                      </tr>
                      <tr>
                        <td valign="TOP">
                          C. Protected classification characteristics under
                          California or federal law.
                        </td>
                        <td valign="TOP">
                          Age (40 years or older), race, color, ancestry,
                          national origin, citizenship, religion or creed,
                          marital status, medical condition, physical or mental
                          disability, sex (including gender, gender identity,
                          gender expression, pregnancy or childbirth and related
                          medical conditions), sexual orientation, veteran or
                          military status, genetic information (including
                          familial genetic information).
                        </td>
                        <td valign="TOP">YES</td>
                      </tr>
                      <tr>
                        <td valign="TOP">D. Commercial information.</td>
                        <td valign="TOP">
                          Records of personal property, products or services
                          purchased, obtained, or considered, or other
                          purchasing or consuming histories or tendencies.
                        </td>
                        <td valign="TOP">YES</td>
                      </tr>
                      <tr>
                        <td valign="TOP">E. Biometric information.</td>
                        <td valign="TOP">
                          Genetic, physiological, behavioral, and biological
                          characteristics, or activity patterns used to extract
                          a template or other identifier or identifying
                          information, such as, fingerprints, faceprints, and
                          voiceprints, iris or retina scans, keystroke, gait, or
                          other physical patterns, and sleep, health, or
                          exercise data.
                        </td>
                        <td valign="TOP">NO</td>
                      </tr>

                      <tr>
                        <td valign="TOP">
                          F. Internet or other similar network activity.
                        </td>
                        <td valign="TOP">
                          Browsing history, search history, information on a
                          consumer's interaction with a website, application, or
                          advertisement.
                        </td>
                        <td valign="TOP">YES</td>
                      </tr>

                      <tr>
                        <td valign="TOP">G. Geolocation data.</td>
                        <td valign="TOP">Physical location or movements.</td>
                        <td valign="TOP">NO</td>
                      </tr>
                      <tr>
                        <td valign="TOP">H. Sensory data.</td>
                        <td valign="TOP">
                          Audio, electronic, visual, thermal, olfactory, or
                          similar information.
                        </td>
                        <td valign="TOP">NO</td>
                      </tr>

                      <tr>
                        <td valign="TOP">
                          I. Professional or employment-related information.
                        </td>
                        <td valign="TOP">
                          Current or past job history or performance
                          evaluations.
                        </td>
                        <td valign="TOP">NO</td>
                      </tr>

                      <tr>
                        <td valign="TOP">
                          J. Non-public education information (per the Family
                          Educational Rights and Privacy Act (20 U.S.C. Section
                          1232g, 34 C.F.R. Part 99)).
                        </td>
                        <td valign="TOP">
                          Education records directly related to a student
                          maintained by an educational institution or party
                          acting on its behalf, such as grades, transcripts,
                          class lists, student schedules, student identification
                          codes, student financial information, or student
                          disciplinary records.
                        </td>
                        <td valign="TOP">NO</td>
                      </tr>

                      <tr>
                        <td valign="TOP">
                          K. Inferences drawn from other personal information.
                        </td>
                        <td valign="TOP">
                          Profile reflecting a person's preferences,
                          characteristics, psychological trends,
                          predispositions, behavior, attitudes, intelligence,
                          abilities, and aptitudes.
                        </td>
                        <td valign="TOP">YES</td>
                      </tr>
                    </tbody>
                  </table>

                  <p></p>
                  <p></p>
                  <h2>
                    For purposes of this Privacy Policy, "Personal Information"
                    does not include:
                  </h2>
                  <p></p>
                  <p>
                    <strong>
                      - Publicly available information from government records.
                    </strong>
                    <br />
                    <strong>
                      - Deidentified or aggregated consumer information.
                    </strong>
                    <br />
                    <strong>
                      - Information excluded from "Personal Information,"
                    </strong>
                    like: health or medical information covered by the Health
                    Insurance Portability and Accountability Act of 1996 (HIPAA)
                    and the California Confidentiality of Medical Information
                    Act (CMIA) or clinical trial data; personal information
                    covered by certain sector-specific privacy laws, including
                    the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley
                    Act (GLBA) or California Financial Information Privacy Act
                    (FIPA), and the Driver's Privacy Protection Act of 1994.
                    Company collects Personal Information only if you choose to
                    share it (for example, by inserting your information into
                    forms you complete when registering or updating in the app).
                    The decision to provide this information is optional.
                    However, if you choose not to provide the requested
                    information, you may not be able to use certain features of
                    the Sites.
                  </p>
                  <p>
                    <strong>- Anonymous Usage Data.</strong> The site
                    automatically collects anonymous usage information that does
                    not identify an individual user ("Usage Data"). For example,
                    each time you use the Site, it automatically collect the
                    type of Web browser you use, your operating system, your
                    Internet Service Provider, your IP address, the pages you
                    view, and the time and duration of your visits. Company uses
                    this information to help understand how people use the
                    Sites, and to enhance the services Company offers. Cookies
                    and Web Beacons. The site may use cookies (a small text file
                    placed on your computer to identify your computer and
                    browser) and Web beacons (an electronic file placed on a Web
                    site that monitors usage). These technologies may be used in
                    connection with the Sites. Company uses cookies and Web
                    beacons to improve the quality of the Sites. Company does
                    not use cookies or Web beacons to collect Personal
                    Information. Most Web browsers are initially set up to
                    accept cookies. You may set most browsers to notify you if
                    you receive a cookie, or you may choose to block cookies,
                    though either of those actions may affect your use of the
                    Site.
                  </p>
                  <p></p>
                  <h2>
                    How We Use Information and When We May Share and Disclose
                    Information
                  </h2>
                  <p></p>
                  <p>
                    <strong>Generally. </strong>Company uses Personal
                    Information only at the direction and for the benefit of the
                    User for internal purposes, such as to provide you with the
                    Sites, to enable Company or the User to notify you of new
                    products or services, and to otherwise communicate with you
                    about your use of the Site or the User. Company will
                    disclose your Personal Information to the User in accordance
                    with this Privacy Policy. Company will not disclose Personal
                    Information to third parties without your consent, including
                    for their direct marketing purposes, except as explained in
                    this Privacy Policy.
                  </p>
                  <p>
                    <strong>Service Providers.</strong> From time to time,
                    Company or the User may establish a business relationship
                    with other businesses whom Company or the User believes is
                    trustworthy and who we have asked to confirm that their
                    privacy practices are consistent with ours ("Service
                    Providers"). For example, Company may contract with Service
                    Providers to provide certain services. Company only provide
                    our Service Providers with the information necessary or
                    helpful for them to perform these services on Company's
                    behalf. Each Service Provider must agree to use reasonable
                    security procedures and practices, appropriate to the nature
                    of the information involved, in order to protect your
                    Personal Information from unauthorized access, use, or
                    disclosure. Service Providers are prohibited from using
                    Personal Information other than for the purpose for which
                    such information was provided or as authorized by you.
                  </p>
                  <p>
                    <strong>Advertising.</strong> We may work with third-party
                    advertisers, search providers and ad networks
                    ("Advertisers"). These companies may collect and use
                    information about your use of the Sites in order to provide
                    advertisements about goods and services that may be of
                    interest to you. These companies may place or recognize a
                    unique cookie on your computer or use other technologies
                    such as Web beacons. This Privacy Policy does not cover any
                    use of information that an Ad Network may collect from you.
                    Standard Analytics Information. Company may use, and
                    disclose to third parties, certain Usage Data regarding the
                    Sites. However, in such cases, your Usage Data is aggregated
                    with the Usage Data of others and does not identify you
                    individually. Other Transfers. Company may share Personal
                    Information and Usage Data with businesses controlling,
                    controlled by, or under common control with it. If Company
                    is merged, acquired, or sold, or in the event of a transfer
                    of some or all of Company's assets, we may disclose or
                    transfer Personal Information and Usage Data in connection
                    with such transaction.
                  </p>
                  <p>
                    <strong>Compliance with Laws and Law Enforcement.</strong>
                    Company cooperates with government and law enforcement
                    officials and private parties to enforce and comply with the
                    law. Company may disclose Personal Information and any other
                    information about you to government or law enforcement
                    officials or private parties if, in its discretion, it
                    believes it is necessary or appropriate for any of the
                    following reasons: (a) to respond to legal requests
                    (including court orders and subpoenas); (b) to protect the
                    safety, property, or rights of Company, or any third party;
                    (c) to prevent or stop any illegal, unethical, or legally
                    actionable activity; or (d) to comply with the law.
                  </p>
                  <p>
                    <strong>
                      Be Careful When You Share Information with Others
                    </strong>
                  </p>
                  <p>
                    Please be aware that whenever you send messages, make
                    postings, or otherwise post or transmit any information or
                    content on this Site or any other of the Sites or any public
                    forum available on or through the Sites, that information or
                    content may be accessed by others. In addition, when you
                    share information or any other communications with third
                    parties, that information may be passed along or made public
                    by others. This means that anyone with access to such
                    information can potentially use it for any purpose,
                    including sending unsolicited communications.
                  </p>
                  <p>
                    <strong>Security</strong>
                  </p>
                  <p>
                    Company is very concerned about safeguarding the
                    confidentiality of your Personal Information. Company
                    employs reasonable physical and electronic measures designed
                    to protect your information from unauthorized access.
                    However, no data transmission over the Internet or other
                    network can be guaranteed to be 100% secure. As a result,
                    while Company strives to protect information transmitted on
                    or through the Sites, Company cannot and does not guarantee
                    the security of any information you upload, store, share,
                    transmit, or provide on or through any Site and you do so at
                    your own risk.
                  </p>
                  <p>
                    <strong>Links to Other Web sites</strong>
                  </p>
                  <p>
                    The Sites may contain links to other Web sites, or allow
                    others to send you such links. A link to a third party's Web
                    site does not mean that Company or the User endorses it or
                    that we are affiliated with it. Company dos not exercise
                    control over third-party Web sites. You access such
                    third-party Web sites or content at your own risk. You
                    should always read the privacy policy of a third-party Web
                    site before providing any information to the Web site.
                    Children's Privacy
                  </p>
                  <p>
                    Company does not knowingly collect Personal Information from
                    children under the age of 13. If Company becomes aware that
                    a user is under the age of 13, Company will terminate any
                    account that user may have with it. If Company becomes aware
                    that it has inadvertently received Personal Information from
                    a child under the age of 13, Company will delete such
                    information from its records.
                  </p>
                  <p>
                    <strong>Processing in the United States</strong>
                  </p>
                  <p>
                    Please be aware that your Personal Information may be
                    transferred to and maintained on servers or databases
                    located outside your state, province, or country. If you are
                    located outside of the United States, please be advised that
                    we process and store all information in the United States.
                    The laws in the United States may not be as protective of
                    your privacy as those in your location. By using the Sites,
                    you agree that the collection, use, transfer, and disclosure
                    of your Personal Information will be governed by the
                    applicable laws in the United States.
                  </p>
                  <p>
                    <strong>Privacy Policy Changes</strong>
                  </p>
                  <p>
                    From time to time, this Privacy Policy may be changed. If
                    this Privacy Policy is changed, you will be informed by
                    posting of the revised Privacy Policy on the Site. Those
                    changes will go into effect on the Revision Date shown in
                    the revised Privacy Policy. Your continued use of the Sites
                    constitutes your consent to the revised Privacy Policy.
                  </p>
                  <p>
                    <strong>FOR CALIFORNIA CONSUMERS ONLY:</strong>
                  </p>
                  <p>
                    The California Consumer Privacy Act of 2018 ("CCPA"), as
                    amended from time to time, applies solely to visitors,
                    users, and others who reside in the State of California.The
                    CCPA provides California residents with specific rights
                    regarding their personal information.If you are a California
                    resident, this section describes your CCPA rights and
                    explains how to exercise those rights.
                  </p>
                  <p>
                    Access to Specific Information and Data Portability
                    Rights.You have the right to request that Company disclose
                    certain information to you about its collection and use of
                    your Personal Information over the past 12 months.Once
                    Company receives and confirms your verifiable consumer
                    request, it will disclose to you:
                  </p>
                  <p>
                    The categories of Personal Information it collected about
                    you.
                    <br />
                    The categories of sources for the Personal Information it
                    collected about you.
                    <br />
                    Its business or commercial purpose for collecting or selling
                    that Personal Information.
                    <br />
                    The categories of third parties with whom it shares that
                    Personal Information.
                    <br />
                    The specific pieces of Personal Information it collected
                    about you (also called a data portability request).
                    <br />
                    If Company sold or disclosed your Personal Information for a
                    business purpose, two separate lists disclosing: Sales,
                    identifying the Personal Information categories that each
                    category of recipient purchased; and Disclosures for a
                    business purpose, identifying the Personal Information
                    categories that each category of recipient obtained.
                  </p>
                  <p>
                    Deletion Request Rights. You have the right to request that
                    Company delete any of your Personal Information that it has
                    collected from you and retained, subject to certain
                    exemptions.Once Company receives and confirms your
                    verifiable consumer request, Company will delete (and direct
                    its service provider(s) to delete) your Personal Information
                    from our records, unless an exemption applies. Company may
                    deny your deletion request if retaining the information is
                    necessary for Company or its service provider(s) to:
                    Complete the transaction for which the Personal Information
                    was collected, provide a good or service that you requested,
                    take actions reasonably anticipated within the context of
                    its ongoing business relationship with you, or otherwise
                    perform our contract with you.
                  </p>
                  <p>
                    Detect security incidents, protect against malicious,
                    deceptive, fraudulent, or illegal activity, or prosecute
                    those responsible for such activities. Debug products to
                    identify and repair errors that impair existing intended
                    functionality.
                  </p>
                  <p>
                    Exercise free speech, ensure the right of another consumer
                    to exercise their free speech rights, or exercise another
                    right provided for by law. Comply with the California
                    Electronic Communications Privacy Act (Cal. Penal Code §
                    1546, et seq.).
                  </p>
                  <p>
                    Engage in public or peer-reviewed scientific, historical, or
                    statistical research in the public interest that adheres to
                    all other applicable ethics and privacy laws, when the
                    information's deletion may likely render impossible or
                    seriously impair the research's achievement, if you
                    previously provided informed consent.
                  </p>
                  <p>
                    Enable solely internal uses that are reasonably aligned with
                    consumer expectations based on your relationship with
                    Company. Comply with a legal obligation.
                  </p>
                  <p>
                    Make other internal and lawful uses of that information that
                    are compatible with the context in which you provided it.
                  </p>
                  <p>
                    Exercising Access, Data Portability, and Deletion Rights.To
                    exercise the access, data portability, and deletion rights
                    described above, please submit a verifiable consumer request
                    to Company by emailing info@doortoken.org directly.
                  </p>
                  <p>
                    Only you, or someone legally authorized to act on your
                    behalf, may make a verifiable consumer request related to
                    your personal information. You may also make a verifiable
                    consumer request on behalf of your minor child.
                  </p>
                  <p>
                    You may only make a verifiable consumer request for access
                    or data portability twice within a 12-month period. The
                    verifiable consumer request must: Provide sufficient
                    information that allows Company to reasonably verify you are
                    the person about whom Company collected personal information
                    or an authorized representative.
                  </p>
                  <p>
                    Describe your request with sufficient detail that allows the
                    Company to properly understand, evaluate, and respond to it.
                  </p>
                  <p>
                    Company cannot respond to your request or provide you with
                    personal information if it cannot verify your identity or
                    authority to make the request and confirm the personal
                    information relates to you. Making a verifiable consumer
                    request does not require you to create an account with
                    Company.Company will only use personal information provided
                    in a verifiable consumer request to verify the requestor's
                    identity or authority to make the request. Response Timing
                    and Format.Company endeavors to respond to a verifiable
                    consumer request within forty-five (45) days of its receipt.
                    If more time is required (up to 90 days), you will be
                    informed of the reason and extension period in writing by
                    mail or electronically, at your option.
                  </p>
                  <p>
                    Any disclosures Company provides will only cover the
                    12-month period preceding the verifiable consumer request's
                    receipt. The response Company provide will also explain the
                    reasons it cannot comply with a request, if applicable. For
                    data portability requests, Company will select a format to
                    provide your personal information that is readily useable
                    and should allow you to transmit the information from one
                    entity to another entity without hindrance. Company does not
                    charge a fee to process or respond to your verifiable
                    consumer request unless it is excessive, repetitive, or
                    manifestly unfounded. If Company determines that the request
                    warrants a fee, it will tell you why that decision was made
                    and provide you with a cost estimate before completing your
                    request.
                  </p>
                  <p>
                    Other California Privacy Rights.California's "Shine the
                    Light" law (Civil Code § 1798.83) permits users of the Site
                    that are California residents to request certain information
                    regarding Company's disclosure of personal information to
                    third parties for their direct marketing purposes.To make
                    such a request, please send an email to info@doortoken.org.
                  </p>
                </div>
              </div>
            </div>
          </div>`
    },
  ];
};


export default diffConditions;