import {
  GET_STAKINGS_INIT,
  GET_STAKINGS_SUCCESS,
  END_STAKING_SUCCESS,
} from '../types'

const initialState = {
  stakings: [],
}

export default function stakingReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_STAKINGS_INIT:
      return {
        ...state,
        stakings: [],
      }
    case GET_STAKINGS_SUCCESS:
      return {
        ...state,
        stakings: payload,
      }
    case END_STAKING_SUCCESS:
      return {
        ...state,
        stakings: state.stakings.map((staking) => {
          if (staking.id !== payload) {
            return staking
          }
          return Object.assign({}, staking, {
            is_ended: true,
          })
        }),
      }
    default:
      return state
  }
}
