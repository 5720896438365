import {
    WALLET_INIT, WALLET_SUCCESS, WALLET_FAILED,
    TRANSACTION_SUCCESS, TRANSACTION_INIT, TRANSACTION_FAILED,
    EXTERNAL_WALLETS_INIT, EXTERNAL_WALLETS_SUCCESS, EXTERNAL_WALLETS_ADD,
    EXTERNAL_WALLETS_REMOVE,
    GET_BANKS_INIT,
    GET_BANKS_SUCCESS,
} from '../types';

const initialState = {
    data: {
        balance: 0,
        unlockedBalance: 0,
        totalBalance: 0,
        transactions: null,
    },
    initialized: false,
    loading: false,
    error: '',
    externalWallets: [],
    banks: [],
}

export default function walletReducer(state = initialState, { type, payload }) {
    switch (type) {
        case WALLET_INIT:
            return {
                ...state,
                data: {
                    balance: 0,
                    unlockedBalance: 0,
                    totalBalance: 0,
                    transactions: null,
                },
                loading: false,
                initialized: true,
                error: '',
            };
        case WALLET_SUCCESS:
            return {
                ...state,
                data:{
                    balance: payload.balance,
                    unlockedBalance: payload.unlockedBalance,
                    totalBalance: payload.totalBalance,
                    transactions: payload.transactions,
                },
                loading: false,
                error: '',
            };
        case WALLET_FAILED:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case TRANSACTION_INIT:
            return {
                ...state,
                data: {
                    transactions: null,
                },
                loading: true,
                error: '',
            };
        case TRANSACTION_SUCCESS:
            return {
                ...state,
                data:{
                    ...state.data,
                    transactions: payload,
                },
                loading: false,
                error: '',
            };
        case TRANSACTION_FAILED:
            return {
                ...state,
                data:{
                    ...state.data,
                    transactions: null,
                },
                loading: false,
                error: payload,
            };
        case EXTERNAL_WALLETS_INIT:
            return {
                ...state,
                externalWallets: [],
            }
        case EXTERNAL_WALLETS_SUCCESS:
            return {
                ...state,
                externalWallets: payload,
            }
        case EXTERNAL_WALLETS_ADD:
            return {
                ...state,
                externalWallets: [
                    { eth_address: payload },
                    ...state.externalWallets,
                ],
            }
        case EXTERNAL_WALLETS_REMOVE:
            return {
                ...state,
                externalWallets: state.externalWallets.filter(wallet => (
                    wallet.eth_address !== payload
                )),
            }
        case GET_BANKS_INIT:
            return {
                ...state,
                banks: [],
            }
        case GET_BANKS_SUCCESS:
            return {
                ...state,
                banks: payload,
            }
        default:
            return state;
    }
}