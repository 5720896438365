import { useDispatch, useSelector } from 'react-redux'

import {AuthService, UserService} from '../../services'
import { LOCAL_STORAGE_KEY } from '../../consts'
import {
  USER_INIT, USER_SUCCESS, USER_FAILED, USER_RESET, NOTI_TYPE,
  LEAD_ZIPCODE_INIT, LEAD_ZIPCODE_SUCCESS, LEAD_ZIPCODE_FAILED,
  LEADS_INIT, LEADS_SUCCESS, LEADS_FAILED, DELETE_ZIPCODE,
  LEADS_SETTING_INIT, LEADS_SETTING_FAILED, LEADS_SETTING_SUCCESS,
  NOTIFICATIONS_SUCCESS, NOTIFICATIONS_LOAD, NOTIFICATION_READ,
  NOTIFICATION_ADD, USER_REQUEST_VERIFY, USER_REQUEST_EMAIL_UPDATE,
  LEAD_DETAIL_INIT, LEAD_DETAIL_SUCCESS,
} from '../types'
import { useNotification } from './notification.hook'
import { useProgress } from './progress.hook'

const { JWT_TOKEN, USER } = LOCAL_STORAGE_KEY;

export const useUser = () => {
  const dispatch = useDispatch();
  const {
    data: user,
    leadZipcode,
    leadSetting,
    leads,
    loading,
    error,
    notifications,
    leadDetail,
  } = useSelector(({ user }) => user)
  const { setNewNotification } = useNotification()
  const { startProgress, stopProgress } = useProgress()

  const userLogin = async (email, password) => {
    try {
      if (user) {
        return user
      }

      dispatch({ type: USER_INIT })
      startProgress()
      const { data } = await AuthService.login(email, password)
      if(data?.user === undefined) {
        return data
      }
      localStorage.setItem(JWT_TOKEN, data.token)
      localStorage.setItem(USER, JSON.stringify(data.user))

      const payload = data.user
      dispatch({ type: USER_SUCCESS, payload })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Login Successfully.')
      stopProgress()
      return payload
    } catch ({ response, message }) {
      dispatch({
        type: USER_FAILED,
        payload: response?.data?.message || message
      })
      setNewNotification(NOTI_TYPE.WARNING, response?.data?.message || message)
      stopProgress()
      return false
    }
  }

  const verify2FA = async(otp, email) => {
    try {
      dispatch({ type: USER_INIT })
      startProgress()
      const {data} = await AuthService.verifyOtp(otp, email)
      localStorage.setItem(JWT_TOKEN, data.token)
      localStorage.setItem(USER, JSON.stringify(data.user))

      const payload = data.user
      dispatch({ type: USER_SUCCESS, payload })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Login Successfully.')
      stopProgress()
      return payload
    } catch ({ response, message }) {
      dispatch({
        type: USER_FAILED,
        payload: response?.data?.message || message
      })
      setNewNotification(NOTI_TYPE.WARNING, response?.data?.message || message)
      stopProgress()
      return false
    }
  }

  const userSignup = async (body) => {
    try {
      if (user) {
        return true
      }
      dispatch({ type: USER_INIT })
      startProgress()
      const { data } = await AuthService.signup(body);
      localStorage.setItem(JWT_TOKEN, data.token);
      localStorage.setItem(USER, JSON.stringify(data.user));
      const payload = data.user;
      // dispatch({ type: USER_SUCCESS, payload });
      // setNewNotification(NOTI_TYPE.SUCCESS, "Register successfully.");
      // setNewNotification(NOTI_TYPE.SUCCESS, "Verification email sent.");
      stopProgress();
      return true;
    } catch ({ response, message }) {
      dispatch({
        type: USER_FAILED,
        payload: response?.data?.message || message
      });
      setNewNotification(NOTI_TYPE.WARNING, response?.data?.message || message)
      stopProgress();
      return false;
    }
  }

  const sendForgotPasswordLink = async (body) => {
    try {
      startProgress();
      await AuthService.sendForgotPasswordLink(body);
      setNewNotification(NOTI_TYPE.SUCCESS, "Password reset link sent! Please check your inbox.");
      stopProgress();
      return true;
    } catch ({ response, message }) {
      stopProgress();
      setNewNotification(NOTI_TYPE.WARNING, "Failed to send password reset link!");
      return false;
    }
  }

  const resetPassword = async (body) => {
    try {
      startProgress();
      await AuthService.resetPassword(body);
      setNewNotification(NOTI_TYPE.SUCCESS, "Password was changed successfully!");
      stopProgress();
      return true;
    } catch ({ response, message }) {
      stopProgress();
      setNewNotification(NOTI_TYPE.WARNING, "Failed to reset password!");
      return false;
    }
  }

  const logout = async () => {
    await Promise.all([
      localStorage.removeItem(JWT_TOKEN),
      localStorage.removeItem(USER),
      sessionStorage.removeItem(JWT_TOKEN)
    ])
    dispatch({ type: USER_RESET });
  }

  const updateUser = (payload) => dispatch({ type: USER_SUCCESS, payload });

  const loadUserLeadZip = async () => {
    try {
      dispatch({type: LEAD_ZIPCODE_INIT})
      startProgress()
      const response = await UserService.getUserLeadZipcode()
      const payload = {
        leadZipcode: response.data.UserLeadZipcodeList,
      }
      dispatch({type: LEAD_ZIPCODE_SUCCESS, payload})
      stopProgress()
      return response.data
    } catch ({response, message}) {
      dispatch({
        type: LEAD_ZIPCODE_FAILED,
        payload: response?.data?.message || message
      })
      stopProgress()
      return false
    }
  }

  const changeLeadZip = async (zipOption) => {
    try {
      startProgress()
      let finalResponse;
      const response = await UserService.changeLeadZipcode(zipOption);
      console.log("here");
      dispatch({
        type: USER_SUCCESS, 
        payload: {...user, zip_code_option: zipOption}                    
      })
      console.log(zipOption)
    
      stopProgress()
      
      setNewNotification(NOTI_TYPE.SUCCESS, response.data.message)
      return finalResponse;
    } catch (error) {
      stopProgress()
      return false
    }
  }

  const addUserLeadZipcode = async (zipcode) => {
    try {
      startProgress()
      let response = await UserService.addUserLeadZipcode({zipcode})
      await loadUserLeadZip()
      setNewNotification(NOTI_TYPE.SUCCESS, "Success! Zipcode added.")
      stopProgress()
      return response
    } catch ({response, message}) {
      dispatch({
        type: LEAD_ZIPCODE_FAILED,
        payload: response?.data?.message || message
      })
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!")
      stopProgress();
      return false
    }
  }

  const loadUserLeads = async (params) => {
    try {
      dispatch({type: LEADS_INIT})
      startProgress()
      const response = await UserService.getUserLeads(params)
      const payload = {
        leads: response.data,
      }
      dispatch({type: LEADS_SUCCESS, payload})
      stopProgress()
      return payload
    } catch ({response, message}) {
      dispatch({
        type: LEADS_FAILED,
        payload: response?.data?.message || message
      })
      stopProgress();
      return false
    }
  }

  const loadLeadDetail = async (id) => {
    try {
      dispatch({
        type: LEAD_DETAIL_INIT,
      })
      startProgress()
      const response = await UserService.getLeadDetail(id)
      dispatch({
        type: LEAD_DETAIL_SUCCESS,
        payload: response.data,
      })
      stopProgress()
    } catch ({response, message}) {
      setNewNotification(NOTI_TYPE.WARNING, response?.data?.message || message)
      stopProgress()
    }
  }

  const loadUserLeadSetting = async () => {
    try {
      dispatch({type: LEADS_SETTING_INIT});
      startProgress();
      const response = await UserService.getUserLeadSetting();
      const payload = {
        is_auto_licence: response.data.is_auto_licence,
        is_email_notification: response.data.is_email_notification,
      }
      dispatch({type: LEADS_SETTING_SUCCESS, payload});
      stopProgress();
      return payload;
    } catch ({response, message}) {
      dispatch({
        type: LEADS_SETTING_FAILED,
        payload: response?.data?.message || message
      });
      stopProgress();
      return false;
    }
  }

  const updateUserLeadSetting = async (data) => {
    try {
      startProgress();
      const response = await UserService.updateUserLeadSetting(data);
      dispatch({type: LEADS_SETTING_SUCCESS, payload: data});
      stopProgress();
      setNewNotification(NOTI_TYPE.SUCCESS, "Success! Lead setting updated.");
      return response;
    } catch ({response, message}) {
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
      stopProgress();
      return false;
    }
  }

  const deleteZipcode = async (id) => {
    try {
      startProgress()
      await UserService.deleteZipcode(id)
      dispatch({ type: DELETE_ZIPCODE, payload: { id } })
      setNewNotification(NOTI_TYPE.SUCCESS, "Success! Zipcode deleted.")
      stopProgress()
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!")
      stopProgress()
    }
  }

  const getUser = async () => {
    try {
      startProgress()
      const payload = await UserService.getUser()
      dispatch({ type: USER_SUCCESS, payload: payload.data })
      stopProgress()
    } catch ({ response, message }) {
      setNewNotification(NOTI_TYPE.WARNING, response?.data?.message || message)
      stopProgress()
    }
  }

  const updateProfile = async (data) => {
    try {
      startProgress()
      const payload = await UserService.updateProfile(data)
      dispatch({ type: USER_SUCCESS, payload: payload.data })
      setNewNotification(NOTI_TYPE.SUCCESS, "Success! Profile updated")
      stopProgress()
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
      stopProgress()
    }
  }

  const submit_auth2 = async (data) => {
    try {
      startProgress()
      const payload = await AuthService.submit_auth2(data);
      stopProgress()
      return true;
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
      stopProgress()
      return false;
    }
  }

  const uploadVerificationDocuments = async (data) => {
    try {
      await UserService.uploadVerificationDocuments(data)
      dispatch({
        type: USER_REQUEST_VERIFY,
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Verification Documents Uploaded.')
    } catch (e) {
      setNewNotification(NOTI_TYPE.WARNING, 'Unable to upload documents. Please try again.')
    }
  }

  const verifyEmail = async (data) => {
    try {
      startProgress()
      await AuthService.verifyEmail(data)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Email Verified Successfully')
      stopProgress()
    } catch(err) {
      stopProgress()
      setNewNotification(NOTI_TYPE.WARNING, 'Failed to verify email.')
    }
  }

  const resendEmailVerification = async () => {
    try {
      startProgress()
      await AuthService.resendEmailVerification()
      setNewNotification(NOTI_TYPE.SUCCESS, 'Verification Email Sent.')
      stopProgress()
      return true
    } catch(err) {
      stopProgress()
      setNewNotification(NOTI_TYPE.WARNING, 'Failed to send verification email.')
      return false
    }
  }

  const requestEmailUpdate = async (email) => {
    try {
      startProgress()
      await AuthService.requestEmailUpdate(email)
      dispatch({ type: USER_REQUEST_EMAIL_UPDATE, payload: email })
      stopProgress()
      setNewNotification(NOTI_TYPE.SUCCESS, 'Please check your email for verification.')
    } catch(err) {
      stopProgress();
      setNewNotification(NOTI_TYPE.WARNING, 'Failed to update email.')
    }
  }

  const sendSMS = async(data) => {
    try {
      startProgress()
      await AuthService.sendSMS(data)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Verification Code Sent.')
      stopProgress()
      return true
    } catch(err) {
      stopProgress()
      setNewNotification(NOTI_TYPE.WARNING, err.response.data || 'Failed to send verification code.')
      return false
    }
  }

  const verifySMS = async(data) => {
    try {
      startProgress();
      await AuthService.verifySMS(data);
      const payload = await UserService.getUser();
      dispatch({ type: USER_SUCCESS, payload: payload.data });
      setNewNotification(NOTI_TYPE.SUCCESS, "Phone Number Verified.");
      stopProgress();
      return true;
    } catch(err) {
      stopProgress();
      setNewNotification(NOTI_TYPE.WARNING, "Failed to verify phone number.");
      return false;
    }
  }

  // Get unread notifications.
  const getNotifications = async() => {
    dispatch({ type: NOTIFICATIONS_LOAD })

    try {
      const response = await UserService.getNotifications()

      dispatch({
        type: NOTIFICATIONS_SUCCESS,
        payload: response.data.notifications,
      })
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser!");
      return false;
    }
  }

  const markNotificationAsRead = async (id) => {
    try {
      await UserService.markNotificationAsRead(id)
      dispatch({
        type: NOTIFICATION_READ,
        payload: id,
      })
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser!")
      return false
    }
  }

  const getVerificationDocs = async () => {
    try {
      startProgress()
      const urls = await UserService.getVerificationDocs()
      stopProgress()
      return urls
    } catch(err) {
      setNewNotification(NOTI_TYPE.WARNING, 'Something went wrong. Please refresh the browser and try again.')
      stopProgress()
    }
  }

  // Add a new notification received via websocket channel.
  const addNotification = (notification) => {
    dispatch({
      type: NOTIFICATION_ADD,
      payload: notification,
    })
  }

  const resetPasswordAfterLogin = async (body) => {
    try {
      startProgress()
      await AuthService.resetPasswordAfterLogin(body)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Password was changed successfully.')
      stopProgress()
      return true
    } catch ({ response, message }) {
      stopProgress()
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to reset password.')
      return false
    }
  }

  const changeTargetSetting = async (target_entire_us) => {
    try {
      startProgress()
      const response = await UserService.changeTargetSetting(target_entire_us)
      setNewNotification(NOTI_TYPE.SUCCESS, response.data.message)
      dispatch({ type: USER_SUCCESS, payload: {...user, target_entire_us:!user?.target_entire_us} });
      stopProgress()
    } catch ({ response, message }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to save.')
      stopProgress()
    }
  }

  const changeTargetStateCity = async (target_state, target_city) => {
    try {
      startProgress()
      const response = await UserService.changeTargetStateCity(target_state, target_city)
      setNewNotification(NOTI_TYPE.SUCCESS, response.data.message)
      dispatch({ type: USER_SUCCESS, payload: {...user, target_state:target_state, target_city: target_city} });
      stopProgress()
    } catch ({ response, message }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to save.')
      stopProgress()
    }
  }


  return {
    user,
    leadZipcode,
    leads,
    leadSetting,
    error,
    loading,
    notifications,
    leadDetail,
    userLogin,
    logout,
    getUser,
    updateUser,
    userSignup,
    loadUserLeadZip,
    changeLeadZip,
    addUserLeadZipcode,
    loadUserLeads,
    loadLeadDetail,
    deleteZipcode,
    loadUserLeadSetting,
    updateUserLeadSetting,
    updateProfile,
    sendForgotPasswordLink,
    resetPassword,
    uploadVerificationDocuments,
    verifyEmail,
    resendEmailVerification,
    requestEmailUpdate,
    sendSMS,
    verifySMS,
    getNotifications,
    markNotificationAsRead,
    getVerificationDocs,
    addNotification,
    resetPasswordAfterLogin,
    changeTargetSetting,
    changeTargetStateCity,
    submit_auth2,
    verify2FA
  }
}

// Initializer
export const initUserFromStorage = async (dispatch) => {
  const saved = localStorage.getItem(USER);

  if (!saved) {
    return;
  }

  try {
    const payload = await UserService.getUser();
    dispatch({ type: USER_SUCCESS, payload: payload.data });
  } catch(err) {
    //
  }
};
