import { LIST_INIT, LIST_SUCCESS, LIST_FAILED, FETCH_EDIT_LIST_SUCCESS, DELETE_LIST,
    LIST_SEARCH_INIT, LIST_SEARCH_SUCCESS, LIST_SEARCH_FAILED } from '../types'

const initialState = {
    data: {
        lists: [],
        searchedLists: null,
        editList: {},
    },
    initialized: false,
    editInitialize: false,
    loading: false,
    error: '',
};

export default function listReducer(state = initialState, { type, payload }) {
    switch (type) {
        case LIST_INIT:
            return {
                ...state,
                data: {
                    ...state.data,
                    lists: [],
                },
                loading: false,
                editInitialize: false,
                error: '',
            };
        case LIST_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    lists: payload.lists,
                },
                loading: false,
                initialized: true,
                error: '',
            };
        case LIST_FAILED:
            return {
                ...state,
                loading: false,
                editInitialize: true,
                initialized: false,
                error: payload,
            };
        case LIST_SEARCH_INIT:
            return {
                ...state,
                data: {
                    ...state.data,
                    searchedLists: null,
                },
                loading: true,
                error: '',
            }
        case LIST_SEARCH_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    searchedLists: payload,
                },
                loading: false,
            }
        case LIST_SEARCH_FAILED:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        case FETCH_EDIT_LIST_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    editList: payload.list,
                },
                loading: false,
                editInitialize: true,
                initialized: false,
                error: '',
            };
        case DELETE_LIST:
            const lists = [...state.data.lists];
            let index = lists.findIndex(x => x.id === payload.id);
            lists.splice(index, 1);
            return {
                ...state,
                data: {
                    ...state.data,
                    lists
                }
            }
        default:
            return state;
    }
}