import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { NOTI_TYPE } from '../../../../../store/types'
import { useStakingPool, useNotification } from '../../../../../store/hooks'

const UpdateStakingPoolPage = () => {
  const { id: stakingPoolId } = useParams()
  const history = useHistory()

  const { stakingPools, getStakingPoolById, updateStakingPool } = useStakingPool()
  const { setNewNotification } = useNotification()

  const [currentStakingPool, setCurrentStakingPool] = useState(null)

  useEffect(() => {
    (async () => {
      const found = stakingPools.find(pool => parseInt(pool.id, 10) === parseInt(stakingPoolId, 10))
      if (!found) {
        await getStakingPoolById(stakingPoolId)
      } else {
        setCurrentStakingPool(Object.assign({}, found))
      }
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (currentStakingPool) {
      return
    }
    const found = stakingPools.find(pool => parseInt(pool.id, 10) === parseInt(stakingPoolId, 10))
    if (found) {
      setCurrentStakingPool(Object.assign({}, found))
    }
  }, [stakingPools]) // eslint-disable-line

  const handleChange = (event) => {
    const newStakingPool = {
      ...currentStakingPool,
      [event.target.name]: event.target.name !== 'is_active'
        ? event.target.value
        : event.target.checked,
    }
    setCurrentStakingPool(newStakingPool)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!currentStakingPool.name) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please enter the name.'
      )
      return
    }

    if (!currentStakingPool.percent) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please enter the percentage.'
      )
      return
    }

    if (!currentStakingPool.duration) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please enter the duration in days.'
      )
      return
    }

    const success = await updateStakingPool(stakingPoolId, currentStakingPool)
    if (success) {
      history.push('/admin/staking-pools')
    }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Staking Pool Information</Card.Title>
      </Card.Header>
      <Card.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Name
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                required
                value={currentStakingPool ? currentStakingPool.name : 0}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Percent
                <span className="required">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                name="percent"
                min="0"
                required
                value={currentStakingPool ? currentStakingPool.percent : 0}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Duration (in days)
                <span className="required">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                name="duration"
                min="0"
                required
                value={currentStakingPool ? currentStakingPool.duration : 0}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Min Amount
                <span className="required">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                name="min_amount"
                min="0"
                required
                value={currentStakingPool ? currentStakingPool.min_amount : 0}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Max Amount
                <span className="required">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                name="max_amount"
                min="0"
                required
                value={currentStakingPool ? currentStakingPool.max_amount : 0}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Is Active
                <span className="required">*</span>
              </label>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="is_active"
                  className="custom-control-input"
                  id="is_active"
                  checked={currentStakingPool ? currentStakingPool.is_active : true}
                  onChange={handleChange}
                />
                <label
                  className="custom-control-label"
                  htmlFor="is_active"
                >
                  Is Active
                </label>
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Update Staking Pool
          </button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default UpdateStakingPoolPage
