import React, { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import Paginator from '../../../../components/Paginator'
import PageSize from '../../../../components/PageSize'
import TableHeader from '../../../../components/TableHeader'
import { useCrypto } from '../../../../../store/hooks'

const ListSearch = () => {
  const { crypto, loadCryptoData } = useCrypto()

  const headers = [
    { name: 'id', label: 'ID' },
    { name: 'bank_name', label: 'Bank Name', sortBy: 'bank_name' },
    { name: 'wallet_name', label: 'Wallet Name', sortBy: 'wallet_name' },
    { name: 'balance', label: 'Balance', sortBy: 'balance' },
    { name: 'action' },
  ]

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(['bank_name', 'asc'])

  useEffect(() => {
    (async () => {
      await loadCryptoData({
        limit,
        page,
        sortBy: sort[0],
        sortOrder: sort[1],
      })
    })()
  }, [page, limit, sort]) // eslint-disable-line

  const handlePageSizeChange = (size) => {
    setLimit(size)
    setPage(1)
  }

  const renderWallets = () => {
    if (!crypto || !crypto.rows.length) {
      return null
    }

    return crypto.rows.map((element, index) => (
      <tr key={index}>
        <td>
          <Link to={`/admin/wallet-detail/${element.id}`}>
            {element.id}
          </Link>
        </td>
        <td>
          {element.bank_name}
        </td>
        <td>
          {element.wallet.wallet_name}
        </td>
        <td>
          {element.balance}
        </td>
        <td>
          <Dropdown className="dropdown d-block" drop="left">
            <Dropdown.Toggle variant="" className="btn btn-success light sharp i-false" data-toggle="dropdown">
              <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-right">
              <Link className="dropdown-item" to={`/admin/wallet-detail/${element.id}`}>
                Details
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    ))
  }

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Manage Wallet:</h4>
      </div>
      <div className="card-body">
        <div className="table-responsive table-hover">
          <div className="dataTables_wrapper">
            <table className="table card-table dataTable">
              <TableHeader
                headers={headers}
                currentSort={sort}
                onChange={(newSort) => { setSort(newSort) }}
              />
              <tbody>
                { renderWallets() }
              </tbody>
            </table>
            <div className="table-footer">
              <Paginator
                recordsCount={crypto?.count || 0}
                recordsPerPage={limit}
                currentPage={page}
                onChange={setPage}
              />
              <PageSize
                currentSize={limit}
                onChange={handlePageSizeChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListSearch
