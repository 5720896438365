import HttpClient from './http-client';


export const referralService = (function () {
    const getReferralUserList = async params => (
        await HttpClient.post('/users/getreferraluser')
    )

    return {
        getReferralUserList
    }
})()