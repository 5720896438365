import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { format as formatDate, parseISO } from 'date-fns'
import swal from 'sweetalert'

import { useSupport } from '../../../../../store/hooks'

const SupportPage = () => {
  const { supports, getSupports, deleteSupport } = useSupport()

  useEffect(() => {
    (async () => {
      await getSupports()
    })()
  }, []) // eslint-disable-line

  const handleRemove = async (userId) => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to remove this support user?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteSupport(userId)
      }
    })
  }

  const renderSupports = () => {
    if (!supports.length) {
      return (
        <tr>
          <td colSpan={4} className="text-center">
            No support users found
          </td>
        </tr>
      )
    }

    return supports.map(user => (
      <tr key={user.id}>
        <td>
          <Link to={`/admin/supports/${user.id}`}>
            { user.fname } { user.lname }
          </Link>
        </td>
        <td>
          { user.email }
        </td>
        <td>
          { formatDate(parseISO(user.createdAt), 'MM-dd-yyyy HH:mm') }
        </td>
        <td className="text-right button-row">
          <Link to={`/admin/supports/${user.id}`} className="btn btn-info btn-xs">
            Update
          </Link>
          <button
            type="button"
            className="btn btn-danger btn-xs"
            onClick={() => handleRemove(user.id)}
          >
            Remove
          </button>
        </td>
      </tr>
    ))
  }

  return (
    <>
      <div className="d-flex flex-row-reverse mb-md-3">
        <Link to="/admin/supports/add" className="btn btn-primary">
          Add new support
        </Link>
      </div>
      <Card>
        <Card.Header>
          <Card.Title>Support Users</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Date Added</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  { renderSupports() }
                </tbody>
              </table>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default SupportPage
