import React, { Fragment } from "react";

/// Components
import Markup from "./jsx";

/// Style
import "./vendor/bootstrap-select/css/bootstrap-select.min.css";
import "./css/style.css";

import { withResizeDetector } from "react-resize-detector";

const App = () => {
  const body = document.querySelector("body");
  body.setAttribute("data-typography", "poppins");
  body.setAttribute("data-theme-version", "light");
  body.setAttribute("data-layout", "vertical");
  body.setAttribute("data-nav-headerbg", "color_1");
  body.setAttribute("data-headerbg", "color_1");
  body.setAttribute("data-sibebarbg", "color_1");
  body.setAttribute("data-primary", "color_1");
  body.setAttribute("data-sidebar-position", "fixed");
  body.setAttribute("data-header-position", "fixed");
  body.setAttribute("data-container", "wide");
  body.setAttribute("direction", "ltr");

  let sidebarStyle = 'full'
  if (body.clientWidth < 768) {
    sidebarStyle = 'overlay'
  }
  body.setAttribute('data-sidebar-style', sidebarStyle);

  return (
    <Fragment>
      <Markup />
    </Fragment>
  );
};

export default withResizeDetector(App);
