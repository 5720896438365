import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { DropzoneArea } from 'material-ui-dropzone'

import FileList from '../../../../components/FileList'

import { uploadFiles } from '../../../../../utils'
import { useProgress, useNotification, useUser } from '../../../../../store/hooks'
import { NOTI_TYPE } from '../../../../../store/types'

export default function VerifyAccount() {
  const { startProgress, stopProgress } = useProgress()
  const { setNewNotification } = useNotification()
  const { user, uploadVerificationDocuments, getVerificationDocs } = useUser()
  const history = useHistory()

  const [isLoaded, setIsLoaded] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const [files, setFiles] = useState([])
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [stateValue, setStateValue] = useState('')
  const [zip, setZip] = useState('')
  const [attachments, setAttachments] = useState([])

  useEffect(() => {
    if (user && !isLoaded) {
      setIsLoaded(true)

      setAddress1(user.address1 || '')
      setAddress2(user.address2 || '')
      setCity(user.city || '')
      setStateValue(user.state || '')
      setZip(user.zip || '')

      if (user.verified === 2) {
        setIsPending(true)

        getVerificationDocs().then((response) => {
          setAttachments(response.data.files)
        })
      }
    }
  }, [user]) // eslint-disable-line

  const handleStateChange = (event) => {
    let value = event.target.value

    // Stripe digit characters from a state.
    value = value.replace(/\d/g, '')

    // Prevent state from being more than 2 letters.
    if (value.length > 2) {
      return
    }

    setStateValue(value)
  }

  const handleZipChange = (event) => {
    let value = event.target.value

    // Stripe non-digit characters from a phone number and zip code.
    value = value.replace(/\D/g, '')

    // Prevent zip codes from being more than 5 digits.
    if (value.length > 5) {
      return
    }

    setZip(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!address1) {
      setNewNotification(NOTI_TYPE.WARNING, 'Please enter your address.')
      return
    }

    if (!city) {
      setNewNotification(NOTI_TYPE.WARNING, 'Please enter your city.')
      return
    }

    if (!stateValue) {
      setNewNotification(NOTI_TYPE.WARNING, 'Please enter your state.')
      return
    }

    if (!zip) {
      setNewNotification(NOTI_TYPE.WARNING, 'Please enter your zip code.')
      return
    }

    // In case of pending users, they can skip uploading files
    // to preserve the ones already submitted.
    if (!isPending && !files.length) {
      setNewNotification(NOTI_TYPE.WARNING, 'Please upload atleast one document.')
      return
    }

    try {
      startProgress()

      let uploadedFiles = []
      if (files.length) {
        uploadedFiles = await uploadFiles(files)
      }

      await uploadVerificationDocuments({
        address1,
        address2,
        city,
        state: stateValue,
        zip,
        files: uploadedFiles,
      })

      stopProgress()

      history.push('/dashboard/verify')
    } catch (err) {
      setNewNotification(NOTI_TYPE.WARNING, 'Unable to upload documents. Please try again.')
      stopProgress()
    }
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Address</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                value={address1}
                onChange={(event) => { setAddress1(event.target.value) }}
              />
            </div>
            <div className="form-group col-md-12">
              <label>Address 2</label>
              <input
                type="text"
                className="form-control"
                value={address2}
                onChange={(event) => { setAddress2(event.target.value) }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>City</label>
              <input
                type="text"
                className="form-control"
                value={city}
                onChange={(event) => { setCity(event.target.value) }}
              />
            </div>
            <div className="form-group col-md-4">
              <label>State</label>
              <input
                type="text"
                className="form-control"
                value={stateValue}
                onChange={handleStateChange}
              />
            </div>
            <div className="form-group col-md-2">
              <label>Zip</label>
              <input
                type="text"
                className="form-control"
                value={zip}
                onChange={handleZipChange}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      {
        isPending && (
          <FileList files={attachments} />
        )
      }
      <DropzoneArea
        acceptedFiles={[".pdf, .doc, .docx, .jpeg, .jpg, .png"]}
        showFileNames
        dropzoneText={(
          <span>
            Upload Documents to Verify Your Account<br />
            <small>
              Drivers License with Name/Address
            </small>
          </span>
        )}
        maxFileSize={15000000}
        onChange={setFiles}
      />
      <div className="form-row mt-3">
        <div className="form-group col-md-4">
          <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
            { user && user.verified === 0 ? 'Request Verification' : 'Resubmit Verification' }
          </button>
        </div>
      </div>
    </>
  )
}