import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ListService } from '../../services'
import { LIST_INIT, LIST_SUCCESS, LIST_FAILED, FETCH_EDIT_LIST_SUCCESS,
  LIST_SEARCH_INIT, LIST_SEARCH_SUCCESS, LIST_SEARCH_FAILED, NOTI_TYPE } from '../types'
import { useProgress } from './progress.hook'
import { useNotification } from './notification.hook'

export const useList = () => {
  const dispatch = useDispatch();
  const { data, initialized } = useSelector(({ list }) => list)

  const lists = data.lists || [];
  const searchedLists = data.searchedLists
  const list = data.editList || {};

  const { startProgress, stopProgress } = useProgress()
  const { setNewNotification } = useNotification()

  const loadLists = async (filtered = false) => {
    try {
      dispatch({ type: LIST_INIT })
      startProgress()
      const listData = await ListService.getLists(filtered)
      const payload = {
        lists: listData.data.lists,
      }
      dispatch({ type: LIST_SUCCESS, payload })
      stopProgress()
      return payload
    } catch ({ response, message }) {
      dispatch({
        type: LIST_FAILED,
        payload: response?.data?.message || message
      })
      stopProgress()
      return false
    }
  }

  const loadListsByType = async (list_type = 0) => {
    try {
      dispatch({ type: LIST_INIT })
      startProgress()
      const listData = await ListService.getListsByType(list_type)
      const payload = {
        lists: listData.data.lists,
      }
      dispatch({ type: LIST_SUCCESS, payload })
      stopProgress()
      return payload
    } catch ({ response, message }) {
      dispatch({
        type: LIST_FAILED,
        payload: response?.data?.message || message
      })
      stopProgress()
      return false
    }
  }

  const searchLists = async (params) => {
    try {
      dispatch({ type: LIST_SEARCH_INIT })
      startProgress()
      const response = await ListService.searchLists(params)
      const payload = response.data
      dispatch({ type: LIST_SEARCH_SUCCESS, payload })
      stopProgress()
      return payload
    } catch ({ response, message }) {
      dispatch({
        type: LIST_SEARCH_FAILED,
        payload: response?.data?.message || message
      })
      stopProgress()
      return false
    }
  }

  const getListById = async (id, access = false) => {
    try {
      startProgress()
      const response = await ListService.getListById(id, access)
      const payload = {
        list: response.data,
      }
      dispatch({ type: FETCH_EDIT_LIST_SUCCESS, payload })
      stopProgress()
      return payload
    } catch ({ response, message }) {
      dispatch({
        type: LIST_FAILED,
        payload: response?.data?.message || message
      })
      stopProgress()
      return false
    }
  }

  const editList = async (id, data) => {
    try {
      startProgress();
      await ListService.editList(id, data);
      setNewNotification(NOTI_TYPE.SUCCESS, "Success! List Updated.");
      stopProgress();
      return true;
    } catch ({ response, message }) {
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
      stopProgress();
      return false;
    }
  };

  const addList = async (data) => {
    try {
      startProgress();
      await ListService.addList(data);
      setNewNotification(NOTI_TYPE.SUCCESS, "Success! New List Added.")
      stopProgress();
      return true;
    } catch ({ response, message }) {
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
      stopProgress();
      return false;
    }
  }

  const getUsers = async () => {
    try {
      startProgress()
      const response = await ListService.getUsers(data)
      stopProgress()
      return response.data
    } catch ({ response, message }) {
      setNewNotification(NOTI_TYPE.WARNING, response?.data?.message || message)
      stopProgress()
      return []
    }
  }

  const addUserAccess = async (list_id, user_id) => {
    try {
      startProgress()
      await ListService.addUserAccess(list_id, user_id)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Added successfully.')
      stopProgress()
      return true
    } catch ({ response, message }) {
      setNewNotification(NOTI_TYPE.WARNING, response?.data?.message || message)
      stopProgress()
      return false
    }
  }

  const removeUserAccess = async (list_id, user_id) => {
    try {
      startProgress()
      await ListService.removeUserAccess(list_id, user_id)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Removed successfully.')
      stopProgress()
      return true
    } catch ({ response, message }) {
      setNewNotification(NOTI_TYPE.WARNING, response?.data?.message || message)
      stopProgress()
      return false
    }
  }

  const loadCustomLists = async () => {
    try {
      startProgress()
      const listData = await ListService.getLists(false, true)
      stopProgress()
      return listData.data.lists
    } catch ({ response, message }) {
      stopProgress()
      return []
    }
  }

  return {
    lists,
    searchedLists,
    list,
    editList,
    addList,
    initialized,
    loadLists,
    searchLists,
    getListById,
    getUsers,
    addUserAccess,
    removeUserAccess,
    loadCustomLists,
    loadListsByType
  }
}

// Initializer
export const useListInitializer = () => {
  const { loadLists, initialized } = useList()
  //init the list if there is not
  useEffect(() => {
    (async () => {
      if (!initialized) {
        loadLists()
      }
    })()
  }, [initialized]) // eslint-disable-line
}
