import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { usePromo } from '../../../../../store/hooks'

const UpdatePromoPage = () => {
  const { id: promoId } = useParams()
  const history = useHistory()

  const { promos, getPromoById, updatePromo } = usePromo()
  const [currentPromo, setCurrentPromo] = useState(null)

  useEffect(() => {
    (async () => {
      const found = promos.find(promo => parseInt(promo.id, 10) === parseInt(promoId, 10))
      if (!found) {
        await getPromoById(promoId)
      } else {
        setCurrentPromo(Object.assign({}, found))
      }
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (currentPromo) {
      return
    }
    const found = promos.find(promo => parseInt(promo.id, 10) === parseInt(promoId, 10))
    if (found) {
      setCurrentPromo(Object.assign({}, found))
    }
  }, [promos]) // eslint-disable-line

  const handleChange = (event) => {
    const newPromo = {
      ...currentPromo,
      [event.target.name]: event.target.name !== 'is_active'
        ? event.target.value
        : event.target.checked,
    }
    setCurrentPromo(newPromo)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const success = await updatePromo(promoId, currentPromo)
    if (success) {
      history.push('/admin/promos')
    }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Promotion Code Information</Card.Title>
      </Card.Header>
      <Card.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Code
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="code"
                required
                value={currentPromo ? currentPromo.code : ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Percent
                <span className="required">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                name="percent"
                min="0"
                required
                value={currentPromo ? currentPromo.percent : ''}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Is Active
                <span className="required">*</span>
              </label>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="is_active"
                  className="custom-control-input"
                  id="is_active"
                  checked={currentPromo ? currentPromo.is_active : true}
                  onChange={handleChange}
                />
                <label
                  className="custom-control-label"
                  htmlFor="is_active"
                >
                  Is Active
                </label>
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Update Promotion Code
          </button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default UpdatePromoPage
