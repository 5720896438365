import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

import  PhoneVerifyModal  from '../../../../components/PhoneVerifyModal'
import { useUser, useNotification } from '../../../../../store/hooks'
import { NOTI_TYPE } from '../../../../../store/types'
import { countryList } from '../../../../../utils'
import { Switch } from '@material-ui/core'

const Profile = () => {
  const { user, getUser, updateProfile, requestEmailUpdate } = useUser()
  const { setNewNotification } = useNotification()
  const [form, setForm] = useState({
    fname: '',
    lname: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
    email: '',
    phone: '',
  })

  const [phoneVerifyVisible, setPhoneVerifyVisible] = useState(false)

  useEffect(() => {
    (async () => {
      await getUser()
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!user) {
      return
    }
    setForm({
      fname: user.fname || '',
      lname: user.lname || '',
      email: user.email || '',
      phone: user.phone || '',
      address1: user.address1 || '',
      address2: user.address2 || '',
      city: user.city || '',
      state: user.state || '',
      zip: user.zip || '',
      country: user.country || 'US',
      is_2fa: user.is_2fa || '',
    })
  }, [user])

  const handleChange = (e) => {
    if(e.target.name == "is_2fa"){
      if (user?.phone_verified == false){
          setNewNotification(NOTI_TYPE.WARNING, 'Please verify your phone number to enable 2FA.');
          return false;
      }
    }
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const handleEmailChange = async () => {
    if (form.email) {
      await requestEmailUpdate(form.email)
    }
  }

  const handleSubmit = async () => {
    await updateProfile(form)
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Basic Information</Card.Title>
      </Card.Header>
      <Card.Body>
        <form method="POST">
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>First Name</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                defaultValue={user?.fname}
                name="fname"
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                name="lname"
                defaultValue={user?.lname}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Email</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="btn btn-info input-group-btn"
                  onClick={handleEmailChange}
                >
                  Change
                </button>
              </div>
            </div>
            <div className="form-group col-md-6">
              <label>Phone</label>
              <div className="input-group">
                <input
                  type="tel"
                  className="form-control"
                  name="phone"
                  readOnly
                  value={form.phone}
                />
                <button
                  type="button"
                  className="btn btn-info input-group-btn"
                  onClick={() => { setPhoneVerifyVisible(true) }}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Address Line 1</label>
              <input
                type="text"
                className="form-control"
                defaultValue={user?.address1}
                name="address1"
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-12">
              <label>Address Line 2</label>
              <input
                type="text"
                className="form-control"
                defaultValue={user?.address2}
                name="address2"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>City</label>
              <input
                type="text"
                className="form-control"
                name="city"
                defaultValue={user?.city}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>State/Province/Region</label>
              <input
                type="text"
                className="form-control"
                name="state"
                value={form.state}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>ZIP/Postal Code</label>
              <input
                type="text"
                className="form-control"
                name="zip"
                value={form.zip}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Country</label>
              <select
                className="form-control"
                name="country"
                value={form.country}
                onChange={handleChange}
              >
                {
                  Object.keys(countryList).map(country => (
                    <option key={country} value={country}>
                      { countryList[country] }
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
          <div style={{ alignContent: "center" }} className="form-group row col-md-6">
            <div style={{ paddingTop: '6px'}}>2 Step Verification: </div>
            <Switch name="is_2fa" checked={Boolean(form.is_2fa)} onChange={({ target }) => handleChange({ target: { name: target.name, value: target.checked } })} />
          </div>
          <div className="button-row">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Update
            </button>
            <Link
              to="/dashboard/reset-password"
              className="btn btn-info"
            >
              Reset Password
            </Link>
          </div>
        </form>
        <PhoneVerifyModal
          show={phoneVerifyVisible}
          phone={form.phone}
          onHide={() => setPhoneVerifyVisible(false)}
        />
      </Card.Body>
    </Card>
  )
}

export default Profile
