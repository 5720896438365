import {useDispatch, useSelector} from "react-redux";
import {ListUserMapService} from '../../services';
import {LIST_MAP_INIT, LIST_MAP_SUCCESS, LIST_MAP_FAILED, UNSUBSCRIBE_LIST_MAP} from '../types';
import {useProgress} from "./progress.hook";

export const useUserListMap = () => {
    const dispatch = useDispatch();
    const {data: {listMap}, initialized} = useSelector(({listMap}) => listMap);
    const { startProgress, stopProgress } = useProgress();
    const loadListMap = async () => {
        try {
            dispatch({type: LIST_MAP_INIT});
            startProgress();
            const response = await ListUserMapService.getSubscriptionListMap();
            const payload = {
                listMap: response.data.subscriptionList,
            }
            dispatch({type: LIST_MAP_SUCCESS, payload});
            stopProgress();
            return payload;
        } catch ({response, message}) {
            dispatch({
                type: LIST_MAP_FAILED,
                payload: response?.data?.message || message
            });
            stopProgress();
            return false;
        }
    };
    const subscribeToList = async (list_id) => {
        try {
            startProgress();
            let response = await ListUserMapService.subscribeToList({list_id});
            await loadListMap();
            stopProgress();
            return response;
        } catch ({response, message}) {
            dispatch({
                type: LIST_MAP_FAILED,
                payload: response?.data?.message || message
            });
            stopProgress();
            return false;
        }
    };

    const unSubscribeFromList = async (listID) => {
        try {
            startProgress();
            let response = await ListUserMapService.unSubscribeFromList(listID);
            dispatch({type: UNSUBSCRIBE_LIST_MAP, payload: {listID}});
            stopProgress();
            return response;
        } catch ({response, message}) {
            dispatch({
                type: LIST_MAP_FAILED,
                payload: response?.data?.message || message
            });
            stopProgress();
            return false;
        }
    };

    return {
        listMap,
        initialized,
        loadListMap,
        subscribeToList,
        unSubscribeFromList
    };
};
