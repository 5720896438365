import React, { useEffect, useState } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { io } from 'socket.io-client'

import AdminRoute from './AdminRoute'

/// Layout
import Nav from '../../layouts/nav'

/// Dashboard
import Dashboard from "./dashboard";
import Home from "./dashboard/home";

/// My Wallet
import Wallet from "./dashboard/wallet";
import Transaction from "./dashboard/wallet/Transaction";
import Deposit from "./dashboard/wallet/Deposit";
import DepositDoor from "./dashboard/wallet/DepositDoor";
import DepositETHtoDoor from './dashboard/wallet/DepositETHtoDoor'
import Withdrawal from "./dashboard/wallet/Withdrawal";
import ViewWallets from './dashboard/wallet/ViewWallets';
import WalletDetail from './dashboard/wallet/WalletDetail';

/// My Nil Wallet

import NilWallet from "./dashboard/nilWallet";
import NilTransaction from "./dashboard/nilWallet/Transaction";
import NilDeposit from "./dashboard/nilWallet/Deposit";
import DepositETHtoNil from "./dashboard/nilWallet/DepositETHtoNil";
import NilWithdrawal from "./dashboard/nilWallet/Withdrawal";
import NilViewWallets from './dashboard/nilWallet/ViewWallets';
import UserNft from './dashboard/nilWallet/UserNft';
import NilWalletDetail from './dashboard/nilWallet/WalletDetail';

// MY ETH Wallet
import ETHWallet from "./dashboard/eth";
import ETHDeposit from "./dashboard/eth/Deposit";
import ETHViewWallets from './dashboard/eth/ViewWallets';
import ETHTransaction from "./dashboard/eth/Transaction";
import ETHWithdrawal from "./dashboard/eth/Withdrawal";
import ETHDepositDirect from "./dashboard/eth/DepositETH";

/// My Property
import Property from "./dashboard/property";
import AddProperty from "./dashboard/property/AddProperty";
import EditProperty from "./dashboard/property/EditProperty";

/// My Lists
import Subscribelist from './dashboard/list/SubscribeList';

/// My Leads
import Lead from "./dashboard/lead";
import LeadSetting from './dashboard/lead/LeadSetting';
import LeadDetails from './dashboard/lead/LeadDetails';
import RequestLeadList from './dashboard/lead/RequestLeadList'
import RequestLeadDetail from './dashboard/lead/RequestLeadDetail'

/// My Requests
import MyRequests from './dashboard/request'
import AddRequest from './dashboard/request/AddRequest'
import EditRequest from './dashboard/request/EditRequest'

/// My Staking
import MyStaking from './dashboard/staking'
import AddStaking from './dashboard/staking/AddStaking'

/// My Referral Network
import MyReferralNetwork from './dashboard/referral/MyReferralNetwork';

/// Other
import Profile from "./dashboard/user/Profile";
import EmailInbox from "./dashboard/user/EmailInbox";
import VerifyAccount from "./dashboard/user/VerifyAccount";
import AdminExperience from "./dashboard/user/AdminExperience";
import Verification from './dashboard/verification';
import ResetPassword from './dashboard/user/ResetPassword'

/// Admin Users
import Users from "./admin/user";
import UsersVerify from "./admin/user/UserVerify";
import UsersDetail from "./admin/user/UserDetail";
import VerifiedUsers from './admin/user/VerifiedUsers'

/// Admin Property
import AdminProperty from "./admin/property";
import PropertyVerify from "./admin/property/PropertyVerify";
import PropertyDetail from "./admin/property/PropertyDetail";

/// Bank Transactions
import Transactions from "./admin/transactions";

/// List
import ListSearch from "./admin/list";
import ListDetail from "./admin/list/ListDetail";
import AddList from "./admin/list/AddList";
import ListSubscribers from "./admin/list/ListSubscribers";
import ListLeads from "./admin/list/ListLeads";

import LeadSearch from "./admin/lead";
import LeadInfo from "./admin/lead/LeadInfo";
import Setting from "./admin/setting";
import AdminWallet from "./admin/wallet";
import AdminWalletDetail from "./admin/wallet/detail";
import SyncData from "./admin/wallet/sync";

import SupportPage from './admin/support/index'
import AddSupportPage from './admin/support/AddSupportPage'
import UpdateSupportPage from './admin/support/UpdateSupportPage'

import PromoPage from './admin/promo/index'
import AddPromoPage from './admin/promo/AddPromoPage'
import UpdatePromoPage from './admin/promo/UpdatePromoPage'

import StakingPoolPage from './admin/stakingPool'
import AddStakingPoolPage from './admin/stakingPool/AddStakingPoolPage'
import UpdateStakingPoolPage from './admin/stakingPool/UpdateStakingPoolPage'
import AdminStaking from './admin/staking';

import { LOCAL_STORAGE_KEY } from "../../../consts";
import { useUser } from "../../../store/hooks"

const dashboardRoutes = [
  { url: "home", component: Home},
  /// My Wallet
  { url: "my-wallet", component: Wallet },
  { url: "transactions", component: Transaction },
  { url: "deposit", component: Deposit },
  { url: "deposit-door", component: DepositDoor },
  { url: "deposit-eth", component: DepositETHtoDoor },
  { url: "withdrawal", component: Withdrawal },
  { url: 'view-wallets', component: ViewWallets },
  { url: 'wallet/:id?', component: WalletDetail },

  /// My Nil Wallet
  { url: "NIL/my-wallet", component: NilWallet },
  { url: "NIL/transactions", component: NilTransaction },
  { url: "NIL/deposit", component: NilDeposit },
  { url: "NIL/deposit-eth", component: DepositETHtoNil },
  { url: "NIL/withdrawal", component: NilWithdrawal },
  { url: "NIL/view-wallets", component: NilViewWallets },
  { url: "NFT/wallet", component: UserNft },
  // { url: 'nil/wallet/:id?', component: WalletDetail },


  /// My ETH Wallet
  { url: "ETH/my-wallet", component: ETHWallet },
  { url: "ETH/transactions", component: ETHTransaction },
  { url: "ETH/deposit", component: ETHDeposit },
  { url: "ETH/view-wallets", component: ETHViewWallets },
  { url: "ETH/withdrawal", component: ETHWithdrawal },
  { url: "ETH/deposit-direct", component: ETHDepositDirect },
  
  
  // // { url: 'nil/wallet/:id?', component: WalletDetail },


  /// My Property
  { url: "my-property", component: Property },
  { url: "add-new-property", component: AddProperty },
  { url: "edit-property/:id?", component: EditProperty },

  /// My Lists
  { url: "subscribe-to-list", component: Subscribelist },

  /// My Leads
  { url: "my-leads", component: Lead },
  { url: "lead-settings", component: LeadSetting },
  { url: "lead-details/:id?", component: LeadDetails },
  { url: "my-request-leads", component: RequestLeadList },
  { url: 'request-lead-details/:id', component: RequestLeadDetail },

  /// My Requests
  { url: 'my-requests', component: MyRequests },
  { url: 'add-request', component: AddRequest },
  { url: "edit-request/:id?", component: EditRequest },

  /// My Staking
  { url: 'my-staking', component: MyStaking },
  { url: 'add-staking', component: AddStaking },

  /// My Referral Network
  { url: 'my-referral', component: MyReferralNetwork },

  /// User
  { url: "profile", component: Profile },
  { url: "email-inbox", component: EmailInbox },
  { url: "verify-account", component: VerifyAccount },
  { url: "admin-experience", component: AdminExperience },
  { url: "verify/:token?", component: Verification },
  { url: 'reset-password', component: ResetPassword },

  /// Admin User
  { url: "admin-users", component: Users },
  { url: "admin-users-verify", component: UsersVerify },
  { url: "admin-user-detail", component: UsersDetail },

   /// Admin Property
   { url: "admin-property", component: AdminProperty },
   { url: "admin-property-verify", component: PropertyVerify },
   { url: "admin-property-detail", component: PropertyDetail },
]

const adminRoutes = [
  /// admin_user
  { url: "search-users", component: Users },
  { url: "verify-users", component: UsersVerify },
  { url: "edit-user/:id?", component: UsersDetail },
  { url: 'verified-users', component: VerifiedUsers },

  /// admin_property
  { url: "property-search", component: AdminProperty },
  { url: "verify-property", component: PropertyVerify },
  { url: "edit-property/:id?", component: PropertyDetail },

  /// Bank Transaction
  { url: "transactions", component: Transactions },

  /// List
  { url: "search-lists", component: ListSearch },
  { url: "list-detail/:id?", component: ListDetail },
  { url: "add-list", component: AddList },
  { url: "list/:id/subscribers", component: ListSubscribers },
  { url: "list/:id/leads", component: ListLeads },

  /// Lead
  { url: "search-leads", component: LeadSearch },
  { url: "lead-details/:id", component: LeadInfo },

  /// AdminWallet
  { url: "admin-wallet", component: AdminWallet },
  { url: "sync-data", component: SyncData },
  { url: "wallet-detail/:id", component: AdminWalletDetail },
  /// Settings
  { url: "settings", component: Setting },

  { url: 'supports', component: SupportPage },
  { url: 'supports/add', component: AddSupportPage },
  { url: 'supports/:id', component: UpdateSupportPage },

  { url: 'promos', component: PromoPage },
  { url: 'promos/add', component: AddPromoPage },
  { url: 'promos/:id', component: UpdatePromoPage },

  { url: 'staking', component: AdminStaking },
  { url: 'staking-pools', component: StakingPoolPage },
  { url: 'staking-pools/add', component: AddStakingPoolPage },
  { url: 'staking-pools/:id', component: UpdateStakingPoolPage },
]

export default function AuthPage() {
  const history = useHistory()
  const { user } = useUser()

  const [isSmallScreen, setSmallScreen] = useState(false)
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    if (document.querySelector('body').clientWidth < 768) {
      setSmallScreen(true)
    }

    let intercom = document.createElement('script')
    intercom.innerHTML = `window.intercomSettings = {app_id: "r32w2y3d"};`
    document.body.appendChild(intercom)

    intercom = document.createElement('script')
    intercom.innerHTML =
      `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');`
      + `ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};`
      + `i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;`
      + `var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;`
      + `s.src='https://widget.intercom.io/widget/r32w2y3d';var x=d.getElementsByTagName('script')[0];`
      + `x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}`
      + `else{w.addEventListener('load',l,false);}}})();`
    document.body.appendChild(intercom)

    if (!localStorage.getItem(LOCAL_STORAGE_KEY.JWT_TOKEN)) {
      if (history.location.pathname.indexOf('/dashboard') === 0
        || history.location.pathname.indexOf('/admin') === 0) {
        history.push('/auth/login')
      }
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (user) {
      if (socket?.connected) {
        socket.disconnect()
      }

      let token = sessionStorage.getItem(LOCAL_STORAGE_KEY.JWT_TOKEN) || ''
      if (!token) {
        token = localStorage.getItem(LOCAL_STORAGE_KEY.JWT_TOKEN) || ''
      }

      setSocket(io(process.env.REACT_APP_BACKEND_URL, {
        auth: {
          token,
        },
        transports: ['websocket'],
      }))
    }
    return () => {
      if (socket?.connected) {
        socket.disconnect()
      }
    }
  }, [user]) // eslint-disable-line

  if (!user) {
    return null
  }

  return (
    <Route path="/:path/:path?/:path?/:path?" exact>
      <div id="main-wrapper" className={`show ${!isSmallScreen ? 'menu-toggle' : ''}`}>
        <Nav socket={socket} />
        <div className="rightside-event content-body">
          <div className="container-fluid">
            <Switch>
              <Route path='/admin/:path?/:path?/:path?' exact>
                <Switch>
                  {adminRoutes.map((data, i) => (
                    <AdminRoute
                      key={i}
                      exact
                      user={user}
                      path={`/admin/${data.url}`}
                      component={data.component}
                    />
                  ))}
                  <Route path='/dashboard' exact component={Dashboard} />
                </Switch>
              </Route>
              <Route path='/dashboard/:path?/:path?' exact>
                <Switch>
                  {dashboardRoutes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/dashboard/${data.url}`}
                      component={data.component}
                    />
                  ))}
                  <Route path='/dashboard' exact component={Dashboard} />
                </Switch>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </Route>
  )
}
