import React from 'react'

const TableHeader = ({ headers, currentSort, onChange }) => {
  const handleClick = (header) => {
    if (!header.sortBy) {
      return
    }

    if (header.sortBy === currentSort[0] && currentSort[1] === 'asc') {
      onChange([header.sortBy, 'desc'])
      return
    }
    onChange([header.sortBy, 'asc'])
  }

  return (
    <thead>
      <tr>
        {
          headers.map(header => {
            let className = ''
            if (header.sortBy) {
              if (header.sortBy === currentSort[0]) {
                if (currentSort[1] === 'asc') {
                  className = 'sorting_asc'
                } else {
                  className = 'sorting_desc'
                }
              } else {
                className = 'sorting'
              }
            }

            return (
              <th
                key={header.name}
                className={className}
                style={header.style || {}}
                onClick={() => { handleClick(header) }}
              >
                { header.label || '' }
              </th>
            )
          })
        }
      </tr>
    </thead>
  )
}

export default TableHeader
