import React, { useState, useEffect, useRef } from 'react'
import { DateRangePicker } from 'react-date-range'
import { format as formatDate, startOfDay } from 'date-fns'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const DateRange = ({ defaultRange, onChange }) => {
  const wrapperRef = useRef()
  // https://reactjs.org/docs/hooks-faq.html#why-am-i-seeing-stale-props-or-state-inside-my-function
  const internalState = useRef({
    visible: false,
    range: {
      startDate: defaultRange[0],
      endDate: defaultRange[1],
    },
  })

  const [visible, setVisible] = useState(false)

  const [range, setRange] = useState({
    startDate: defaultRange[0],
    endDate: defaultRange[1],
    key: 'selection',
  })

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (internalState.current.visible) {
          propagateChange()
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef]) // eslint-disable-line

  const propagateChange = () => {
    setVisible(false)
    internalState.current.visible = false
    onChange(startOfDay(internalState.current.range.startDate),
      startOfDay(internalState.current.range.endDate))
  }

  const handleOpen = () => {
    if (visible) {
      propagateChange()
    } else {
      setVisible(true)
      internalState.current.visible = true
    }
  }

  const handleInputClick = () => {
    if (!visible) {
      setVisible(true)
      internalState.current.visible = true
    }
  }

  const handleChange = (range) => {
    setRange(range.selection)
    internalState.current.range = range.selection
  }

  return (
    <div className="date-range-wrapper" ref={wrapperRef}>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          value={`${formatDate(range.startDate, 'MMMM dd, yyyy')} - ${formatDate(range.endDate, 'MMMM dd, yyyy')}`}
          readOnly
          onClick={handleInputClick}
        />
        <button
          type="button"
          className="btn btn-info input-group-btn"
          onClick={handleOpen}
        >
          Change
        </button>
      </div>
      {
        visible && (
          <DateRangePicker
            className="date-range-picker-wrapper"
            ranges={[range]}
            onChange={handleChange}
          />
        )
      }
    </div>
  )
}

export default DateRange
