import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, Row, Col } from 'react-bootstrap'
import { format as formatDate, parseISO } from 'date-fns'

import { useProperty } from '../../../../../store/hooks/'

const LeadInfo = () => {
  const { id: leadID } = useParams()
  const { adminLeads, searchLeads } = useProperty()

  const [loaded, setLoaded] = useState(false)

  // If leads are not loaded, load them first.
  useEffect(() => {
    if ((!adminLeads || !adminLeads.rows.length) && !loaded) {
      (async () => {
        setLoaded(true)
        await searchLeads({
          id: leadID,
        })
      })()
    }
  }, [adminLeads]); // eslint-disable-line

  const lead = (adminLeads?.rows || []).find(item => item.id === parseInt(leadID, 10))

  const renderRates = () => {
    let average = -1
    if (lead && lead.property_user_map?.length) {
      let sum = 0
      let count = 0
      lead.property_user_map.forEach((map) => {
        // `0` means there is no rate yet.
        if (map.rate) {
          sum += map.rate
          count += 1
        }
      })
      if (count) {
        average = sum / count
      }
    }

    return (
      <Card>
        <Card.Header>
          <Card.Title>Average Rate</Card.Title>
        </Card.Header>
        <Card.Body>
          {
            average > 0 ? `${average.toFixed(1)} out of 4` : 'No rates received yet'
          }
        </Card.Body>
      </Card>
    )
  }

  const renderLicenses = () => {
    let tableBody
    if (!lead || !lead.property_user_map.length) {
      tableBody = (
        <tr>
          <td colSpan={4} className="text-center">No licenses</td>
        </tr>
      )
    } else {
      tableBody = lead.property_user_map.map((license, index) => (
        <tr key={license.id}>
          <td>{ index + 1 }</td>
          <td>
            { license.user.fname } { license.user.lname }
            {
              license.user.email && (
                <>
                  <br />
                  Email: { license.user.email }
                </>
              )
            }
            {
              license.user.phone && (
                <>
                  <br />
                  Phone: { license.user.phone }
                </>
              )
            }
          </td>
          <td>
            {
              license.user.company && license.user.company.name && (
                <>
                  { license.user.company.name }<br />
                  { license.user.company.address1 }<br />
                  {
                    license.user.company.address2 && (
                      <>
                        { license.user.company.address2 }<br />
                      </>
                    )
                  }
                  { license.user.company.city }, { license.user.company.state }, { license.user.company.zip }
                </>
              )
            }
          </td>
          <td>
            { formatDate(parseISO(license.createdAt), 'MMMM dd') }
          </td>
        </tr>
      ))
    }

    return (
      <Card>
        <Card.Header>
          <Card.Title>License History</Card.Title>
        </Card.Header>
        <Card.Body>
          <table className="table">
            <thead>
              <tr>
                <th>No.</th>
                <th>User</th>
                <th>Company</th>
                <th>Licensed At</th>
              </tr>
            </thead>
            <tbody>
              { tableBody }
            </tbody>
          </table>
        </Card.Body>
      </Card>
    )
  }

  return (
    <Row>
      <Col lg={6}>
        <Card>
          <Card.Header>
            <Card.Title>Lead Contact Information</Card.Title>
          </Card.Header>
          <Card.Body>
            <div>
              { lead?.user?.fname } { lead?.user?.lname }<br />
              { lead?.address1 }<br />
              { lead?.address2 ? (<div>{ lead?.address2 }</div>) : null }
              <div>
                { lead?.city ? `${lead?.city}, `  : '' }
                { lead?.state ? `${lead?.state}, `  : '' }
                { lead?.zip }
              </div>
              Phone: {lead?.user?.phone}<br />
              Email: {lead?.user?.email}<br />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <Card.Header>
            <Card.Title>Current Requests</Card.Title>
          </Card.Header>
          <Card.Body>
            <form>
              <div className="form-group">
                {
                  lead?.property_list_map && lead.property_list_map.map(x =>
                  <div className="form-check mb-2" key={x.id}>
                    <input type="checkbox" className="form-check-input" checked readOnly/>
                    <label className="form-check-label">{ x.list.property_label }</label>
                  </div>)
                }
              </div>
            </form>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6}>
        { renderRates() }
      </Col>
      <Col lg={12}>
        { renderLicenses() }
      </Col>
    </Row>
  )
}

export default LeadInfo
