import {
    USER_INIT, USER_SUCCESS, USER_FAILED, USER_RESET,
    LEAD_ZIPCODE_INIT, LEAD_ZIPCODE_SUCCESS, LEAD_ZIPCODE_FAILED,
    LEADS_INIT, LEADS_SUCCESS, LEADS_FAILED, DELETE_ZIPCODE,
    LEADS_SETTING_FAILED, LEADS_SETTING_SUCCESS, LEADS_SETTING_INIT,
    NOTIFICATIONS_LOAD, NOTIFICATIONS_SUCCESS, NOTIFICATION_READ,
    NOTIFICATION_ADD, USER_REQUEST_VERIFY, USER_REQUEST_EMAIL_UPDATE,
    LEAD_DETAIL_INIT, LEAD_DETAIL_SUCCESS,
} from '../types';

const initialState = {
    data: null,
    error: '',
    initialized: false,
    loading: false,
    leadZipcode: {
        data: [],
        error: '',
        loading: false,
        loaded: false,
    },
    leads: {
        data: null,
        error: '',
        loading: false
    },
    leadSetting: {
        data: {
            is_auto_licence: false,
            is_email_notification: false
        },
        error: '',
        loading: false
    },
    notifications: [],
    leadDetail: null,
};

export default function userReducer(state = initialState, { type, payload }) {
    switch (type) {
        case USER_INIT:
            return {
                ...state,
                data: null,
                error: '',
                initialized: true,
                loading: true,
                leadZipcode: {
                    data: [],
                    error: '',
                    loading: false,
                    loaded: false,
                },
            };
        case USER_SUCCESS:
            return {
                ...state,
                data: {...state.data, ...payload},
                error: '',
                initialized: true,
                loading: false,
            };
        case USER_FAILED:
            return {
                ...state,
                data: null,
                error: payload,
                initialized: true,
                loading: false,
            };
        case USER_RESET:
            return {
                ...state,
                data: null,
                error: '',
                initialized: false,
                loading: false,
            };
        case USER_REQUEST_VERIFY:
            return {
                ...state,
                data: {
                    ...state.data,
                    verified: 2,
                },
            }
        case USER_REQUEST_EMAIL_UPDATE:
            return {
                ...state,
                data: {
                    ...state.data,
                    email: payload,
                    email_verified: false,
                },
            }
        case LEAD_ZIPCODE_INIT:
            return {
                ...state,
                leadZipcode: Object.assign({}, state.leadZipcode, {
                    data: [],
                    loading: true,
                    error: '',
                }),
            }
        case LEAD_ZIPCODE_SUCCESS:
            return {
                ...state,
                leadZipcode: Object.assign({}, state.leadZipcode, {
                    data: payload.leadZipcode,
                    loading: false,
                    loaded: true,
                }),
            }
        case LEAD_ZIPCODE_FAILED:
            return {
                ...state,
                leadZipcode: Object.assign({}, state.leadZipcode, {
                    loading: false,
                    loaded: true,
                    error: payload,
                }),
            }
        case LEADS_INIT:
            return {
                ...state,
                leads: {
                    data: null,
                    loading: true,
                    error: ''
                }
            };
        case LEADS_SUCCESS:
            return {
                ...state,
                leads: {
                    data: payload.leads,
                    loading: false,
                    error: ''
                }
            };
        case LEADS_FAILED:
            return {
                ...state,
                leads: {
                    data: null,
                    loading: false,
                    error: payload
                }
            };
        case DELETE_ZIPCODE:
            const data = [...state.leadZipcode.data];
            let index = data.findIndex(x => x.id === payload.id);
            data.splice(index, 1);
            return {
                ...state,
                leadZipcode: {
                    ...state.data,
                    data
                }
            }
        case LEADS_SETTING_INIT:
            return {
                ...state,
                leadSetting: {
                    data: {
                        is_auto_licence: true,
                        is_email_notification: false
                    },
                    loading: true,
                    error: ''
                }
            };
        case LEADS_SETTING_SUCCESS:
            return {
                ...state,
                leadSetting: {
                    data: payload,
                    loading: false,
                    error: ''
                }
            };
        case LEADS_SETTING_FAILED:
            return {
                ...state,
                leadSetting: {
                    ...state.leadSetting,
                    loading: false,
                    error: payload
                }
            };
        case NOTIFICATIONS_LOAD:
            return {
                ...state,
                notifications: [],
            }
        case NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: payload,
            }
        case NOTIFICATION_READ:
            return {
                ...state,
                notifications: state.notifications.filter(item => (
                    item.id !== payload
                )),
            }
        case NOTIFICATION_ADD:
            return {
                ...state,
                notifications: [...state.notifications, payload],
            }
        case LEAD_DETAIL_INIT:
            return {
                ...state,
                leadDetail: null,
            }
        case LEAD_DETAIL_SUCCESS:
            return {
                ...state,
                leadDetail: payload,
            }
        default:
            return state;
    }
}