import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { useSupport } from '../../../../../store/hooks'

const AddSupportPage = () => {
  const history = useHistory()

  const { addSupport } = useSupport()

  const [form, setForm] = useState({
    email: '',
    fname: '',
    lname: '',
    password: '',
  })

  const handleChange = (event) => {
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const success = await addSupport(form)
    if (success) {
      history.push('/admin/supports')
    }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Support User Information</Card.Title>
      </Card.Header>
      <Card.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                First Name
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="fname"
                required
                value={form.fname}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Last Name
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="lname"
                required
                value={form.lname}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Email
                <span className="required">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                required
                value={form.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Password
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="password"
                required
                value={form.password}
                onChange={handleChange}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Add Support User
          </button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default AddSupportPage
