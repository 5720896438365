import { useDispatch, useSelector } from 'react-redux'

import { StakingService } from '../../services'
import {
  GET_STAKINGS_INIT,
  GET_STAKINGS_SUCCESS,
  END_STAKING_SUCCESS,
  NOTI_TYPE,
} from '../types'
import { useNotification } from './notification.hook'
import { useProgress } from "./progress.hook"

export const useStaking = () => {
  const dispatch = useDispatch()

  const { stakings } = useSelector(({ staking }) => staking)
  const { setNewNotification } = useNotification()
  const { startProgress, stopProgress } = useProgress()

  const getStakings = async () => {
    try {
      dispatch({ type: GET_STAKINGS_INIT })
      startProgress()
      const response = await StakingService.getStakings()
      dispatch({type: GET_STAKINGS_SUCCESS, payload: response.data})
      stopProgress()
    } catch (error) {
      stopProgress()
    }
  }

  const getStakingsAdmin = async () => {
    try {
      dispatch({ type: GET_STAKINGS_INIT })
      startProgress()
      const response = await StakingService.getStakingsAdmin()
      dispatch({type: GET_STAKINGS_SUCCESS, payload: response.data})
      stopProgress()
    } catch (error) {
      stopProgress()
    }
  }

  const addStaking = async (params) => {
    try {
      startProgress()
      await StakingService.addStaking(params)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Added a new staking successfully.')
      stopProgress()
      return true
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to add a staking.')
      stopProgress()
      return false
    }
  }

  const endStaking = async (id) => {
    try {
      startProgress()
      await StakingService.endStaking(id)
      dispatch({type: END_STAKING_SUCCESS, payload: parseInt(id, 10)})
      setNewNotification(NOTI_TYPE.SUCCESS, 'Ended a staking successfully.')
      stopProgress()
      return true
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to end a staking.')
      stopProgress()
      return false
    }
  }

  return {
    stakings,
    getStakings,
    addStaking,
    endStaking,
    getStakingsAdmin
  }
}
