import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import StripeCheckout from 'react-stripe-checkout'

import logo from '../../../images/brand-logo.png'
import { NOTI_TYPE } from '../../../store/types'
import { useUser, useNotification, useList, useUserListMap, useWallet, useSetting } from '../../../store/hooks'

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY

const FullRegister = ({ history }) => {
  const { lists, loadListsByType } = useList()
  const { depositByStripe } = useWallet()
  const { userSignup, submit_auth2 } = useUser()
  const { setNewNotification } = useNotification()
  const { listMap, unSubscribeFromList, subscribeToList } = useUserListMap()
  const { setting, loadSetting } = useSetting()

  const [amount, setAmount] = useState(100)
  const [promo, setPromo] = useState('')
  const [tokenRate, setTokenRate] = useState(0.01)

  const [registrationData, setRegistrationData] = useState({})
  const [secondData, setSecondData] = useState({
    is_consumer: true,
    is_advertiser: false,
    how_found: []
  });
  const [listIds, setListIds] = useState([]);

  const [pageStep, setPageStep] = useState(1);

  const handleSecondDataChanges = (name, value) => {
    if ((name == "is_consumer" && value == true) || (name == "is_advertiser" && value == false)) {
      setSecondData((prev) => ({
        ...prev,
        is_consumer: true,
        is_advertiser: false
      }))
    } else if ((name == "is_consumer" && value == false) || (name == "is_advertiser" && value == true)) {
      setSecondData((prev) => ({
        ...prev,
        is_consumer: false,
        is_advertiser: true
      }))
    } else {
      setSecondData((prev) => ({
        ...prev,
        [name]: value,
      }))
    }

  }

  const handleListSelect = (e) => {
    let how_found = [...secondData.how_found];
    const value = e.target.name

    console.log(value);
    if (e.target.checked) {
      if (!how_found.includes(value)) {
        how_found = [...how_found, value]
      }
    } else if (how_found.includes(value)) {
      const index = how_found.findIndex(i => i === value)
      how_found.splice(index, 1)
    }

    setSecondData((prev) => ({
      ...prev,
      how_found,
    }))
  }

  const handlePhoneChange = (phone) => {
    setRegistrationData(prev => Object.assign({}, prev, {
      // Stripe non-digit characters from a phone number.
      phone: phone.replace(/\D/g, '')
    }))
  }

  const handleBlur = (e) => {
    const newRegistrationData = { ...registrationData }
    newRegistrationData[e.target.name] = e.target.value
    setRegistrationData(newRegistrationData)
  }

  const handleStep1 = async (e) => {
    e.preventDefault()

    if (!registrationData.phone) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please enter your phone number.'
      )
      return
    }

    const signupSuccess = await userSignup(Object.assign({}, registrationData, {
      phone: `+${registrationData.phone}`
    }))
    if (signupSuccess) {
      setPageStep(2);
    }
  }

  const handleStep2 = async (e) => {
    e.preventDefault()

    let submitData = {
      is_advertiser: secondData.is_advertiser,
      is_consumer: secondData.is_consumer,
      how_found: secondData.how_found.toString()
    }

    const submitSuccess = await submit_auth2(Object.assign({}, submitData, {
      phone: `+${registrationData.phone}`
    }))
    if (submitSuccess) {
      await loadListsByType(true);
      if (secondData.is_advertiser) {
        setPageStep(3);
      } else {
        setPageStep(4);
      }
    }
  }

  const handleStep3 = async (e) => {
    e.preventDefault()

    await loadSetting()
    setPageStep(5);
  }

  const handleToken = async (token) => {
    if (amount <= 0 || !amount) {
      setNewNotification(NOTI_TYPE.WARNING, 'Invalid amount.')
      return
    }
    await depositByStripe(token.id, amount, promo)
    setAmount(0)
  }

  useEffect(() => {
    if (setting && setting.length) {
      let rate = setting.find(x => x.label === 'token_rate_cc')
      if (rate) {
        setTokenRate(parseFloat(rate.value))
      } else {
        rate = setting.find(x => x.label === 'token_rate')
        if (rate) {
          setTokenRate(parseFloat(rate.value))
        } else {
          setTokenRate(0.01)
        }
      }
    }
  }, [setting])

  const handleCustomListSelect = async (e) => {
    let _listIds = [...listIds]
    const value = Number(e.target.value)

    if (e.target.checked) {
      if (!_listIds.includes(value)) {
        _listIds = [..._listIds, value]
        await handleSubscribe(value);
      }
    } else if (_listIds.includes(value)) {
      const index = _listIds.findIndex(i => i === value)
      _listIds.splice(index, 1)
      await handleUnsubscribe(value);
    }
    setListIds(_listIds);
  }

  const handleSubscribe = async (listId) => {
    await subscribeToList(listId)
  }

  const handleUnsubscribe = async (listId) => {
    const list = listMap.filter(x => x.frn_listid === listId)
    if (list.length === 1 && list[0].id) {
      await unSubscribeFromList(list[0].id)
    }
  }

  const handleSubmitStep5 = () => {
    window.location.href = `${window.location.origin}/#/dashboard/my-wallet`;
    window.location.reload(false);
  }

  const Step2 = () => {

    return (
      <div className="authfy-panel panel-login active second-block-page">
        <form onSubmit={handleStep2}>
          <div className="row social-check-row">
            <div className="col-xs-12 col-sm-12">
              <div className="authfy-heading social-first-block">
                <h3 className="auth-title">How will you get the most out of DOOR?</h3>
              </div>
              <div className="form-group">
                <div className="submit-checkbox">
                  <input
                    type="checkbox"
                    name="is_consumer"
                    id="is_consumer"
                    className="form-check-input"
                    checked={secondData.is_consumer}
                    onChange={() => { handleSecondDataChanges('is_consumer', !secondData.is_consumer) }}
                  />
                  <label htmlFor="is_consumer">I am a Consumer looking to earn DOOR</label>
                </div>
                <div className="submit-checkbox">
                  <input
                    type="checkbox"
                    name="is_advertiser"
                    id="is_advertiser"
                    className="form-check-input"
                    checked={secondData.is_advertiser}
                    onChange={() => { handleSecondDataChanges('is_advertiser', !secondData.is_advertiser) }}
                  />
                  <label htmlFor="is_advertiser">I am a Business looking to find customers</label>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12">
              <div className="authfy-heading social-second-block">
                <h3 className="auth-title">How did you find out about DOOR?</h3>
              </div>
              <div className="form-group checkbox-block-group">
                <div className="submit-checkbox">
                  <input
                    type="checkbox"
                    name="facebook_ad"
                    id="facebook_ad"
                    className="form-check-input"
                    checked={secondData.how_found.includes("facebook_ad")}
                    onChange={handleListSelect} />
                  <label htmlFor="facebook_ad">Facebook Ad</label>
                </div>
                <div className="submit-checkbox">
                  <input
                    type="checkbox"
                    name="bitcoinist"
                    id="bitcoinist"
                    className="form-check-input"
                    checked={secondData.how_found.includes("bitcoinist")}
                    onChange={handleListSelect} />
                  <label htmlFor="bitcoinist">Bitcoinist</label>
                </div>
                <div className="submit-checkbox">
                  <input
                    type="checkbox"
                    name="bitcolmnist"
                    id="bitcolmnist"
                    className="form-check-input"
                    checked={secondData.how_found.includes("bitcolmnist")}
                    onChange={handleListSelect} />
                  <label htmlFor="bitcolmnist">Bitcolumnist</label>
                </div>
                <div className="submit-checkbox">
                  <input
                    type="checkbox"
                    name="yahoo_finance"
                    id="yahoo_finance"
                    className="form-check-input"
                    checked={secondData.how_found.includes("yahoo_finance")}
                    onChange={handleListSelect} />
                  <label htmlFor="yahoo_finance">Yahoo Finance</label>
                </div>
                <div className="submit-checkbox">
                  <input
                    type="checkbox"
                    name="abm_crypto"
                    id="abm_crypto"
                    className="form-check-input"
                    checked={secondData.how_found.includes("abm_crypto")}
                    onChange={handleListSelect} />
                  <label htmlFor="abm_crypto">AMB Crypto</label>
                </div>
                <div className="submit-checkbox">
                  <input
                    type="checkbox"
                    name="referral_link"
                    id="referral_link"
                    className="form-check-input"
                    checked={secondData.how_found.includes("referral_link")}
                    onChange={handleListSelect} />
                  <label htmlFor="referral_link">Referral link</label>
                </div>
                <div className="submit-checkbox">
                  <input
                    type="checkbox"
                    name="friend_or_family"
                    id="friend_or_family"
                    className="form-check-input"
                    checked={secondData.how_found.includes("friend_or_family")}
                    onChange={handleListSelect} />
                  <label htmlFor="friend_or_family">Friend or family</label>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group submit">
            <button className="btn btn-lg btn-primary btn-block sign-up-button" type="submit" onClick={handleSubmitStep5}>Continue</button>
          </div>
        </form>
      </div>
    );
  }


  const Step3 = () => {

    return (
      <div className="authfy-panel panel-login active second-block-page">
        <form onSubmit={handleStep3}>
          <div className="row social-check-row">
            <div className="col-xs-12 col-sm-12">
              <div className="authfy-heading social-first-block">
                <h3 className="auth-title">I want leads from people who are looking for these products or services:</h3>
              </div>
              <div className="form-group">
                {
                  lists.map(list =>
                    <div key={list.id} className="submit-checkbox">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`check-${list.id}`}
                        value={list.id}
                        checked={listIds.includes(list.id)}
                        onChange={handleCustomListSelect}
                      />
                      <label className="form-check-label" htmlFor={`check-${list.id}`}>
                        {list.property_label}
                      </label>
                    </div>
                  )
                }
              </div>
            </div>
          </div>

          <div className="form-group submit">
            <button className="btn btn-lg btn-primary btn-block sign-up-button" type="submit">Continue</button>
          </div>
        </form>
      </div>
    );
  }



  const Step4 = () => {

    return (
      <div className="authfy-panel panel-login active second-block-page">
        <form onSubmit={handleStep3}>
          <div className="row social-check-row">
            <div className="col-xs-12 col-sm-12">
              <div className="authfy-heading social-first-block">
                <h3 className="auth-title">Services that I may need in the future:</h3>
              </div>
              <div className="form-group">
                {
                  lists.map(list =>
                    <div key={list.id} className="submit-checkbox">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`check-${list.id}`}
                        value={list.id}
                        checked={listIds.includes(list.id)}
                        onChange={handleCustomListSelect}
                      />
                      <label className="form-check-label" htmlFor={`check-${list.id}`}>
                        {list.property_label}
                      </label>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className="form-group submit">
            <button className="btn btn-lg btn-primary btn-block sign-up-button" type="submit">Continue</button>
          </div>
        </form>
      </div>
    );
  }

  const Step5 = () => {

    return (
      <div className="authfy-panel panel-login active second-block-page">
        <div className="row social-check-row">
          <div className="col-xs-12 col-sm-12">
            <div className="authfy-heading social-first-block">
              <h3 class="auth-title">Purchase Door Tokens</h3>
            </div>
            <div className="form-group">
              <div className="mb-3">
                <label>If you want you can buy Door Tokens now or do it at a later time through the application. </label>
                <div className="input-group mb-3 input-primary  dolar-icon">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    value={amount}
                    min={0}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      for {(amount / tokenRate).toFixed(2)} Door Tokens
                    </span>
                  </div>
                </div>
              </div>
              <StripeCheckout
                stripeKey={STRIPE_KEY}
                token={handleToken}
                amount={amount * 100}
                name="Door Tokens"
                billingAddress
                shippingAddress
                label="Purchase Door Tokens with Credit Card"
              />
            </div>
          </div>
        </div>

        <div className="form-group submit">
          <button className="btn btn-lg btn-primary btn-block step-button" type="button">Go to App</button>
        </div>
      </div>
    );
  }

  const Step1 = () => {

    return (
      <div className="authfy-panel panel-signup text-center active">
        <div className="authfy-heading">
          <h3 className="auth-title">Sign up and get Door</h3>
        </div>
        <form onSubmit={handleStep1}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="First name"
              name="fname"
              onChange={handleBlur}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Last name"
              name="lname"
              onChange={handleBlur}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Email address"
              name="email"
              onChange={handleBlur}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Zipcode"
              name="zipcode"
              onChange={handleBlur}
              required
            />
          </div>
          <div className="form-group">
            <PhoneInput
              country="us"
              placeholder="Phone"
              inputProps={{
                required: true,
              }}
              value={registrationData.phone || ''}
              onChange={handlePhoneChange}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              name="password"
              onChange={handleBlur}
              required
            />
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="tc_agree"
                required
              />
              <label
                className="custom-control-label"
                htmlFor="tc_agree"
              >
                I agree to <a href="http://doortoken.org/terms.html" style={{ textDecoration: "underline" }} target="_blank" rel="noreferrer">Terms and Conditions</a>.
              </label>
            </div>
          </div>
          <div className="form-group">
            <button className="btn btn-lg btn-primary btn-block sign-up-button" type="submit">
              Sign me up
            </button>
          </div>
        </form>
        <Link className="text-signup-account" to="/auth/login">
          Already have an account?
        </Link>
      </div>
    );
  }

  return (
    <div className="mh100vh unauth-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-7 col-md-5 col-lg-4 authfy-panel-left">
            <div className="brand-logo text-center">
              <img src={logo} width="150" alt="Door Coin" />
            </div>
            <div className="authfy-login">
              {pageStep == 1 && Step1()}
              {pageStep == 2 && Step2()}
              {pageStep == 3 && Step3()}
              {pageStep == 4 && Step4()}
              {pageStep == 5 && Step5()}
            </div>
          </div>
          <div className="col-md-7 col-lg-8 authfy-panel-right hidden-xs hidden-sm">
            <div className="hero-heading">
              <div className="headline">
                <h3>Unlock your Door</h3>
                <p>
                  Join our growing community of people being rewarded to connect with advertisers. <br />
                  Every time a consumer opts-in to receive information about a product or service they earn Door Coin.<br />
                  <strong>Your Data. Your Money.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullRegister
