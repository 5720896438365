import {
  SETTING_SUCCESS, SETTING_FAILED, UPDATE_SETTING
} from '../types';

const initialState = {
  setting: [
    { label: 'token_rate', value: '0.01' },
    { label: 'token_rate_cc', value: '0.01' },
    { label: 'withdraw_limit', value: '100000' },
  ],
  loading: false,
  error: '',
};

export default function settingReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SETTING_SUCCESS:
      return {
        setting: payload,
        loading: false,
        error: '',
      };
    case SETTING_FAILED:
      return {
        ...state,
        setting: [...initialState.setting],
        loading: false,
        error: payload,
      };
    case UPDATE_SETTING: {
      return {
        ...state,
        setting: [...state.setting].map((setting) => {
          if (setting.label === 'token_rate') {
            setting.value = payload.token_rate
          }
          if (setting.label === 'token_rate_cc') {
            setting.value = payload.token_rate_cc
          }
          if (setting.label === 'withdraw_limit') {
            setting.value = payload.withdraw_limit
          }
          if (setting.label === 'transfer_fee') {
            setting.value = payload.transfer_fee
          }
          if (setting.label === 'avg_property_size') {
            setting.value = payload.avg_property_size
          }
          if (setting.label === 'cc_delay') {
            setting.value = payload.cc_delay
          }
          if (setting.label === 'promo_delay') {
            setting.value = payload.promo_delay
          }
          if (setting.label === 'refer_bonus') {
            setting.value = payload.refer_bonus
          }
          return setting
        }),
      }
    }
    default:
      return state;
  }
}
