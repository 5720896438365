import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import swal from 'sweetalert'
import { addDays, subDays, format as formatDate, parseISO } from 'date-fns'

import DateRange from '../../../../components/DateRange'
import Paginator from '../../../../components/Paginator'
import PageSize from '../../../../components/PageSize'
import TableHeader from '../../../../components/TableHeader'
import { useNotification, useUser, useLead, useWallet } from '../../../../../store/hooks'
import { NOTI_TYPE } from '../../../../../store/types'

const RequestLeadList = () => {
  const history = useHistory()
  const {
    leadZipcode: { data: leadZipcodeData, loaded: isZipcodeLoaded },
    loadUserLeadZip,
  } = useUser()
  const { requestLeads, loadRequestLeads, licenseRequestLead } = useLead()
  const { wallet: { unlockedBalance }, loadWallet } = useWallet()
  const { setNewNotification } = useNotification()

  const headers = [
    { name: 'title', label: 'Title', sortBy: 'title' },
    { name: 'address', label: 'Address' },
    { name: 'status', label: 'Status' },
    { name: 'list', label: 'List', style: { width: 160 } },
    { name: 'updatedAt', label: 'Updated At', sortBy: 'updatedAt', style: { width: 200 } },
  ]

  const [range, setRange] = useState([subDays(new Date(), 600), new Date()])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(['updatedAt', 'desc'])

  useEffect(() => {
    loadWallet()
    loadUserLeadZip()
  }, []) // eslint-disable-line

  useEffect(() => {
    (async () => {
      await loadRequestLeads({
        range_start: range[0].toISOString(),
        range_end: addDays(range[1], 1).toISOString(),
        limit,
        page,
        sortBy: sort[0],
        sortOrder: sort[1],
      })
    })()
  }, [range, page, limit, sort]) // eslint-disable-line

  useEffect(() => {
    if (isZipcodeLoaded && !leadZipcodeData.length) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please input the zip code first.'
      )
      history.push('/dashboard/lead-settings')
    }
  }, [isZipcodeLoaded]) // eslint-disable-line

  const handleRangeChange = async (startRange, endRange) => {
    setRange([startRange, endRange])
    setPage(1)
  }

  const handlePageSizeChange = (size) => {
    setLimit(size)
    setPage(1)
  }

  const handleLicense = (lead) => {
    swal({
      title: 'Are you sure?',
      text: `You want to license this request in ${lead.requiredDoorToken} Door token?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        if (unlockedBalance && (lead.requiredDoorToken <= unlockedBalance)) {
          await licenseRequestLead({
            leadId: lead.id,
            doorToken: lead.requiredDoorToken,
          })
          await loadRequestLeads({
            range_start: range[0].toISOString(),
            range_end: addDays(range[1], 1).toISOString(),
            limit,
            page,
          })
          await loadWallet()
        } else {
          setNewNotification(
            NOTI_TYPE.WARNING,
            `Required Door token: ${lead.requiredDoorToken}, add more door token by deposit.`
          )
          history.push('/dashboard/deposit')
        }
      }
    })
  }

  const renderLeads = () => {
    if (!requestLeads || !requestLeads.rows.length) {
      return (
        <tr>
          <td colSpan={5} className="text-center">No leads found.</td>
        </tr>
      )
    }

    // FIXME: Backend should NOT return address for un-licensed leads.
    return requestLeads.rows.map(lead => (
      <tr key={lead.id}>
        <td>
          {
            lead.request_user_map.length > 0 ? (
              <Link to={`/dashboard/request-lead-details/${lead.id}`}>
                { lead.title }
              </Link>
            ) : lead.title
          }
        </td>
        <td>
          {
            lead.request_user_map.length > 0 ? (
              <span>{`${lead.user.address1} ${lead.user.address2}`}</span>
            ) : (
              <span style={{filter: 'blur(2px)'}}>XXX {`${lead.user.address2}`}</span>
            )
          }
        </td>
        <td>
          {
            lead.request_user_map.length > 0 ? (
              <span className="badge badge-primary">Lead Licensed</span>
            ) : (
              <button type="button" className="badge badge-success" onClick={() => handleLicense(lead)}>
                License this Lead
              </button>
            )
          }
        </td>
        <td>
          {
            lead.request_list_map.map(x => (
              <span key={x.id} className="badge badge-primary list-label">
                { x.list.property_label }
              </span>
            ))
          }
        </td>
        <td>
          { formatDate(parseISO(lead.updatedAt), 'MM-dd-yyyy HH:mm') }
        </td>
      </tr>
    ))
  }

  return (
    <div className="my-leads-page">
      <div className="form-head d-flex mb-md-3">
        <DateRange
          defaultRange={range}
          onChange={handleRangeChange}
        />
      </div>
      <Card>
        <Card.Header>
          <Card.Title>Request Leads</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <TableHeader
                  headers={headers}
                  currentSort={sort}
                  onChange={(newSort) => { setSort(newSort) }}
                />
                <tbody>
                  { renderLeads() }
                </tbody>
              </table>
              <div className="table-footer">
                <Paginator
                  recordsCount={requestLeads?.count || 0}
                  recordsPerPage={limit}
                  currentPage={page}
                  onChange={setPage}
                />
                <PageSize
                  currentSize={limit}
                  onChange={handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default RequestLeadList
