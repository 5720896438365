import HttpClient from './http-client'

export const CompanyService = (function () {
  const getCompany = async () => (
    await HttpClient.get('/company')
  )

  const updateCompany = async params => (
    await HttpClient.post('/company', params)
  )

  return {
    getCompany,
    updateCompany,
  }
})()
