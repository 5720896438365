import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { useSupport } from '../../../../../store/hooks'

const UpdateSupportPage = () => {
  const { id: userId } = useParams()
  const history = useHistory()

  const { supports, getSupportById, updateSupport } = useSupport()
  const [currentSupport, setCurrentSupport] = useState(null)

  useEffect(() => {
    (async () => {
      const found = supports.find(support => parseInt(support.id, 10) === parseInt(userId, 10))
      if (!found) {
        await getSupportById(userId)
      } else {
        setCurrentSupport({
          ...found,
          password: '',
        })
      }
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (currentSupport) {
      return
    }
    const found = supports.find(support => parseInt(support.id, 10) === parseInt(userId, 10))
    if (found) {
      setCurrentSupport({
        ...found,
        password: '',
      })
    }
  }, [supports]) // eslint-disable-line

  const handleChange = (event) => {
    const newSupport = {
      ...currentSupport,
      [event.target.name]: event.target.value,
    }
    setCurrentSupport(newSupport)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const success = await updateSupport(userId, currentSupport)
    if (success) {
      history.push('/admin/supports')
    }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Support User Information</Card.Title>
      </Card.Header>
      <Card.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                First Name
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="fname"
                required
                value={currentSupport ? currentSupport.fname : ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Last Name
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="lname"
                required
                value={currentSupport ? currentSupport.lname : ''}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Email
                <span className="required">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                required
                value={currentSupport ? currentSupport.email : ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Password
              </label>
              <input
                type="text"
                className="form-control"
                name="password"
                value={currentSupport ? currentSupport.password : ''}
                onChange={handleChange}
              />
              <div className="help-block">
                Leave the password field as blank to keep the current password.
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Update Support User
          </button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default UpdateSupportPage
