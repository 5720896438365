import {
  GET_REQUESTS_INIT,
  GET_REQUESTS_SUCCESS,
  GET_REQUEST_BY_ID_SUCCESS,
  DELETE_REQUEST_SUCCESS,
} from '../types'

const initialState = {
  requests: null,
  currentRequest: {},
}

export default function requestReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_REQUESTS_INIT:
      return {
        ...state,
        requests: null,
      }
    case GET_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: payload,
      }
    case GET_REQUEST_BY_ID_SUCCESS:
      return {
        ...state,
        currentRequest: payload,
      }
    case DELETE_REQUEST_SUCCESS:
      const requests = [...state.requests.rows]
      let index = requests.findIndex(request => parseInt(request.id, 10) !== parseInt(payload, 10))
      requests.splice(index, 1)
      return {
        ...state,
        requests: {
          rows: requests,
          count: state.requests.count - 1,
        },
      }
    default:
      return state
  }
}
