import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import logo from '../../../images/brand-logo.png'

import { useUser } from '../../../store/hooks'

const EmailVerify = () => {
  const history = useHistory()
  const { token } = useParams()

  const { user, verifyEmail, getUser } = useUser()

  useEffect(() => {
    (async () => {
      if (token) {
        await verifyEmail({ token })
      }
    })()
  }, []) // eslint-disable-line

  const handleContinue = async () => {
    if (!user) {
      history.push('/auth/login')
    } else {
      await getUser()
      history.push('/dashboard/verify')
    }
  }

  return (
    <div className="container-fluid email-verify-page">
      <img src={logo} width="150" alt="Door Coin" />
      <div className="contents-wrapper">
        <i className="fa fa-check-circle" />
        <p>
          You have verified your email.
        </p>
      </div>
      <button type="button" className="btn btn-primary" onClick={handleContinue}>
        Continue
      </button>
    </div>
  )
}

export default EmailVerify
