import HttpClient from './http-client'

export const PromoService = (function () {
  const getPromos = async () => (
    await HttpClient.get('/promos')
  )

  const getPromoById = async id => (
    await HttpClient.get(`/promos/${id}`)
  )

  const addPromo = async params => (
    await HttpClient.post('/promos', params)
  )

  const updatePromo = async (id, params) => (
    await HttpClient.put(`/promos/${id}`, params)
  )

  const deletePromo = async id => (
    await HttpClient.delete(`/promos/${id}`)
  )

  return {
    getPromos,
    getPromoById,
    addPromo,
    updatePromo,
    deletePromo,
  }
})()
