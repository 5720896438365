import HttpClient from "./http-client";

export const UsersService = (function() {
    const getUserById = async(id) => {
        return await HttpClient.get(`/users/${id}`);
    };

    const searchUsers = async (params) => {
        return await HttpClient.get('/users/search', {
            params,
        })
    }

    const GenerateDoor = async () => {
        return await HttpClient.get('/users/generateDoorId')
    }

    const searchUnverifiedUsers = async (params) => {
        return await HttpClient.get('/users/search', {
            params: {
                ...params,
                verified: false,
            }
        })
    }

    const searchVerifiedUsers = async (params) => {
        return await HttpClient.get('/users/search', {
            params: {
                ...params,
                verified: true,
            },
        })
    }

    const updateUser = async(id, data) => {
        return await HttpClient.put(`/users/${id}`, data);
    };

    const deleteUser = async(id) => {
        return await HttpClient.delete(`/users/${id}`);
    };

    const activateUser = async(id) => {
        return await HttpClient.get(`/users/${id}/activate`);
    }

    const lockUser = async(id) => {
        return await HttpClient.put(`/users/${id}/lock`)
    }

    const unlockUser = async(id) => {
        return await HttpClient.put(`/users/${id}/unlock`)
    }

    const loginAs = async (userId) => {
        return await HttpClient.get(`/users/login-as/${userId}`)
    }

    const rejectUser = async (id, reason) => {
        return await HttpClient.put(`/users/${id}/reject`, {
            reason,
        })
    }

    const exportUsers = async () => {
        return await HttpClient.get('/users/export')
    }

    const loadUserNft = async (params) => {
        return await HttpClient.get(`/users/nft?page=${params?.page}&limit=${params?.limit}`)
    }

    return {
        getUserById,
        updateUser,
        deleteUser,
        searchUsers,
        searchUnverifiedUsers,
        searchVerifiedUsers,
        activateUser,
        lockUser,
        unlockUser,
        loginAs,
        rejectUser,
        exportUsers,
        loadUserNft,
        GenerateDoor
    }
})()
