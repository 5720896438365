import { useDispatch, useSelector } from 'react-redux'

import { RequestService } from '../../services'
import {
  GET_REQUESTS_INIT,
  GET_REQUESTS_SUCCESS,
  GET_REQUEST_BY_ID_SUCCESS,
  DELETE_REQUEST_SUCCESS,
  NOTI_TYPE,
} from '../types'
import { useNotification } from './notification.hook'
import { useProgress } from "./progress.hook"

export const useRequest = () => {
  const dispatch = useDispatch()

  const { requests, currentRequest } = useSelector(({ request }) => request)
  const { setNewNotification } = useNotification()
  const { startProgress, stopProgress } = useProgress()

  const getRequests = async (params) => {
    try {
      dispatch({ type: GET_REQUESTS_INIT })
      startProgress()
      const response = await RequestService.getRequests(params)
      dispatch({type: GET_REQUESTS_SUCCESS, payload: response.data})
      stopProgress()
    } catch (error) {
      stopProgress()
    }
  }

  const getRequestById = async (id) => {
    try {
      startProgress()
      const response = await RequestService.getRequestById(id)
      dispatch({
        type: GET_REQUEST_BY_ID_SUCCESS,
        payload: response.data.request,
      })
      stopProgress()
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to retrieve a request.')
      stopProgress()
    }
  }

  const addRequest = async (params) => {
    try {
      startProgress()
      await RequestService.addRequest(params)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Added a new request successfully.')
      stopProgress()
      return true
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to add a request.')
      stopProgress()
      return false
    }
  }

  const updateRequest = async (id, params) => {
    try {
      startProgress()
      await RequestService.updateRequest(id, params)
      setNewNotification(NOTI_TYPE.SUCCESS, 'Updated the request successfully.')
      stopProgress()
      return true
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to update the request.')
      stopProgress()
      return false
    }
  }

  const deleteRequest = async (id) => {
    try {
      startProgress()
      await RequestService.deleteRequest(id)
      dispatch({
        type: DELETE_REQUEST_SUCCESS,
        payload: id,
      })
      setNewNotification(NOTI_TYPE.SUCCESS, 'Deleted the request successfully.')
      stopProgress()
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to delete the request.')
      stopProgress()
    }
  }

  
  const deleteRequestByDescription = async (description) => {
    try {
      startProgress()
      await RequestService.deleteRequestByDescription(description)      
      setNewNotification(NOTI_TYPE.SUCCESS, 'Deleted the request successfully.')
      stopProgress()
    } catch ({ response }) {
      setNewNotification(NOTI_TYPE.WARNING, response.data.message || 'Failed to delete the request.')
      stopProgress()
    }
  }

  return {
    requests,
    currentRequest,
    getRequests,
    getRequestById,
    addRequest,
    updateRequest,
    deleteRequest,
    deleteRequestByDescription
  }
}
