import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'

import { useWallet } from '../../../../../store/hooks'
import ExternalWalletModal from './ExternalWalletModal'

function formatNumber(x) {
  return parseFloat(x).toFixed(5).replace(/\B(?=(\d{3})+(?!\d))/g, '')
}

const Wallet = () => {
  const { wallet: { balance, totalBalance }, loadWallet, loadExternalWallets } = useWallet()


  useEffect(() => {
    (async () => {
      await loadWallet({token_symbol: "ETH"})
      await loadExternalWallets()

      const script = document.createElement("script");
      script.src = "https://widget.nomics.com/embed.js";
      script.async = true;
      document.body.appendChild(script);
    })()
  }, []) // eslint-disable-line



  return (
    <div className="wallet-page">
      <Card>
        <Card.Header className="d-block d-sm-flex border-0 pb-0">
          <h4 className="fs-20 text-black">ETH Wallet</h4>
          <p className="mb-0 fs-13">
            This is your account balance.
          </p>
        </Card.Header>
        <Card.Body>
          <div className="balance-wrapper">
            <Link to="/dashboard/NIL/view-wallets" className="balance-value">
              {formatNumber(totalBalance)} ETH
            </Link>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body className="p-0">
          <div className="media p-4 top-up-bx col-md-12 align-items-center">
            <div className="media-body">
              <h3 className="fs-20 text-white">Buy ETH</h3>
              <p className="text-white font-w200 mb-0 fs-14">
                Purchase ETH using a Credit Card
              </p>
            </div>
            <Link to="/dashboard/ETH/deposit" className="icon-btn ml-3">
              <svg
                width={15}
                height={27}
                viewBox="0 0 15 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.9375 6.23198L5.9375 24.875C5.9375 25.6689 6.58107 26.3125 7.375 26.3125C8.16892 26.3125 8.8125 25.6689 8.8125 24.875L8.8125 6.23201L11.2311 8.66231L11.2311 8.66232C11.7911 9.22502 12.7013 9.22719 13.264 8.66716C13.8269 8.107 13.8288 7.1968 13.2689 6.6342L12.9145 6.98689L13.2689 6.63419L8.3939 1.73557L8.38872 1.73036L8.38704 1.72877C7.82626 1.17279 6.92186 1.17467 6.36301 1.72875L6.36136 1.73031L6.35609 1.73561L1.48109 6.63424L1.48108 6.63425C0.921124 7.19694 0.9232 8.10708 1.48597 8.66719C2.04868 9.22724 2.95884 9.22508 3.51889 8.66237L3.51891 8.66235L5.9375 6.23198Z"
                  fill="#6418C3"
                  stroke="#6418C3"
                />
              </svg>
            </Link>
          </div>         
        </Card.Body>
      </Card>
      <div className="alert alert-primary notification">
        <p className="notificaiton-title mb-2">
          <strong>Quick Guide to your Wallet</strong>
        </p>
        <p>
          You can buy, deposit ETH through the MyDoorWallet.com application to pay the transaction fee.  <br/><br/>
        </p>
      </div>
      <div>
        <div className="nomics-ticker-widget" data-name="ETH" data-base="ETH"  data-quote="USD"></div>
      </div>
    </div>
  )
}

export default Wallet
