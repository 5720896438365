import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import MetisMenu from 'metismenujs'

import { detectMob } from '../../../utils'
import { ROLE_ADMIN, ROLE_USER, ROLE_SUPPORT } from '../../../consts'

import Nil_Token_logo from '../../../images/nil_icon_black.png';
import NFT_Token_logo from '../../../images/nft_logo.png';
import ETH_Token_logo from '../../../images/eth_icon.png';
import Door_Token_logo from '../../../images/logo.png';

const adminMenuList = [
  {
    id: 'menu_user',
    name: 'Users',
    icon: 'flaticon-381-user-9',
    children: [
      { name: 'Search Users', link: '/admin/search-users' },
      { name: 'Verify Users', link: '/admin/verify-users' },
      { name: 'Verified Users', link: '/admin/verified-users' },
      { name: 'Support Users', link: '/admin/supports' },
    ],
  },
  {
    id: 'menu_property',
    name: 'Property',
    icon: 'flaticon-381-home',
    children: [
      { name: 'Property Search', link: '/admin/property-search' },
      { name: 'Verify Property', link: '/admin/verify-property' },
    ],
  },
  {
    id: 'menu_financial',
    name: 'Financials',
    icon: 'flaticon-381-lock-1',
    children: [
      { name: 'Bank Transactions', link: '/admin/transactions' },
    ],
  },
  {
    id: 'menu_list',
    name: 'Lists',
    icon: 'flaticon-381-list',
    children: [
      { name: 'List Search', link: '/admin/search-lists' },
    ],
  },
  {
    id: 'menu_lead',
    name: 'Leads',
    icon: 'flaticon-381-magnet-2',
    children: [
      { name: 'Lead Search', link: '/admin/search-leads' },
    ],
  },
  {
    id: 'menu_wallet',
    name: 'Wallet',
    icon: 'flaticon-381-id-card',
    children: [
      { name: 'Management', link: '/admin/admin-wallet' },
      { name: 'Sync Data', link: '/admin/sync-data' },
    ],
  },
  {
    id: 'menu_staking',
    name: 'Staking',
    icon: 'flaticon-381-television',
    children: [
      { name: 'Staking List', link: '/admin/staking' },
      { name: 'Staking Pools', link: '/admin/staking-pools' },
    ],
  },
  {
    id: 'menu_setting',
    name: 'Settings',
    icon: 'flaticon-381-home',
    children: [
      { name: 'Settings', link: '/admin/settings' },
      { name: 'Promotion Codes', link: '/admin/promos' },
      
    ],
  },
]

const userMenuList = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    icon: 'flaticon-381-networking',
    to: "/dashboard/home"
  },
  {
    id: 'menu_wallet',
    name: 'Door Wallet',
    iconType: 'Image',
    icon: Door_Token_logo,
    children: [
      { name: 'My Balance', link: '/dashboard/my-wallet' },      
      { name: 'Buy Door with CC', link: '/dashboard/deposit' },
      { name: 'Buy Door with ETH', link: '/dashboard/deposit-eth' },
      { name: 'View Wallets', link: '/dashboard/view-wallets' },
      { name: 'Transactions', link: '/dashboard/transactions' },
      { name: 'Deposit', link: '/dashboard/deposit-door' },
      { name: 'Withdraw', link: '/dashboard/withdrawal' },
    ],
  },
  {
    id: 'menu_nil_wallet',
    name: 'NIL Wallet',
    iconType: 'Image',
    icon: Nil_Token_logo,
    children: [
      { name: 'My Balance', link: '/dashboard/NIL/my-wallet' },      
      { name: 'Buy NIL with CC', link: '/dashboard/NIL/deposit' },
      { name: 'Buy NIL with ETH', link: '/dashboard/NIL/deposit-eth' },
      { name: 'View Wallets', link: '/dashboard/NIL/view-wallets' },
      { name: 'Transactions', link: '/dashboard/NIL/transactions' },      
      { name: 'Withdraw', link: '/dashboard/NIL/withdrawal' }
    ],
  },
  {
    id: 'menu_nft_wallet',
    name: 'NFT Wallet',
    iconType: 'Image',
    icon: NFT_Token_logo,
    children: [
      { name: 'Wallet', link: '/dashboard/NFT/wallet' },
    ],
  },
  {
    id: 'menu_eth_wallet',
    name: 'ETH Wallet',
    iconType: 'Image',
    icon: ETH_Token_logo,
    children: [
      { name: 'My Balance', link: '/dashboard/ETH/my-wallet' },      
      { name: 'Buy ETH with CC', link: '/dashboard/ETH/deposit' },     
      { name: 'Deposit ETH', link: '/dashboard/ETH/deposit-direct' }, 
      { name: 'View Wallets', link: '/dashboard/ETH/view-wallets' },
      { name: 'Transactions', link: '/dashboard/ETH/transactions' },      
      { name: 'Withdraw', link: '/dashboard/ETH/withdrawal' },      
    ],
  },
  // {
  //   id: 'my_home',
  //   name: 'My Home',
  //   icon: 'flaticon-381-magnet-2',
  //   children: [
  //     {
  //       id: 'menu_property',
  //       name: 'My Property',
  //       icon: 'flaticon-381-home',
  //       children: [
  //         { id: 'menu_property', name: 'My Property', link: '/dashboard/my-property' },
  //         { id: 'menu_property2', name: 'Add New Property', link: '/dashboard/add-new-property' },
  //       ],
  //     },
  //     {
  //       id: 'menu_lead',
  //       name: 'My Leads',
  //       icon: 'flaticon-381-magnet-2',
  //       children: [
  //         {  id: 'menu_property3', name: 'My Leads', link: '/dashboard/my-leads' },
  //         { id: 'menu_property4', name: 'My Request Leads', link: '/dashboard/my-request-leads' },
  //         { id: 'menu_property5', name: 'Lead Settings', link: '/dashboard/lead-settings' },
  //         { id: 'menu_property6', name: 'Subscribe to List', link: '/dashboard/subscribe-to-list' },
  //       ],
  //     },
  //     {
  //       id: 'menu_requests',
  //       name: 'My Requests',
  //       icon: 'flaticon-381-notepad',
  //       children: [
  //         { id: 'menu_property7', name: 'Current Requests', link: '/dashboard/my-requests' },
  //         { id: 'menu_property8', name: 'Request a Service', link: '/dashboard/add-request' },
  //       ],
  //     },
  //   ],
  // },
  {
    id: 'menu_staking',
    name: 'My Stakes',
    icon: 'flaticon-381-television',
    children: [
      { name: 'My Stakes', link: '/dashboard/my-staking' },
      { name: 'Add New Staking', link: '/dashboard/add-staking' },
    ],
  },
  {
    id: 'menu_referral',
    name: 'My Referrals',
    icon: 'flaticon-381-internet',
    children: [      
      { name: 'Refer and Earn', link: '/dashboard/my-referral' },
    ]    
  },
  {
    id: 'menu_help',
    name: 'Help',
    icon: 'flaticon-381-heart',
    children: [
      { name: 'Community Chat', link: 'https://doorcoin.com/discord', isExternal: true },
      { name: 'F.A.Q.', link: 'https://doortoken.gitbook.io/door-token/', isExternal: true },
      { name: 'Tutorial', link: 'https://doortoken.gitbook.io/door-help', isExternal: true },
    ],
  },
]

const supportMenuList = [
  {
    id: 'menu_user',
    name: 'Users',
    icon: 'flaticon-381-user-9',
    children: [
      { name: 'Search Users', link: '/admin/search-users' },
      { name: 'Verify Users', link: '/admin/verify-users' },
      { name: 'Verified Users', link: '/admin/verified-users' },
    ],
  },
  {
    id: 'menu_property',
    name: 'Property',
    icon: 'flaticon-381-home',
    children: [
      { name: 'Property Search', link: '/admin/property-search' },
      { name: 'Verify Property', link: '/admin/verify-property' },
    ],
  },
  {
    id: 'menu_lead',
    name: 'Leads',
    icon: 'flaticon-381-magnet-2',
    children: [
      { name: 'Lead Search', link: '/admin/search-leads' },
    ],
  },
]

class MM extends Component {
  componentDidMount() {
    this.$el = this.el
    this.mm = new MetisMenu(this.$el)

    this.activateMenu()
  }

  componentDidUpdate(prevProps) {
    const { pathname, userRole, toggle } = this.props;
    // By default, until an user info is fetched, the user role is
    // set to User. Because Admin uses different menu, we need to
    // update metis menu after getting correct user role.
    if (prevProps.userRole !== userRole) {
      this.mm.dispose()
      this.mm.update()
    }

    if (prevProps.pathname !== pathname) {
      this.activateMenu()
    }

    // When sidebar is collapsed, highlight the current path's menu.
    if (prevProps.toggle !== toggle && toggle) {
      this.activateMenu()
    }
  }

  activateMenu() {
    const { pathname, userRole } = this.props
    
    let menuList = []
    if (userRole === ROLE_ADMIN) {
      menuList = adminMenuList
    } else if (userRole === ROLE_SUPPORT) {
      menuList = supportMenuList
    } else if (userRole === ROLE_USER) {
      menuList = userMenuList
    }

    const currentMenu = menuList.find(menu => {
      let returnMenu;
      if (menu?.children?.length) {
        returnMenu = menu?.children?.find(child => {
          let currentChild;
          if (!child.children) {
            currentChild = child.link === pathname
          } else {
            currentChild = child.children.find(childChild => childChild.link === pathname);
          }
          return currentChild
        })
      } else {
        returnMenu = menu.to === pathname
      }
      return returnMenu
    });

    if (currentMenu) {
      const $ul = this.$el.querySelector(`#${currentMenu.id} > ul`);
      if ($ul) {
        this.mm.show($ul)
        // Below three actions should be done by show(),
        // but `transitionend` event is not fired for elements with `display: none`,
        // so we manually invoke them.
        $ul.classList.remove('mm-collapsing')
        $ul.style.height = ''
        this.mm.setTransitioning(false)

        const otherUls = this.$el.querySelectorAll('ul')
        otherUls.forEach((otherUl) => {
          if (otherUl.parentElement && otherUl.parentElement.id === currentMenu.id) {
            return
          }
          otherUl.classList.remove('mm-collapsing')
          otherUl.classList.add('mm-collapse')
          otherUl.style.height = ''
        })

        const $link = $ul.querySelector(`a[href='#${pathname}']`);
        if ($link) {
          const siblings = this.$el.querySelectorAll('a');
          if (userRole === ROLE_USER) {
            document.getElementById("dashboard").classList.remove("mm-active");
          }
          siblings.forEach((sibling) => {
            sibling.classList.remove('mm-active')
          })
          $link.classList.add('mm-active')
        }
      } else {
        const $ul = this.$el.querySelector(`#${currentMenu.id}`);
        const list = document.getElementsByClassName('mm-active');
        if(list.length){
          Array(...list).map(($active_class) => {
            $active_class.classList.remove("mm-active")
          })
        }
        $ul.classList.add('mm-active');
      }
    }
  }

  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          { this.props.children }
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      toggle: false,
    }
  }

  toggleFunc = () => {
    const $wrapper = document.querySelector('#main-wrapper')
    $wrapper.classList.toggle('menu-toggle')
    this.setState({
      toggle: $wrapper.classList.contains('menu-toggle')
    })
  }

  resizeSidebar = () => {
    const wrapper = document.querySelector('#main-wrapper')
    if (window.innerWidth >= 468) {
      wrapper.classList.add('large')
      wrapper.classList.remove('small')
    } else {
      wrapper.classList.add('small')
      wrapper.classList.remove('large')
    }
  }

  clickMenu = () => {
    if (detectMob()) {
      document.querySelector('#main-wrapper').classList.remove('menu-toggle')
    }
  }

  componentDidMount() {
    document.querySelector('.nav-control').addEventListener('click', this.toggleFunc)
    window.addEventListener('resize', this.resizeSidebar)

    if (window.innerWidth < 468) {
      const wrapper = document.querySelector('#main-wrapper')
      wrapper.classList.add('small')
      wrapper.classList.remove('large')
    }
  }

  componentWillUnmount() {
    const btn = document.querySelector('.nav-control')
    btn.removeEventListener('click', this.toggleFunc)
    window.removeEventListener('resize', this.resizeSidebar)
  }

  render() {
    const { location, userRole } = this.props
    const { toggle } = this.state

    let menuList = []
    if (userRole === ROLE_ADMIN) {
      menuList = adminMenuList
    } else if (userRole === ROLE_SUPPORT) {
      menuList = supportMenuList
    } else if (userRole === ROLE_USER) {
      menuList = userMenuList
    }

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM
            id="menu"
            className="metismenu"
            pathname={location.pathname}
            userRole={userRole}
            toggle={toggle}
          >
            {
              menuList.map(menu => (
                <li key={menu.id} id={menu.id}>
                  <Link className={`${menu?.children?.length ? 'has-arrow ai-icon' : null}`} to={menu?.to ? menu.to : "#"} aria-expanded="false" onClick={() => {return menu?.children?.length ?  null: this.clickMenu()}}>
                    {menu.iconType === "Image" ? <img src={menu?.icon} alt="sidebarlogo" className="sidebar-image"></img> : <i className={`${menu.icon}`}></i> }
                    <span className="nav-text">{ menu.name }</span>
                  </Link>
                  {menu?.children?.length && <ul>
                    {
                      menu?.children?.map(child => (
                        <li key={child.name}>
                          {
                            child.isExternal !== true ? (
                              <>
                              <Link
                              className={child?.children && "has-arrow ai-icon"}
                                to={child.link}
                                onClick={() => this.clickMenu()}
                              >
                                { child.name }
                              </Link>
                              {child?.children && <ul>
                              {child?.children?.map(childs => (
                                <li key={childs.name} id={childs.id} style={{marginLeft: "10%"}}>
                                  <Link
                                    to={childs.link}
                                    onClick={() => this.clickMenu()}
                                  >
                                { childs.name }
                              </Link>
                                  </li>
                              ))}
                                </ul>
  }
                                </>
                              // </MM>
                            ) : (
                              <a href={child.link} target="_blank" rel="noreferrer" >
                                { child.name }
                              </a>
                            )
                          }
                        </li>
                      ))
                    }
                  </ul>}
                </li>
              ))
            }
          </MM>
        </PerfectScrollbar>
      </div>
    )
  }
}

export default withRouter(SideBar)
