import React, { Fragment, useEffect, useState } from "react";
import { format as formatDate, parseISO } from "date-fns";
import { useReferral } from "../../../../../store/hooks";
import { Card, Col, Row } from "react-bootstrap";


function formatNumber(x) {
  return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const MyReferralNetwork = () => {
  const [user, setUser] = useState({});
  const [referralUser, setReferralUSer] = useState([]);
  const [totalEarning, setTotalEarning] = useState("");
  const { loadReferralUser } = useReferral();

  useEffect(() => {
    const loginUser = localStorage.getItem("user");
    setUser(JSON.parse(loginUser));
    loadReferralUser()
      .then((data) => {
        setReferralUSer(data?.data?.referralUserList);
        setTotalEarning(data?.data?.totalEarningFromReferral);
      })
      .catch((error) => {
        console.log("Error");
      });
    console.log("{}{");
  }, []);

  const handleClickCopy = () => {
    const referalLink = `${window.location.origin}/#/auth/register?referral=${user?.refer_code}`;
    navigator.clipboard.writeText(referalLink);
  };

  const renderReferUser = () => {
    if (!referralUser.length) {
      return (
        <tr>
          <td colSpan={5} className="text-center">
            No user found.
          </td>
        </tr>
      );
    }

    return referralUser.map((referUser, index) => (
      <tr key={referUser.id}>
        <td>{index + 1}</td>
        <td>{referUser.from_user?.fname + referUser.from_user?.lname}</td>
        <td>
          {referUser.from_user?.is_deleted ? (
            <span className="badge badge-outline-danger">Not Active</span>
          ) : (
            <span className="badge badge-outline-success">Active</span>
          )}
        </td>
        <td>
          {referUser?.createdAt
            ? formatDate(parseISO(referUser.createdAt), "MM-dd-yyyy")
            : ""}
        </td>
      </tr>
    ));
  };

  return (
    <Fragment>
      <Row>
        <Col xl={12} xxl={12} lg={12}>
          <Card>
            <Card.Header className="d-block d-sm-flex border-0 pb-0">
              <div>
                <h4 className="text-black fs-20">
                  It's easy to earn door token in few simple steps
                </h4>
                <p className="fs-13 mb-0">
                  Refer and earn % commission of every buy token. Be your own
                  Boss!
                </p>
              </div>
            </Card.Header>
            <Card.Body>
              <div>
                <h2 className="text-black d-flex justify-content-center">
                  How it works?
                </h2>
              </div>
              <Row className="mt-5 d-flex justify-content-center">
                <Col xl={3} xxl={3} lg={3} className="widget-stat mb-3 mr-2 ml-2  ">
                  <div class="card bg-light">                    
                    <h4 className="text-black fs-20 mt-5 d-flex justify-content-center text-center">
                      Share your link
                    </h4>
                    <div class="card-body mb-0">
                      <p class="card-text">Share your links via Telegram, Twitter, Emails or anyway you want. The more you promote, the more you earn.</p>
                      <div class="d-sm-flex justify-content-center mt-4">
                        <h5 class="btn btn-primary d-sm-flex justify-content-center" onClick={handleClickCopy} style={{cursor: "pointer"}}>
                          Click to copy link
                        </h5>
                      </div>
                    </div>                    
                  </div>
                </Col>
                <Col xl={3} xxl={3} lg={3} className="widget-stat mb-3 mr-2 ml-2">
                  <div class="card bg-light">                    
                    <h4 className="text-black fs-20 mt-5 d-flex justify-content-center text-center">
                      Use your Referral Code
                    </h4>
                    <div class="card-body mb-0">
                      <p class="card-text">Anyone who signs up using your Referral Code or Link will automatically be part of your referral network.</p>
                      <p class="mt-4 text-center text-bg">Your referral code is <span style={{color: "#6418C3", fontSize: "18px", fontWeight: "bold"}}>{user?.refer_code}</span></p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} xxl={3} lg={3} className="widget-stat mb-3 mr-2 ml-2">
                  <div class="card bg-light">                    
                    <h4 className="text-black fs-20 mt-5 d-flex justify-content-center text-center">
                      Earn DOOR every time they buy!
                    </h4>
                    <div class="card-body mb-0">
                      <p class="card-text">You will earn 10% commission on anyone who buys door via MyDoorWallet.com that registers using your code..</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} xxl={12} lg={12} className="mt-5">
          <Card>
            <Card.Body>
              <div>
                <h2 className="text-black d-flex justify-content-center mb-5">
                  Your Referral Network
                </h2>
              </div>
              <div>
                <Card>
                  <Card.Header>                    
                    <Card.Title>Your total referral earning: <span style={{fontWeight: "bold"}}>{formatNumber(totalEarning)} Door</span></Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="table-responsive">
                      <table className="table table-striped table-responsive-sm">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Is Active</th>
                            <th>Joined Date</th>
                          </tr>
                        </thead>
                        <tbody>{renderReferUser()}</tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default MyReferralNetwork;
