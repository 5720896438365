import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { DropzoneArea } from 'material-ui-dropzone'
import swal from 'sweetalert'
import "./style.css"
import { NOTI_TYPE } from '../../../../../store/types'
import { useUser, useProgress, useNotification, useCompany } from '../../../../../store/hooks/'
import { stateList, getCitiesByState, uploadFiles } from '../../../../../utils'

const zipCodeTitle = {
  "zip": "Filter By Zipcode",
  "state": "Filter By Sate",
  "entire": "Entire country"
}

const LeadSettings = () => {
  const { startProgress, stopProgress } = useProgress()
  const { setNewNotification } = useNotification()

  const {
    user,
    leadZipcode: { data: leadZipcodeData },
    leadSetting: { data: leadSettingData },
    loadUserLeadZip, addUserLeadZipcode, deleteZipcode,
    loadUserLeadSetting, updateUserLeadSetting,
    changeTargetSetting, changeTargetStateCity, changeLeadZip
  } = useUser()

  const { company, getCompany, updateCompany } = useCompany()

  const [zipcode, setZipcode] = useState('')
  const [targetEntireUS, setTargetEntireUS] = useState(false)
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [cityList, setCityList] = useState([])
  const [zipSelection, setZipSelection] = useState("");

  const [companyInfo, setCompanyInfo] = useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    logo: '',
  })

  const [logos, setLogos] = useState([])

  useEffect(() => {
    loadUserLeadSetting()
    loadUserLeadZip()
    getCompany()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (company) {
      setCompanyInfo({
        name: company.name || '',
        address1: company.address1 || '',
        address2: company.address2 || '',
        city: company.city || '',
        state: company.state || '',
        zip: company.zip || '',
        logo: company.logo || '',
      })
    }
  }, [company])

  useEffect(() => {
    (async () => {
      setTargetEntireUS(user.target_entire_us === true)
      const newState = user.target_state || ''
      setState(newState)
      setCity(user.target_city || '')
      console.log(user.zip_code_option);
      setZipSelection(user.zip_code_option);
      if (newState) {
        const cities = await getCitiesByState(newState)
        setCityList(cities)
      }
    })()
  }, [user])

  const handleLeadSettingChange = async (e) => {
    startProgress()
    await updateUserLeadSetting({
      ...leadSettingData,
      [e.target.name]: e.target.checked,
    })
    stopProgress()
  }

  const handleZipcodeChange = (event) => {
    if (!Number.isNaN(+event.target.value) && event.target.value.length <= 5)
      setZipcode(event.target.value)
  }

  const handleTargetEntireUSChange = (event) => {
    const value = event.target.checked
    setTargetEntireUS(value)
    changeTargetSetting(value)
  }

  const handleZipcodeAdd = async () => {
    if (!zipcode) {
      swal('Please enter zipcode.')
    } else {
      const index = leadZipcodeData.findIndex(x => x.zipcode === zipcode)
      if (index >= 0) {
        swal('Zipcode already exists.')
      } else {
        startProgress()
        await addUserLeadZipcode(zipcode)
        setZipcode('')
        stopProgress()
      }
    }
  }

  const handleDelete = async (ZipCodeID) => {
    swal({
      title: 'Are you sure?',
      text: 'This will delete the zipcode',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        startProgress()
        await deleteZipcode(ZipCodeID)
        stopProgress()
      }
    })
  }

  const handleStateChange = async (event) => {
    const newState = event.target.value
    setState(newState)

    const cities = await getCitiesByState(newState)
    setCity('')
    setCityList(cities)
  }

  const handleStateCitySave = () => {
    if (!state) {
      return
    }
    changeTargetStateCity(state, city || null)
  }

  const handleCompanyInfoChange = (event) => {
    let value = event.target.value

    if (event.target.name === 'zip') {
      // Stripe non-digit characters from a zip code.
      value = value.replace(/\D/g, '')

      // Prevent zip codes from being more than 5 digits.
      if (value.length > 5) {
        return
      }
    }

    if (event.target.name === 'companyBio') {
      if (value.length > 280) {
        value = companyInfo?.companyBio
      }
    }

    if (event.target.name === 'state') {
      // Stripe digit characters from a state.
      value = value.replace(/\d/g, '')

      // Prevent state from being more than 2 letters.
      if (value.length > 2) {
        return
      }
    }

    setCompanyInfo((prev) => ({
      ...prev,
      [event.target.name]: value,
    }))
  }

  const handleCompanySubmit = async (event) => {
    event.preventDefault()

    try {
      startProgress()
      if (logos.length) {
        const uploaded = await uploadFiles(logos)
        if (uploaded.length) {
          companyInfo.logo = uploaded[0].path
        }
      }

      await updateCompany({
        name: companyInfo.name,
        address1: companyInfo.address1,
        address2: companyInfo.address2,
        city: companyInfo.city,
        state: companyInfo.state,
        zip: companyInfo.zip,
        logo: companyInfo.logo,
      })

      stopProgress()
    } catch (err) {
      stopProgress()
      setNewNotification(NOTI_TYPE.WARNING, 'Unable to update companay. Please try again.')
    }
  }

  const handleChangeZipSelection = async (e) => {
    const zipSelection = e.target.value;
    if (zipSelection) {
      await changeLeadZip(zipSelection);
    }
    setZipSelection(zipSelection);
  }

  const renderCitySelector = () => {
    return (
      <div className="city-selector-container">
        <div className="row">
          <div className="col-sm-4">
            <select
              className="form-control"
              value={state}
              onChange={handleStateChange}
            >
              <option value="">- States -</option>
              {
                Object.keys(stateList).map(abbr => (
                  <option key={abbr} value={abbr}>
                    {stateList[abbr]}
                  </option>
                ))
              }
            </select>
          </div>
          <div className="col-sm-4">
            <select
              className="form-control"
              value={city}
              onChange={(event) => { setCity(event.target.value) }}
            >
              <option value="">- All cities -</option>
              {
                cityList.map(({ city: option }) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))
              }
            </select>
          </div>
          <div className="col-sm-2">
            <button
              type="button"
              className="btn btn-primary"
              disabled={!state && !city}
              onClick={handleStateCitySave}
            >
              Target
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Row>
      <Col lg={6}>
        <Card>
          <Card.Header>
            <Card.Title>Lead Settings</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="form-group">
              <div className="form-check mb-2">
                <input
                  type="checkbox" className="form-check-input" name="is_auto_licence"
                  checked={leadSettingData.is_auto_licence} onChange={handleLeadSettingChange} />
                <label className="form-check-label">Automatically License Data that Matches my Criteria</label>
              </div>
              <div className="alert alert-info">
                <strong>Note</strong> We recommend selecting automated licensing to ensure you receive the leads immediately
                and can be first to respond
              </div>
              <div className="form-check mb-2">
                <input type="checkbox" className="form-check-input" name="is_email_notification"
                  checked={leadSettingData.is_email_notification} onChange={handleLeadSettingChange} />
                <label className="form-check-label">Notify me when a new Lead matches my criteria</label>
              </div>
              <div className="alert alert-info">
                <strong>Note</strong> We will email you when a lead matches your criteria.
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <Card.Header>
            <Card.Title>Target the following areas</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="alert alert-info">
              <strong>Note</strong> If you add zip codes to the restriction list, we will only deliver leads in those zip codes.
            </div>
            <div className = "row mb-5">
              <div className = "from-group" >
                <label>Filter Option</label>
                <select
                  className="form-control"
                  value={zipSelection}
                  onChange={handleChangeZipSelection}
                >
                  <option value="">- Select Filter Option -</option>
                  {
                    Object.keys(zipCodeTitle).map(name => (
                      <option key={name} value={name}>
                        {zipCodeTitle[name]}
                      </option>
                    ))
                  }
                </select>
                </div>  
            </div>
            { zipSelection == 'zip' &&
            <><div className="zip-option-wrapper">
              <form className="form-inline">
                <div className="form-group">
                  <label className="sr-only">Zip Code</label>
                  <input
                    type="postal"
                    required
                    name="zipcode"
                    value={zipcode}
                    disabled={zipSelection === "state"}
                    onChange={handleZipcodeChange}
                    className="form-control"
                    />
                </div>
                <button type="button"  disabled={zipSelection === "state"} onClick={handleZipcodeAdd} className="btn btn-primary">
                  Add Zip
                </button>
              </form>              
            </div>
            <div className="zip-code-list mt-5">
                Targeted Zips:&nbsp;&nbsp;
                {
                  leadZipcodeData && leadZipcodeData.map(code =>
                    <span className="badge badge-rounded badge-success" key={code.id}>
                      {code.zipcode}
                      <i style={{ cursor: 'pointer' }} className="ml-2 fa fa-close" onClick={() => handleDelete(code.id)}></i>
                    </span>
                  )
                }
              </div></>
            }
            { zipSelection == 'entire' &&
            <div className="form-group mt-4">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="target_entire_us"
                  checked={true}                  
                />
                <label
                  className="custom-control-label"
                  htmlFor="target_entire_us"
                >
                  Target the entire US
                </label>
              </div>
            </div>}
            {zipSelection == 'state' && renderCitySelector()}            
          </Card.Body>
        </Card>
      </Col>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Company Information</Card.Title>
          </Card.Header>
          <Card.Body>
            <form onSubmit={handleCompanySubmit}>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>
                    Company Name
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    required
                    value={companyInfo.name}
                    onChange={handleCompanyInfoChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>
                    Street Address
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="address1"
                    required
                    value={companyInfo.address1}
                    onChange={handleCompanyInfoChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Street Address 2</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address2"
                    value={companyInfo.address2}
                    onChange={handleCompanyInfoChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>
                    City
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    required
                    value={companyInfo.city}
                    onChange={handleCompanyInfoChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>
                    State
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    required
                    value={companyInfo.state}
                    onChange={handleCompanyInfoChange}
                  />
                </div>
                <div className="form-group col-md-2">
                  <label>
                    Zip
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="zip"
                    required
                    value={companyInfo.zip}
                    onChange={handleCompanyInfoChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    filesLimit={1}
                    dropzoneText="Upload a company logo"
                    showFileNames
                    onChange={setLogos}
                  />
                </div>
                <div className="form-group col-md-5">
                  {/* {
                    companyInfo.logo && (
                      <img src={companyInfo.logo} alt={companyInfo.name} />
                    )
                  } */}
                  <label>
                    Company bio
                    <span className="required">*</span>
                  </label>
                  <textarea
                    cols="100"
                    style={{ height: "100px" }}
                    className="form-control"
                    name="companyBio"
                    required
                    value={companyInfo?.companyBio}
                    onChange={handleCompanyInfoChange}
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Update Company
              </button>
            </form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default LeadSettings
