import React, {useEffect} from "react";
import {Link} from 'react-router-dom';
import { format as formatDate, parseISO } from 'date-fns'
import { Card } from "react-bootstrap";
import {useParams} from 'react-router-dom';
import { useList } from "../../../../../store/hooks";

const ListLeads = () => {
    const { list, getListById } = useList();
    const {id: ListID} = useParams();

    useEffect(() => {
        (async () => {
            await getListById(ListID);
        })()
    }, []); // eslint-disable-line

    return (
        <Card>
            <Card.Header>
                <Card.Title>Leads</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="table-responsive">
                    <table className="table table-striped table-responsive-sm">
                        <thead>
                            <tr>
                                <th>Address</th>
                                <th>Date Joined</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            list.leads &&  list.leads.length > 0
                            ? list.leads.map(element =>
                                <tr key={element.id}>
                                    <td>
                                        <Link to={`/dashboard/lead-details/${element.id}`}>
                                            {element.address1} {element.address2}
                                        </Link>
                                    </td>
                                    <td>
                                        { formatDate(parseISO(element.createdAt), 'MM-dd-yyyy HH:mm') }
                                    </td>
                                </tr>
                            )
                            :  <tr><td colSpan={5}>No data found!</td></tr>
                        }
                        </tbody>
                    </table>
                </div>
            </Card.Body>
        </Card>
    );
};

export default ListLeads;
