import HttpClient from "./http-client";

export const AuthService = (function () {
  const login = async (email, password) => {

    let Ipv4 = "";
    try {
      const response = await fetch('https://geolocation-db.com/json/d802faa0-10bd-11ec-b2fe-47a0872c6708');
      const data = await response.json();
      Ipv4 = data?.IPv4
    } catch (error) { }

    return await HttpClient.post("/auth/login", {
      email,
      password,
      ip: Ipv4
    });
  };

  const verifyOtp = async (otp ,email) => {
    let Ipv4 = "";
    try {
      const response = await fetch('https://geolocation-db.com/json/d802faa0-10bd-11ec-b2fe-47a0872c6708');
      const data = await response.json();
      Ipv4 = data?.IPv4
    } catch (error) { }

    return await HttpClient.post("/auth/2faotpverify", {
      code: otp,
      email,
      ip: Ipv4
    });
  };

  const signup = async (body) => {

    let Ipv4 = "";
    try {
      const response = await fetch('https://geolocation-db.com/json/d802faa0-10bd-11ec-b2fe-47a0872c6708');
      const data = await response.json();
      Ipv4 = data?.IPv4
    } catch (error) { }
    body.ip = Ipv4;
    
    return await HttpClient.post("/auth/signup", body);
  };

  const submit_auth2 = async data => (

    await HttpClient.post('/auth/submit_auth2', data)
  )

  const sendForgotPasswordLink = async (body) => {
    return await HttpClient.post("/auth/forgot", body);
  };

  const resetPassword = async (body) => {
    return await HttpClient.post("/auth/verify-forgot", body);
  }

  const verifyEmail = async (data) => {
    return await HttpClient.post('/auth/verify-email', data)
  }

  const resendEmailVerification = async() => {
    return await HttpClient.post("/auth/resend-verify-email");
  }

  const requestEmailUpdate = async (email) => {
    return await HttpClient.post('/auth/request-email-update', {
      email,
    })
  }

  const sendSMS = async(data) => {
    return await HttpClient.post("/auth/send-sms", data);
  }

  const verifySMS = async(data) => {
    return await HttpClient.post("/auth/verify-sms", data);
  }

  const resetPasswordAfterLogin = async (data) => {
    return await HttpClient.post('/auth/reset-password', data)
  }

  return {
    login,
    verifyOtp,
    signup,
    sendForgotPasswordLink,
    resetPassword,
    verifyEmail,
    resendEmailVerification,
    requestEmailUpdate,
    sendSMS,
    verifySMS,
    resetPasswordAfterLogin,
    submit_auth2
  }
})()
