import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import StripeCheckout from 'react-stripe-checkout'

import { NOTI_TYPE } from '../../../../../store/types'
import { useUser, useWallet, useNotification, useSetting } from '../../../../../store/hooks'
import DepositByMetaMaskModal from '../../../../components/DepositByMetaMaskModal'

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY

const Deposit = () => {
  const { user } = useUser()
  const { depositByStripe } = useWallet()
  const { setNewNotification } = useNotification()
  const { setting, loadSetting } = useSetting()

  const [amount, setAmount] = useState(100)
  const [promo, setPromo] = useState('')
  const [depsoitByMetaMaskModal, setDepositByMetaMaskModal] = useState(false)
  const [tokenRate, setTokenRate] = useState(0.01);
  const [maxETH, setMaxETH] = useState(0.1);

  useEffect(() => {
    (async () => {
      await loadSetting()
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (setting && setting.length) {
      let rate = setting.find(x => x.label === 'etc_rate_cc')
      if (rate) {
        setTokenRate(parseFloat(rate.value))
      } else {
        rate = setting.find(x => x.label === 'eth_rate')
        if (rate) {
          setTokenRate(parseFloat(rate.value))
        } else {
          setTokenRate(0.01)
        }
      }
      let max_eth = setting.find(x => x.label === 'eth_max_per_transaction');
      if(max_eth){
        let max = max_eth.value * rate?.value;        
        setMaxETH(max);
      }
    }
  }, [setting])

  const handleToken = async (token) => {
    if (amount < 20 || !amount) {
      setNewNotification(NOTI_TYPE.WARNING, 'Invalid amount.')
      return
    }
    
    const token_symbol = "ETH";
    await depositByStripe(token.id, amount, promo, token_symbol)
    setAmount(0)
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h4 className="mb-3">Purchase ETH</h4>
          <div className="alert alert-primary">
          You can purchase ETH using a Credit Card. After purchasing the ETH you will be able to see them in your ETH Wallet. 
          There is a standard 7 Day Hold on Credit Card Purchases.
          </div>
          <div className="mb-3">
            <label>
              What US Dollar Amount are you wanting to buy in Door Tokens?( Minimum deposit Amount is $20, Maximum deposit Amount is ${maxETH.toFixed(2)} )
            </label>
            <div className="input-group mb-3 input-primary">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                type="number"
                className="form-control"
                value={amount}
                min={0}
                onChange={(e) => setAmount(e.target.value)}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  for {(amount / tokenRate).toFixed(2)} ETH
                </span>
              </div>
            </div>            
          </div>
          <hr className="mb-4" />
          <StripeCheckout
            stripeKey={STRIPE_KEY}
            token={handleToken}
            amount={amount * 100}
            name="ETH"
            billingAddress
            shippingAddress
            disabled = { amount < 20 || amount > maxETH}
            email={user?.email}
            label="Purchase ETH with Credit Card"
          />
        </Card.Body>
      </Card>      
    </>
  )
}

export default Deposit
