import HttpClient from './http-client'

export const StakingService = (function () {
  const getStakings = async () => (
    await HttpClient.get('/stakings')
  )

  const getStakingsAdmin = async () => (
    await HttpClient.get('/stakings/get_all')
  )

  const addStaking = async params => (
    await HttpClient.post('/stakings', params)
  )

  const endStaking = async id => (
    await HttpClient.put(`/stakings/${id}/end`)
  )

  return {
    getStakings,
    addStaking,
    endStaking,
    getStakingsAdmin
  }
})()
