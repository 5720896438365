import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import SearchBox from '../../../../components/SearchBox'
import Paginator from '../../../../components/Paginator'
import PageSize from '../../../../components/PageSize'
import TableHeader from '../../../../components/TableHeader'
import { useList } from '../../../../../store/hooks'

const ListSearch = () => {
  const { searchedLists, searchLists } = useList()

  const headers = [
    { name: 'list', label: 'Lists', sortBy: 'list' },
    { name: 'price', label: 'Price', sortBy: 'property_price' },
    { name: 'status', label: 'Status' },
    { name: 'use_access', label: 'Use Access' },
    { name: 'list_type', label: 'Type' },
    { name: 'action' },
  ]

  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(['list', 'asc'])

  useEffect(() => {
    (async () => {
      await searchLists({
        search,
        limit,
        page,
        sortBy: sort[0],
        sortOrder: sort[1],
      })
    })()
  }, [search, page, limit, sort]) // eslint-disable-line

  const handleSearch = (keyword) => {
    setSearch(keyword)
    setPage(1)
  }

  const handlePageSizeChange = (size) => {
    setLimit(size)
    setPage(1)
  }

  const renderLists = () => {
    if (!searchedLists || !searchedLists.rows.length) {
      return (
        <tr>
          <td colSpan={6} className="text-center">No lists found.</td>
        </tr>
      )
    }

    return searchedLists.rows.map((element, index) => (
      <tr key={index}>
        <td>
          <Link to={`/admin/list-detail/${element.id}`}>
            {element.list}
          </Link>
        </td>
        <td>
          {element.property_price.toLocaleString()} Door
        </td>
        <td>
          <Link
            to="#"
            className={`btn-link ${element.is_active ? 'text-success' : 'text-danger'}`}
          >
            { element.is_active ? 'Active' : 'In-active' }
          </Link>
        </td>
        <td>
          { element.use_access ? 'True' : 'False' }
        </td>
        <td>
          { element.list_type ? 'Custom' : 'Property' }
        </td>
        <td>
          <Dropdown className="dropdown d-block" drop="left">
            <Dropdown.Toggle variant="" className="btn btn-success light sharp i-false" data-toggle="dropdown">
              <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-right">
              <Link className="dropdown-item" to={`/admin/list-detail/${element.id}`}>
                Edit List
              </Link>
              <Link className="dropdown-item" to={`/admin/list/${element.id}/subscribers`}>
                View Subscribers
              </Link>
              <Link className="dropdown-item" to={`/admin/list/${element.id}/leads`}>
                View Leads
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    ))
  }

  return (
    <>
      <div className="form-head d-flex mb-md-3">
        <SearchBox onSearch={handleSearch} />
        <Link
          className="ml-auto btn bgl-primary text-primary"
          to="add-list"
        >
          Add List
        </Link>
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Manage Lists:</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <TableHeader
                  headers={headers}
                  currentSort={sort}
                  onChange={(newSort) => { setSort(newSort) }}
                />
                <tbody>
                  { renderLists() }
                </tbody>
              </table>
              <div className="table-footer">
                <Paginator
                  recordsCount={searchedLists?.count || 0}
                  recordsPerPage={limit}
                  currentPage={page}
                  onChange={setPage}
                />
                <PageSize
                  currentSize={limit}
                  onChange={handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ListSearch
