import React, {useEffect} from "react";
import {Link} from 'react-router-dom';
import { format as formatDate, parseISO } from 'date-fns'
import { Card } from "react-bootstrap";
import {useParams} from 'react-router-dom';
import { useList } from "../../../../../store/hooks";

const ListSubscribers = () => {
    const { list, getListById } = useList();
    const {id: ListID} = useParams();
    useEffect(() => {
        (async () => {
            await getListById(ListID);
        })()
    }, []); // eslint-disable-line

    return (
        <Card>
            <Card.Header>
                <Card.Title>Subscribers</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="table-responsive">
                    <table className="table table-striped table-responsive-sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Date Joined</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            list.subscribers &&  list.subscribers.length > 0
                            ? list.subscribers.map(user =>
                                <tr key={user.id}>
                                    <td></td>
                                    <td><Link to={`/admin/edit-user/${user.id}`}>{`${user.fname} ${user.lname}`}</Link></td>
                                    <td>
                                    {
                                        user.is_deleted
                                        ? <span className="badge badge-danger">De-activated</span>
                                        : <div>{
                                            user.verified
                                            ? <span className="badge badge-success">Verified</span>
                                            : <span className="badge badge-danger">Not Verified</span>
                                            }</div>
                                    }

                                    </td>
                                    <td>{ formatDate(parseISO(user.createdAt), 'MM-dd-yyyy HH:mm') }</td>
                                </tr>
                            )
                            :  <tr><td colSpan={5}>No data found!</td></tr>
                        }
                        </tbody>
                    </table>
                </div>
            </Card.Body>
        </Card>
    );
};

export default ListSubscribers;
