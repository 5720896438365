import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import swal from 'sweetalert'

import UserAccessModal from './UserAccessModal'
import { useList } from '../../../../../store/hooks'

const ListDetail = () => {
  const { id: listId } = useParams()
  const { list, getListById, editList, addUserAccess, removeUserAccess } = useList()

  const [form, setForm] = useState({
    list_name: '',
    property_label: '',
    description: '',
    property_price: 0,
    is_active: false,
    use_access: false,
  })

  const [userAccessModalVisible, setUserAccessModalVisible] = useState(false)

  useEffect(() => {
    (async () => {
      await getListById(listId, true)
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!list.list) {
      return
    }
    setForm({
      list_name: list.list.list || '',
      property_label: list.list.property_label || '',
      description: list.list.description || '',
      property_price: list.list.property_price || 0,
      is_active: list.list.is_active || false,
      use_access: list.list.use_access || false,
    })
  }, [list])

  const handleIsActiveChange = (event) => {
    setForm((prev) => ({ ...prev, is_active: event.target.checked }))
  }

  const handleUseAccessChange = (event) => {
    setForm((prev) => ({ ...prev, use_access: event.target.checked }))
  }

  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    await editList(listId, form)
  }

  const handleUserAccessAdd = async (userId) => {
    const response = await addUserAccess(listId, userId)
    if (response) {
      await getListById(listId, true)
    }
  }

  const handleUserAccessRemove = async (userId) => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to remove this user from access list?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const response = await removeUserAccess(listId, userId)
        if (response) {
          await getListById(listId, true)
        }
      }
    })
  }

  const renderUserAccesses = () => {
    return (
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Users</h4>
        </div>
        <div className="card-body">
          <div className="form-head text-right mb-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => { setUserAccessModalVisible(true) }}
            >
              Allow New User
            </button>
          </div>
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (!list.userAccesses || !list.userAccesses.length)
                    ? (
                      <tr>
                        <td colSpan={3} className="text-center">
                          No users.
                        </td>
                      </tr>
                    ) : (
                      list.userAccesses.map(user => (
                        <tr key={user.id}>
                          <td>{ user.fname } { user.lname }</td>
                          <td>{ user.email }</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => { handleUserAccessRemove(user.id) }}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
          <UserAccessModal
            show={userAccessModalVisible}
            onAdd={handleUserAccessAdd}
            onHide={() => { setUserAccessModalVisible(false) }}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <Row>
        <Col xl={4} lg={6} sm={6}>
          <div className="widget-stat card bg-success">
            <div className="card-body  p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-list"></i>
                </span>
                <div className="media-body text-white text-right">
                  <p className="mb-1">Number of Subscribers</p>
                  <h3 className="text-white">
                    {list.subscribers && list.subscribers.length.toLocaleString()}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={4} lg={6} sm={6}>
          <div className="widget-stat card bg-info">
            <div className="card-body  p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-list"></i>
                </span>
                <div className="media-body text-white text-right">
                  <p className="mb-1">Number of Properties</p>
                  <h3 className="text-white">
                    {list.leads && list.leads.length.toLocaleString()}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={4} lg={6} sm={6}>
          <div className="widget-stat card bg-primary">
            <div className="card-body  p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-list"></i>
                </span>
                <div className="media-body text-white text-right">
                  <p className="mb-1">Number of Leads</p>
                  <h3 className="text-white">
                    {list.leads && list.leads.length.toLocaleString()}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Edit List</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>List Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={form.list_name}
                  name="list_name"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Property Label</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={form.property_label}
                  name="property_label"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Property Description</label>
                <textarea
                  rows={4}
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={form.description}
                  name="description"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Default Price Per Lead</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={form.property_price}
                  name="property_price"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-3">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="is_active"
                    checked={form.is_active}
                    onChange={handleIsActiveChange}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="is_active"
                  >
                    Is Active
                  </label>
                </div>
              </div>
              <div className="form-group col-md-3">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="use_access"
                    checked={form.use_access}
                    onChange={handleUseAccessChange}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="use_access"
                  >
                    Use Access
                  </label>
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-info">
              Update
            </button>
          </form>
        </div>
      </div>
      { renderUserAccesses() }
    </>
  )
}

export default ListDetail
