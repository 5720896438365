import React, { Fragment, useEffect, useState } from "react";
import { Card, Row, Col, Tab } from "react-bootstrap"; 

import logo from '../../../../images/logo.png'

// Chart
import ActivityLine from "../../../components/Dhrev/Home/chart/ActivityLine";
import ActivityLine2 from "../../../components/Dhrev/Home/chart/ActivityLine2";
import ActivityLine3 from "../../../components/Dhrev/Home/chart/ActivityLine3";

import StateInitialized from '../../../stateInitialized';

const Dashboard = () => {

  return (
     <Fragment>
        <Row>
         <Col xl={3} lg={6} sm={6}>
            <Card className="widget-stat bg-primary">
               <Card.Body className="p-4">
               <div className="media">
                  <span className="mr-3">
                     <img src={logo} alt="" />
                  </span>
                  <div className="media-body text-white text-right">
                     <p className="mb-1">Door Tokens</p>
                     <h3 className="text-white">76</h3>
                  </div>
               </div>
               </Card.Body>
            </Card>
         </Col>
         <Col xl={3} lg={6} sm={6}>
            <Card className="widget-stat bg-success">
               <Card.Body className="p-4">
               <div className="media">
                  <span className="mr-3">
                     <i className="flaticon-381-lock-1"></i>
                  </span>
                  <div className="media-body text-white text-right">
                     <p className="mb-1">Usage</p>
                     <h3 className="text-white">26</h3>
                  </div>
               </div>
               </Card.Body>
            </Card>
         </Col>
         <Col xl={3} lg={6} sm={6}>
            <Card className="widget-stat bg-info">
               <Card.Body className="p-4">
               <div className="media">
                  <span className="mr-3">
                     <i className="flaticon-381-list"></i>
                  </span>
                  <div className="media-body text-white text-right">
                     <p className="mb-1">Lists</p>
                     <h3 className="text-white">2</h3>
                  </div>
               </div>
               </Card.Body>
            </Card>
         </Col>
         <Col xl={3} lg={6} sm={6}>
            <Card className="widget-stat bg-warning">
               <Card.Body className="p-4">
               <div className="media">
                  <span className="mr-3">
                     <i className="flaticon-381-home"></i>
                  </span>
                  <div className="media-body text-white text-right">
                     <p className="mb-1">Properties</p>
                     <h3 className="text-white">1</h3>
                  </div>
               </div>
               </Card.Body>
            </Card>
         </Col>

         <Col xl={12} xxl={12} lg={12}>
            <Card>
               <Card.Header className="d-sm-flex d-block pb-0 border-0">
                  <div>
                     <h4 className="text-black fs-20">How much your Property Data is being Used</h4>
                     <p className="fs-13 mb-0">
                        Everytime an advertiser accesses your data it is tracked and you earn door tokens.
                     </p>
                  </div>
               </Card.Header>
               <Card.Body id="user-activity">
                  <Tab.Container defaultActiveKey="all">
                     <Tab.Content
                        className="tab-content"
                        id="myTabContent"
                     >
                        <Tab.Pane eventKey="all">
                           <ActivityLine />
                        </Tab.Pane>
                        <Tab.Pane eventKey="btc">
                           <ActivityLine2 />
                        </Tab.Pane>
                        <Tab.Pane eventKey="ethereum">
                           <ActivityLine3 />
                        </Tab.Pane>
                     </Tab.Content>
                  </Tab.Container>
               </Card.Body>
            </Card>
         </Col>
         <Col xl={12} xxl={12} lg={12}>
            <Card>
               <Card.Header className="d-block d-sm-flex border-0 pb-0">
                  <div>
                     <h4 className="text-black fs-20">How much Property Data you have Accessed</h4>
                     <p className="fs-13 mb-0">
                        Thw following graph tracks how much property data you are accessing each day.
                     </p>
                  </div>
               </Card.Header>
               <Card.Body className="card-carousel">
                  <div className="owl-bank-wallet owl-carousel owl-loaded owl-drag mb-4">
                     {/* <CardSlider /> */}
                  </div>
               </Card.Body>
            </Card>
         </Col>
      </Row>
        <StateInitialized/>
     </Fragment>
  );
};

export default Dashboard;