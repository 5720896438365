import {
  GET_PROMOS_INIT,
  GET_PROMOS_SUCCESS,
  GET_PROMO_BY_ID_SUCCESS,
  ADD_PROMO_SUCCESS,
  UPDATE_PROMO_SUCCESS,
  DELETE_PROMO_SUCCESS,
} from '../types'

const initialState = {
  promos: [],
}

export default function promoReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROMOS_INIT:
      return {
        ...state,
        promos: [],
      }
    case GET_PROMOS_SUCCESS:
      return {
        ...state,
        promos: payload,
      }
    case GET_PROMO_BY_ID_SUCCESS:
    case ADD_PROMO_SUCCESS:
      return {
        ...state,
        promos: [
          ...state.promos,
          payload,
        ],
      }
    case UPDATE_PROMO_SUCCESS:
      return {
        ...state,
        promos: state.promos.map(promo => (
          parseInt(promo.id, 10) !== parseInt(payload.id, 10) ? promo : payload
        )),
      }
    case DELETE_PROMO_SUCCESS:
      return {
        ...state,
        promos: state.promos.filter(promo => (
          parseInt(promo.id, 10) !== parseInt(payload, 10)
        )),
      }
    default:
      return state
  }
}