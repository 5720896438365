import {
  GET_COMPANY_SUCCESS,
} from '../types'

const initialState = {
  company: null,
}

export default function companyReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: payload,
      }
    default:
      return state
  }
}