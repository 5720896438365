export const WALLET_INIT = 'WALLET_INIT';
export const WALLET_SUCCESS = 'WALLET_SUCCESS';
export const WALLET_FAILED = 'WALLET_FAILED';
export const TRANSACTION_INIT = 'TRANSACTION_INIT';
export const TRANSACTION_SUCCESS = 'TRANSACTION_SUCCESS';
export const TRANSACTION_FAILED = 'TRANSACTION_FAILED';
export const EXTERNAL_WALLETS_INIT = 'EXTERNAL_WALLETS_INIT'
export const EXTERNAL_WALLETS_SUCCESS = 'EXTERNAL_WALLETS_SUCCESS'
export const EXTERNAL_WALLETS_ADD = 'EXTERNAL_WALLETS_ADD'
export const EXTERNAL_WALLETS_REMOVE = 'EXTERNAL_WALLETS_REMOVE'

export const GET_BANKS_INIT = 'GET_BANKS_INIT'
export const GET_BANKS_SUCCESS = 'GET_BANKS_SUCCESS'
