import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { NOTI_TYPE } from '../../../../../store/types'
import { useStakingPool, useNotification } from '../../../../../store/hooks'

const AddStakingPoolPage = () => {
  const history = useHistory()

  const { addStakingPool } = useStakingPool()
  const { setNewNotification } = useNotification()

  const [form, setForm] = useState({
    name: '',
    percent: 0,
    duration: 0,
    min_amount: 0,
    max_amount: 0,
    is_active: true,
  })

  const handleChange = (event) => {
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }))
  }

  const handleIsActiveChange = (event) => {
    setForm((prev) => ({
      ...prev,
      is_active: event.target.checked,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!form.name) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please enter the name.'
      )
      return
    }

    if (!form.percent) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please enter the percentage.'
      )
      return
    }

    if (!form.duration) {
      setNewNotification(
        NOTI_TYPE.WARNING,
        'Please enter the duration in days.'
      )
      return
    }

    const success = await addStakingPool(form)
    if (success) {
      history.push('/admin/staking-pools')
    }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Staking Pool Information</Card.Title>
      </Card.Header>
      <Card.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Name
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                required
                value={form.name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Percent
                <span className="required">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                name="percent"
                min="0"
                required
                value={form.percent}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Duration
                <span className="required">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                name="duration"
                min="0"
                required
                value={form.duration}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Min Amount
                <span className="required">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                name="min_amount"
                min="0"
                required
                value={form.min_amount}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Max Amount
                <span className="required">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                name="max_amount"
                min="0"
                required
                value={form.max_amount}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>
                Is Active
                <span className="required">*</span>
              </label>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="is_active"
                  checked={form.is_active}
                  onChange={handleIsActiveChange}
                />
                <label
                  className="custom-control-label"
                  htmlFor="is_active"
                >
                  Is Active
                </label>
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Add Staking Pool
          </button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default AddStakingPoolPage
