import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { addDays, subDays, format as formatDate, parseISO } from 'date-fns'
import { Card } from 'react-bootstrap'

import DateRange from '../../../../components/DateRange'
import SearchBox from '../../../../components/SearchBox'
import Paginator from '../../../../components/Paginator'
import PageSize from '../../../../components/PageSize'
import TableHeader from '../../../../components/TableHeader'
import { useProperty } from '../../../../../store/hooks'

const PropertyVerify = () => {
  const { properties, searchProperties } = useProperty()

  const headers = [
    { name: 'address', label: 'Address', sortBy: 'address' },
    { name: 'owner', label: 'Owner', sortBy: 'owner' },
    { name: 'status', label: 'Status' },
    { name: 'createdAt', label: 'Date Added', sortBy: 'createdAt' },
  ]

  const [search, setSearch] = useState('')
  const [range, setRange] = useState([subDays(new Date(), 700), new Date()])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(['createdAt', 'desc'])

  useEffect(() => {
    (async () => {
      await searchProperties({
        search,
        range_start: range[0].toISOString(),
        range_end: addDays(range[1], 1).toISOString(),
        limit,
        page,
        sortBy: sort[0],
        sortOrder: sort[1],
        verified: false,
      })
    })()
  }, [search, range, page, limit, sort]) // eslint-disable-line

  const handleSearch = (keyword) => {
    setSearch(keyword)
    setPage(1)
  }

  const handleRangeChange = async (startRange, endRange) => {
    setRange([startRange, endRange])
    setPage(1)
  }

  const handlePageSizeChange = (size) => {
    setLimit(size)
    setPage(1)
  }

  const renderProperties = () => {
    if (!properties || !properties.rows.length) {
      return (
        <tr>
          <td colSpan={4} className="text-center">No properties found.</td>
        </tr>
      )
    }

    return properties.rows.map(property => (
      <tr key={property.id}>
        <td>
          <Link to={`/admin/edit-property/${property.id}`}>
            {`${property.address1} ${property.address2}`}
          </Link>
        </td>
        <td>
          <Link to={`/admin/edit-user/${property?.user?.id}`}>{`${property?.user?.fname} ${property?.user?.lname}`}</Link>
        </td>
        <td>
          {
            property.is_verified === 0 ? (
              <span className="badge badge-danger">Not Verified</span>
            ) : (
              <span className="badge badge-info">Pending To Review</span>
            )
          }
        </td>
        <td>
          { formatDate(parseISO(property.createdAt), 'MM-dd-yyyy HH:mm') }
        </td>
      </tr>
    ))
  }

  return (
    <>
      <div className="form-head d-flex mb-md-3">
        <SearchBox onSearch={handleSearch} />
        <DateRange
          defaultRange={range}
          onChange={handleRangeChange}
        />
      </div>
      <Card>
        <Card.Header>
          <Card.Title>Properties That Need Verification</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <TableHeader
                  headers={headers}
                  currentSort={sort}
                  onChange={(newSort) => { setSort(newSort) }}
                />
                <tbody>
                  { renderProperties() }
                </tbody>
              </table>
              <div className="table-footer">
                <Paginator
                  recordsCount={properties?.count || 0}
                  recordsPerPage={limit}
                  currentPage={page}
                  onChange={setPage}
                />
                <PageSize
                  currentSize={limit}
                  onChange={handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default PropertyVerify
