import {
  USER_INIT,
  PROPERTY_INIT,
  PROPERTY_SUCCESS,
  PROPERTY_FAILED,
  FETCH_EDIT_PROPERTY_SUCCESS,
  DELETE_PROPERTY,
  ADMIN_LEADS_INIT,
  ADMIN_LEADS_SUCCESS,
} from '../types'

const initialState = {
  data: {
    properties: null,
    editProperty: {},
  },
  initialized: false,
  editInitialize: false,
  loading: false,
  error: '',
  adminLeads: null,
}

export default function propertyReducer(state = initialState, { type, payload }) {
  switch (type) {
    case USER_INIT:
      return {
        ...state,
        initialized: false,
      }
    case PROPERTY_INIT:
      return {
        ...state,
        data: {
          properties: null,
          editProperty:{},
        },
        loading: false,
        initialized: true,
        editInitialize: false,
        error: '',
      };
    case PROPERTY_SUCCESS:
      return {
        ...state,
        data:{
          properties: payload.properties,
        },
        loading: false,
        error: '',
      };
    case PROPERTY_FAILED:
      return {
        ...state,
        data:{
          properties: null,
          editProperty: {}
        },
        loading: false,
        editInitialize: true,
        initialized: false,
        error: payload,
      };
    case FETCH_EDIT_PROPERTY_SUCCESS:
      return {
        ...state,
        data: {
          ...state,
          editProperty:payload.property,
        },
        loading: false,
        editInitialize: true,
        initialized: false,
        error: '',
      };
    case DELETE_PROPERTY:
      const properties = [...state.data.properties.rows];
      let index = properties.findIndex(x => x.id === payload.id);
      properties.splice(index, 1);
      return {
        ...state,
        data: {
          ...state.data,
          properties: {
            rows: properties,
            count: state.data.properties.count - 1,
          },
        }
      }
    case ADMIN_LEADS_INIT:
      return {
        ...state,
        adminLeads: null,
      }
    case ADMIN_LEADS_SUCCESS:
      return {
        ...state,
        adminLeads: payload,
      }
    default:
      return state;
  }
}