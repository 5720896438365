import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { format as formatDate, parseISO } from 'date-fns'
import swal from 'sweetalert'

import { usePromo } from '../../../../../store/hooks'

const PromoPage = () => {
  const { promos, getPromos, deletePromo } = usePromo()

  useEffect(() => {
    (async () => {
      await getPromos()
    })()
  }, []) // eslint-disable-line

  const handleRemove = async (promoId) => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to remove this promotion code?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deletePromo(promoId)
      }
    })
  }

  const renderPromos = () => {
    if (!promos.length) {
      return (
        <tr>
          <td colSpan={5} className="text-center">
            No promotion codes found
          </td>
        </tr>
      )
    }

    return promos.map(promo => (
      <tr key={promo.id}>
        <td>
          <Link to={`/admin/promos/${promo.id}`}>
            { promo.code }
          </Link>
        </td>
        <td>
          { parseFloat(promo.percent).toFixed(2) }
        </td>
        <td>
          { promo.is_active ? 'Active' : 'Inactive' }
        </td>
        <td>
          { formatDate(parseISO(promo.createdAt), 'MM-dd-yyyy HH:mm') }
        </td>
        <td className="text-right button-row">
          <Link to={`/admin/promos/${promo.id}`} className="btn btn-info btn-xs">
            Update
          </Link>
          <button
            type="button"
            className="btn btn-danger btn-xs"
            onClick={() => handleRemove(promo.id)}
          >
            Remove
          </button>
        </td>
      </tr>
    ))
  }

  return (
    <>
      <div className="d-flex flex-row-reverse mb-md-3">
        <Link to="/admin/promos/add" className="btn btn-primary">
          Add new promotion code
        </Link>
      </div>
      <Card>
        <Card.Header>
          <Card.Title>Promotion Codes</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive table-hover">
            <div className="dataTables_wrapper">
              <table className="table card-table dataTable">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Percent</th>
                    <th>Is Active</th>
                    <th>Date Added</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  { renderPromos() }
                </tbody>
              </table>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default PromoPage
