import { combineReducers } from 'redux';

import notification from './notification.reducer';

import user from './user.reducer';
import users from './users.reducer';
import wallet from './wallet.reducer';
import property from './property.reducer';
import list from './list.reducer';
import listMap from './userListmap.reducer';
import progress from './progress.reducer';
import setting from './setting.reducer';
import crypto from './crypto.reducer';
import support from './support.reducer'
import company from './company.reducer'
import promo from './promo.reducer'
import request from './request.reducer'
import lead from './lead.reducer'
import stakingPool from './stakingPool.reducer'
import staking from './staking.reducer'

export default combineReducers({
    notification,
    user,
    users,
    wallet,
    list,
    property,
    listMap,
    progress,
    setting,
    crypto,
    support,
    company,
    promo,
    request,
    lead,
    stakingPool,
    staking,
})
