import React, { Fragment, useEffect, useState } from "react";
import { Card, Row, Col, Tab } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { useWallet, useSetting, useUsers } from '../../../../../store/hooks'
import StateInitialized from '../../../../stateInitialized';
import Nil_Token_logo from '../../../../../images/nil_icon_black.png';
import NFT_Token_logo from '../../../../../images/nft_logo.png';
import ETH_Token_logo from '../../../../../images/eth_icon.png';
import Door_Token_logo from '../../../../../images/logo.png';
import Top_Banner from '../../../../../images/mdw-door.png';

function formatNumber(x) {
   return parseFloat(x).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function formatNumberETH(x) {
   return parseFloat(x).toFixed(5).replace(/\B(?=(\d{3})+(?!\d))/g, '')
}

const Home = () => {
   const [tokenRate, setTokenRate] = useState({ rate: "0", balance: "0" });
   const [nilRate, setNilRate] = useState({ rate: "0", balance: "0" });
   const [ethRate, setEthRate] = useState({ rate: "0", balance: "0" });
   const [nftRate, setNftRate] = useState(0);
   const { setting, loadSetting } = useSetting();
   const { loadUserNft } = useUsers();
   const { loadWallet } = useWallet();

   useEffect(() => {
      (async () => {
         const door = await loadWallet({ token_symbol: "DOOR" });
         setTokenRate({ ...tokenRate, balance: door?.totalBalance })
         const NIL = await loadWallet({ token_symbol: "NIL" });
         setNilRate({ ...nilRate, balance: parseInt(NIL?.totalBalance) })
         const ETH = await loadWallet({ token_symbol: "ETH" });
         setEthRate({ ...ethRate, balance: ETH?.totalBalance })
         await loadSetting();

         const userNftDb = await loadUserNft({ page: 1, limit: 10 });
         setNftRate(userNftDb?.count ? userNftDb?.count : 0);
      })()

      const script = document.createElement("script");
      script.src = "https://widget.nomics.com/embed.js";
      script.async = true;
      document.body.appendChild(script);
   }, [])

   useEffect(() => {
      if (setting && setting.length > 0) {
         setting.forEach((field) => {
            if (field.label === 'token_rate') {
               setTokenRate({ ...tokenRate, rate: field.value })
            } else if (field.label === 'nil_rate') {
               setNilRate({ ...nilRate, rate: field.value })
            } else if (field.label === "eth_rate") {
               setEthRate({ ...ethRate, rate: field.value });
            }
         })
      }
   }, [setting])

   return (
      <Fragment>
         <Row>
            <Col xl={12} lg={12} sm={12}>
               <a href="https://doorcoin.com" target="_blank"><img src={Top_Banner} alt="" style={{width: "100%", marginBottom: '20px'}}/></a>
            </Col>
            <Col xl={3} lg={6} sm={6}>
               <Card className="widget-stat bg-warning">
                  <Card.Body className="p-4">
                     <div className="media">
                        <span className="mr-3">
                           <img src={Door_Token_logo} alt="" />
                        </span>
                        <div className="media-body text-white text-right">
                           <p className="mb-1">Door Token</p>
                           <h3 className="text-white">{formatNumber(tokenRate?.balance)}</h3>
                           <p className="mb-1">${tokenRate?.rate}</p>
                        </div>
                     </div>
                  </Card.Body>
               </Card>
            </Col>
            <Col xl={3} lg={6} sm={6}>
               <Card className="widget-stat" style={{ backgroundColor: "#DF69CC" }}>
                  <Card.Body className="p-4">
                     <div className="media">
                        <span className="mr-3" style={{ backgroundColor: "rgba(255, 255, 255, 0.25)" }}>
                           <img src={Nil_Token_logo} style={{ width: "81%" }} alt="" />
                        </span>
                        <div className="media-body text-white text-right">
                           <p className="mb-1">Nil Token</p>
                           <h3 className="text-white">{formatNumber(nilRate?.balance)}</h3>
                           <p className="mb-1">${nilRate?.rate}</p>
                        </div>
                     </div>
                  </Card.Body>
               </Card>
            </Col>
            <Col xl={3} lg={6} sm={6}>
               <Card className="widget-stat bg-info">
                  <Card.Body className="p-4">
                     <div className="media">
                        <span className="mr-3">
                           <img src={ETH_Token_logo} style={{ width: "81%" }} alt="" />
                        </span>
                        <div className="media-body text-white text-right">
                           <p className="mb-1">Ethereum Token</p>
                           <h3 className="text-white">{formatNumberETH(ethRate?.balance)}</h3>
                           <p className="mb-1">${formatNumberETH(ethRate?.rate)}</p>
                        </div>
                     </div>
                  </Card.Body>
               </Card>
            </Col>
            <Col xl={3} lg={6} sm={6}>
               <Card className="widget-stat" style={{ backgroundColor: "#6E6E6E" }}>
                  <Card.Body className="p-4">
                     <div className="media">
                        <span className="mr-3" style={{ backgroundColor: "rgba(255, 255, 255, 0.25)" }}>
                           <img src={NFT_Token_logo} style={{ width: "81%" }} alt="" />
                        </span>
                        <div className="media-body text-white text-right">
                           <p className="mb-1">NFT token</p>
                           <h3 className="text-white">{nftRate}</h3>
                        </div>
                     </div>
                  </Card.Body>
               </Card>
            </Col>

            <Col xl={12} xxl={12} lg={12}>
               <Card>
                  <Card.Body className="p-0">
                     <div className="media p-4 top-up-bx col-md-6 align-items-center">
                        <div className="media-body">
                           <h3 className="fs-20 text-white">Buy Door Coin</h3>
                           <p className="text-white font-w200 mb-0 fs-14">
                              Purchase Door Coin using a Credit Card
                           </p>
                        </div>
                        <Link to="/dashboard/deposit" className="icon-btn ml-3">
                           <svg
                              width={15}
                              height={27}
                              viewBox="0 0 15 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M5.9375 6.23198L5.9375 24.875C5.9375 25.6689 6.58107 26.3125 7.375 26.3125C8.16892 26.3125 8.8125 25.6689 8.8125 24.875L8.8125 6.23201L11.2311 8.66231L11.2311 8.66232C11.7911 9.22502 12.7013 9.22719 13.264 8.66716C13.8269 8.107 13.8288 7.1968 13.2689 6.6342L12.9145 6.98689L13.2689 6.63419L8.3939 1.73557L8.38872 1.73036L8.38704 1.72877C7.82626 1.17279 6.92186 1.17467 6.36301 1.72875L6.36136 1.73031L6.35609 1.73561L1.48109 6.63424L1.48108 6.63425C0.921124 7.19694 0.9232 8.10708 1.48597 8.66719C2.04868 9.22724 2.95884 9.22508 3.51889 8.66237L3.51891 8.66235L5.9375 6.23198Z"
                                 fill="#6418C3"
                                 stroke="#6418C3"
                              />
                           </svg>
                        </Link>
                     </div>
                     <div className="media p-4 withdraw-bx col-md-6 align-items-center">
                        <div className="media-body">
                           <h3 className="fs-20 text-white">Buy NIL Coin</h3>
                           <p className="text-white font-w200 mb-0 fs-14">
                              Purchase NIL Coin using a Credit Card
                           </p>
                        </div>
                        <Link to="/dashboard/NIL/deposit" className="icon-btn ml-3">
                           <svg
                              width={15}
                              height={27}
                              viewBox="0 0 15 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M5.9375 6.23198L5.9375 24.875C5.9375 25.6689 6.58107 26.3125 7.375 26.3125C8.16892 26.3125 8.8125 25.6689 8.8125 24.875L8.8125 6.23201L11.2311 8.66231L11.2311 8.66232C11.7911 9.22502 12.7013 9.22719 13.264 8.66716C13.8269 8.107 13.8288 7.1968 13.2689 6.6342L12.9145 6.98689L13.2689 6.63419L8.3939 1.73557L8.38872 1.73036L8.38704 1.72877C7.82626 1.17279 6.92186 1.17467 6.36301 1.72875L6.36136 1.73031L6.35609 1.73561L1.48109 6.63424L1.48108 6.63425C0.921124 7.19694 0.9232 8.10708 1.48597 8.66719C2.04868 9.22724 2.95884 9.22508 3.51889 8.66237L3.51891 8.66235L5.9375 6.23198Z"
                                 fill="#6418C3"
                                 stroke="#6418C3"
                              />
                           </svg>
                        </Link>
                     </div>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
         <StateInitialized />
      </Fragment>
   )
}

export default Home;
