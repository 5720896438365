import { useDispatch, useSelector } from "react-redux";
import {SettingService} from "../../services";
import {useNotification} from "./notification.hook";
import {useProgress} from "./progress.hook";
import {SETTING_SUCCESS, SETTING_FAILED, UPDATE_SETTING, NOTI_TYPE} from "../types";


export const useSetting = () => {
  const dispatch = useDispatch();
  const { setting } = useSelector(({ setting }) => setting);
  const { setNewNotification } = useNotification();
  const { startProgress, stopProgress } = useProgress();

  const loadSetting = async () => {
    try {
      startProgress();
      const response = await SettingService.getSetting();
      dispatch({ type: SETTING_SUCCESS, payload: response.data });
      stopProgress();
    } catch ({ response, message }) {
      dispatch({
        type: SETTING_FAILED,
        payload: response?.data?.message || message
      });
      stopProgress();
    }
  }

  const updateSetting = async (data) => {
    try {
      startProgress();
      await SettingService.updateSetting(data);
      // dispatch({ type: UPDATE_SETTING, payload: data });
      setNewNotification(NOTI_TYPE.SUCCESS, "Success! Setting updated.");
      stopProgress();
    } catch ({ response, message }) {
      dispatch({
        type: SETTING_FAILED,
        payload: response?.data?.message || message
      });
      setNewNotification(NOTI_TYPE.WARNING, "Something went wrong, Please refresh the browser and try again!");
      stopProgress();
      return false;
    }
  }

  return {
    setting,
    loadSetting,
    updateSetting,
  };
};
